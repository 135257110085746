import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import TeamTable from './TeamTable';
import { MY_TEAM } from '~/constants/endpoints/my_team';
import history, { getQueryParam } from '~/services/history';
import { v4 as uuidv4 } from 'uuid';

export const makeColumns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: 'Equipe',
    dataIndex: 'team',
    key: 'team',
    ellipsis: true,
  },
  {
    title: 'Cargo',
    dataIndex: 'role',
    key: 'role',
    ellipsis: true,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    render: item => (
      <Button
        type="link"
        onClick={() =>
          history.push(`/minha-producao?cooperative_member_id=${item}`)
        }
      >
        Gerenciar Produção
      </Button>
    ),
  },
];

export async function fetchTableData(setTableData, setLoading, queries) {
  setLoading(true);

  try {
    const { data } = await api.get(MY_TEAM, {
      params: queries,
    });
    setLoading(false);
    setTableData(data);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar dados da tabela')
    );
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const name = getQueryParam('person_name');
  const team = getQueryParam('team_name');
  const position = getQueryParam('position');

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        person_name: name,
        team_id: team,
        position,
      });
    }

    loadTableData();
  }, [page, page_size, name, team, position]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        person_name: name,
        team_id: team,
        position,
      }),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      key: uuidv4(),
      name: safeRead(item, ['person_display', 'name_display'], '--'),
      team: safeRead(item, ['teams'], '--')
        .map(teams => teams.team_name)
        .join('/'),
      role: safeRead(item, ['position_display', 'position_display'], '--'),
      action: safeRead(item, ['person_display', 'id'], '--'),
    }));
  }

  return [];
}

export default TeamTable;
