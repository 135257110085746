import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Row, Col, Divider, Modal, Radio } from 'antd';

import checkPermission from '~/services/utils/checkPermission';
import badgesStatus from '~/constants/badgesStatus';
import renderStatusBadge from '~/services/utils/renderStatusBadge';
import { AbsenceOvertimeContext } from '../../index';
import { ModalItemContainer, ModalItem } from '../../styles';

export default function DetailModal({ visible, setVisible }) {
  const canApprove = checkPermission('change_request');
  const [absenceType, setAbsenceType] = useState();

  const {
    detailData,
    setConfirmationModalVisible,
    setItemsToUpdateStatus,
    setConfirmationType,
    selectedRowRequestTypes,
    setSelectedRowRequestTypes,
  } = useContext(AbsenceOvertimeContext);
  const { Text, Title } = Typography;

  const handleAbsenceType = (event) => {
    setAbsenceType(event.target.value);
    setSelectedRowRequestTypes([{
      id: detailData.id,
      requestType: event.target.value
    }]);
  }

  useEffect(() => {
    setSelectedRowRequestTypes([{
      id: detailData.id,
      requestType: detailData.request_type,
    }]);
  }, []);

  return (
    <Modal
      title={<Title level={4}>Detalhes da solicitação</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <ModalItemContainer>
        <ModalItem width="34%">
          <Text strong style={{ display: 'block' }}>
            ID
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.key}
          </Text>
        </ModalItem>

        <ModalItem width="66%">
          <Text strong style={{ display: 'block' }}>
            Nome
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.name}
          </Text>
        </ModalItem>

        <ModalItem>
          <Text strong style={{ display: 'block' }}>
            Data do evento
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.date}
          </Text>
        </ModalItem>

        {/* <ModalItem>
          <Text strong style={{ display: 'block' }}>
            Equipe
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.project}
          </Text>
        </ModalItem> */}

        <ModalItem>
          <Text strong style={{ display: 'block' }}>
            Solicitação
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.request_type_display}
          </Text>
        </ModalItem>

        <ModalItem>
          <Text strong style={{ display: 'block' }}>
            Duração
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.duration}
          </Text>
        </ModalItem>

        <ModalItem>
          <Text strong style={{ display: 'block' }}>
            Status
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {renderStatusBadge({
              status: detailData.status.status,
              status_display: detailData.status_display,
            })}
          </Text>
        </ModalItem>

        <ModalItem width="67%">
          <Text strong style={{ display: 'block' }}>
            Data de solicitação
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.created_at}
          </Text>
        </ModalItem>

        <ModalItem width="100%">
          <Text strong style={{ display: 'block' }}>
            Motivo
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {detailData.reason}
          </Text>
        </ModalItem>


        {(detailData.request_type == 'ausencia' ||
        detailData.request_type == 'ausencia_a_abonar' ||
        detailData.request_type == 'ausencia_a_descontar') &&
          <ModalItem width="100%">
            <Text strong style={{ display: 'block' }}>
              Tipo de abono
            </Text>
            <Radio.Group name="absence_type" value={absenceType} onChange={(value) => handleAbsenceType(value)}>
              <Radio value={"ausencia"}>Ausência</Radio>
              <Radio value={"ausencia_a_abonar"}>Ausência a abonar</Radio>
              <Radio value={"ausencia_a_descontar"}>Ausência a descontar</Radio>
            </Radio.Group>
          </ModalItem>
        }

        {detailData.status.status === 'aprovado' ||
        detailData.status.status === 'reprovado' ? (
          <ModalItem width="100%">
            <Text strong style={{ display: 'block' }}>
              Responsável pela
              {detailData.status.status === 'aprovado'
                ? ' aprovação'
                : ' reprovação'}
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {detailData.examiner_display.name_display}
            </Text>
          </ModalItem>
        ) : null}

        {detailData.status.status === 'reprovado' ? (
          <ModalItem width="100%">
            <Text strong style={{ display: 'block' }}>
              Motivo da reprovação
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {detailData.disapproval_reason}
            </Text>
          </ModalItem>
        ) : null}
      </ModalItemContainer>

      <Row type="flex" justify="end">
        <Col>
          {(detailData.status.status === 'em_analise' ||
            detailData.status.status === 'aprovado' ||
            detailData.status.status === 'aprovado_pelo_financeiro') &&
          canApprove ? (
            <Button
              onClick={() => {
                setItemsToUpdateStatus([detailData.key]);
                setConfirmationType(badgesStatus.DISAPPROVED);
                setConfirmationModalVisible(true);
              }}
              type="danger"
            >
              Reprovar
            </Button>
          ) : null}

          {detailData.status.status === 'em_analise' && canApprove ? (
            <Divider type="vertical" />
          ) : null}

          {(detailData.status.status === 'em_analise' ||
            detailData.status.status === 'reprovado') &&
          canApprove ? (
            <Button
              disabled={(detailData.request_type == 'ausencia' ||
              detailData.request_type == 'ausencia_a_abonar') && !absenceType}
              onClick={() => {
                setItemsToUpdateStatus([detailData.key]);
                setConfirmationType(badgesStatus.APPROVED);
                setConfirmationModalVisible(true);
              }}
              type="primary"
            >
              Aprovar
            </Button>
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
}

DetailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
