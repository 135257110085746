import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import checkPermission from '~/services/utils/checkPermission';
import { makeColumns, formatTableData, ProfileContext } from '../index';
import Table from '~/components/DataDisplay/Table';
import { getAllQueriesParams } from '~/services/history';

export default function ProfileTable({
  tableData,
  loadingTableData,
  setRegisterOrEditModalVisible,
  setAddUserModalVisible,
}) {
  const { fetchTableData, setCurrentRow, setGroupEditMode } = useContext(
    ProfileContext
  );
  const { page, page_size } = getAllQueriesParams();

  return (
    <Table
      title={() =>
        checkPermission('add_group') ? (
          <Button
            onClick={() => setRegisterOrEditModalVisible(true)}
            type="primary"
          >
            Cadastrar Grupo
          </Button>
        ) : null
      }
      columns={makeColumns(
        fetchTableData,
        setAddUserModalVisible,
        setCurrentRow,
        setGroupEditMode,
        setRegisterOrEditModalVisible
      )}
      dataSource={formatTableData(tableData.results)}
      paginated
      currentPage={parseInt(page, 10) || 1}
      pageSize={parseInt(page_size, 10) || 10}
      count={tableData.count}
      scroll={{ x: true }}
      loading={loadingTableData}
    />
  );
}

ProfileTable.propTypes = {
  tableData: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingTableData: PropTypes.bool.isRequired,
  setRegisterOrEditModalVisible: PropTypes.func.isRequired,
  setAddUserModalVisible: PropTypes.func.isRequired,
};
