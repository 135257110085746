import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonGroup = styled(Button.Group)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-bottom: 20px;

  button {
    width: 100%;
  }
`;
