import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.loading_screen';

export default defineMessages({
  loading: {
    id: `${scope}.loading`,
    defaultMessage: 'Carregando',
  },
});
