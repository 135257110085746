import { useEffect, useState } from 'react';
import safeRead from 'safely-read';
import { message } from 'antd';
import { GET_PERSON_BY_ID, PATCH_PERSON } from '~/constants/endpoints/person';
import { TEAM } from '~/constants/endpoints/team';
import { AssignDefaultTeam } from './AssignDefaultTeam';

import api from '~/services/api';

export async function fetchData(setUserData, userID, setLoadingUserData) {
  setLoadingUserData(true);
  try {
    const { data } = await api.get(GET_PERSON_BY_ID(userID));

    setUserData(data);
    setLoadingUserData(false);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar informações do usuário')
    );
    setUserData({});
    setLoadingUserData(false);
  }
}

export async function PatchPerson(id, values) {
  const default_team = safeRead(values, ['default_team']);

  try {
    await api.patch(PATCH_PERSON(id), {
      default_team,
    });
    message.success('Cadastro alterado com sucesso.');
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Não foi possivel realizar a operação.')
    );
  }
}

export function useUserData(id) {
  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState(false);

  useEffect(() => {
    async function loadUserData() {
      await fetchData(setUserData, id, setLoadingUserData);
    }

    loadUserData();
  }, [id]);

  return {
    userData,
    setUserData,
    loadingUserData,
    setLoadingUserData,
    fetchData: () => fetchData(setUserData, id, setLoadingUserData),
  };
}

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar equipes')
    );
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export default AssignDefaultTeam;
