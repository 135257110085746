import styled from 'styled-components';

export const EmptyMsg = styled.span`
  color: ${({ theme }) => theme.disabledColor};
`;

export const MapContainer = styled.div`
  height: 400px;
  width: 100%;

  .leaflet-container {
    height: 100%;
    width: 100%;
  }
`;
