import React, { useState, useEffect, useCallback } from 'react';
import { Select, message } from 'antd';
import { withFormik } from 'formik';

import ManageVacationsAllowences from './ManageVacationsAllowences';
import api from '~/services/api';
import { GET_PERSON } from '~/constants/endpoints/person';
import {
  PAID_LEAVE_REFERENCE_PERIODS_BY_ID,
  PAID_LEAVE_MANAGE,
} from '~/constants/endpoints/paid_leave';
import formatDate from '~/services/utils/formatDate';

export const VacationsAllowenceContext = React.createContext({});

export const typeError = {
  error_code: '',
};

export function setError(type) {
  typeError.error_code = type;
}

export async function fetchUsers(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(GET_PERSON, {
      params: {
        name,
        is_cooperative_member: true,
        is_active: true,
      },
    });
    response = data;
  } catch (error) {
    console.log(error);
  }

  return response;
}

export function useSearchUsers() {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [users, setUsers] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingUsers(true);

      const data = await fetchUsers(search);

      setUsers(data);

      setLoadingUsers(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingUsers,
    users,
  };
}

export function renderUsersItems(user) {
  const { Option } = Select;

  return (
    <Option key={user.id} value={user.id}>
      {user.label}
    </Option>
  );
}

export async function fetchReferencePeriod(userId, setPeriod) {
  try {
    const { data } = await api.get(PAID_LEAVE_REFERENCE_PERIODS_BY_ID(userId));

    setPeriod(data);
  } catch (error) {
    message.error('Não foi possível buscar período de referência');
    setPeriod([]);
  }
}

export function renderPeriodItems(period) {
  const { Option } = Select;

  return (
    <Option
      key={`${period[0]}-${period[1]}`}
      value={`${period[0]}/${period[1]}`}
    >
      {formatDate(period[0])} - {formatDate(period[1])}
    </Option>
  );
}

export async function fetchUserManagedData(
  referencePeriodStart,
  userId,
  setData
) {
  try {
    const { data } = await api.get(PAID_LEAVE_MANAGE(userId), {
      params: {
        start_period: referencePeriodStart,
      },
    });

    setData(data || []);
  } catch (error) {
    message.error('Não foi possível os dados do usuário selecionado.');
    setData([]);
  }
}

export function useGetManagementPaidLeave() {
  const [referencePeriodQuery, setReferencePeriodQuery] = useState('');
  const [cooperativeId, setCooperativeId] = useState('');

  const [managementDetails, setManagementDetails] = useState(null);
  const [managementDetailsLoading, setManagementDetailsLoading] = useState(
    false
  );
  const [referencePeriod, setReferencePeriod] = useState([]);
  const [managementPeriodsLoading, setManagementPeriodsLoading] = useState(
    false
  );

  const handleSetReferencePeriodQuery = useCallback(value => {
    setReferencePeriodQuery(value);
  }, []);

  const handleSetCooperativeId = useCallback(value => {
    setCooperativeId(value);
  }, []);

  useEffect(() => {
    const loadPaidLeave = async () => {
      setManagementDetailsLoading(true);
      if (cooperativeId && referencePeriodQuery) {
        await fetchUserManagedData(
          referencePeriodQuery,
          cooperativeId,
          setManagementDetails
        );
      }
      setManagementDetailsLoading(false);
    };

    loadPaidLeave();
  }, [cooperativeId, referencePeriodQuery]);

  useEffect(() => {
    const fetchPeriods = async () => {
      setManagementPeriodsLoading(true);
      if (cooperativeId) {
        await fetchReferencePeriod(cooperativeId, setReferencePeriod);
      }
      setManagementPeriodsLoading(false);
    };

    fetchPeriods();
  }, [cooperativeId]);

  return {
    managementDetails,
    managementDetailsLoading,
    referencePeriod,
    managementPeriodsLoading,
    referencePeriodQuery,
    cooperativeId,
    setManagementDetails,
    handleSetReferencePeriodQuery,
    handleSetCooperativeId,
    setManagementDetailsLoading,
  };
}

export function blockDayOffAnnualLeaveRequest(selectedPeriod, listOfPeriods) {
  // const rawSelectedPeriod = JSON.stringify([
  //   `${(selectedPeriod || '').split('|')[0]}`,
  //   `${(selectedPeriod || '').split('|')[1]}`,
  // ]);

  // if (
  //   JSON.stringify(listOfPeriods[listOfPeriods.length - 1]) ===
  //   rawSelectedPeriod
  // ) {
  //   return true;
  // }

  // if (
  //   JSON.stringify(listOfPeriods[listOfPeriods.length - 2]) ===
  //   rawSelectedPeriod
  // ) {
  //   return true;
  // }

  if (!selectedPeriod) {
    return true;
  }

  return false;
}

export default withFormik({
  mapPropsToValues: () => ({ usersField: null, periodField: null }),
})(ManageVacationsAllowences);
