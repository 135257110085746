import React, { useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import Budget from './Budget';
import { BUDGET, PUT_BUDGET } from '~/constants/endpoints/budget';
import { BUDGET_HISTORY } from '~/constants/endpoints/budget-history';

export const BudgetContext = React.createContext({});

export async function budget(verba) {
  const defaultResponse = {
    results: {},
  };
  try {
    const { data } = await api.get(BUDGET(verba));
    return data;
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'amount', [0]],
        `Ocorreu um erro ao tentar carregar valor ${verba}`
      )
    );
    return defaultResponse;
  }
}

export async function BudgetHistory(verba) {
  try {
    const { data } = await api.get(BUDGET_HISTORY, {
      params: { budget_kind: verba },
    });
    return data;
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'amount', [0]],
        `Ocorreu um erro ao tentar carregar historio de ${verba}`
      )
    );
  }
  return [];
}

export async function budgetAmount(amount, verba) {
  try {
    await api.put(PUT_BUDGET(verba), {
      amount,
    });
    if (verba === 'verba_lider') {
      message.success('Verba líder atualizada com sucesso');
    } else {
      message.success('Verba preposto atualizada com sucesso');
    }
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'amount', [0]],
        `Ocorreu um erro ao alterar valor de ${verba}`
      )
    );
  }
}

export const handleModalVisible = (setVisible, setModalType, modalType) => {
  setModalType(modalType);
  setVisible(prevState => !prevState);
};

export function useBudget() {
  const [budgetleader, setBudgetleader] = React.useState(undefined);
  const [budgetPrepository, setBudgetPrepository] = React.useState(undefined);

  useEffect(() => {
    const LoadBudgetKind = async () => {
      const verbaLiderData = await budget('verba_lider');
      const verbaPrepostoData = await budget('verba_preposto');
      setBudgetleader(verbaLiderData);
      setBudgetPrepository(verbaPrepostoData);
    };
    LoadBudgetKind();
  }, []);

  return {
    budgetleader,
    setBudgetleader,
    budgetPrepository,
    setBudgetPrepository,
  };
}

export async function fetchListData(
  setLoadingHistory,
  setBudgetleaderHistory,
  setBudgetPrepositoryHistory
) {
  setLoadingHistory(true);
  const leaderHistoryData = await BudgetHistory('verba_lider');
  const prepositoryHistoryData = await BudgetHistory('verba_preposto');
  setBudgetleaderHistory({ results: leaderHistoryData.results });
  setBudgetPrepositoryHistory({
    results: prepositoryHistoryData.results,
  });
  setLoadingHistory(false);
}

export function useBudgetHistory() {
  const [budgetleaderHistory, setBudgetleaderHistory] = React.useState({
    results: [],
  });
  const [loadingHistory, setLoadingHistory] = React.useState(false);
  const [
    budgetPrepositoryHistory,
    setBudgetPrepositoryHistory,
  ] = React.useState({
    results: [],
  });

  useEffect(() => {
    const LoadBudgetHistory = async () => {
      fetchListData(
        setLoadingHistory,
        setBudgetleaderHistory,
        setBudgetPrepositoryHistory
      );
    };
    LoadBudgetHistory();
  }, []);

  return {
    budgetleaderHistory,
    setBudgetleaderHistory,
    loadingHistory,
    setLoadingHistory,
    budgetPrepositoryHistory,
    setBudgetPrepositoryHistory,
  };
}

export default Budget;
