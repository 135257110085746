/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Divider, Modal, message, Typography } from 'antd';
import safeRead from 'safely-read';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import checkPermission from '~/services/utils/checkPermission';
import VacationsAllowenceApproval from './VacationsAllowenceApproval';
import renderBadge from '~/services/utils/renderStatusBadge';
import formatDate from '~/services/utils/formatDate';
import api from '~/services/api';
import {
  GET_PAID_LEAVE_APPROVAL,
  PAID_LEAVE_APPROVAL_ID,
} from '~/constants/endpoints/paid_leave_approval';
import { /* history, */ getQueryParam } from '~/services/history';
import paidLeaveStatus from '~/constants/paid_leave_status';
import badgesStatus from '~/constants/badgesStatus';
import Input from '~/components/DataEntry/Input';

export const tableColumns = (setSelecteDetail, setDetailVisible) => [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: '27%',
  },
  {
    title: 'Tipo de solicitação',
    dataIndex: 'requestType',
    key: 'requestType',
  },
  {
    title: 'Data Ínicio',
    dataIndex: 'begin_date',
    key: 'begin_date',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    title: 'Data Fim',
    dataIndex: 'last_date',
    key: 'last_date',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: row => renderBadge(row),
  },
  {
    title: 'Ações',
    dataIndex: 'actions',
    key: 'actions',
    render: (col, line) => (
      <Button
        type="link"
        style={{ padding: 0 }}
        onClick={() => {
          setSelecteDetail(line.originalRequestPayload);
          setDetailVisible(true);
        }}
      >
        Detalhes
      </Button>
    ),
  },
];

export async function fetchTableData(setTableData, setLoading, queries) {
  setLoading(true);
  try {
    const { data } = await api.get(GET_PAID_LEAVE_APPROVAL, {
      params: queries,
    });

    setLoading(false);
    setTableData(data);
  } catch (error) {
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const name = getQueryParam('name');
  const type = getQueryParam('type');
  const status = getQueryParam('status');
  const team = getQueryParam('team');
  const ordering = getQueryParam('ordering');
  const date_start = getQueryParam('date_start');
  const date_end = getQueryParam('date_end');

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        name,
        paid_leave_type: type,
        status,
        date_start,
        date_end,
        team,
        ordering,
      });
    }

    loadTableData();
  }, [
    page,
    page_size,
    name,
    type,
    status,
    team,
    date_start,
    date_end,
    ordering,
  ]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    date_start,
    date_end,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        name,
        paid_leave_type: type,
        date_start,
        date_end,
        status,
        team,
        ordering,
      }),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      originalRequestPayload: item,
      key: safeRead(item, ['id']),
      name: safeRead(item, ['person_display', 'name_display'], '-'),
      requestType: safeRead(item, ['paid_leave_type_display'], '-'),
      begin_date: safeRead(item, ['begin_date'], '-', formatDate),
      last_date: safeRead(item, ['last_date'], '-', formatDate),
      status: {
        status: safeRead(item, ['status']),
        status_display: safeRead(item, ['status_display']),
      },
      actions: safeRead(item, ['id']),
    }));
  }

  return [];
}

export function handleDetailModalData(detailObject) {
  const type = safeRead(detailObject, ['paid_leave_type']);

  const renderItems = middleRow => [
    {
      key: 'id',
      title: 'Número (ID)',
      value: safeRead(detailObject, ['paid_leave_id'], '-'),
    },
    {
      key: 'name',
      title: 'Nome',
      value: safeRead(detailObject, ['name_display'], '-'),
    },
    {
      key: 'periodoAquisitivo',
      title: 'Período aquisitivo',
      value: `${safeRead(
        detailObject,
        ['reference_period_display', 'reference_period_start'],
        '-',
        formatDate
      )} a ${safeRead(
        detailObject,
        ['reference_period_display', 'reference_period_end'],
        '-',
        formatDate
      )}`,
    },
    {
      key: 'dataSolicitacao',
      title: 'Data de solicitação',
      value: safeRead(detailObject, ['created_at'], '-', formatDate),
    },
    {
      key: 'tipo',
      title: 'Tipo',
      value: safeRead(detailObject, ['paid_leave_type_display'], '-'),
    },
    ...middleRow, // Informação customizada especifica para cada tipo de modal
    {
      key: 'duracao',
      title: 'Duração',
      value: safeRead(
        detailObject,
        ['total_days'],
        '-',
        day => `${day} dias`
      ),
    },
    {
      key: 'saldoAprovacao',
      title: 'Saldo após aprovação',
      value: `${detailObject.balance_remaining} dias`,
      width: '67%',
    },
    {
      key: 'status',
      title: 'Status',
      value: renderBadge(detailObject),
      width: '100%',
    },
    {
      key: 'motivoRecusa',
      title: 'Motivo da reprovação',
      value: safeRead(detailObject, ['disapproval_reason'], '-'),
      width: '100%',
    },
  ];

  if (type === paidLeaveStatus.VACATION) {
    const vacationInfo = [
      {
        key: 'periodoFerias',
        title: 'Período de férias',
        value: `${safeRead(
          detailObject,
          ['begin_date'],
          '-',
          formatDate
        )} a ${safeRead(detailObject, ['last_date'], '-', formatDate)}`,
      },
      {
        key: 'adiantamento',
        title: 'Deseja 70% de adiantamento?',
        value: safeRead(detailObject, ['receive_advance_money'], '-', v =>
          v ? 'Sim' : 'Não'
        ),
        width: '100%',
      },
    ];

    return renderItems(vacationInfo);
  }

  if (type === paidLeaveStatus.ALLOWENCE) {
    const vacationInfo = [
      {
        key: 'dataAbono',
        title: 'Data do abono',
        value: `${safeRead(
          detailObject,
          ['begin_date'],
          '-',
          formatDate
        )} a ${safeRead(detailObject, ['last_date'], '-', formatDate)}`,
      },
    ];

    return renderItems(vacationInfo);
  }

  if (type === paidLeaveStatus.QUITTING) {
    return [
      {
        key: 'id',
        title: 'Número (ID)',
        value: safeRead(detailObject, ['paid_leave_id'], '-'),
      },
      {
        key: 'name',
        title: 'Nome',
        value: safeRead(detailObject, ['name_display'], '-'),
      },
      {
        key: 'periodoAquisitivo',
        title: 'Período aquisitivo',
        value: `${safeRead(
          detailObject,
          ['reference_period_display', 'reference_period_start'],
          '-',
          formatDate
        )} a ${safeRead(
          detailObject,
          ['reference_period_display', 'reference_period_end'],
          '-',
          formatDate
        )}`,
      },
      {
        key: 'dataSolicitacao',
        title: 'Data de solicitação',
        value: safeRead(detailObject, ['created_at'], '-', formatDate),
        width: '50%',
      },
      {
        key: 'tipo',
        title: 'Tipo',
        value: safeRead(detailObject, ['paid_leave_type_display'], '-'),
        width: '50%',
      },
    ];
  }

  return [];
}

export async function changeItemStatus(
  requestId,
  type,
  disapprovalReason,
  setDetailModalVisible,
  fetchData
) {
  const typeVerb = () => {
    if (type === badgesStatus.APPROVED) {
      return 'aprovada';
    }
    if (type === badgesStatus.DISAPPROVED) {
      return 'reprovada';
    }

    return 'realizada';
  };

  const payload = {
    status: type,
  };

  if (type === badgesStatus.DISAPPROVED) {
    payload.disapproval_reason = disapprovalReason;
  }

  try {
    await api.patch(PAID_LEAVE_APPROVAL_ID(requestId), payload);

    message.success(`A solicitação foi ${typeVerb()} com sucesso.`);
    setDetailModalVisible();
    fetchData();
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao efetuar a ação.')
    );
  }
}

export async function approveDisapproveItem(
  itemId,
  requestType,
  reason,
  setDetailModalVisible,
  fetchData
) {
  const { confirm } = Modal;

  confirm({
    centered: true,
    title: 'Deseja realmente aprovar esta solicitação?',
    onOk: () =>
      changeItemStatus(
        itemId,
        requestType,
        reason,
        setDetailModalVisible,
        fetchData
      ),
  });
}

export function DisapprovalReasonModal({
  visible,
  setVisible,
  data,
  setDetailModalVisible,
  fetchData,
}) {
  const { Title } = Typography;
  return (
    <Modal
      title={<Title level={4}>Reprovar solicitação</Title>}
      visible={visible}
      onCancel={() => setVisible(false)}
      centered
      footer={false}
      destroyOnClose
    >
      <Formik
        initialValues={{}}
        validationSchema={yup.object().shape({
          reasonInput: yup.string().required('Campo obrigatório'),
        })}
        onSubmit={values => {
          changeItemStatus(
            safeRead(data, ['paid_leave_id']),
            badgesStatus.DISAPPROVED,
            safeRead(values, ['reasonInput']),
            setDetailModalVisible,
            fetchData
          );

          setVisible(false);
        }}
      >
        {({ values, handleSubmit }) => (
          <>
            <Field
              id="reasonInput"
              name="reasonInput"
              label="Motivo da reprovação"
              required
              component={Input}
              type="textarea"
            />
            <Divider />
            <Row type="flex" justify="end">
              <Col>
                <Button onClick={() => setVisible(false)}>Cancelar</Button>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  disabled={!safeRead(values, ['reasonInput'])}
                  onClick={handleSubmit}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export function DetailModalAction({ data, setDetailModalVisible, fetchData }) {
  const [disapprovalVisible, setDisapprovalReason] = useState();

  return (
    <>
      {/* <Row type="flex" justify="center">
        <Button
          type="link"
          onClick={() =>
            history.push(
              `/ferias-abonos?reference_period=${safeRead(data, [
                'reference_period_display',
                [0],
                [0],
              ])}&cooperative_member_id=${safeRead(data, [
                'person_display',
                'id',
              ])}`
            )
          }
        >
          Ver o saldo de férias e abonos deste usuário
        </Button>
      </Row> */}
      <br />
      <Row type="flex" justify="end">
        <Col>
          {(safeRead(data, ['status']) === badgesStatus.IN_ANALYSIS ||
            safeRead(data, ['status']) === badgesStatus.APPROVED) &&
          checkPermission('change_paidleave') ? (
            <Button type="danger" onClick={() => setDisapprovalReason(true)}>
              Reprovar
            </Button>
          ) : null}
          {safeRead(data, ['status']) === badgesStatus.IN_ANALYSIS &&
          checkPermission('change_paidleave') ? (
            <Divider type="vertical" />
          ) : null}
          {(safeRead(data, ['status']) === badgesStatus.DISAPPROVED ||
            safeRead(data, ['status']) === badgesStatus.IN_ANALYSIS) &&
          checkPermission('change_paidleave') ? (
            <Button
              type="primary"
              onClick={() =>
                approveDisapproveItem(
                  safeRead(data, ['paid_leave_id']),
                  badgesStatus.APPROVED,
                  null,
                  setDetailModalVisible,
                  fetchData
                )
              }
            >
              Aprovar
            </Button>
          ) : null}
        </Col>
      </Row>
      <DisapprovalReasonModal
        visible={disapprovalVisible}
        setVisible={setDisapprovalReason}
        data={data}
        fetchData={fetchData}
        setDetailModalVisible={setDetailModalVisible}
      />
    </>
  );
}

export default VacationsAllowenceApproval;
