import React from 'react';
import { Button } from 'antd';
import ProjectsTable from './ProjectsTable';
import renderStatusBadge from '~/services/utils/renderStatusBadge';
import history, { getQueryParam } from '~/services/history';

export function formatTableData(data = []) {
  return data.map(({ parent_team_display, id, ...item }) => ({
    key: id,
    parent_team_display: parent_team_display || '--',
    ...item,
  }));
}

export function makeColumns(setEditVisible, setCurrentProject) {
  return [
    {
      title: 'Equipe',
      dataIndex: 'team_name',
      key: 'team_name',
      width: 140,
    },
    {
      title: 'Equipe Pai',
      dataIndex: 'parent_team_display',
      key: 'parent_team_display',
      width: 140,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 140,
      render: (_, rawData) =>
        renderStatusBadge({
          status: rawData.status,
          status_display: rawData.status_display,
        }),
    },
    {
      title: '',
      dataIndex: 'team_details',
      key: 'team_details',
      width: 100,
      render: (_, data) => (
        <Button
          onClick={() => {
            setCurrentProject(data.key);
            setEditVisible(true);
          }}
          type="link"
        >
          Detalhes
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: 'team_production',
      key: 'team_production',
      width: 100,
      render: (_, data) => (
        <Button
        type="link"
        onClick={() =>
          history.push(`/producao-da-equipe?team_id=${data.key}`)
        }
      >
        Ver Produção
      </Button>
      ),
    },
  ];
}

export default ProjectsTable;
