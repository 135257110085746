import { message } from 'antd';
import safeRead from 'safely-read';
import { cleanMoneyMask } from 'masks-br';

import EditHomeSilver from './EditHomeSilver';
import { HOME_GROWN_PATCH } from '~/constants/endpoints/home_grown';
import api from '~/services/api';

export async function patchHomeGrown(id, values) {
  try {
    await api.patch(HOME_GROWN_PATCH(id), values);
    message.success('Bônus alterado com sucesso.');
  } catch (error) {
    message.error(safeRead(error, 'Não foi possível realizar a operação.'));
  }
}

export const editItemBonus = async (values, item) => {
  const payload_6hours = {
    id: item.bonus[0].working_hours === 6 ? item.bonus[0].id : item.bonus[1].id,
    bonus_hours: safeRead(values, ['bonus_6hours']),
    required_time: safeRead(values, ['required_time']),
    bonus_reward: cleanMoneyMask(safeRead(values, ['bonus_reward'])),
  };

  const payload_8hours = {
    id: item.bonus[1].working_hours === 6 ? item.bonus[0].id : item.bonus[1].id,
    bonus_hours: safeRead(values, ['bonus_8hours']),
    required_time: safeRead(values, ['required_time']),
    bonus_reward: cleanMoneyMask(safeRead(values, ['bonus_reward'])),
  };

  Promise.all([
    await patchHomeGrown(payload_6hours.id, {
      bonus_hours: safeRead(payload_6hours, ['bonus_hours']),
      required_time: safeRead(payload_6hours, ['required_time']),
      bonus_reward: safeRead(payload_6hours, ['bonus_reward']),
    }),
    await patchHomeGrown(payload_8hours.id, {
      bonus_hours: safeRead(payload_8hours, ['bonus_hours']),
      required_time: safeRead(payload_8hours, ['required_time']),
      bonus_reward: safeRead(payload_8hours, ['bonus_reward']),
    }),
  ]);
};

export default EditHomeSilver;
