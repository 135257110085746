import React from 'react';
import { Modal, Button, Row, Col, Typography, Divider } from 'antd';
import PropTypes from 'prop-types';

import { UserRegistryContext } from '../../../../index';
import { createRecoverPasswordRequest } from '../../index';

export default function PasswordChangeModal({ visible, setVisible }) {
  const { userData } = React.useContext(UserRegistryContext);

  const [loading, setLoading] = React.useState(false);

  const { Text, Title } = Typography;
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      closable
      destroyOnClose
      width={350}
      title={<Title level={4}>Redefinir Senha</Title>}
    >
      <Row>
        <Col>
          <Text>E-mail para redefinição de senha:</Text>
        </Col>
        <br />
        <Col>
          <Text strong>{userData.corporate_email}</Text>
        </Col>
        <br />
        <Col>
          <Text>
            O e-mail acima receberá link de acesso para redefinir a senha de
            acesso ao AD/LDAP.
          </Text>
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={24} type="flex" justify="end">
        <Col>
          <Button onClick={() => setVisible(false)}>Cancelar</Button>
          <Divider type="vertical" />
          <Button
            id="submit-btn"
            type="primary"
            loading={loading}
            onClick={async () => {
              await createRecoverPasswordRequest(
                userData.corporate_email,
                setLoading
              );
              setVisible(false);
            }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

PasswordChangeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
