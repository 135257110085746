import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Typography,
  Timeline,
  message as AntMessage,
  Tooltip,
  /*Spin,*/
} from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import shortid from 'shortid';
import { HelpCircle } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { useGeolocation } from 'react-use';

import { setProduction } from '~/store/modules/production/actions';
import {
  TopHolder,
  ActionButton,
  TimelineItem,
  TimelineBox,
  LoginIcon,
  LogoutIcon,
  // WarningBox,
  TimelineHolder,
} from './styles';
import api from '~/services/api';
import Skeleton from '~/components/DataDisplay/Skeleton';
import { WORKTIME_CLOCK } from '~/constants/endpoints/daily_worktime_clock';
import RectificationModal from './modals/Rectification';
import ConfirmModal from './modals/ConfirmModal/ConfirmModal';
import formatOrdinal from '~/services/utils/formatOrdinal';
import messages from './messages';
import Counter from './Counter';
import CountdownCounter from './CountdownCouter';

export const renderLabel = (tmItem, formatMessage) => {
  const hasRectification = safeRead(tmItem, ['rectification']);
  const rectificationReproved =
    safeRead(hasRectification, ['status']) === 'reprovado';

  const handleStatus = () => {
    const content = (
      <>
        {' '}
        {formatOrdinal(tmItem.position, 'female')}{' '}
        {tmItem.check_in
          ? formatMessage(messages.checkIn)
          : formatMessage(messages.checkOut)}
        {' - '}
        {formatMessage(messages.rectification)}{' '}
      </>
    );

    if (rectificationReproved) {
      return <strike>{content}</strike>;
    }

    return content;
  };

  if (hasRectification) {
    if (tmItem.field) {
      return (
        <>
          <Tooltip
            title={safeRead(hasRectification, ['status_display'])}
            placement="right"
          >
            {handleStatus()}
            <HelpCircle width={15} height={15} style={{ paddingTop: 5 }} />
            <br />
            {tmItem.field}
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip
          title={safeRead(hasRectification, ['status_display'])}
          placement="right"
        >
          {handleStatus()}
          <HelpCircle width={15} height={15} />
          <br />
          {rectificationReproved ? (
            <strike>
              {' '}
              <b>{moment(tmItem.worktime_clock).format('HH:mm')}</b>
            </strike>
          ) : (
            <b>{moment(tmItem.worktime_clock).format('HH:mm')}</b>
          )}
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {formatOrdinal(tmItem.position, 'female')}{' '}
      {tmItem.check_in
        ? formatMessage(messages.checkIn)
        : formatMessage(messages.checkOut)}{' '}
      <br />
      <b>{moment(tmItem.worktime_clock).format('HH:mm')}</b>
    </>
  );
};

function WorkTime({ intl }) {
  const dispatch = useDispatch();
  const { data: productionData } = useSelector(state => state.production);
  const {
    latitude,
    longitude,
    /* loading: loadingGeolocation,
    error: geolocationError,*/
  } = useGeolocation({ enableHighAccuracy: true });

  const [checkinLoading, setCheckinLoading] = useState(false);
  const [checkInType, setCheckInType] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [listLoading, setListLoading] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [initialTimer, setInitialTimer] = useState(null);
  const [rectificationModalVisible, setRectificationModalVisible] = useState(
    false
  );
  const [hadMinimumBreak, setHadMinimumBreak] = useState(false);

  //The checkBoxValue sets the state of a minimum break from the checkbox inside the confirm modal
  //When the quick rectification were trigger, this will carry the checkbox value
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  const [countdown, setCountdown] = useState('00:00');
  const [timelineToCountDown, setTimelineToCountDown] = useState([]);

  const { Text } = Typography;

  let entry = 0;
  let out = 0;
  let typeValue = 0;

  const getProductionHistory = useCallback(async () => {
    try {
      const { data } = await api.get(WORKTIME_CLOCK);
      const timeline = safeRead(data, ['timeline'], []);
      const formattedTimeline = timeline
        .reverse()
        .reduce((result, value, index, array) => {
          if (index % 2 === 0) result.push(array.slice(index, index + 2));
          return result;
        }, [])
        .reverse()
        .map(item => item.reverse());

      setTimelineToCountDown(formattedTimeline);

      dispatch(setProduction(formattedTimeline));

      setHadMinimumBreak(safeRead(data, ['had_minimum_break']));
      setInitialTimer(safeRead(data, ['worked'], null));
      setHadMinimumBreak(data.had_minimum_break);
      setIsWorking(safeRead(data, ['working'], false));
    } catch (e) {
      dispatch(setProduction([]));
      setIsWorking(false);
    }

    setListLoading(false);
  }, [dispatch]);

  const handleRectification = async ({ time, reason }, { setSubmitting }) => {
    let requestBody;

    if (checkInType === false) {
      requestBody = {
        quick_rectification: time.format('YYYY-MM-DDTHH:mm'),
        check_in: checkInType,
        reason,
        latitude,
        longitude,
        minimum_break: checkBoxValue,
      };
    } else {
      requestBody = {
        quick_rectification: time.format('YYYY-MM-DDTHH:mm'),
        check_in: checkInType,
        reason,
        latitude,
        longitude,
      };
    }

    try {
      await api.post(WORKTIME_CLOCK, requestBody);

      getProductionHistory();
    } catch (e) {
      AntMessage.error(
        safeRead(
          e,
          ['response', 'data', 'detail'],
          'Ocorreu um erro ao registrar rasura'
        )
      );
    }

    setSubmitting(false);
    setRectificationModalVisible(false);
  };

  const showConfirm = async type => {
    setCheckInType(type);
    setConfirmModalVisible(confirmModalVisible => !confirmModalVisible);
  };

  useEffect(() => {
    getProductionHistory();
  }, [getProductionHistory]);

  const renderWorkTime = item => {
    if (item.check_in) {
      entry += 1;
      typeValue = entry;
    } else {
      out += 1;
      typeValue = out;
    }

    return (
      <Timeline key={shortid.generate()}>
        {item.map(timelineItem => {
          return (
            <TimelineItem
              check_in={timelineItem.check_in ? 1 : 0}
              has_rectification={timelineItem.rectification ? 1 : 0}
              key={timelineItem.id}
            >
              <TimelineBox>
                {renderLabel(timelineItem, intl.formatMessage)}
              </TimelineBox>
            </TimelineItem>
          );
        })}
      </Timeline>
    );
  };

  return (
    <TopHolder>
      <div style={{ padding: 12 }}>
        {/* {Boolean(geolocationError) && (
          <WarningBox>
            <FormattedMessage {...messages.geolocationWarn} />
          </WarningBox>
        )}

        {!loadingGeolocation && (!latitude || !longitude) && (
          <WarningBox>
            Não foi possível obter sua latitude e longitude!
          </WarningBox>
        )}

        {loadingGeolocation && (
          <WarningBox>
            <Spin size="small" spinning style={{ marginRight: 10 }} /> Obtendo
            dados de geolocalização
          </WarningBox>
        )} */}
        <ActionButton
          id="check-in-btn"
          type="primary"
          disabled={countdown !== '00:00' && countdown !== '59:59'}
          block
          // disabled={
          //   geolocationError || loadingGeolocation || !latitude || !longitude
          // }
          onClick={() => showConfirm(true)}
          loading={checkinLoading}
        >
          <>
            <LoginIcon />
            <FormattedMessage {...messages.checkIn} />
            <CountdownCounter
              countdown={countdown}
              setCountdown={setCountdown}
              timelineToCountDown={timelineToCountDown}
            />
          </>
        </ActionButton>

        <ActionButton
          type="danger"
          block
          id="check-out-btn"
          // disabled={
          //   geolocationError || loadingGeolocation || !latitude || !longitude
          // }
          onClick={() => {
            showConfirm(false);
          }}
          loading={checkinLoading}
        >
          <>
            <LogoutIcon />
            <FormattedMessage {...messages.checkOut} />
          </>
        </ActionButton>
        <Text strong>
          <FormattedMessage {...messages.todayProduction} />
        </Text>

        {listLoading || !initialTimer ? (
          <Skeleton count={1} width="50%" lineWidthList={['80%']} />
        ) : (
          <Counter isWorking={isWorking} initialTimer={initialTimer} />
        )}
      </div>

      <br />

      <div style={{ paddingLeft: 12 }}>
        {listLoading ? (
          <Skeleton
            count={1}
            width="50%"
            lineWidthList={['80%', '60%', '70%', '90%']}
          />
        ) : (
          productionData.length > 0 && (
            <TimelineHolder>
              {(productionData || []).map(renderWorkTime)}
            </TimelineHolder>
          )
        )}
      </div>

      <RectificationModal
        visible={rectificationModalVisible}
        setVisible={setRectificationModalVisible}
        onOk={handleRectification}
        checkInType={checkInType}
        type={typeValue}
        checkinList={productionData}
      />

      {confirmModalVisible ? (
        <ConfirmModal
          confirmModalVisible={confirmModalVisible}
          setConfirmModalVisible={setConfirmModalVisible}
          checkInType={checkInType}
          setCheckinLoading={setCheckinLoading}
          getProductionHistory={getProductionHistory}
          setRectificationModalVisible={setRectificationModalVisible}
          hadMinimumBreak={hadMinimumBreak}
          latitude={latitude}
          longitude={longitude}
          setCheckBoxValue={setCheckBoxValue}
          checkBoxValue={checkBoxValue}
        />
      ) : null}
    </TopHolder>
  );
}

WorkTime.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(WorkTime);
