export default [
  { pos: 1, type: 'entrada', label: 'Entrada' },
  { pos: 1, type: 'saida', label: 'Saída' },
  { pos: 2, type: 'entrada', label: 'Entrada' },
  { pos: 2, type: 'saida', label: 'Saída' },
  { pos: 3, type: 'entrada', label: 'Entrada' },
  { pos: 3, type: 'saida', label: 'Saída' },
  { pos: 4, type: 'entrada', label: 'Entrada' },
  { pos: 4, type: 'saida', label: 'Saída' },
  { pos: 5, type: 'entrada', label: 'Entrada' },
  { pos: 5, type: 'saida', label: 'Saída' },
  { pos: 6, type: 'total', label: 'Total' },
];
