import React, { useState, useContext } from 'react';
import { Row, Typography, Col, Button, Divider } from 'antd';
import moment from 'moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Container } from '../Type/styles';
import { PickerHolder } from './styles';
import { RequestAbsenceOvertimeContext } from '../RequestAbsenceOvertime';

const { Title } = Typography;

export default function Dates() {
  const { onDateSubmit, onDateBack, step1, step2, toggleModal } = useContext(
    RequestAbsenceOvertimeContext
  );
  const [selectedDays, setSelectedDays] = useState(step2 || []);
  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const months = moment.months();
  const weekdaysLong = moment.weekdays();
  const weekdaysShort = moment.weekdaysShort();

  const handleSelection = (day, { selected, disabled }) => {
    if (disabled) {
      return null;
    }

    if (selected) {
      return setSelectedDays(prev =>
        prev.filter(item => !DateUtils.isSameDay(item, day))
      );
    }

    return setSelectedDays(prev => [...prev, day]);
  };

  return (
    <>
      <br />
      <Row>
        <Title level={4}>
          Selecione os dias de{' '}
          {step1 === 'hora_extra' ? 'Hora Extra' : 'Ausência'}
        </Title>
      </Row>
      <Container>
        <PickerHolder>
          <DayPicker
            selectedDays={selectedDays}
            months={months}
            weekdaysLong={weekdaysLong}
            weekdaysShort={weekdaysShort}
            onDayClick={handleSelection}
            disabledDays={[{ before: tomorrow }]}
          />
        </PickerHolder>
      </Container>
      <br />
      <br />
      <Row type="flex" justify="space-between">
        <Button ghost type="primary" onClick={onDateBack}>
          Voltar
        </Button>
        <Col>
          <Button type="link" onClick={toggleModal}>
            Cancelar
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            disabled={!selectedDays.length}
            onClick={() => onDateSubmit(selectedDays)}
          >
            Avançar
          </Button>
        </Col>
      </Row>
    </>
  );
}
