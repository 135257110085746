import React, { useEffect } from 'react';
import { Row, Col, Collapse, Typography, Pagination } from 'antd';
import moment from 'moment';
import GrossPayment from './GrossPayment/GrossPayment';
import InfoHolder from './InfoHolder/InfoHolder';
import Cards from './Cards/Cards';
import { Container } from './styles';
import history, {
  getQueryParam,
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import checkPermission from '~/services/utils/checkPermission';
import renderStatusBadge from '~/services/utils/renderStatusBadge';
import {
  CollapsePanelContext,
  useConsolidatedProduction,
  useCompensatoryTime,
} from './index';
import { useTableData } from '../Modals/ManageAbsenceOvertimeModal/index';
import Skeleton from '~/components/DataDisplay/Skeleton';

export default function CollapseList() {
  const { Text } = Typography;
  const { Panel } = Collapse;

  const { page_size, page, ...queries } = getAllQueriesParams();

  const month = getQueryParam('month');
  const year = getQueryParam('year');
  const team = getQueryParam('team');

  const [key, setKey] = React.useState(undefined);

  const {
    consolidatedProduction,
    setConsolidatedProduction,
    fetchConsolidatedProduction,
    loading,
  } = useConsolidatedProduction(year, month, team);

  const {
    compensatoryTime,
    setCompensatoryTime,
    fetchCompensatoryTime,
  } = useCompensatoryTime(key, year, month);

  const {
    tableData,
    loadingTableData,
    setTableData,
    setLoadingTableData,
    fetchTableData,
    page: pageTable,
    page_size: page_sizeTable,
  } = useTableData(key, month, year);

  useEffect(() => {
    if (month > 12 || month < 1 || !month || !year) {
      const queries = getAllQueriesParams();

      pushWithQueriesParams(history.location.pathname, {
        year: moment().format('YYYY'),
        month: moment().format('MM'),
        ...queries,
      });
    }
  }, [month, year]);

  return (
    <>
      {checkPermission('view_consolidatedproduction') && (
        <Container>
          {loading === 'yes' ? (
            <Collapse activeKey="10000" defaultActiveKey="10000">
              <Panel
                collapsible="disabled"
                showArrow={false}
                header={
                  <Row type="flex" justify="space-between">
                    <Col span={10}>
                      <Skeleton lineWidthList={['100%']} />
                    </Col>
                    <Col span={3}>
                      <Skeleton lineWidthList={['100%']} />
                    </Col>
                  </Row>
                }
              />
            </Collapse>
          ) : null}
          {consolidatedProduction.results.length > 0 && (
            <Collapse
              accordion
              destroyInactivePanel
              onChange={async key => {
                if (key) {
                  setKey(key);
                }
              }}
            >
              {consolidatedProduction.results.map(user => {
                return (
                  <Panel
                    header={
                      <Row type="flex" justify="space-between">
                        <Col span={10}>{user.person_display.name_display}</Col>
                        <Col>
                          {user.status !== null
                            ? renderStatusBadge({
                                status: user.status.status,
                                status_display: user.status.status_display,
                              })
                            : null}
                        </Col>
                      </Row>
                    }
                    key={user.person_display.id}
                    showArrow={false}
                  >
                    <CollapsePanelContext.Provider
                      value={{
                        user,
                        key,
                        year,
                        month,
                        consolidatedProduction,
                        setConsolidatedProduction,
                        fetchConsolidatedProduction,
                        compensatoryTime,
                        setCompensatoryTime,
                        fetchCompensatoryTime,
                        tableData,
                        setTableData,
                        setLoadingTableData,
                        loadingTableData,
                        fetchTableData,
                        pageTable,
                        page_sizeTable,
                        loading,
                      }}
                      key={user.person_display.id}
                    >
                      <Row gutter={32} type="flex" justify="start">
                        <Col span={18}>
                          <Row justify="start">
                            <Col>
                              <InfoHolder />
                            </Col>
                            <Col>
                              <Cards />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col>
                              <GrossPayment />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CollapsePanelContext.Provider>
                  </Panel>
                );
              })}
            </Collapse>
          )}
          <br />
          <Row gutter={[16, 16]} type="flex" justify="start">
            <Col>
              <Pagination
                total={consolidatedProduction.count}
                showSizeChanger
                current={parseInt(page, 10) || 1}
                pageSize={parseInt(page_size, 10) || 10}
                showTotal={(total, range) => (
                  <div style={{ marginRight: 30 }}>
                    <Text>
                      Exibindo
                      <Text strong>
                        {' '}
                        {total === 0 ? 0 : range[1] - range[0] + 1}{' '}
                      </Text>
                      registros de
                      <Text strong> {total} </Text>
                    </Text>
                  </div>
                )}
                onShowSizeChange={(_, size) =>
                  pushWithQueriesParams(history.location.pathname, {
                    page: 1,
                    page_size: size,
                    ...queries,
                  })
                }
                onChange={val =>
                  pushWithQueriesParams(history.location.pathname, {
                    page: val,
                    page_size: page_size || 10,
                    ...queries,
                  })
                }
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
