import React from 'react';

import { Divider } from 'antd';
import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm/index';
import TeamTable from './TeamTable';

export default function MySquad() {
  return (
    <PageContainer title="Meus liderados">
      <SearchForm />
      <Divider />
      <TeamTable />
      <br />
    </PageContainer>
  );
}
