import React, { useState } from 'react';
import safeRead from 'safely-read';
import { Button, Row, Col } from 'antd';
import Table from '~/components/DataDisplay/Table';
import { makeColumns, formatTableData, useTableData } from './index';
import UploadPeopleModal from '../Modals/UploadPeopleModal';
import UploadPositionsModal from '../Modals/UploadPositionsModal/UploadPositionsModal';
import { getAllQueriesParams } from '~/services/history';
import checkPermission from '~/services/utils/checkPermission';

// import { ButtonGroup } from './styles';

export default function UserTable() {
  const { tableData, loadingTableData, fetchTableData } = useTableData();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [
    uploadModalPositionsVisible,
    setUploadModalPositionsVisible,
  ] = useState(false);
  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);
  const { page, page_size } = getAllQueriesParams();

  return (
    <>
      <Row gutter={[8, 8]} type="flex" justify="start">
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          {checkPermission('add_person') && (
            <Button
              type="primary"
              block
              onClick={() => setUploadModalPositionsVisible(true)}
            >
              Importar cargos de cooperado
            </Button>
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          {checkPermission('add_position') && (
            <Button
              type="primary"
              block
              onClick={() => setUploadModalVisible(true)}
            >
              Importar carga de cooperados
            </Button>
          )}
        </Col>
      </Row>

      <br />
      <Table
        dataSource={formatTableData(tableRows)}
        columns={makeColumns}
        paginated
        loading={loadingTableData}
        currentPage={parseInt(page, 10) || 1}
        count={tableCount}
        pageSize={parseInt(page_size, 10) || 10}
        scroll={{ x: 120 }}
      />
      {uploadModalPositionsVisible ? (
        <UploadPositionsModal
          uploadModalVisible={uploadModalPositionsVisible}
          setUploadModalVisible={setUploadModalPositionsVisible}
          fetchTableData={fetchTableData}
        />
      ) : null}
      {uploadModalVisible ? (
        <UploadPeopleModal
          uploadModalVisible={uploadModalVisible}
          setUploadModalVisible={setUploadModalVisible}
          fetchTableData={fetchTableData}
        />
      ) : null}
    </>
  );
}
