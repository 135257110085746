import React from 'react';
import { Form, Field, Formik } from 'formik';
import { Button } from 'antd';
import Input from '~/components/DataEntry/Input';
import { emailSchema } from '~/constants/formSchemas/forgotPassword';
import { createRecoverToken } from './index';

export default function RequestRecover() {
  return (
    <Formik
      onSubmit={createRecoverToken}
      validationSchema={emailSchema}
      validateOnChange
      validateOnBlur={false}
      initialValues={{
        email: '',
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Field
            id="email-field"
            name="email"
            component={Input}
            label="E-mail"
            type="email"
          />

          <Button
            id="submit-btn"
            disabled={!values.email || errors.email}
            type="primary"
            block
            size="large"
            htmlType="submit"
          >
            Enviar
          </Button>
        </Form>
      )}
    </Formik>
  );
}
