import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { message } from 'antd';
import safeRead from 'safely-read';
import {
  SIGN_IN_REQUEST,
  SIGN_OUT,
  TOGGLE_KEEP_SIGNED,
  signInSuccess,
  signFailure,
  refreshTokenSuccess,
  refreshTokenFailure,
} from './actions';
import { AUTH, AUTH_REFRESH } from '~/constants/endpoints/auth';
import { KEEP_SIGNED, REFRESH_TOKEN } from '~/constants/localstorage';
import { userPermissionsRequest, getUserDataRequest } from '../user/actions';
import { permissionsRequest } from '../permissions/actions';
import { groupsRequest } from '../profile_groups/actions';

import history from '~/services/history';

import api from '~/services/api';

export function* signIn({ payload }) {
  try {
    const { username, password, recaptcha } = payload;
    const keepSigned = yield select(state => state.auth.keepSigned);
    const { data } = yield call(api.post, AUTH, {
      username,
      password,
      recaptcha,
    });

    const { access, refresh } = data;

    if (keepSigned) {
      localStorage.setItem(REFRESH_TOKEN, refresh);
    } else {
      sessionStorage.setItem(REFRESH_TOKEN, refresh);
    }

    yield put(signInSuccess(access, refresh));

    yield put(userPermissionsRequest());

    yield put(permissionsRequest());

    yield put(groupsRequest());

    yield put(getUserDataRequest());

    message.success('Login realizado com sucesso!');
    history.push('/');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Ocorreu um erro interno, tente novamente mais tarde.'
      )
    );

    yield put(signFailure(
      safeRead(error, ['response', 'data', 'error_code'],'')
    ));
  }
}

export function* refreshTokenRequestOnReload() {
  const keepSigned = yield select(state => state.auth.keepSigned);
  let refreshToken;

  if (keepSigned) {
    refreshToken = localStorage.getItem(REFRESH_TOKEN);
  } else {
    refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
  }

  try {
    const { data } = yield call(api.post, AUTH_REFRESH, {
      refresh: refreshToken,
    });

    const { access } = data;

    yield put(userPermissionsRequest());
    yield put(permissionsRequest());
    yield put(groupsRequest());
    yield put(getUserDataRequest());
    yield put(refreshTokenSuccess(access, refreshToken));
  } catch (error) {
    yield put(refreshTokenFailure());
  }
}

export function signOut() {
  localStorage.clear();
  sessionStorage.clear();
}

export function* toggleKeepSigned() {
  const keepSigned = yield select(state => state.auth.keepSigned);

  if (keepSigned) {
    localStorage.setItem(KEEP_SIGNED, keepSigned);
  } else {
    localStorage.removeItem(KEEP_SIGNED);
  }
}

export default all([
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeLatest('persist/REHYDRATE', refreshTokenRequestOnReload),
  takeLatest(SIGN_OUT, signOut),
  takeLatest(TOGGLE_KEEP_SIGNED, toggleKeepSigned),
]);
