import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider } from 'antd';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  Container,
  PasswordImage,
  PageTitle,
  ImgContainer,
  ForgotPasswordContainer,
  LogoImg,
} from './styles';
import { RequestRecover, Recover } from './Forms';
import history from '~/services/history';

import logo from '~/assets/image/logo-vertical.png';
import passwordImg from '~/assets/image/password.png';

import messages from './messages';

export default function ForgotPassword() {
  const token = new URLSearchParams(history.location.search).get('token');
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Helmet
        title={`${formatMessage(
          messages.recoverPassword
        )} | Portal Coopersystem`}
      />
      <ImgContainer>
        <PasswordImage src={passwordImg} alt="Password" />
      </ImgContainer>

      <ForgotPasswordContainer>
        <PageTitle level={3}>
          <FormattedMessage {...messages.recoverPassword} />
        </PageTitle>

        {!token ? <RequestRecover /> : <Recover />}

        <Divider />
        <Link to="/autenticacao">Voltar para tela de login</Link>
        <LogoImg src={logo} />
      </ForgotPasswordContainer>
    </Container>
  );
}
