import produce from 'immer';
import {
  SIGN_IN_SUCCESS,
  SIGN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_UP_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  TOGGLE_KEEP_SIGNED,
  REFRESH_TOKEN_INTERCEPTOR_REQUEST,
  SIGN_OUT,
} from './actions';

const INITIAL_STATE = {
  token: {
    access: null,
    refresh: null,
  },
  signed: false,
  loading: false,
  refreshingToken: false,
  keepSigned: true,
  errorcode: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SIGN_UP_REQUEST: {
        draft.loading = true;
        break;
      }
      case SIGN_IN_REQUEST: {
        draft.loading = true;
        break;
      }
      case SIGN_IN_SUCCESS: {
        draft.token.access = action.payload.access;
        draft.token.refresh = action.payload.refresh;
        draft.signed = true;
        draft.loading = false;
        draft.errorcode = null;
        break;
      }
      case SIGN_FAILURE: {
        draft.loading = false;
        draft.errorcode = action.payload.errorcode;
        break;
      }
      case 'persist/REHYDRATE': {
        draft.refreshingToken = true;
        break;
      }
      case REFRESH_TOKEN_SUCCESS: {
        draft.token.access = action.payload.access;
        draft.token.refresh = action.payload.refresh;
        draft.signed = true;
        draft.refreshingToken = false;
        break;
      }
      case REFRESH_TOKEN_INTERCEPTOR_REQUEST: {
        draft.token.access = action.payload.access;
        draft.refreshingToken = false;
        break;
      }
      case REFRESH_TOKEN_FAILURE: {
        draft.refreshingToken = false;
        break;
      }
      case SIGN_OUT: {
        draft.token = {
          access: null,
          refresh: null,
        };
        draft.signed = false;
        draft.keepSigned = false;
        break;
      }
      case TOGGLE_KEEP_SIGNED: {
        draft.keepSigned = !state.keepSigned;
        break;
      }
      default:
    }
  });
}
