/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, message, Typography, Divider, Row, Col, Button } from 'antd';
import safeRead from 'safely-read';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import Input from '~/components/DataEntry/Input';
import { PAID_LEAVE_APPROVAL_ID } from '~/constants/endpoints/paid_leave_approval';
import badgesStatus from '~/constants/badgesStatus';
import api from '~/services/api';

const { Title } = Typography;

export async function handlePaidLeaveApproval({
  id,
  status,
  disapproval_reason,
  updatePaidLeave,
  setDetailModalVisible,
}) {
  const typeVerb = () => {
    if (status === badgesStatus.APPROVED) {
      return 'aprovada';
    }
    if (status === badgesStatus.DISAPPROVED) {
      return 'reprovada';
    }

    return 'realizada';
  };

  try {
    await api.patch(PAID_LEAVE_APPROVAL_ID(id), {
      status,
      disapproval_reason,
    });

    message.success(`A solicitação foi ${typeVerb()} com sucesso.`);

    await updatePaidLeave();

    setDetailModalVisible(false);
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao efetuar a ação.')
    );
  }
}

export function DisapprovalReasonModal({
  visible,
  setVisible,
  request_id,
  setDetailModalVisible,
  updatePaidLeave,
}) {
  return (
    <Modal
      title={<Title level={4}>Reprovar solicitação</Title>}
      visible={visible}
      onCancel={() => setVisible(false)}
      centered
      footer={false}
      destroyOnClose
    >
      <Formik
        initialValues={{}}
        validationSchema={yup.object().shape({
          reasonInput: yup.string().required('Campo obrigatório'),
        })}
        onSubmit={async ({ reasonInput }) => {
          await handlePaidLeaveApproval({
            disapproval_reason: reasonInput,
            status: badgesStatus.DISAPPROVED,
            id: request_id,
            setDetailModalVisible,
            updatePaidLeave,
          });
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <>
            <Field
              id="reasonInput"
              name="reasonInput"
              label="Motivo da reprovação"
              required
              component={Input}
              type="textarea"
            />
            <Divider />
            <Row type="flex" justify="end">
              <Col>
                <Button onClick={() => setVisible(false)}>Cancelar</Button>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  disabled={!safeRead(values, ['reasonInput'])}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export { default as CooperativeActions } from './CooperativeActions';
export { default as ManagerActions } from './ManagerActions';
