import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Icon, Divider } from 'antd';
import moment from 'moment';

import { useDeepCompareEffect } from 'react-use';
import { useSelector } from 'react-redux';

import { common as theme } from '~/constants/theme';

function Counter({ isWorking, initialTimer }) {
  const { data: productionData } = useSelector(state => state.production);
  const [timer, setTimer] = useState(new Date(moment(initialTimer)));

  useDeepCompareEffect(() => {
    setTimer(new Date(moment(initialTimer)));
  }, [initialTimer, productionData]);

  useDeepCompareEffect(() => {
    let interval;

    if (isWorking) {
      interval = setInterval(() => {
        if (timer && typeof timer.getSeconds === 'function') {
          setTimer(timer.setSeconds(timer.getSeconds() + 1));
        } else {
          clearInterval(interval);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorking, productionData]);

  return (
    <div>
      <Row>
        {isWorking ? (
          <Icon
            type="play-circle"
            theme="twoTone"
            twoToneColor={theme.primaryColor}
          />
        ) : (
          <Icon
            type="pause-circle"
            theme="twoTone"
            twoToneColor={theme.errorColor}
          />
        )}
        <Divider type="vertical" />
        {moment(timer).format('HH:mm:ss')}
      </Row>
    </div>
  );
}

export default Counter;

Counter.propTypes = {
  isWorking: PropTypes.bool.isRequired,
  initialTimer: PropTypes.string.isRequired,
};
