import { defineMessages } from 'react-intl';

export const scope = 'portal.sider';

export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Início',
  },
  production: {
    id: `${scope}.production`,
    defaultMessage: 'Produção',
  },
  management: {
    id: `${scope}.management`,
    defaultMessage: 'Gestão',
  },
  myProduction: {
    id: `${scope}.my_production`,
    defaultMessage: 'Minha Produção',
  },
  absenceOvertime: {
    id: `${scope}.absence_overtime`,
    defaultMessage: 'Ausência e Hora Extra',
  },
  vacationsAllowences: {
    id: `${scope}.vacations_allowences`,
    defaultMessage: 'Férias e Abonos',
  },
  requests: {
    id: `${scope}.requests`,
    defaultMessage: 'Solicitações',
  },
  rectification: {
    id: `${scope}.rectification`,
    defaultMessage: 'Rasura',
  },
});
