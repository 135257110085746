import React from 'react';
import LoadingSkeleton from 'react-loading-skeleton';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function Skeleton({
  lineWidthList,
  count,
  lineHeight,
  ...props
}) {
  if (lineWidthList) {
    return (
      <Container>
        <div style={{ lineHeight }}>
          {lineWidthList.map(item => (
            <LoadingSkeleton
              key={shortid.generate()}
              count={1}
              {...props}
              width={item}
            />
          ))}
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <LoadingSkeleton count={count} {...props} />
    </Container>
  );
}

Skeleton.propTypes = {
  lineWidthList: PropTypes.arrayOf(PropTypes.any),
  count: PropTypes.number,
  lineHeight: PropTypes.number,
};

Skeleton.defaultProps = {
  lineWidthList: undefined,
  count: undefined,
  lineHeight: undefined,
};
