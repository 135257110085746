import React, { useState } from 'react';
import safeRead from 'safely-read';
import { Button } from 'antd';

import checkPermission from '~/services/utils/checkPermission';
import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm';
import Table from '~/components/DataDisplay/Table';
import {
  tableColumns,
  useTableData,
  formatTableData,
  handleDetailModalData,
  DetailModalAction,
} from '.';
import history, {
  getAllQueriesParams,
  pushWithQueriesParams,
} from '~/services/history';
import DetailModal from '~/components/DataDisplay/Modals/DetailModal';
import ManageVacationsAllowences from './Modals/ManageVacationsAllowences';

export default function VacationsAllowenceApproval() {
  const { tableData, loadingTableData, fetchTableData } = useTableData();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(false);
  const tableRows = safeRead(tableData, ['results'], []);
  const tableCount = safeRead(tableData, ['count'], 0);
  const { page, page_size, date_start, date_end } = getAllQueriesParams();

  return (
    <PageContainer title="Férias e Abonos">
      <SearchForm />
      <br />

      {checkPermission('add_paidleave') ? (
        <Button type="primary" onClick={() => setManageModalVisible(true)}>
          Gerenciar Férias e Abonos
        </Button>
      ) : null}

      <br />
      <br />

      <Table
        dataSource={formatTableData(tableRows)}
        columns={tableColumns(setSelectedDetail, setDetailModalVisible)}
        paginated
        currentPage={parseInt(page, 10) || 1}
        count={tableCount}
        pageSize={parseInt(page_size, 10) || 10}
        loading={loadingTableData}
        onChange={(pagination, filters, sorter, extra) => {
          pushWithQueriesParams(history.location.pathname, {
            date_start,
            date_end,
            page: 1,
            page_size,
            ordering:
              sorter.order === 'ascend'
                ? sorter.columnKey
                : `-${sorter.columnKey}`,
          });
        }}
      />

      <DetailModal
        title="Detalhes da solicitação"
        visible={detailModalVisible}
        setVisible={setDetailModalVisible}
        data={handleDetailModalData(selectedDetail)}
        actions={
          <DetailModalAction
            data={selectedDetail}
            setDetailModalVisible={() => setDetailModalVisible(false)}
            fetchData={fetchTableData}
          />
        }
      />
      <ManageVacationsAllowences
        visible={manageModalVisible}
        setVisible={setManageModalVisible}
        fetchTableData={fetchTableData}
      />
    </PageContainer>
  );
}
