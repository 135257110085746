import React from 'react';
import { Icon } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import safeRead from 'safely-read';
import { Breadcrumb } from './styles';
import { breadcrumbEnum } from '~/components/routes/paths';

export default function DataDisplay() {
  const location = useLocation();
  const { locale } = useSelector(state => state.language);

  const splittedRoutes = safeRead(location, ['pathname'], ['/'], loc =>
    loc.split('/')
  );

  const routes =
    splittedRoutes[0] === '' && splittedRoutes[1] === ''
      ? ['']
      : splittedRoutes.map(item => (item === '' ? '' : item));

  const composedRoutes = routes.reverse().map((item, index) => {
    const mountingRoute = routes
      .slice(routes.length - 1 - index, routes.length)
      .reverse()
      .join('/');

    if (mountingRoute === '') {
      return '/';
    }

    return mountingRoute;
  });

  const renderTitle = title => {
    if (title) {
      return breadcrumbEnum[locale][title];
    }

    return null;
  };

  if (composedRoutes.length === 1) {
    return null;
  }

  return (
    <Breadcrumb style={{ marginBottom: 12 }}>
      {composedRoutes.map(item => {
        return item === '/' ? (
          <Breadcrumb.Item key={item}>
            <Link to={item}>
              <Icon type="home" />
            </Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={item}>
            <Link to={item}>{renderTitle(item)}</Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}
