import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  padding: 12px;
  margin: 16px 0 !important;
  background-color: ${({ theme }) => shade(0.04, theme.componentBackground)};
  border-radius: ${({ theme }) => theme.borderRadiusBase};

  .info-title {
    color: ${({ theme }) => theme.textColor};
  }

  .info-description {
    color: ${({ theme }) => theme.textColor};
    font-size: 20px;
    font-weight: bold;
  }
`;
