import React from 'react';
import PropTypes from 'prop-types';
import { Carousel, Typography, Button, Divider, Row, Col } from 'antd';
import { Form, Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import Helmet from 'react-helmet';
import moment from 'moment';
import {
  CarouselContainer,
  LoginContainer,
  PageTitle,
  LogoImg,
  LogoImgTop,
  CarouselHolder,
  CarouselPage,
  CarouselImgs,
  CarouselPageSection,
} from './styles';
import logo from '~/assets/image/logo-vertical.png';
import carouselPages from './carouselPages';
import Input from '~/components/DataEntry/Input';
import Checkbox from '~/components/DataEntry/Checkbox';
import { signInRequest, toggleKeepSigned } from '~/store/modules/auth/actions';

import messages from './messages';

function Login({ intl }) {
  const recaptchaRef = React.useRef(null);
  const reCAPTCHAKey = process.env.REACT_APP_RE_CAPTCHA_SITE_KEY;
  const [reCaptchaValue, setReCaptchaValue] = React.useState(null);
  const { Paragraph, Title, Text } = Typography;
  const dispatch = useDispatch();
  const { loading, keepSigned, errorcode } = useSelector(s => s.auth);
  const handleLogin = values => {
    const { username, password } = values;
    dispatch(signInRequest(username, password, reCaptchaValue));

    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    setReCaptchaValue(null);
  };

  return (
    <>
      <Helmet title="Portal Coopersystem" />
      <CarouselContainer>
        <PageTitle>
          <FormattedMessage {...messages.moreSecurity} />
          <br /> <FormattedMessage {...messages.andAgility} />
        </PageTitle>
        <CarouselHolder>
          <Carousel autoplay dotPosition="bottom" style={{ height: '100%' }}>
            {carouselPages.map(page => (
              <CarouselPage key={page.key}>
                <CarouselPageSection>
                  <CarouselImgs src={page.image} />
                </CarouselPageSection>
                <CarouselPageSection>
                  <Title level={3}>{page.title}</Title>
                  <Paragraph>{page.subtitle}</Paragraph>
                </CarouselPageSection>
              </CarouselPage>
            ))}
          </Carousel>
        </CarouselHolder>
        <Row style={{ textAlign: 'center' }}>
          <Col>
            <Text>
              Coopersystem © {moment().format('YYYY')} - Todos os direitos
              reservados
            </Text>
          </Col>
          <Col>
            <Button
              type="link"
              href="https://dpo.privacytools.com.br/policy-view/dqy1D5RoQ/1/politica-de-privacidade-portal-coopersystem/pt_BR"
              target="_blank"
            >
              Política de Privacidade
            </Button>
          </Col>
        </Row>
      </CarouselContainer>

      <LoginContainer>
        <PageTitle id="login-title" level={3}>
          Portal Coopersystem
        </PageTitle>
        <LogoImgTop src={logo} />
        <Formik
          onSubmit={(v, b) => handleLogin(v, b)}
          initialValues={{
            username: '',
            password: '',
            keepLogged: keepSigned,
          }}
        >
          {({ handleSubmit, values }) => (
            <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
              <Field
                id="username-field"
                name="username"
                component={Input}
                label={intl.formatMessage(messages.userLabel)}
                disabled={loading}
              />
              <Field
                id="password-field"
                name="password"
                component={Input}
                label={intl.formatMessage(messages.passwordLabel)}
                type="password"
                disabled={loading}
              />
              <Field
                id="keep-logged-checkbox"
                name="keepLogged"
                component={Checkbox}
                label={intl.formatMessage(messages.keepLoggedLabel)}
                disabled={loading}
                onChange={() => dispatch(toggleKeepSigned())}
              />
              {errorcode == 'RECAPTCHA_REQUIRED' &&
                <div style={{display: 'flex', justifyContent: 'center', paddingBlock: 16}}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={reCAPTCHAKey}
                    onChange={(value) => setReCaptchaValue(value)}
                  />
                </div>
              }
              <Button
                id="sign-in-btn"
                type="primary"
                block
                size="large"
                htmlType="submit"
                loading={loading}
                disabled={!values.username || !values.password}
              >
                <FormattedMessage {...messages.signButton} />
              </Button>
            </Form>
          )}
        </Formik>
        <Divider />
        <Button type="link" href="https://passwordreset.microsoftonline.com/passwordreset#!/">
          Esqueceu sua senha?
        </Button>

        <Row
          type="flex"
          style={{ position: 'absolute', bottom: 18 }}
          align="middle"
        >
          <Col>
            <LogoImg src={logo} />
          </Col>
          {/* <Col>
            <Button type="link" href={PolicyPDF} target="_blank">
              Política de Privacidade
            </Button>
          </Col> */}
        </Row>
      </LoginContainer>
    </>
  );
}

Login.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Login);
