import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Button, Divider } from 'antd';
import { Formik, Form, Field } from 'formik';

import Transfer from './Transfer';
import Input from '~/components/DataEntry/Input';
import { patchPerfil, postPerfil, makeEditState } from './index';
import { ProfileContext } from '../../index';
import { Container, ActionsContainer } from './styles';

const { Title } = Typography;

export default function RegisterOrEdit({ visible, setVisible }) {
  const {
    fetchTableData,
    currentRow,
    groupEditMode,
    setGroupEditMode,
    setCurrentRow,
  } = useContext(ProfileContext);
  const [targetKeys, setTargetKeys] = useState(
    groupEditMode ? makeEditState(currentRow.groups) : []
  );

  useEffect(() => {
    return () => {
      setCurrentRow(undefined);
      setGroupEditMode(false);
    };
  }, [setCurrentRow, setGroupEditMode, setVisible]);

  return (
    <Modal
      title={
        <Title level={4}>
          {groupEditMode ? 'Editar Perfil' : 'Cadastrar Perfil'}
        </Title>
      }
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={900}
      closable
      destroyOnClose
    >
      <Container>
        <Formik
          initialValues={{
            groups: targetKeys.filter(item => !isNaN(item)),
            name: groupEditMode ? currentRow.name : undefined,
          }}
          onSubmit={values =>
            groupEditMode
              ? patchPerfil(values, fetchTableData, setVisible, currentRow.key)
              : postPerfil(values, fetchTableData, setVisible)
          }
        >
          {({ setFieldValue, handleSubmit, values, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                id="input-name"
                name="name"
                component={Input}
                label="Nome do Perfil"
              />

              <Field
                id="input-transfer"
                name="groups"
                labelLeft="Permissões Disponíveis:"
                labelRight={`Perfil ${values.name || ''}:`}
                component={Transfer}
                targetKeys={targetKeys}
                setTargetKeys={setTargetKeys}
                onChange={value => setFieldValue('groups', value)}
              />

              <ActionsContainer>
                <Button htmlType="submit" onClick={() => setVisible(false)}>
                  Cancelar
                </Button>

                <Divider type="vertical" />

                <Button
                  disabled={!values.name}
                  loading={isSubmitting}
                  htmlType="submit"
                  type="primary"
                >
                  {groupEditMode ? 'Salvar Perfil' : 'Cadastrar Perfil'}
                </Button>
              </ActionsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}

RegisterOrEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
