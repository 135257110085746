import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid ${({ theme }) => darken(0.02, theme.componentBackground)};
  background-color: ${({ theme }) => darken(0.01, theme.componentBackground)};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 12px;
`;

export const LeadingContainer = styled.div`
  padding: 12px;

  min-height: 82px;
  min-width: 92px;

  p {
    margin-top: 12px;
    margin-bottom: 0;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 215px;
  padding: 0 12px;
`;

export const TrailingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
`;

export const VacationAllowenceBadge = styled.div`
  text-align: center;
  padding: 2px;
  border-radius: 4px;
  width: 100%;
  font-weight: bold;
  color: white;
  background-color: ${({ vacation }) => (vacation ? 'seaGreen' : 'steelBlue')};
`;

export const Title = styled.h3`
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
`;
