import { useEffect, useState } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';

import api from '~/services/api';
import { TEAM_ID } from '~/constants/endpoints/team';
import { GET_PERSON } from '~/constants/endpoints/person';
import EditProject from './EditProject';

export const tabs = {
  PROJECT_DATA: 'project_data',
  SUPERVISORS: 'supervisors',
  USER: 'user',
};

export async function fetchPersons(params) {
  let response = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(GET_PERSON, { params });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar persons.'));
  }

  return response;
}

export function usePersons({ page_size, page, supervised_teams, name, teams }) {
  const [loadingPersons, setLoadingPersons] = useState(false);
  const [persons, setPersons] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadPersons() {
      setLoadingPersons(true);

      const data = await fetchPersons({
        page_size,
        page,
        supervised_teams,
        name,
        teams,
      });

      setPersons(data);

      setLoadingPersons(false);
    }

    loadPersons();
  }, [name, page, page_size, teams, supervised_teams]);

  return {
    loadingPersons,
    setLoadingPersons,
    persons,
    setPersons,
    updatePersons: async () => {
      setLoadingPersons(true);

      const data = await fetchPersons({
        page_size,
        page,
        supervised_teams,
        name,
        teams,
      });

      setPersons(data);

      setLoadingPersons(false);
    },
  };
}

export async function getProjectById(id) {
  let response;

  try {
    const { data } = await api.get(TEAM_ID(id));

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes.'));
  }

  return response;
}

export function useProjectData(id) {
  const [project, setProject] = useState(undefined);
  const [loadingProject, setLoadingProject] = useState(false);

  useEffect(() => {
    async function loadProjectData() {
      setLoadingProject(true);

      const data = await getProjectById(id);

      setProject(data);

      setLoadingProject(false);
    }

    loadProjectData();
  }, [id]);

  return { project, setProject, loadingProject };
}

export default EditProject;
