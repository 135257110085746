import React, { useContext } from 'react';
import { Row, Col, Typography, Select as AntdSelect, Button, List } from 'antd';
import { Field, Formik, Form } from 'formik';
import safeRead from 'safely-read';
import { useParams } from 'react-router-dom';
import { InfoHolder, Title } from '../../styles';
import Select from '~/components/DataEntry/Select';
import PasswordChangeModal from './Modal/PasswordChangeModal';
import {
  useAuthUsersData,
  PatchPersonIsCooperativeMember,
  PatchUserActive,
} from './index';
import { UserRegistryContext } from '../../index';
import Skeleton from '~/components/DataDisplay/Skeleton';

const { Text } = Typography;

export default function Access() {
  const [visible, setVisible] = React.useState(false);
  const { id } = useParams();

  const {
    userData,
    loadingUserData,
    fetchData,
    setUserData,
    setLoadingUserData,
  } = useContext(UserRegistryContext);

  const {
    authUsersData,
    getAuthUsersGroups,
    setAuthGroupsData,
  } = useAuthUsersData(safeRead(userData, ['user'], null));

  const list = [
    { id: 'Sim', value: 'true' },
    { id: 'Não', value: 'false' },
  ];

  const isActive = [
    { id: 'Ativo ', value: 'true' },
    { id: 'Inativo ', value: 'false' },
  ];

  return (
    <>
      <Row justify="center">
        <Col sm={24} md={24} lg={6} xl={6} />
        <Col sm={24} md={24} lg={12} xl={12}>
          {loadingUserData ? (
            <Skeleton
              width="100%"
              lineWidthList={['100%', '100%', '100%', '100%', '100%']}
            />
          ) : (
            <Formik
              initialValues={{
                is_active: `${userData.is_active}`,
                is_cooperative_member: `${userData.is_cooperative_member}`,
              }}
              onSubmit={async values => {
                await PatchUserActive(
                  userData.user,
                  authUsersData.username,
                  values
                );
                await PatchPersonIsCooperativeMember(id, values);
                await fetchData(setUserData, id, setLoadingUserData);
                await getAuthUsersGroups(id, setAuthGroupsData);
              }}
            >
              {({ handleSubmit }) => (
                <>
                  <Form onSubmit={handleSubmit}>
                    <InfoHolder>
                      <Title level={4}>Dados de Acesso</Title>

                      <Row gutter={24} type="flex" justify="center">
                        <Col sm={10} md={8} lg={10} xl={8}>
                          <Text>Perfil: </Text>
                        </Col>
                        <Col sm={8} md={8} lg={8} xl={8}>
                          {safeRead(authUsersData, ['groups_display'], [])
                            .length > 0 ? (
                            <List
                              size="small"
                              grid="none"
                              dataSource={safeRead(authUsersData, [
                                'groups_display',
                              ])}
                              renderItem={item => (
                                <List.Item>
                                  <Text>
                                    {safeRead(
                                      item,
                                      ['name'],
                                      '--',
                                      name => `• ${name}`
                                    )}
                                  </Text>
                                </List.Item>
                              )}
                            />
                          ) : (
                            <Text>Não há dados</Text>
                          )}
                        </Col>
                        <br />
                        <br />
                        <Col sm={6} md={6} lg={6} xl={6} />
                      </Row>
                      <Row gutter={24} type="flex" justify="center">
                        <Col sm={10} md={8} lg={10} xl={8}>
                          <Text>Usuário AD/LDAP: </Text>
                        </Col>
                        <Col sm={8} md={8} lg={8} xl={8}>
                          <Text>{authUsersData.username}</Text>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                          {/* <Button type="link" style={{ top: 4 }}>
                          Confirmar
                        </Button> */}
                        </Col>
                      </Row>
                      <br />
                      <Row gutter={24} type="flex" justify="center">
                        <Col sm={10} md={8} lg={10} xl={8} style={{ top: 8 }}>
                          <Text>Acesso ao Sistema: </Text>
                        </Col>
                        <Col sm={8} md={8} lg={8} xl={8}>
                          <Field
                            id="is_active"
                            name="is_active"
                            placeholder="Selecione"
                            data={isActive}
                            renderItem={item => (
                              <AntdSelect.Option
                                key={item.id}
                                value={item.value}
                              >
                                {item.id}
                              </AntdSelect.Option>
                            )}
                            component={Select}
                          />
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} />
                      </Row>
                      <Row gutter={24} type="flex" justify="center">
                        <Col sm={10} md={8} lg={10} xl={8} style={{ top: 8 }}>
                          <Text>É Cooperado?</Text>
                        </Col>
                        <Col sm={8} md={8} lg={8} xl={8}>
                          <Field
                            id="is_cooperative_member"
                            name="is_cooperative_member"
                            placeholder="Selecione"
                            data={list}
                            renderItem={item => (
                              <AntdSelect.Option
                                key={item.id}
                                value={item.value}
                              >
                                {item.id}
                              </AntdSelect.Option>
                            )}
                            component={Select}
                          />
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6} />
                      </Row>
                    </InfoHolder>
                    <div style={{ textAlign: 'center' }}>
                      <Button type="link" onClick={() => setVisible(true)}>
                        Redefinir Senha
                      </Button>
                      {visible ? (
                        <PasswordChangeModal
                          visible={visible}
                          setVisible={setVisible}
                        />
                      ) : null}
                    </div>

                    <Row type="flex" justify="end">
                      <Col>
                        <Button type="primary" htmlType="submit">
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Formik>
          )}
        </Col>
        <Col sm={24} md={24} lg={6} xl={6} />
      </Row>
    </>
  );
}
