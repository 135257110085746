import React, { useState } from 'react';
import { Row, Button, Typography, Spin } from 'antd';
import { Redirect } from 'react-router-dom';

import checkPermission from '~/services/utils/checkPermission';
import { getQueryParam } from '~/services/history';
import ResignationRequest from './ResignationRequest';
import { CooperativeActions, ManagerActions } from './DetailModalActions';
import DetailModal from '~/components/DataDisplay/Modals/DetailModal';
import PeriodForm from './PeriodForm';
import Timeline from './Timeline';
import PageContainer from '~/components/Layout/PageContainer';
import RequestDayOffModal from './Modals/RequestDayOff';
import { HistoryContainer, LoadingContainer } from './styles';
import RequestHistory from './RequestHistory';
import CountTable from './CountTable';
import {
  usePaidLeaveReferencePeriods,
  useGetPaidLeave,
  VacationsContext,
  updatePaidLeave,
} from './index';

const { Title } = Typography;

export default function VacationsAllowences() {
  const cooperativeId = getQueryParam('cooperative_member_id');

  const { referencePeriods } = usePaidLeaveReferencePeriods();
  const { paidLeave, setPaidLeave, paidLeaveLoading } = useGetPaidLeave();
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [detail, setDetail] = useState([]);
  const [requestDayOffModalVisible, setRequestDayOffModalVisible] = useState(
    false
  );

  return cooperativeId && !checkPermission('view_paidleave') ? (
    <Redirect to="/404" />
  ) : (
    <PageContainer title="Solicitar Férias e Abonos">
      <VacationsContext.Provider
        value={{
          paidLeave,
          paidLeaveLoading,
          updatePaidLeave: () => updatePaidLeave(setPaidLeave),
          setDetailModalVisible,
          setDetail,
        }}
      >
        {referencePeriods && (
          <>
            {' '}
            <PeriodForm referencePeriods={referencePeriods} />
            <br />
            {!cooperativeId && (
              <Row type="flex" justify="start">
                <Button
                  onClick={() => setRequestDayOffModalVisible(true)}
                  id="request-vacations-allowences"
                  type="primary"
                >
                  Solicitar Férias / Abono
                </Button>
              </Row>
            )}
            <br />
            {paidLeaveLoading ? (
              <LoadingContainer>
                <Spin size="large" />
              </LoadingContainer>
            ) : (
              <>
                <Timeline />
                <Title level={4}>Histórico de solicitações</Title>
                <HistoryContainer>
                  <RequestHistory />

                  <div>
                    <CountTable />
                  </div>
                </HistoryContainer>
              </>
            )}
            {requestDayOffModalVisible ? (
              <RequestDayOffModal
                visible={requestDayOffModalVisible}
                setVisible={setRequestDayOffModalVisible}
                referencePeriods={referencePeriods}
              />
            ) : null}
            {detailModalVisible ? (
              <DetailModal
                visible={detailModalVisible}
                setVisible={setDetailModalVisible}
                title="Detalhes da solicitação"
                data={detail}
                actions={
                  cooperativeId ? (
                    <ManagerActions detail={detail} />
                  ) : (
                    <CooperativeActions detail={detail} />
                  )
                }
              />
            ) : null}
          </>
        )}
      </VacationsContext.Provider>
    </PageContainer>
  );
}
