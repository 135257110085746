import styled from 'styled-components';
import { transparentize } from 'polished';
import { tabletWidth } from '~/constants/metrics';

export const StepHolder = styled.div`
  padding: 14px 10%;
  background-color: ${({ theme }) => transparentize(0.5, theme.bodyBackground)};
  border-radius: 4px;

  @media (max-width: ${tabletWidth}) {
    padding: 14px;
  }
`;
