import styled from 'styled-components';
import { Layout } from 'antd';
import { largeDesktopWidth } from '~/constants/metrics';

export const Container = styled(Layout)`
  min-height: 100% !important;
  width: 100%;
  background-color: ${({ theme }) => theme.bodyBackground};
  overflow-x: hidden;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: ${largeDesktopWidth}) {
    padding: 73px 8px 8px 8px;
  }

  @media (min-width: ${largeDesktopWidth}) {
    padding: 77px 0 12px 0;
  }
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ collapsed }) =>
    collapsed ? '100%' : '-webkit-calc(100% - 220px)'};
  width: ${({ collapsed }) => (collapsed ? '100%' : 'moz-calc(100% - 220px)')};
  width: ${({ collapsed }) => (collapsed ? '100%' : 'calc(100% - 220px)')};
  background-color: ${({ theme }) => theme.componentBackground};
  padding: 24px;
  box-shadow: ${({ theme }) => theme.boxShadowBase};
`;
