import styled from 'styled-components';
import { shade } from 'polished';
import { Typography } from 'antd';
import { mobileWidth } from '~/constants/metrics';

export const TabHolder = styled.div`
  text-align: center;
`;

export const InfoHolder = styled.div`
  padding: 12px;
  margin: 16px 0 !important;
  text-align: start;
  background-color: ${({ theme }) => shade(0.04, theme.componentBackground)};
  border-radius: ${({ theme }) => theme.borderRadiusBase};

  .info-description {
    color: ${({ theme }) => theme.textColor};
    font-size: 28px;
    font-weight: bold;
  }
`;

export const Title = styled(Typography.Title)`
  text-align: center;
`;

export const Text = styled(Typography.Text)`
  padding-top: 10px;
`;

export const ModalItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 18px;

  @media (max-width: ${mobileWidth}) {
    flex-direction: column;
  }
`;
