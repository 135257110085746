import React from 'react';
import { Typography } from 'antd';
import safeRead from 'safely-read';
import { CollpaseItemContainer, CollapseItem } from './styles';
import formatPrice from '~/services/utils/formatPrice';
import { CollapsePanelContext } from '../index';

const { Text } = Typography;

export default function InfoHolder() {
  const { user } = React.useContext(CollapsePanelContext);

  return (
    <CollpaseItemContainer>
      <CollapseItem width="38%">
        <Text strong style={{ display: 'block' }}>
          Cargo
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(user, ['position_display', 'position_display'], '--')}
        </Text>
      </CollapseItem>

      <CollapseItem width="20%">
        <Text strong style={{ display: 'block' }}>
          Valor/hora
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(user, ['general', 'hourly_rate'], '--', rate =>
            formatPrice(rate)
          )}
        </Text>
      </CollapseItem>

      <CollapseItem width="20%">
        <Text strong style={{ display: 'block' }}>
          Verba líder
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(
            user,
            ['general', 'leader_budget'],
            'Não',
            verba => `Sim - ${formatPrice(verba)}`
          )}
        </Text>
      </CollapseItem>

      <CollapseItem width="20%">
        <Text strong style={{ display: 'block' }}>
          Verba preposto
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(
            user,
            ['general', 'responsible_agent_budget'],
            'Não',
            verba => `Sim - ${formatPrice(verba)}`
          )}
        </Text>
      </CollapseItem>

      <CollapseItem width="38%">
        <Text strong style={{ display: 'block' }}>
          Produção de pagamento deste mês
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(
            user,
            ['general', 'total_hours'],
            '--',
            value => `${value}h`
          )}
        </Text>
      </CollapseItem>

      <CollapseItem width="20%">
        <Text strong style={{ display: 'block' }}>
          Férias (D.A.R.)
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(
            user,
            ['general', 'annual_leave_hours'],
            '0h',
            value => `${value}h`
          )}
        </Text>
      </CollapseItem>

      <CollapseItem width="20%">
        <Text strong style={{ display: 'block' }}>
          Abonos
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(
            user,
            ['general', 'day_off_hours'],
            '0h',
            value => `${value}h`
          )}
        </Text>
      </CollapseItem>
    </CollpaseItemContainer>
  );
}
