/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import {
  Button,
  Divider,
  Typography,
  Select as AntdSelect,
  Spin,
  Alert,
} from 'antd';

import safelyRead from 'safely-read';
import moment from 'moment';
import Select from '~/components/DataEntry/Select';
import DatePicker from '~/components/DataEntry/DatePicker';
import {
  fieldType,
  getTotalBalance,
  postDayoffRequest,
  calculateLastDayOffOrVacations,
} from '../index';
import { BackgroundContainer, DateContainer } from '../styles';
import { VacationsAllowenceContext } from '../../ManageVacationsAllowences';

const { Text } = Typography;

function DayOff({
  type,
  setVisible,
  selectedUser,
  setFieldValue,
  fetchData,
  fetchTableData,
  values,
  setSubmitting,
  isSubmitting,
  selectedPeriod,
  lastDayOfVacationsOfDayOff,
  lastDayOfVacationsOfDayOffLoading,
  setLastDayOfVacationsOfDayOff,
  setLastDayOfVacationsOfDayOffLoading,
}) {
  const { total_days } = values;

  const { managementDetails, managementDetailsLoading } = useContext(
    VacationsAllowenceContext
  );

  const dayOffs = getTotalBalance(managementDetails, type);
  const paidDays = [];

  // eslint-disable-next-line no-unused-vars
  const CreateArrayOfDaysOff = Array.from({ length: dayOffs }, (v, k) => {
    return paidDays.push({ key: k + 1, value: k + 1 });
  });

  useEffect(() => {
    setFieldValue('begin_date', undefined);
    setFieldValue('total_days', undefined);
  }, [selectedPeriod]);

  useEffect(() => {
    if (type === fieldType.dayOff) {
      if (values.begin_date && values.total_days) {
        calculateLastDayOffOrVacations(
          values.begin_date,
          values.total_days,
          setLastDayOfVacationsOfDayOff,
          setLastDayOfVacationsOfDayOffLoading
        );
      }
    }
  }, [values.begin_date, values.total_days, type]);

  return (
    <>
      {managementDetailsLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {dayOffs === 0 ? (
            <Alert
              message="Esse período aquisitivo está sem saldo para solicitar abono."
              type="info"
              showIcon
            />
          ) : (
            <>
              <BackgroundContainer>
                <Text strong>Saldo atual</Text>

                <Text strong>
                  {`${getTotalBalance(managementDetails, type)}`} dia(s)
                </Text>
              </BackgroundContainer>
              <Field
                id="duration-input"
                name="total_days"
                label="Duração (dias úteis)"
                placeholder="Selecione..."
                component={Select}
                style={{ width: '100%', maxWidth: 280 }}
                data={paidDays}
                disabled={getTotalBalance(managementDetails, type) === 0}
                renderItem={item => (
                  <AntdSelect.Option key={item.key} value={item.value}>
                    {item.value}
                  </AntdSelect.Option>
                )}
                allowClear
              />
              <>
                <DateContainer>
                  <Field
                    id="begin-date-input"
                    name="begin_date"
                    label="Data de início"
                    allowClear={false}
                    component={DatePicker}
                    onChange={item => {
                      setFieldValue(
                        'begin_date',
                        new Date(item._d).toISOString().split('T')[0]
                      );
                    }}
                  />
                </DateContainer>

                {values.begin_date && values.total_days ? (
                  <BackgroundContainer>
                    <Text strong>Data de término do descanso:</Text>
                    {lastDayOfVacationsOfDayOffLoading === false ? (
                      <Text strong>
                        {safelyRead(
                          lastDayOfVacationsOfDayOff,
                          ['last_date'],
                          'Erro ao tentar buscar data de descanso',
                          item => ` ${moment(item).format('DD/MM/YYYY')}`
                        )}
                      </Text>
                    ) : (
                      <Spin />
                    )}
                  </BackgroundContainer>
                ) : null}
              </>
              <BackgroundContainer col>
                <div style={{ width: '100%' }}>
                  <Text strong>Período selecionado</Text>

                  {type === fieldType.dayOff && (
                    <Text strong>{total_days || '0'} dia(s)</Text>
                  )}
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                  <Text strong>Saldo restante após aprovação</Text>

                  {type === fieldType.dayOff && total_days && (
                    <Text strong>
                      {getTotalBalance(managementDetails, type) - total_days}{' '}
                      dia(s)
                    </Text>
                  )}

                  {type === fieldType.dayOff && !total_days && (
                    <Text strong>
                      {getTotalBalance(managementDetails, type)} dia(s)
                    </Text>
                  )}
                </div>
              </BackgroundContainer>
            </>
          )}
        </>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Button onClick={() => setVisible(false)}>Cancelar</Button>

        <>
          <Divider type="vertical" />
          <Button
            loading={isSubmitting}
            onClick={async () => {
              setSubmitting(true);
              await postDayoffRequest(
                selectedUser,
                values,
                setVisible,
                selectedPeriod
              );
              await fetchData();
              await fetchTableData();
              setSubmitting(false);
            }}
            type="primary"
            disabled={!values.begin_date || !values.total_days}
          >
            Solicitar
          </Button>
        </>
      </div>
    </>
  );
}

DayOff.propTypes = {
  type: PropTypes.string.isRequired,
  setVisible: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setSubmitting: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  selectedUser: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchTableData: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  lastDayOfVacationsOfDayOff: PropTypes.objectOf(PropTypes.any).isRequired,
  lastDayOfVacationsOfDayOffLoading: PropTypes.bool.isRequired,
  setLastDayOfVacationsOfDayOff: PropTypes.func.isRequired,
  setLastDayOfVacationsOfDayOffLoading: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    reference_period: undefined,
    total_days: undefined,
    begin_date: undefined,
    receive_advance_money: false,
  }),
})(DayOff);
