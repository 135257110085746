import { message } from 'antd';
import safeRead from 'safely-read';
import moment from 'moment';
import api from '~/services/api';
import {
  CONTROL_CONSOLIDATED_PRODUCTION,
  CONTROL_CONSOLIDATED_PRODUCTION_BY_ID,
} from '~/constants/endpoints/control_consolidated_production';

export async function fetchMonthInfo(year = 2021, setSheetDate) {
  try {
    const { data } = await api.get(CONTROL_CONSOLIDATED_PRODUCTION, {
      params: {
        date_year: year,
      },
    });

    const months = moment.months();

    const sheetDate = data.map((item, index) => {
      const yearMonth = item.reference_date.split('-');

      return {
        id: safeRead(item, ['id']),
        key: index,
        payroll_closing_date: item.payroll_closing_date,
        year: Number(yearMonth[0]),
        month: months[index],
      };
    });

    setSheetDate(sheetDate);
  } catch (error) {
    message.error(`Erro ao carregar informações do ano ${year}.`);
    setSheetDate([]);
  }
}

export function patchControlConsolidadeProduction(controlConsolidated) {
  try {
    Promise.all([
      controlConsolidated.map(async item => {
        await api.patch(CONTROL_CONSOLIDATED_PRODUCTION_BY_ID(item.id), {
          payroll_closing_date: item.selectedDay,
        });
      }),
    ]);
    message.success('Dias de fechamento alterados com sucesso.');
  } catch (error) {
    message.error(`Não foi possivel atualizar os dias de fechamento.`);
  }
}
