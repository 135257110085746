import * as yup from 'yup';

export const confirmPassword = yup.object().shape({
  newPassword: yup
    .string()
    .min(8, 'Nova senha deve conter pelo menos 8 caracteres.')
    .test(
      'Padrão senha',
      'Sua senha deve conter no mínimo 1 letra e 1 número.',
      value => {
        const chars = value.split('') || [];
        let hasNumber = false;
        let hasLetter = false;

        chars.forEach(val => {
          if (val.match(/[a-z]/i)) {
            hasLetter = true;
          }

          if (isNaN(val)) {
            hasNumber = true;
          }
        });

        return hasNumber + hasLetter;
      }
    )
    .required('Nova senha é um campo obrigatório.'),
  confirmPassword: yup
    .string()
    .when('newPassword', (newPassword, field) =>
      newPassword
        ? field
            .required('Confirmação da nova senha é um campo obrigatório.')
            .oneOf(
              [yup.ref('newPassword')],
              'Confirmação da nova deve ser igual a nova senha.'
            )
        : field
    ),
});
