import React, { useEffect, useState } from 'react';
import Table from '~/components/DataDisplay/Table';
import { Modal, Typography, Select, Button, Icon, Divider } from 'antd';
import { getAllQueriesParams } from '~/services/history';
import { MdDelete } from 'react-icons/md';
import { AiFillEdit } from 'react-icons/ai';
import { store } from '~/store';

export const TableView = ({
  teamListData,
  count,
  isLoading,
  onActionTable,
}) => {
  const { page, page_size } = getAllQueriesParams();
  const userPermission = store.getState()?.user?.profile?.permissions;
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Diretor',
      dataIndex: 'diretor',
      key: 'diretor',
    },
    {
      title: 'Coordenador',
      dataIndex: 'coordenador',
      key: 'coordenador',
    },
    {
      title: 'Líder',
      dataIndex: 'leaderName',
      key: 'leaderName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ações',
      key: 'edit',
      render: data => {
        return (
          <div>
            {userPermission.includes('change_squad') && (
              <AiFillEdit
                onClick={() => onActionTable({ key: 'EDIT', data })}
                cursor="pointer"
                fontSize="20px"
              />
            )}

            {
              userPermission.includes('change_squad') && userPermission.includes('delete_squad') && (
                <Divider type="vertical" />

              )
            }


            {userPermission.includes('delete_squad') && (
              <MdDelete
                onClick={() => onActionTable({ key: 'DELETE', data })}
                cursor="pointer"
                fontSize="20px"
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={teamListData}
        columns={columns}
        loading={isLoading}
        paginated
        currentPage={parseInt(page, 10) || 1}
        count={count}
        pageSize={parseInt(page_size, 10) || 10}
      />
    </div>
  );
};
