import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';

import api from '~/services/api';
import renderStatusBadge from '~/services/utils/renderStatusBadge';
import {
  GET_LIST_REQUEST_APPROVAL,
  PATCH_REQUEST_APPROVAL,
} from '~/constants/endpoints/request_approval';
import { TEAM } from '~/constants/endpoints/team';

import history, {
  pushWithQueriesParams,
  getQueryParam,
  getAllQueriesParams,
} from '~/services/history';

export const AbsenceOvertimeContext = React.createContext({
  fetchTableData: () => {},
  setSelectedRowKeys: () => {},
  selectedRowKeys: [],
  tableData: {
    results: [],
    count: 0,
    next: null,
    previous: null,
  },
  loadingTableData: false,
  setDetailModalVisible: () => {},
  setDetailData: () => {},
  detailData: null,
  selectedRowRequestTypes: [],
  setSelectedRowRequestTypes: () => {},
  patchRequestApproval: () => {},
  itemsToUpdateStatus: [],
  setItemsToUpdateStatus: () => {},
  confirmationType: undefined,
  setConfirmationType: () => {},
  confirmationModalVisible: false,
  setConfirmationModalVisible: () => {},
  from: '',
  setFrom: () => {},
});

export async function getListRequestApproval() {
  const { page, page_size, status__in, ...queries } = getAllQueriesParams();

  const defaultResponse = {
    results: [],
    count: 0,
    next: null,
    previous: null,
  };

  try {
    if (status__in === undefined || status__in === null) {
      const { data } = await api.get(GET_LIST_REQUEST_APPROVAL, {
        params: {
          page,
          page_size,
          status__in: 'em_analise,aprovado,cancelado,reprovado,cancelado',
          ...queries,
        },
      });
      return data;
    } else {
      const { data } = await api.get(GET_LIST_REQUEST_APPROVAL, {
        params: {
          page,
          page_size,
          status__in,
          ...queries,
        },
      });
      return data;
    }
  } catch (error) {
    const errorMessage = safeRead(
      error,
      ['response', 'data', 'detail'],
      'Erro ao buscar dados na tabela de Request approval.'
    );
    message.error(errorMessage);

    if (errorMessage === 'Página inválida.') {
      pushWithQueriesParams(history.location.pathname, {
        page: 1,
        page_size,
        ...queries,
      });
    }
  }

  return defaultResponse;
}

export async function patchRequestApproval(items, status, disapproval_reason, items_request_type) {
  const promises = items.map(item => {
    const itemRequestType = getRequestTypeById(item, items_request_type);

    async function approval() {
      try {
        await api.patch(PATCH_REQUEST_APPROVAL(item), {
          status,
          disapproval_reason,
          request_type: itemRequestType,
        });
      } catch (error) {
        message.error(
          safeRead(
            error,
            ['response', 'data', 'detail'],
            `Ocorreu um erro ao aprovar/reprovar item ${item}`
          )
        );
      }
    }

    return approval();
  });

  await Promise.all(promises);
}

export async function fetchTableData(setLoading, setTableData) {
  setLoading(true);
  const data = await getListRequestApproval();
  setTableData(data);
  setLoading(false);
}

export function getRequestTypeById(id, data) {
  const item = data.find(item => item.id === id);
  if (item) {
    return item?.request_type ? item.request_type : item.requestType;
  } else {
    return null;
  }
}

export function useTableData() {
  const page = parseInt(getQueryParam('page'), 10) || null;
  const page_size = parseInt(getQueryParam('page_size'), 10) || null;
  const name = getQueryParam('name');
  const date_start = getQueryParam('date_start');
  const date_end = getQueryParam('date_end');
  const status__in = getQueryParam('status__in');
  const request = getQueryParam('request_type');
  const id = getQueryParam('id');
  const ordering = getQueryParam('ordering');
  const team = getQueryParam('team');


  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setLoadingTableData, setTableData);
    }

    loadTableData();
  }, [
    page,
    page_size,
    name,
    date_start,
    date_end,
    status__in,
    id,
    request,
    ordering,
    team,
  ]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
  };
}

export function formatTableData(data) {
  return (data || []).map(item => ({
    ...item,
    key: safeRead(item, ['id'], '--'),
    id: safeRead(item, ['id'], '--'),
    date: safeRead(item, ['date'], null, val =>
      moment(val).format('DD/MM/YYYY')
    ),
    name: safeRead(item, ['person_display', 'name_display'], '--'),
    request: safeRead(item, ['request_type_display'], '--'),
    raw_status: safeRead(item, ['status'], '--'),
    status_display: safeRead(item, ['status_display'], '--'),
    status: { status: item.status, status_display: item.status_display },
    duration: safeRead(item, ['hours'], '--', val => {
      const numbers = val.split(':');

      return `${numbers[0]}:${numbers[1]}`;
    }),
    reason: safeRead(item, ['reason'], '--'),
    created_at: safeRead(item, ['created_at'], '--', val =>
      moment(val).format('DD/MM/YYYY')
    ),
    disapproval_reason: safeRead(item, ['disapproval_reason'], '--'),
  }));
}

export function makeColumns(
  setDetailModalVisible,
  setDetailData,
  setItemsToUpdateStatus
) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 100,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: 120,
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'first_name,last_name',
      width: 160,
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Solicitação',
      dataIndex: 'request',
      key: 'request',
      ellipsis: true,
      width: 130,
    },
    {
      title: 'Duração',
      dataIndex: 'duration',
      key: 'duration',
      ellipsis: true,
      width: 100,
    },
    // {
    //   title: 'Valor a faturar',
    //   dataIndex: 'value',
    //   key: 'value',
    //   ellipsis: true,
    //   width: 140,
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 140,
      render: renderStatusBadge,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 130,
      render: (_, rowData) => (
        <Button
          onClick={() => {
            setItemsToUpdateStatus([rowData.key]);
            setDetailData(rowData);
            setDetailModalVisible(true);
          }}
          type="link"
        >
          Detalhes
        </Button>
      ),
    },
  ];
}

export function filterSelectedRowKeys(selectedRowKeys, id) {
  return (selectedRowKeys || []).filter(key => key !== id);
}

export function filterSelectedRowRequestTypes(selectedRowRequestTypes, id) {
  return (selectedRowRequestTypes || []).filter(obj => obj.key !== id);
}

export function buildDateRange(date_start, date_end) {
  const date_range = [];

  if (!date_start && date_end) {
    date_range.push(null);
    date_range.push(moment(date_end));

    return date_range;
  }

  if (date_start) date_range.push(moment(date_start));
  if (date_end) date_range.push(moment(date_end));

  return date_range;
}

export function buildDateRangeStringPair(datePair) {
  return datePair
    ? datePair.reduce((result, value) => {
        const date = new Date(value._d).toISOString().split('T')[0];

        result.push(date);

        return result;
      }, [])
    : null;
}

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}
