import { message } from 'antd';
import ConfirmRequest from './ConfirmRequest';
import { REQUEST_UPDATE_ABSENCE_OVERTIME } from '~/constants/endpoints/request';
import badgesStatus from '~/constants/badgesStatus';
import api from '~/services/api';

export async function submitConfirmRequest(
  requestId,
  reason,
  toggleConfirmModal,
  toggleDetailModal,
  fetchDataTable,
  toggleLoading
) {
  const { IN_ANALYSIS } = badgesStatus;

  toggleLoading(true);

  try {
    await api.patch(REQUEST_UPDATE_ABSENCE_OVERTIME(requestId), {
      status: IN_ANALYSIS,
      reason,
    });

    message.success('Solicitação realizada com sucesso!');
    toggleConfirmModal();
    toggleDetailModal();
    fetchDataTable();
  } catch (error) {
    message.error('Ocorreu um erro ao fazer a solicitação');
  }

  toggleLoading(false);
}

export default ConfirmRequest;
