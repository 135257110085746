import axios from 'axios';
import { refreshExpiredToken, setNewAccessToken } from '../auth-manager';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || '',
  headers: {
    // 'X-Frame-Options': 'DENY'
  }
});

api.interceptors.request.use(refreshExpiredToken);

api.interceptors.request.use(setNewAccessToken);

export default api;
