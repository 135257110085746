import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Typography,
  Divider,
  Select as AntdSelect,
} from 'antd';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import safeRead from 'safely-read';
import formatPrice from '~/services/utils/formatPrice';
import checkPermission from '~/services/utils/checkPermission';
import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';

import { editItemBonus } from '.';
import { getHomeGrown } from '../AlterHomeSilver';
import { dataSelectTime } from '../AddHomeSilver';

const { Title } = Typography;

export default function EditHomeSilver({
  toEditVisible,
  setToEditVisible,
  setHomeGrown,
  setIsLoading,
  item,
}) {
  return (
    <Modal
      title={<Title level={4}>Edição de Bônus</Title>}
      footer={null}
      visible={toEditVisible}
      onCancel={() => setToEditVisible(false)}
      centered
      destroyOnClose
    >
      <Formik
        initialValues={{
          required_time: safeRead(item, ['required_time']),
          bonus_6hours: safeRead(item, ['bonus_6hours']),
          bonus_8hours: safeRead(item, ['bonus_8hours']),
          bonus_reward: formatPrice(safeRead(item, ['bonus_reward'])),
        }}
        onSubmit={async values => {
          await editItemBonus(values, item);
          await getHomeGrown(setHomeGrown, setIsLoading);
          setToEditVisible(false);
        }}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={24} type="flex" justify="start">
              <Col sm={24} md={12} lg={12} xl={12}>
                <Field
                  id="required_time"
                  name="required_time"
                  label="Tempo de Casa"
                  component={Select}
                  onChange={status => {
                    setFieldValue('required_time', status);
                  }}
                  data={dataSelectTime}
                  renderItem={items => (
                    <AntdSelect.Option key={items.key} value={items.value}>
                      {items.value}
                    </AntdSelect.Option>
                  )}
                />
              </Col>
              <Col sm={24} md={12} lg={12} xl={12}>
                <Field
                  id="bonus_reward"
                  name="bonus_reward"
                  label="Valor R$ 0,00"
                  component={Input}
                />
              </Col>
            </Row>
            <Row gutter={24} type="flex" justify="start">
              <Col sm={42} md={12} lg={12} xl={12}>
                <Field
                  id="bonus_6hours"
                  name="bonus_6hours"
                  label="Horas Bônus para contrato de 6h"
                  component={Input}
                />
              </Col>
              <Col sm={42} md={12} lg={12} xl={12}>
                <Field
                  id="bonus_8hours"
                  name="bonus_8hours"
                  label="Horas Bônus para contrato de 8h"
                  component={Input}
                />
              </Col>
            </Row>

            <div>
              <Button onClick={() => setToEditVisible(false)}>Cancelar</Button>

              <Divider type="vertical" />
              {checkPermission('change_homegrown') && (
                <Button
                  disabled={!values.bonus_6hours || !values.bonus_8hours}
                  loading={isSubmitting}
                  htmlType="submit"
                  type="primary"
                >
                  Salvar
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

EditHomeSilver.propTypes = {
  toEditVisible: PropTypes.bool.isRequired,
  setToEditVisible: PropTypes.func.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  setHomeGrown: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
