import { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import { TEAM } from '~/constants/endpoints/team';
import { POST_TEAM_HOURS } from '~/constants/endpoints/team_hours';
import { MY_COMPENSATORY_TIME_BY_ID } from '~/constants/endpoints/my_compensatory_time';
import ManageMensalProduction from './ManageMensalProduction';

export function useSearchTeams() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(undefined);
  const [teamsResponse, setTeamsResponse] = useState(null);

  useEffect(() => {
    async function searchTeams() {
      setLoading(true);

      const { data } = await api.get(TEAM, {
        params: {
          page_size: 10000,
          team_name__icontains: search,
        },
      });
      setTeamsResponse(data.results);

      setLoading(false);
    }

    searchTeams();
  }, [search]);

  return {
    teamsResponse,
    setTeamsResponse,
    setSearch,
    loading,
  };
}

export async function fetchCompensatoryTime(id, setCompensatoryTime) {
  try {
    const { data } = await api.get(MY_COMPENSATORY_TIME_BY_ID(id));

    setCompensatoryTime(data);
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar dados')
    );
  }
}

export function useCompensatoryTime(id) {
  const [compensatoryTime, setCompensatoryTime] = useState(undefined);

  useEffect(() => {
    async function loadCompensatoryData() {
      fetchCompensatoryTime(id, setCompensatoryTime);
    }

    loadCompensatoryData();
  }, [id]);

  return {
    compensatoryTime,
    setCompensatoryTime,
  };
}

export async function PostTeamHours(key, month_team_hours, setVisible) {
  try {
    await api.post(POST_TEAM_HOURS, {
      person: key,
      month_team_hours,
    });
    message.success('Produção do mês registrado com sucesso');
    setVisible(false);
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Não foi possivel realizar a registro.'
      )
    );
    setVisible(false);
  }
}

export default ManageMensalProduction;
