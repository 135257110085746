import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import ProductionChart from './ProductionChart';
import safeRead from 'safely-read';

import api from '~/services/api/index';
import { PRODUCTION_CONTROL_PER_TEAM } from '~/constants/endpoints/production_control_per_team';

export const ProductionChartContext = React.createContext({});

export async function fetchChartData(team, dateRange) {
  if (dateRange.length !== 2 || !team) {
    return [];
  }

  try {
    const { data } = await api.get(PRODUCTION_CONTROL_PER_TEAM, {
      params: {
        team_id: team,
        begin_date: dateRange[0],
        end_date: dateRange[1],
      },
    });
    return data;
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao buscar registro de produção'
      )
    );
    return [];
  }
}

export function useChartData() {
  const [team, setTeam] = useState(undefined);
  const [dateRange, setDateRange] = useState([]);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    async function loadChart() {
      const data = await fetchChartData(team, dateRange);
      setChartData(data);
    }

    loadChart();
  }, [team, dateRange]);

  return { team, setTeam, dateRange, setDateRange, chartData, setChartData };
}

export default ProductionChart;
