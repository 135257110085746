import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import {
  Button,
  Select as AntdSelect,
  Row,
  Col,
  Alert,
  Divider,
  Typography,
  Modal,
} from 'antd';
import debounce from 'lodash.debounce';
import { PatchPerson, useSearchProject } from './index';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { setError } from '../ManageVacationsAllowences';

const { Text, Title } = Typography;

export function AssignDefaultTeam({ selectedUser, setVisible, visible }) {
  const { loadingProjects, projects, setSearch } = useSearchProject();

  return (
    <Modal
      title={<Title level={4}>Atribuir equipe padrão</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <Alert
        message="Este cooperado não possui equipe padrão. Escolha uma equipe abaixo."
        showIcon
      />
      <br />
      <Formik
        initialValues={{
          default_team: '',
        }}
        onSubmit={values => {
          PatchPerson(selectedUser, values);
          setVisible(false);
          setError('');
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col sm={8} md={8} lg={8} xl={8} style={{ top: 16 }}>
                  <Text>Equipe principal:</Text>
                </Col>
                <Col sm={10} md={10} lg={10} xl={10} style={{ top: 8 }}>
                  <Field
                    id="default_team"
                    name="default_team"
                    loading={loadingProjects}
                    component={AutoCompleteSelect}
                    selectSchema={item => (
                      <AntdSelect.Option key={item.id} value={item.id}>
                        {item.team_name}
                      </AntdSelect.Option>
                    )}
                    onChange={e => {
                      if (!e) {
                        setFieldValue('default_team', null);
                      } else {
                        setFieldValue('default_team', e);
                      }

                      setSearch(undefined);
                    }}
                    search={debounce(setSearch, 800)}
                    allowClear
                    data={projects.results}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="end">
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="default"
                      onClick={() => {
                        setVisible(false);
                        setError('');
                      }}
                    >
                      Cancelar
                    </Button>
                    <Divider type="vertical" />
                    <Button type="primary" htmlType="submit">
                      Salvar
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

AssignDefaultTeam.propTypes = {
  selectedUser: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
