import styled from 'styled-components';
import { Button as AntdButton } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  background: #fff;
  border-radius: 4px;
`;

export const Button = styled(AntdButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
