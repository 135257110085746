export const GROUPS_REQUEST = '@portal/GROUPS_REQUEST';
export const GROUPS_SUCCESS = '@portal/GROUPS_SUCCESS';
export const GROUPS_FAILURE = '@portal/GROUPS_FAILURE';

export function groupsRequest() {
  return {
    type: GROUPS_REQUEST,
  };
}

export function groupsSuccess(groups) {
  return {
    type: GROUPS_SUCCESS,
    payload: { groups },
  };
}

export function groupsFailure() {
  return {
    type: GROUPS_FAILURE,
  };
}
