/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  Spin,
  Select as AntdSelect,
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import safeRead from 'safely-read';
import debounce from 'lodash.debounce';

import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import {
  Container,
  HistoryContainer,
  CountTableContainer,
  HistoryCountContainer,
  LoadingContainer,
} from './styles';
import VacationsAllowencesHistory from '~/components/DataDisplay/VacationsAllowences/VacationsAllowencesHistory';
import VacationsAllowencesCountTable from '~/components/DataDisplay/VacationsAllowences/VacationsAllowencesCountTable';
import VacationsAllowencesTimeline from '~/components/DataDisplay/VacationsAllowences/VacationsAllowencesTimeline';
import {
  blockDayOffAnnualLeaveRequest,
  useSearchUsers,
  typeError,
  VacationsAllowenceContext,
  useGetManagementPaidLeave,
} from '.';
import { handleDetailModalData, DetailModalAction } from '../..';
import DetailModal from '~/components/DataDisplay/Modals/DetailModal';
import RequestDayOff from '../RequestDayOff';
import AssignDefaultTeam from '../AssignDefaultTeam';
import formatDate from '~/services/utils/formatDate';

const { Title } = Typography;

export default function ManageVacationsAllowences({
  visible,
  setVisible,
  fetchTableData,
  values,
  resetForm,
  setFieldValue,
}) {
  const { loadingUsers, users, setSearch } = useSearchUsers();
  const {
    managementDetails,
    managementDetailsLoading,
    referencePeriod,
    handleSetReferencePeriodQuery,
    handleSetCooperativeId,
    setManagementDetailsLoading,
  } = useGetManagementPaidLeave();

  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [requestDayOffVisible, setRequestDayOffVisible] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(false);
  const [periodStart, setPeriodStart] = useState('');
  const [
    assignDefaultTeamModalVisible,
    setAssignDefaultTeamModalVisible,
  ] = useState(false);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });

  const { usersField, periodField } = values;

  useEffect(() => {
    setPeriodStart((periodField || '').split('/')[0]);
  }, [periodField]);

  const fetchData = async () => {
    if (periodStart) {
      handleSetReferencePeriodQuery('');
      setManagementDetailsLoading(true);
      handleSetReferencePeriodQuery(periodStart);
      setManagementDetailsLoading(false);
      fetchTableData();
    }
  };

  useEffect(() => {
    if (typeError.error_code === 'NEED_A_DEFAULT_TEAM') {
      setAssignDefaultTeamModalVisible(true);
    }
  }, [typeError.error_code]);

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setVisible(false);
        resetForm();
      }}
      title={<Title level={4}>Gerenciar Férias e Abonos</Title>}
      width={isMobileOrTablet ? '95%' : '70%'}
      destroyOnClose
      centered
      footer={false}
    >
      <VacationsAllowenceContext.Provider
        value={{
          managementDetails,
          managementDetailsLoading,
          periodField,
          periodStart,
          handleSetReferencePeriodQuery,
        }}
      >
        <Container>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Field
                id="usersField"
                name="usersField"
                label="Selecione o usuário"
                loading={loadingUsers}
                component={AutoCompleteSelect}
                selectSchema={item => (
                  <AntdSelect.Option key={item.id} value={item.id}>
                    {item.name_display}
                  </AntdSelect.Option>
                )}
                onChange={e => {
                  if (!e) {
                    setFieldValue('usersField', null);
                    setFieldValue('periodField', undefined);
                    handleSetCooperativeId('');
                    handleSetReferencePeriodQuery('');
                  } else {
                    handleSetCooperativeId(e);
                    setFieldValue('usersField', e);
                    setFieldValue('periodField', undefined);
                    handleSetReferencePeriodQuery('');
                  }
                  setSearch(undefined);
                }}
                search={debounce(setSearch, 800)}
                allowClear
                data={users.results}
              />
              <br />
              {usersField && (
                <>
                  {' '}
                  <Field
                    id="periodField"
                    name="periodField"
                    label="Período aquisitivo"
                    loading={loadingUsers}
                    component={AutoCompleteSelect}
                    selectSchema={period => (
                      <AntdSelect.Option
                        key={`${period[0]}-${period[1]}`}
                        value={`${period[0]}/${period[1]}`}
                      >
                        {formatDate(period[0])} - {formatDate(period[1])}
                      </AntdSelect.Option>
                    )}
                    onChange={e => {
                      if (!e) {
                        setFieldValue('periodField', null);
                      } else {
                        const value = e.split('/');
                        handleSetReferencePeriodQuery(value[0]);
                        setFieldValue('periodField', e);
                      }
                    }}
                    allowClear
                    data={referencePeriod}
                  />
                  <br />
                  <Row type="flex">
                    <Button
                      type="primary"
                      disabled={
                        blockDayOffAnnualLeaveRequest(
                          periodField,
                          referencePeriod
                        ) || managementDetailsLoading
                      }
                      onClick={() => setRequestDayOffVisible(true)}
                    >
                      Solicitar Férias/Abonos
                    </Button>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {managementDetailsLoading ? (
            <>
              {usersField && periodField ? (
                <LoadingContainer>
                  <Spin size="large" />
                </LoadingContainer>
              ) : null}
            </>
          ) : (
            <>
              {periodField && managementDetails ? (
                <>
                  <br />
                  <br />
                  <>
                    <VacationsAllowencesTimeline
                      items={safeRead(managementDetails, ['timeline'], [])}
                      periodStart={periodStart}
                    />
                    <HistoryCountContainer>
                      <HistoryContainer>
                        <Title level={4}>Histórico de solicitações</Title>
                        <VacationsAllowencesHistory
                          items={safeRead(
                            managementDetails,
                            ['timeline', 'history'],
                            []
                          )}
                          periodStart={periodStart}
                          actions={histor => (
                            <Button
                              type="link"
                              onClick={() => {
                                setDetailModalVisible(true);
                                setSelectedDetail(histor);
                              }}
                            >
                              Detalhes
                            </Button>
                          )}
                        />
                      </HistoryContainer>
                      <CountTableContainer>
                        <VacationsAllowencesCountTable
                          items={safeRead(
                            managementDetails,
                            ['timeline', 'balance'],
                            []
                          )}
                          periodField={periodField}
                        />
                      </CountTableContainer>
                    </HistoryCountContainer>
                  </>
                </>
              ) : null}
            </>
          )}
        </Container>
        <DetailModal
          title="Detalhes da solicitação"
          visible={detailModalVisible}
          setVisible={setDetailModalVisible}
          data={handleDetailModalData(selectedDetail)}
          actions={
            <DetailModalAction
              data={selectedDetail}
              setDetailModalVisible={() => setDetailModalVisible(false)}
              fetchData={fetchData}
            />
          }
        />
        {requestDayOffVisible && (
          <RequestDayOff
            visible={requestDayOffVisible}
            setVisible={setRequestDayOffVisible}
            selectedUser={usersField}
            referencePeriods={referencePeriod}
            selectedPeriod={periodStart}
            setSelectedPeriod={setPeriodStart}
            fetchData={fetchData}
            fetchTableData={fetchTableData}
          />
        )}
        {assignDefaultTeamModalVisible && (
          <AssignDefaultTeam
            visible={assignDefaultTeamModalVisible}
            setVisible={setAssignDefaultTeamModalVisible}
            selectedUser={usersField}
          />
        )}
      </VacationsAllowenceContext.Provider>
    </Modal>
  );
}

ManageVacationsAllowences.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  fetchTableData: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  resetForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
