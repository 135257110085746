import React, { useState } from 'react';
import { DatePicker as AntDatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import 'moment/locale/pt-br';

const {
  RangePicker: AntRangePicker,
  WeekPicker: AntWeekPicker,
  MonthPicker: AntMonthPicker,
} = AntDatePicker;
const { Item } = Form;

export function RangePicker(props) {
  const { onChange, field, form, label, extra, format, ...restProps } = props;
  const { value, name } = field;
  const { setFieldValue, setFieldTouched, errors, touched } = form;

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      <AntRangePicker
        value={value}
        format={format || 'DD/MM/YYYY'}
        onChange={(dates, dateStrings) => {
          setFieldValue(name, dates);
          setFieldTouched(name, true, false);
          return onChange && onChange(dates, dateStrings);
        }}
        {...restProps}
      />
    </Item>
  );
}

RangePicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  format: PropTypes.string,
  onChange: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
};

RangePicker.defaultProps = {
  label: undefined,
  extra: undefined,
  format: 'DD/MM/YYYY',
  onChange: undefined,
};

export function WeekPicker(props) {
  const { label, extra, format, onChange, field, form, ...restProps } = props;
  const { value, name } = field;
  const { setFieldValue, setFieldTouched, errors, touched } = form;

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      <AntWeekPicker
        name={name}
        value={value}
        onChange={(date, dateString) => {
          setFieldValue(name, date);
          setFieldTouched(name, true, false);
          return onChange && onChange(date, dateString);
        }}
        format={format || 'w-YYYY'}
        {...restProps}
      />
    </Item>
  );
}

WeekPicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  format: PropTypes.string,
  onChange: PropTypes.func,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
};

WeekPicker.defaultProps = {
  label: undefined,
  extra: undefined,
  format: 'w-YYYY',
  onChange: undefined,
};

export function MonthPicker(props) {
  const { label, extra, format, onChange, field, form, ...restProps } = props;
  const { value, name } = field;
  const { setFieldValue, setFieldTouched, errors, touched } = form;

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      <AntMonthPicker
        value={value ? moment(value) : undefined}
        onChange={(date, dateString) => {
          setFieldValue(name, date);
          setFieldTouched(name, true, false);
          return onChange && onChange(date, dateString);
        }}
        format={format || 'MM/YYYY'}
        {...restProps}
      />
    </Item>
  );
}

MonthPicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  format: PropTypes.string,
  onChange: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
};

MonthPicker.defaultProps = {
  label: undefined,
  extra: undefined,
  format: 'MM/YYYY',
  onChange: undefined,
};

export default function DatePicker(props) {
  const {
    label,
    extra,
    format,
    onChange,
    field,
    form,
    mode,
    ...restProps
  } = props;
  const { value, name } = field;
  const { setFieldValue, setFieldTouched, errors, touched } = form;
  const [isOpen, setIsOpen] = useState(false);

  const renderPicker = () => {
    if (mode === 'year') {
      return (
        <AntDatePicker
          value={value}
          open={isOpen}
          onPanelChange={e => {
            setFieldValue(name, e);
            setFieldTouched(name, true, false);
            setIsOpen(false);
            return onChange && onChange(e);
          }}
          onOpenChange={status => (status ? setIsOpen(true) : setIsOpen(false))}
          onChange={() => setFieldValue(name, null)}
          format="YYYY"
          mode={mode}
          {...restProps}
        />
      );
    }

    return (
      <AntDatePicker
        value={value ? moment(value) : undefined}
        onChange={(date, dateString) => {
          setFieldValue(name, date ? date.toISOString() : null);
          setFieldTouched(name, true, false);
          return onChange && onChange(date, dateString);
        }}
        format={format || 'DD/MM/YYYY'}
        {...restProps}
      />
    );
  };

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      {renderPicker()}
    </Item>
  );
}

DatePicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  format: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  mode: PropTypes.string,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
};

DatePicker.defaultProps = {
  label: undefined,
  extra: undefined,
  format: undefined,
  onChange: undefined,
  onBlur: undefined,
  mode: undefined,
};
