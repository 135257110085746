import { message } from 'antd';
import safeRead from 'safely-read';
import ProjectData from './ProjectData';

import api from '~/services/api';
import { TEAM_ID } from '~/constants/endpoints/team';

export async function patchProject(id, values) {
  try {
    await api.patch(TEAM_ID(id), values);

    message.success('Equipe salva com sucesso!');
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao salvar dados do equipe.')
    );
  }
}

export default ProjectData;
