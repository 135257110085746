import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tree, Button, Empty, Checkbox, Typography } from 'antd';

import { store } from '~/store';
import {
  buildTotalLength,
  buildSelectedLength,
  generateTree,
  isAllChecked,
  isIndeterminate,
  isCheckAllDisabled,
  checkAll,
  formatPermissionsData,
  addSelection,
  removeSelection,
} from './index';
import {
  TransferContainer,
  TransferActionsContainer,
  TreeContainer,
  TreeContainerHeader,
} from './styles';

const { Text } = Typography;

export default function Transfer({
  targetKeys,
  setTargetKeys,
  onChange,
  labelLeft,
  labelRight,
}) {
  const [expandedKeysLeft, setExpandedKeysLeft] = useState([]);
  const [expandedKeysRight, setExpandedKeysRight] = useState([]);
  const [checkedKeysLeft, setCheckedKeysLeft] = useState([]);
  const [checkedKeysRight, setCheckedKeysRight] = useState([]);
  const [autoExpandParentLeft, setAutoExpandParentLeft] = useState(true);
  const [autoExpandParentRight, setAutoExpandParentRight] = useState(true);
  const [halfCheckedKeysRight, setHalfCheckedKeysRight] = useState([]);

  const groups = store.getState().groups.data;
  const formattedPermissions = formatPermissionsData(groups);
  const filteredPermissions = formattedPermissions
    .filter(item => item.children.some(el => targetKeys.includes(`${el.key}`)))
    .map(item => ({
      ...item,
      children: item.children.filter(el => targetKeys.includes(`${el.key}`)),
    }));

  return (
    <TransferContainer>
      <TreeContainer>
        <Text type="secondary">{labelLeft}</Text>

        <div className="tree-holder">
          <TreeContainerHeader>
            {`${buildSelectedLength(checkedKeysLeft)}/${buildTotalLength(
              formattedPermissions
            )} itens selecionados`}
          </TreeContainerHeader>

          <Checkbox
            checked={isAllChecked(checkedKeysLeft, formattedPermissions)}
            indeterminate={isIndeterminate(
              checkedKeysLeft,
              formattedPermissions
            )}
            disabled={isCheckAllDisabled(targetKeys, formattedPermissions)}
            onChange={({ target }) =>
              checkAll(target, formattedPermissions, setCheckedKeysLeft)
            }
          >
            Selecionar Tudo
          </Checkbox>

          <Tree
            checkable
            onExpand={keys => {
              setExpandedKeysLeft(keys);
              setAutoExpandParentLeft(false);
            }}
            expandedKeys={expandedKeysLeft}
            autoExpandParent={autoExpandParentLeft}
            checkedKeys={checkedKeysLeft}
            onCheck={keys => {
              setCheckedKeysLeft(keys);
            }}
          >
            {generateTree(formattedPermissions, targetKeys)}
          </Tree>
        </div>
      </TreeContainer>

      <TransferActionsContainer>
        <Button
          onClick={() =>
            addSelection(
              setTargetKeys,
              setCheckedKeysLeft,
              checkedKeysLeft,
              onChange
            )
          }
          style={{ width: 160 }}
          disabled={checkedKeysLeft.length === 0}
          type="primary"
        >
          {'Adicionar Seleção >'}
        </Button>

        <Button
          onClick={() =>
            removeSelection(
              setTargetKeys,
              setCheckedKeysRight,
              setHalfCheckedKeysRight,
              checkedKeysRight,
              halfCheckedKeysRight,
              onChange
            )
          }
          style={{ width: 160 }}
          disabled={checkedKeysRight.length === 0}
          type="primary"
        >
          {'< Remover Seleção'}
        </Button>
      </TransferActionsContainer>

      <TreeContainer>
        <Text type="secondary">
          {labelRight.split(' ')[0]}{' '}
          <Text strong type="secondary">
            {labelRight.replace('Perfil', '')}
          </Text>
        </Text>

        <div className="tree-holder">
          <TreeContainerHeader>
            {`${buildSelectedLength(checkedKeysRight)}/${buildTotalLength(
              filteredPermissions
            )} itens selecionados`}
          </TreeContainerHeader>

          {filteredPermissions.length > 0 && (
            <Checkbox
              checked={isAllChecked(checkedKeysRight, filteredPermissions)}
              indeterminate={isIndeterminate(
                checkedKeysRight,
                filteredPermissions
              )}
              onChange={({ target }) =>
                checkAll(target, filteredPermissions, setCheckedKeysRight)
              }
            >
              Selecionar Tudo
            </Checkbox>
          )}

          {filteredPermissions.length > 0 ? (
            <Tree
              checkable
              onExpand={keys => {
                setExpandedKeysRight(keys);
                setAutoExpandParentRight(false);
              }}
              expandedKeys={expandedKeysRight}
              autoExpandParent={autoExpandParentRight}
              checkedKeys={checkedKeysRight}
              onCheck={(keys, { halfCheckedKeys }) => {
                setCheckedKeysRight(keys);
                setHalfCheckedKeysRight(halfCheckedKeys);
              }}
              treeData={filteredPermissions}
            />
          ) : (
            <Empty
              style={{ margin: 'auto' }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
      </TreeContainer>
    </TransferContainer>
  );
}

Transfer.propTypes = {
  targetKeys: PropTypes.arrayOf(PropTypes.any).isRequired,
  setTargetKeys: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  labelLeft: PropTypes.string.isRequired,
  labelRight: PropTypes.string.isRequired,
};
