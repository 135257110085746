import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import history from '~/services/history';
import UserRegistry from './UserRegistry';
import api from '~/services/api';
import {
  GET_PERSON_BY_ID,
  DELETE_PERSON_BY_SCHEDULING,
} from '~/constants/endpoints/person';
import { TEAM, TEAM_ID } from '~/constants/endpoints/team';
import { MY_COMPENSATORY_TIME_BY_ID } from '~/constants/endpoints/my_compensatory_time';
import { COMPENSATORY_TIME } from '~/constants/endpoints/compensatory_time';
import { BUDGET } from '~/constants/endpoints/budget';

export const UserRegistryContext = React.createContext({});

export async function fetchData(setUserData, userID, setLoadingUserData) {
  setLoadingUserData(true);
  try {
    const { data } = await api.get(GET_PERSON_BY_ID(userID));

    setUserData(data);
    setLoadingUserData(false);
  } catch (error) {
    history.push('/404');
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao buscar informações do usuário'
      )
    );
    setUserData({});
    setLoadingUserData(false);
  }
}

export async function fetchDeleteScheduling(
  userID,
  setLoadingData,
  setUserData,
  setLoadingUserData
) {
  setLoadingData(true);
  try {
    await api.delete(DELETE_PERSON_BY_SCHEDULING(userID));

    fetchData(setUserData, userID, setLoadingUserData);
    message.success('Agendamento deletado com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao buscar informações do usuário'
      )
    );
  } finally {
    setLoadingData(false);
  }
}

export function useUserData(id) {
  const [userData, setUserData] = useState({});
  const [loadingUserData, setLoadingUserData] = useState(false);

  useEffect(() => {
    async function loadUserData() {
      await fetchData(setUserData, id, setLoadingUserData);
    }

    loadUserData();
  }, [id]);

  return {
    userData,
    setUserData,
    loadingUserData,
    setLoadingUserData,
    fetchData: () => fetchData(setUserData, id, setLoadingUserData),
  };
}

export const handleModalVisible = (setVisible, setModalType, modalType) => {
  setModalType(modalType);
  setVisible(prevState => !prevState);
};

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar equipes')
    );
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export async function getProjectById(id) {
  let response;

  try {
    const { data } = await api.get(TEAM_ID(id));

    response = data;
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar equipes.')
    );
  }

  return response;
}

export function useProjectData(id) {
  const [project, setProject] = useState(undefined);
  const [loadingProject, setLoadingProject] = useState(false);

  useEffect(() => {
    async function loadProjectData() {
      setLoadingProject(true);

      const data = await getProjectById(id);

      setProject(data);

      setLoadingProject(false);
    }

    loadProjectData();
  }, [id]);

  return { project, setProject, loadingProject };
}

export async function fetchCompensatoryTime(id, setCompesantoryTime) {
  try {
    const { data } = await api.get(MY_COMPENSATORY_TIME_BY_ID(id));

    setCompesantoryTime(data);
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar dados')
    );
    setCompesantoryTime({});
  }
}

export function useCompensatoryTime(id) {
  const [compensatoryTime, setCompesatoryTime] = useState('');

  useEffect(() => {
    async function loadCompensatoryData() {
      await fetchCompensatoryTime(id, setCompesatoryTime);
    }

    loadCompensatoryData();
  }, [id]);

  return {
    id,
    compensatoryTime,
    setCompesatoryTime,
    fetchCompensatoryTime: () => fetchCompensatoryTime(id, setCompesatoryTime),
  };
}

export async function fetchCompensatoryTimeHistory(
  id,
  setCompesantoryTimeHystory
) {
  try {
    const { data } = await api.get(COMPENSATORY_TIME, {
      params: { person: id },
    });

    setCompesantoryTimeHystory(data);
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar dados.')
    );
    setCompesantoryTimeHystory([]);
  }
}

export function useCompensatoryTimeHistory(id) {
  const [compensatoryTimeHistory, setCompesantoryTimeHystory] = useState([]);

  useEffect(() => {
    async function loadCompensatoryHistoryData() {
      await fetchCompensatoryTimeHistory(id, setCompesantoryTimeHystory);
    }

    loadCompensatoryHistoryData();
  }, [id]);

  return {
    id,
    compensatoryTimeHistory,
    setCompesantoryTimeHystory,
    fetchCompensatoryTimeHistory: () =>
      fetchCompensatoryTimeHistory(id, setCompesantoryTimeHystory),
  };
}

export async function budget(verba, setTooltipVisible) {
  const defaultResponse = [];
  try {
    const { data } = await api.get(BUDGET(verba));
    return data;
  } catch (error) {
    setTooltipVisible(false);
    return defaultResponse;
  }
}

export function useBudget() {
  const [budgetLeader, setBudgetLeader] = React.useState(undefined);
  const [budgetPrepository, setBudgetPrepository] = React.useState(undefined);
  const [tooltipVisible, setTooltipVisible] = React.useState(true);

  useEffect(() => {
    const LoadBudgetKind = async () => {
      const verbaLiderData = await budget('verba_lider', setTooltipVisible);
      const verbaPrepostoData = await budget(
        'verba_preposto',
        setTooltipVisible
      );
      setBudgetLeader(verbaLiderData);
      setBudgetPrepository(verbaPrepostoData);
    };
    LoadBudgetKind();
  }, []);

  return {
    budgetLeader,
    setBudgetLeader,
    budgetPrepository,
    setBudgetPrepository,
    tooltipVisible,
  };
}

export default UserRegistry;
