import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Select, Radio, Typography } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import PageContainer from '~/components/Layout/PageContainer';

import { toggleTheme } from '~/store/modules/theme/actions';
import { changeLocale } from '~/store/modules/language/actions';

import messages from './messages';

const { Option } = Select;
const { Title } = Typography;

function Settings({ intl }) {
  const dispatch = useDispatch();
  const { colorScheme } = useSelector(state => state.theme);
  const { locale } = useSelector(state => state.language);

  return (
    <PageContainer title={intl.formatMessage(messages.settings)}>
      <Title level={3}>
        <FormattedMessage {...messages.themeLabel} />
      </Title>
      <Radio.Group
        onChange={() => dispatch(toggleTheme())}
        value={colorScheme === 'dark' ? 'dark' : 'light'}
      >
        <Radio value="light">{intl.formatMessage(messages.lightTheme)}</Radio>
        <br />
        <Radio value="dark">{intl.formatMessage(messages.darkTheme)}</Radio>
      </Radio.Group>

      <Divider />

      <Title level={3}>
        <FormattedMessage {...messages.languageLabel} />
      </Title>
      <Select
        style={{ width: 200 }}
        placeholder={intl.formatMessage(messages.selectLanguagePlaceHolder)}
        onChange={e => dispatch(changeLocale(e))}
        optionFilterProp="children"
        value={locale}
      >
        <Option value="pt-BR">Português</Option>
        <Option value="en-US">English</Option>
      </Select>
    </PageContainer>
  );
}

Settings.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Settings);
