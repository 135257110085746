import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'antd';
import { Formik, Form, Field } from 'formik';
import debounce from 'lodash.debounce';

import checkPermission from '~/services/utils/checkPermission';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { useSearchPersons } from './index';
import { Container } from './styles';

export default function SearchPersons({
  mode,
  params,
  onSubmit,
  actionTitle,
  label,
  permissions = '',
}) {
  const { loading, setSearch, persons } = useSearchPersons(params);

  return (
    <Formik
      initialValues={{
        users: mode === 'multiple' ? [] : undefined,
      }}
      onSubmit={async ({ users }, { setSubmitting, resetForm }) => {
        await onSubmit(users);

        resetForm();

        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Container>
            <Field
              id="users-input-select"
              name="users"
              label={label || 'Usuários'}
              mode={mode}
              loading={loading}
              component={AutoCompleteSelect}
              selectSchema={({ id, name_display, groups }) => (
                <Select.Option key={id} value={id} groups={groups}>
                  {name_display}
                </Select.Option>
              )}
              onChange={e => {
                setFieldValue('users', e);
                setSearch(undefined);
              }}
              search={debounce(setSearch, 800)}
              allowClear
              data={persons.results}
              style={{ width: '100%' }}
            />

            <div style={{ width: 20 }} />

            {(checkPermission(permissions) || permissions === '') && (
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                disabled={
                  mode === 'multiple'
                    ? values.users.length === 0
                    : !values.users
                }
              >
                {actionTitle}
              </Button>
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
}

SearchPersons.defaultProps = {
  mode: undefined,
  params: undefined,
  label: undefined,
  permissions: '',
};

SearchPersons.propTypes = {
  mode: PropTypes.string,
  params: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func.isRequired,
  actionTitle: PropTypes.string.isRequired,
  label: PropTypes.string,
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
