import React, { useState } from 'react';
import safeRead from 'safely-read';
import { Button, Row, Col, DatePicker, Select, Alert } from 'antd';
import Table from '~/components/DataDisplay/Table';
import { makeColumns, formatTableData, useTableData } from './index';
import AlterHomeSilver from '../Modals/AlterHomeSilver';
import AddHomeSilver from '../Modals/AddHomeSilver';
import EditHomeSilver from '../Modals/EditHomeSilver';
import UploadPositionsModal from '../Modals/UploadPositionsModal/UploadPositionsModal';
import { getAllQueriesParams } from '~/services/history';
import checkPermission from '~/services/utils/checkPermission';
import DetailPerson from '../Modals/DetailPerson/DetailPerson';
import { EXPORT_HOME_GROWN_SHEETXLSX, PERSON_HOME_GROWN } from '~/constants/endpoints/home_grown';
import api from '~/services/api';

export default function UserTable() {
  const { tableData, loadingTableData, fetchTableData } = useTableData();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [registerVisible, setRegisterVisible] = useState(false);
  const [toEditVisible, setToEditVisible] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [homeGrown, setHomeGrown] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState({});
  const [person_id, setPerson_id] = useState(null);
  const [personModalVisible, setPersonModalVisible] = useState(false);
  const [uploadModalPositionsVisible, setUploadModalPositionsVisible] = useState(false);
  const [status, setStatus] = useState();
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);
  const { page, page_size } = getAllQueriesParams();
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  function compressDetail(item) {
    setPerson_id(item);
    setPersonModalVisible(true);
  }
  function onCloseModal() {
    setPerson_id(null);
    setPersonModalVisible(false);
  }
  function onChange(value, dateString) {
    setBeginDate(dateString[0]);
    setEndDate(dateString[1]);
  }
  function handleChange(value) {
    setStatus(value);
  }
  async function getSheet() {
    var response = await api({
      url: EXPORT_HOME_GROWN_SHEETXLSX,
      method: 'GET',
      responseType: 'blob',
      params: { status, begin_date: beginDate, end_date: endDate },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `Relatorio-Prata-da-Casa.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  return (
    <>
      <Row style={{height: 50}} gutter={[8, 8]} type="flex" justify="start">
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          {(checkPermission('add_homegrown') || checkPermission('change_homegrown')) && (
            <Button style={{ width: 200 }}
              type="primary"
              block
              onClick={() => setUploadModalVisible(true)}
            >
              Configurar Prata da Casa
            </Button>
          )}
        </Col>
      </Row>
      <Row gutter={[8, 8]} type="flex" justify="start">
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          {checkPermission('add_person') && (
            <>
              <div>
                Status:
              </div>
              <Select defaultValue="" style={{ width: 220 }} onChange={handleChange}>
                <Option value="ativo">Ativo</Option>
                <Option value="desligado">Desligado</Option>
              </Select>
              <br />
            </>
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          <div>
            Data:
          </div>
          <RangePicker
            format="DD/MM/YYYY"
            placeholder={['Data Inicial', 'DataFinal']}
            onChange={onChange}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
          <br />
          {checkPermission('view_personhomegrown') && (
          <Button style={{ width: 200, borderColor: '#1DA57A', color: '#1DA57A' }}
            block
            onClick={() => getSheet()}
          >
            Exportar Excel
          </Button>
          )}
        </Col>

        <Col xs={24} sm={24} md={12} lg={10} xl={6}>
        <Alert message="Relatório Operacional: Relatório que exporta a planilha por período" type="info" showIcon />
        </Col>
      </Row>

      <br />
      <Table
        dataSource={formatTableData(tableRows)}
        columns={makeColumns(compressDetail)}
        paginated
        loading={loadingTableData}
        currentPage={parseInt(page, 10) || 1}
        count={tableCount}
        pageSize={parseInt(page_size, 10) || 10}
        scroll={{ x: 120 }}
      />

      {uploadModalPositionsVisible ? (
        <UploadPositionsModal
          uploadModalVisible={uploadModalPositionsVisible}
          setUploadModalVisible={setUploadModalPositionsVisible}
          fetchTableData={fetchTableData}
        />
      ) : null}

      {uploadModalVisible ? (
        <AlterHomeSilver
          uploadModalVisible={uploadModalVisible}
          setUploadModalVisible={setUploadModalVisible}
          setRegisterVisible={setRegisterVisible}
          setToEditVisible={setToEditVisible}
          homeGrown={homeGrown}
          setHomeGrown={setHomeGrown}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setItem={setItem}
        />
      ) : null}

      {registerVisible ? (
        <AddHomeSilver
          setRegisterVisible={setRegisterVisible}
          registerVisible={registerVisible}
          setIsLoading={setIsLoading}
          setHomeGrown={setHomeGrown}
        />
      ) : null}

      {toEditVisible && item ? (
        <EditHomeSilver
          toEditVisible={toEditVisible}
          setToEditVisible={setToEditVisible}
          setIsLoading={setIsLoading}
          setHomeGrown={setHomeGrown}
          item={item}
        />
      ) : null}

      {detailVisible ? (
        <EditHomeSilver
          detailVisible={detailVisible}
          setDetailVisible={setDetailVisible}
          setIsLoading={setIsLoading}
          setHomeGrown={setHomeGrown}
          item={personModalVisible}
        />
      ) : null}

      <DetailPerson
        personModalVisible={personModalVisible}
        setPersonModalVisible={onCloseModal}
        person_id={person_id}
      />

    </>
  );
}
