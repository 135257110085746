import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Divider } from 'antd';

import SearchForm from './SearchForm';
import UsersOnThisProfile from './UsersOnThisProfile';
import { ProfileContext } from '../../index';
import { useUsersOnThisProfile, AddUserContext, updateUsers } from './index';

export default function AddUser({ visible, setVisible }) {
  const { currentRow, setCurrentRow, fetchTableData } = useContext(
    ProfileContext
  );

  const {
    users,
    filteredUsers,
    setFilteredUsers,
    setUsers,
    loading,
    pageSize,
    setPageSize,
    search,
    setSearch,
  } = useUsersOnThisProfile(currentRow.key);

  const { Title, Text } = Typography;

  useEffect(() => {
    return () => setCurrentRow(undefined);
  }, [setCurrentRow, setVisible]);

  return (
    <Modal
      title={<Title level={4}>Atribuir usuários</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <AddUserContext.Provider
        value={{
          profile_id: currentRow.key,
          fetchTableData,
          users,
          filteredUsers,
          setFilteredUsers,
          setUsers,
          loading,
          pageSize,
          setPageSize,
          search,
          setSearch,
          updateUsers: async () => {
            await updateUsers(currentRow.key, setUsers, setFilteredUsers);
          },
        }}
      >
        <Text type="secondary">Perfil:</Text>
        <Title style={{ margin: '10px 0 20px 0' }} level={4}>
          {currentRow.name}
        </Title>

        <SearchForm />

        <Divider />

        <UsersOnThisProfile />
      </AddUserContext.Provider>
    </Modal>
  );
}

AddUser.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
