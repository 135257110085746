import React from 'react';
import { Col, Row, Button, Divider, List, Typography } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import { BudgetHolder } from './styles';
import BudgetModal from './Modals/BudgetModal';
import formatPrice from '~/services/utils/formatPrice';

import PageContainer from '~/components/Layout/PageContainer';
import {
  handleModalVisible,
  useBudgetHistory,
  useBudget,
  BudgetContext,
  budgetAmount,
  BudgetHistory,
  budget,
} from './index';

export default function Budget() {
  const [visible, setVisible] = React.useState(false);
  const [modalType, setModalType] = React.useState(undefined);
  const { Title, Text } = Typography;
  const {
    budgetleaderHistory,
    setBudgetleaderHistory,
    loadingHistory,
    budgetPrepositoryHistory,
    setBudgetPrepositoryHistory,
  } = useBudgetHistory();
  const {
    budgetleader,
    setBudgetleader,
    budgetPrepository,
    setBudgetPrepository,
  } = useBudget();

  return (
    <PageContainer title="Verbas">
      <BudgetContext.Provider
        value={{
          budget,
          budgetAmount,
          BudgetHistory,
          handleModalVisible,
          modalType,
          visible,
          setVisible,
          budgetleader,
          budgetPrepository,
          setBudgetPrepository,
          setBudgetPrepositoryHistory,
          setBudgetleaderHistory,
          setBudgetleader,
        }}
      >
        <Row gutter={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <BudgetHolder>
              <Title level={2}>Verba líder</Title>

              <Text level={4}>Valor atual:</Text>
              <Col>
                <Title level={2}>
                  {safeRead(
                    budgetleader,
                    ['amount'],
                    '--,--',
                    amount => `${formatPrice(amount)}/h`
                  )}
                </Title>
              </Col>
              <Col>
                <Button
                  id="budget-leader-btn"
                  type="primary"
                  onClick={() =>
                    handleModalVisible(setVisible, setModalType, 'verba_lider')
                  }
                >
                  Editar
                </Button>
              </Col>
              <Divider />
              <Text type="secondary">Histórico de alterações:</Text>
              <List
                loading={loadingHistory}
                size="small"
                grid="none"
                dataSource={safeRead(budgetleaderHistory, ['results'])}
                renderItem={item => (
                  <List.Item>
                    <Text>
                      <Row
                        gutter={8}
                        type="flex"
                        justify="center"
                        aligh="center"
                      >
                        <Col>{moment(item.timestamp).format('DD/MM/YYYY')}</Col>
                        <Col>
                          <Text type="secondary"> de </Text>
                        </Col>
                        <Col>
                          {safeRead(
                            item,
                            ['from_amount'],
                            '--,--',
                            amount => `${formatPrice(amount)}/h`
                          )}
                        </Col>
                        <Col>
                          <Text type="secondary">para</Text>
                        </Col>

                        <Col>
                          {safeRead(
                            item,
                            ['to_amount'],
                            '--,--',
                            amount => `${formatPrice(amount)}/h`
                          )}
                        </Col>
                      </Row>
                    </Text>
                  </List.Item>
                )}
              />
            </BudgetHolder>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <BudgetHolder>
              <Title level={2}>Verba preposto</Title>
              <Text>Valor atual:</Text>
              <Col>
                <Title level={2}>
                  {safeRead(budgetPrepository, ['amount'], '--,--', amount =>
                    formatPrice(amount)
                  )}
                </Title>
              </Col>
              <Col>
                <Button
                  id="budget-prepository-btn"
                  type="primary"
                  onClick={() =>
                    handleModalVisible(
                      setVisible,
                      setModalType,
                      'verba_preposto'
                    )
                  }
                >
                  Editar
                </Button>
              </Col>
              <Divider />
              <Text type="secondary">Histórico de alterações:</Text>
              <List
                loading={loadingHistory}
                size="small"
                grid="none"
                dataSource={budgetPrepositoryHistory.results}
                renderItem={item => (
                  <List.Item>
                    <Text>
                      <Row
                        gutter={12}
                        type="flex"
                        justify="center"
                        aligh="center"
                      >
                        <Col>{moment(item.timestamp).format('DD/MM/YYYY')}</Col>
                        <Col>
                          <Text type="secondary">de</Text>
                        </Col>
                        <Col>
                          {safeRead(item, ['from_amount'], '--,--', amount =>
                            formatPrice(amount)
                          )}
                        </Col>
                        <Col>
                          <Text type="secondary">para</Text>
                        </Col>
                        <Col>
                          {safeRead(item, ['to_amount'], '--,--', amount =>
                            formatPrice(amount)
                          )}
                        </Col>
                      </Row>
                    </Text>
                  </List.Item>
                )}
              />
            </BudgetHolder>
          </Col>
          {visible ? (
            <BudgetModal visible={visible} setVisible={setVisible} />
          ) : null}
        </Row>
      </BudgetContext.Provider>
    </PageContainer>
  );
}
