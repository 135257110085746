import { useState } from 'react';
import { message } from 'antd';
import WorkInfo from './WorkInfo';
import { getAllQueriesParams } from '~/services/history';
import api from '~/services/api';
import {
  WORK_MONTH_SUMMARY,
  WORK_MONTH_SUMMARY_MANAGE,
} from '~/constants/endpoints/work_month_summary';
import { useDeepCompareEffect } from 'react-use';
import { useSelector } from 'react-redux';

export function useWorkInfoData() {
  const { cooperative_member_id, year, month } = getAllQueriesParams();

  const { data: productionData } = useSelector(state => state.production);
  const [workInfo, setWorkInfo] = useState(undefined);

  useDeepCompareEffect(() => {
    async function loadWorkInfo() {
      try {
        if ((cooperative_member_id && year && month) || (year && month)) {
          const { data } = await api.get(
            cooperative_member_id
              ? WORK_MONTH_SUMMARY_MANAGE(cooperative_member_id, year, month)
              : WORK_MONTH_SUMMARY(year, month)
          );

          setWorkInfo(data);
        }
      } catch (error) {
        message.error('Erro ao carregar workinfo');
      }
    }

    loadWorkInfo();
  }, [year, month, cooperative_member_id, productionData]);

  return workInfo;
}

export default WorkInfo;
