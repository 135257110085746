import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox as AntCheckbox } from 'antd';

const { Item } = Form;

function getValidationStatus(field, form) {
  const { errors, touched, submitCount } = form;
  const { name } = field;

  if (touched[name] || submitCount > 0) {
    return errors[name] ? 'error' : 'success';
  }

  return '';
}

function getHelpMessage(field, form) {
  const { errors, touched, submitCount } = form;
  const { name } = field;

  if (touched[name] || submitCount > 0) {
    return errors[name];
  }

  return '';
}

export default function Input(props) {
  const { id, label, field, form, helpMessage, disabled, onChange } = props;

  const { setFieldValue, setFieldTouched } = form;

  return (
    <label htmlFor={id}>
      <Item
        validateStatus={getValidationStatus(field, form)}
        help={getHelpMessage(field, form)}
        extra={helpMessage}
      >
        <AntCheckbox
          id={id}
          name={field.name}
          onChange={event => {
            setFieldValue(field.name, event.target.checked);
            setFieldTouched(field.name, true, false);
            // eslint-disable-next-line no-unused-expressions
            onChange && onChange(event);
          }}
          onBlur={field.onBlur}
          checked={field.value}
          disabled={disabled}
        >
          {label}
        </AntCheckbox>
      </Item>
    </label>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  helpMessage: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  helpMessage: '',
  disabled: false,
  onChange: undefined,
};
