import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spin, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import SearchPersons from '~/components/DataEntry/SearchPersons';
import Person from '../shared/Person';
import Search from '../shared/Search';
import { addPersons, deletePerson } from './index';
import { Container } from './styles';
import { usePersons } from '../../index';

const { Title } = Typography;
const { confirm } = Modal;

export default function Users({ project }) {
  const [pageSize, setPageSize] = useState(5);
  const [name, setName] = useState(undefined);
  const { id } = project;
  const { persons, loadingPersons, updatePersons } = usePersons({
    page_size: pageSize,
    page: 1,
    teams: id,
    name,
  });

  return (
    <Container>
      <Title level={4}>Escolha os usuários desta Equipe</Title>

      <SearchPersons
        actionTitle="Atribuir"
        mode="multiple"
        permissions="add_team"
        params={{ teams__exclude: id }}
        onSubmit={async values => {
          await addPersons(id, values);
          await updatePersons();
        }}
      />

      <Search loading={loadingPersons} setSearch={setName} />

      <InfiniteScroll
        dataLength={persons.results.length}
        next={() => setPageSize(prev => prev + 5)}
        hasMore={persons.next !== null}
        loader={
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        }
        height="154px"
      >
        {persons.results.map(({ id: personId, name_display }) => (
          <Person
            key={personId}
            name_display={name_display}
            onRemove={() =>
              confirm({
                centered: true,
                title: `Remover ${name_display} como usuário?`,
                onOk: async () => {
                  await deletePerson(id, personId);

                  await updatePersons();
                },
              })
            }
          />
        ))}
      </InfiniteScroll>
    </Container>
  );
}

Users.defaultProps = {
  project: undefined,
};

Users.propTypes = {
  project: PropTypes.objectOf(PropTypes.any),
};
