import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import safelyRead from 'safely-read';
import { RangePicker } from '~/components/DataEntry/DatePicker';
import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';
import {
  buildDateRange,
  buildDateRangeStringPair,
} from '~/services/utils/dateRangeUtils';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
  getQueryParam,
} from '~/services/history';

function Filters() {
  const { date_start, date_end, ...queries } = getAllQueriesParams();

  return (
    <>
      <Formik
        initialValues={{
          ...queries,
          dateRange: buildDateRange(date_start, date_end),
        }}
        onSubmit={data => {
          const [builtDateStart, builtDateEnd] = buildDateRangeStringPair(
            safelyRead(data, 'dateRange', [])
          );

          delete data.dateRange;

          pushWithQueriesParams(history.location.pathname, {
            ...queries,
            ...data,
            date_start: builtDateStart,
            date_end: builtDateEnd,
            page: 1,
          });
        }}
      >
        {({ handleSubmit, setValues, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={4} md={4} lg={4} xl={4}>
                <Field id="id-input" name="id" label="ID" component={Input} />
              </Col>

              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                <Field
                  id="name-input"
                  name="person_name"
                  label="Nome"
                  component={Input}
                />
              </Col>

              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                <Field
                  id="date-input"
                  name="dateRange"
                  label="Data"
                  component={RangePicker}
                />
              </Col>

              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                <Field
                  id="status-input"
                  name="request_type"
                  label="Solicitação"
                  allowClear
                  component={Select}
                  onChange={e => setFieldValue('request_type', e)}
                  data={[
                    {
                      value: 'pacote',
                      label: 'Pacote',
                    },
                    {
                      value: 'ausencia_a_descontar',
                      label: 'Ausência a descontar',
                    },
                    {
                      value: 'ausencia_a_abonar',
                      label: 'Ausência a abonar',
                    },
                    {
                      value: 'hora_extra_a_faturar',
                      label: 'Hora extra a faturar',
                    },
                  ]}
                  renderItem={item => (
                    <AntdSelect.Option key={item.value} value={item.value}>
                      {item.label}
                    </AntdSelect.Option>
                  )}
                />
              </Col>

              <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                <Field
                  id="status-input"
                  name="status"
                  label="Status"
                  allowClear
                  component={Select}
                  onChange={() => {}}
                  data={[
                    {
                      value: 'em_analise_financeira',
                      label: 'Em Analise Financeira',
                    },
                    {
                      value: 'aprovado_pelo_financeiro',
                      label: 'Aprovado Pelo Financeiro',
                    },
                    {
                      value: 'reprovado_pelo_financeiro',
                      label: 'Reprovado Pelo Financeiro',
                    },
                    {
                      value: 'cancelado',
                      label: 'Cancelado',
                    },
                  ]}
                  renderItem={item => (
                    <AntdSelect.Option key={item.value} value={item.value}>
                      {item.label}
                    </AntdSelect.Option>
                  )}
                />
              </Col>
            </Row>

            <Row type="flex" justify="end">
              <Col>
                <Button
                  onClick={() => {
                    pushWithQueriesParams(history.location.pathname, {
                      page: 1,
                      page_size: Number(getQueryParam('page_size')) || 10,
                    });

                    setValues({
                      dateRange: undefined,
                      id: undefined,
                      person_name: undefined,
                      status: undefined,
                      request_type: undefined,
                    });
                  }}
                >
                  Limpar
                </Button>

                <Divider type="vertical" />

                <Button
                  htmlType="submit"
                  type="primary"
                  icon="search"
                  disabled={
                    !values.id &&
                    !values.person_name &&
                    !values.dateRange &&
                    !values.status &&
                    !values.request_type
                  }
                >
                  Pesquisar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Divider />
    </>
  );
}

export default Filters;
