export const TEAM = '/team/';
export const SQUAD = '/squad/';
export const TEAM_ID = id => `/team/${id}/`;
export const TEAM_MANAGE_ID = id => `/team/${id}/manage`;
export const DELETE_TEAM_MANAGER = (id, manager_id) =>
  `/team/${id}/supervisor/${manager_id}`;
export const DELETE_TEAM_PERSON = (id, person_id) =>
  `/team/${id}/person/${person_id}`;
export const TEAM_PRODUCTION = (team_id) =>
  `/team_production_history/${team_id}/manage`;
