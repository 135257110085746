import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import safeRead from 'safely-read';
import moment from 'moment';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Skeleton from '~/components/DataDisplay/Skeleton';
import PageContainer from '~/components/Layout/PageContainer';

import { ListItem, AvatarHolder, EditPhoto } from './styles';
import messages from './messages';
import ChangePassword from './ChangePassword';
import formatPrice from '~/services/utils/formatPrice';
import UploadAvatarModal from './Modal/ChangeAvatar';
import { useWindowScroll } from 'react-use';

export default function Profile() {
  const profile = useSelector(store => store.user.profile);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [hourlyRateVisible, setHourlyRateVisible] = useState(true);
  const handleChangePasswordVisible = () => setChangePasswordVisible(false);
  const [uploadModalAvatarVisible, setUploadModalAvatarVisible] = useState(
    false
  );

  return (
    <PageContainer title="Meu Perfil">
      <Row gutter={24}>
        <Col sm={24} md={8} lg={8} style={{ textAlign: 'center' }}>
          <AvatarHolder>
            <img
              alt="avatar"
              src={`data:image/png;base64, ${profile.avatar.split("'")[1]}`}
              style={{
                width: 120,
                height: 120,
                objectFit: 'cover',
                borderRadius: '50%',
              }}
            />
            <EditPhoto
              id="edit-button"
              onClick={() => setUploadModalAvatarVisible(true)}
            />
          </AvatarHolder>
          <br />
          <br />
          <Button type="link" onClick={() => window.open('https://sso.coopersystem.com.br')}>
            <b>
              <FormattedMessage {...messages.resetPassword} />
            </b>
          </Button>
        </Col>
        <Col sm={24} md={16} lg={16}>
          <ListItem>
            <b>
              <FormattedMessage {...messages.fullName} />:
            </b>
            {safeRead(profile, ['name_display'], '-')}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.registrationNumber} />:
            </b>
            {safeRead(profile, ['csys_registration_number'], '-')}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.jobPosition} />:
            </b>
            {safeRead(profile, ['position_display', 'position_display'], '-')}
          </ListItem>
          <ListItem>
            <Row type="flex" gutter={12} align="middle">
              <Col>
                <b>
                  <FormattedMessage {...messages.hourlyRate} />:
                </b>
              </Col>

              {hourlyRateVisible ? (
                <Col style={{ width: 90 }}>
                  <Skeleton lineHeight={8} />
                </Col>
              ) : (
                <Col>
                  {safeRead(profile, ['hourly_rate'], '-', value =>
                    formatPrice(value)
                  )}
                </Col>
              )}

              <Col>
                {' '}
                <Button
                  shape="circle"
                  onClick={() =>
                    setHourlyRateVisible(
                      hourlyRateVisible => !hourlyRateVisible
                    )
                  }
                >
                  {hourlyRateVisible ? (
                    <AiOutlineEye style={{ fontSize: 24, paddingTop: 4 }} />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ fontSize: 24, paddingTop: 4 }}
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.profile} />:
            </b>
            {safeRead(profile, ['groups', [0], 'name'], '-')}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.status} />:
            </b>
            {safeRead(profile, ['status_display'], '-')}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.accessionDate} />:
            </b>
            {safeRead(profile, ['affiliation_date'], '-', value =>
              moment(value).format('DD/MM/YYYY')
            )}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.startDate} />:
            </b>
            {safeRead(profile, ['start_date'], '-', value =>
              moment(value).format('DD/MM/YYYY')
            )}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.leaderBudget} />
            </b>
            {safeRead(profile, ['budgets', [1], 'has'], '-') === true ? (
              <FormattedMessage {...messages.yes} />
            ) : (
              <FormattedMessage {...messages.no} />
            )}
          </ListItem>
          <ListItem>
            <b>
              <FormattedMessage {...messages.responsibleBudget} />
            </b>
            {safeRead(profile, ['budgets', [0], 'has'], '-') === true ? (
              <FormattedMessage {...messages.yes} />
            ) : (
              <FormattedMessage {...messages.no} />
            )}
          </ListItem>
        </Col>
      </Row>
      {changePasswordVisible ? (
        <ChangePassword
          visible={changePasswordVisible}
          onCancel={handleChangePasswordVisible}
        />
      ) : null}
      {uploadModalAvatarVisible ? (
        <UploadAvatarModal
          uploadModalVisible={uploadModalAvatarVisible}
          setUploadModalVisible={setUploadModalAvatarVisible}
        />
      ) : null}
    </PageContainer>
  );
}
