import { store } from '~/store';

/**
 *
 * @param {(string|string[])} permissions string of permission or an array of permissions
 * @param {string} operation logical operator, e.g., 'or', 'ou', '||'
 */

export default function checkPermission(permissions, operation = 'and') {
  const { permissions: permissionsStore } = store.getState().user.profile;

  if (typeof permissions === 'string') {
    return permissionsStore.includes(permissions);
  }

  if (['or', '||', 'ou'].includes(String(operation).toLowerCase())) {
    if (typeof permissions === 'object' && permissions.length > 0) {
      return (
        permissionsStore.filter(permission => permissions.includes(permission))
          .length > 0
      );
    }
  }

  const filteredPermissions = permissionsStore.filter(permission =>
    permissions.includes(permission)
  );

  return permissions.length === filteredPermissions.length;
}
