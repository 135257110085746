import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import safelyRead from 'safely-read';
import { Container, InfoItemHolder, TooltipWrapper, TooltipHourItem, ItemLine } from './styles';
import { MonthPicker } from '~/components/DataEntry/DatePicker';
import messages from '../messages';
import { useWorkInfoData } from './index';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';

export default function WorkInfo() {
  const workInfo = useWorkInfoData();
  const working_hours = safelyRead(workInfo, ['working_hours'], []);

  const queries = getAllQueriesParams();
  const onMesAnoChange = e =>
    pushWithQueriesParams(history.location.pathname, {
      ...queries,
      year: e.format('YYYY'),
      month: e.format('MM'),
    });

  const spaceColuns = React.useMemo(() => {
    return workInfo?.allowed_absence_hours > 0 ? 4 : 5;
  }, [workInfo]);

  const RenderInfo = () => {
    return (
      <TooltipWrapper>
        <Row>
          <span className="stats-title">
            Sua jornada no período:
          </span>
        </Row>
        <br />
        {working_hours.map(item => (
          <>
            <Row>
              <Col sm={24} md={12} lg={12} xl={12}>
                <span className="stats-title">Jornada diária </span>
                <span className="stats-description">
                  {safelyRead(item, ['hour'])}hs
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={24} md={12} lg={12} xl={12}>
                <span className="stats-title">Início </span>
                <span className="stats-description">
                  {safelyRead(item, ['start_date'])}
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={24} md={12} lg={12} xl={12}>
              {safelyRead(item, ['end_date']) === null ? (

                <span className="stats-description">
                  {safelyRead(item, ['end_date'], 'vigente')}
                </span>

              ): (
                <>
                <span className="stats-title">Término </span>
                <span className="stats-description">
                  {safelyRead(item, ['end_date'], 'vigente')}
                </span>
                </>

              )}
              </Col>
            </Row>
            <ItemLine />
          </>
        ))}
        <br />
      </TooltipWrapper>
    );
  };

  return (
    <Container>
      <Col sm={24} md={spaceColuns} lg={spaceColuns} xl={spaceColuns}>
        <Field
          name="mesAno"
          component={MonthPicker}
          label={<FormattedMessage {...messages.monthYear} />}
          onChange={onMesAnoChange}
          format="MMMM / YYYY"
          allowClear={false}
        />
      </Col>
      <Col sm={24} md={4} lg={4} xl={4}>
        <span className="info-title">
          <FormattedMessage {...messages.workingHours} />
        </span>
        <br />
        <span className="info-description">
          {safelyRead(
            workInfo,
            ['working_hours'],
            '--',
            working_hour => `${working_hour}h`
          )}
        </span>
      </Col>
      <Col sm={24} md={3} lg={3} xl={3}>
        <span className="info-title">
          <FormattedMessage {...messages.workingDays} />
        </span>
        <br />
        <span className="info-description">
          {safelyRead(workInfo, ['business_days'], '--')}
        </span>
      </Col>
      <InfoItemHolder>
        <span className="info-title">
          <FormattedMessage {...messages.expectedHoursMonth} />
        </span>
        <br />
        <span className="info-description">
          {safelyRead(
            workInfo,
            ['hours_to_work'],
            '--',
            hours_to_work => `${hours_to_work}h`
          )}
        </span>
      </InfoItemHolder>
      <InfoItemHolder>
        <span className="info-title">
          <FormattedMessage {...messages.hoursLeftProduce} />
        </span>
        <br />
        <span className="info-description">
          {safelyRead(
            workInfo,
            ['remaining_hours'],
            '--',
            remaining_hours => `${remaining_hours}h`
          )}
        </span>
      </InfoItemHolder>
      <InfoItemHolder>
        <span className="info-title">
          <FormattedMessage {...messages.workedHours} />
        </span>
        <br />
        <span className="info-description">
          {safelyRead(
            workInfo,
            ['worked_hours'],
            '--',
            worked_hours => `${worked_hours}h`
          )}
        </span>
      </InfoItemHolder>
      {workInfo?.allowed_absence_hours > 0 && (
        <Col sm={24} md={3} lg={3} xl={3}>
          <span className="info-title">
            <FormattedMessage {...messages.absence_analyze} />
          </span>
          <br />
          <span className="info-description">
            {safelyRead(
              workInfo,
              ['allowed_absence_hours'],
              '--',
              allowed_absence_hours => `${allowed_absence_hours}h`
            )}
          </span>
        </Col>
      )}
    </Container>
  );
}
