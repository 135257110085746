import { useState, useEffect } from 'react';
import safelyRead from 'safely-read';
import moment from 'moment';
import Details from './Details';
import api from '~/services/api';
import { PAYABLE_REQUEST_ID } from '~/constants/endpoints/payable_request';
import renderBadge from '~/services/utils/renderStatusBadge';
import formatPrice from '~/services/utils/formatPrice';

export function formatData(data = {}) {
  const requestTypeDisplay = safelyRead(data, 'request_type_display', '--');
  // const requestType = safelyRead(data, 'request_type', '--');
  const name = safelyRead(data, ['person_display', 'name_display'], '--');
  const id = safelyRead(data, 'id', '--');
  const disapprovalReason = safelyRead(data, 'disapproval_reason', '--');
  const project = safelyRead(data, 'team_display', '--');
  const status = safelyRead(data, 'status', '--');
  const statusDisplay = safelyRead(data, 'status_display', '--');
  const hours = safelyRead(data, 'hours', '--');
  const value = safelyRead(data, 'value', '--');
  const reason = safelyRead(data, 'reason', '--');
  const updatedAt = safelyRead(data, 'updated_at', '--');
  const requestedAt = safelyRead(data, 'created_at', '--');
  const hourlyRate = safelyRead(data, 'hourly_rate', '--');
  const examiner = safelyRead(data, ['examiner_display', 'name_display'], '--');

  // if (requestType !== 'pacote') {
  //   return [
  //     { key: 'ID', title: 'ID', value: id, width: '40%' },
  //     { key: name, title: 'Nome', value: name, width: '60%' },
  //     {
  //       key: 'Solicitação',
  //       title: 'Solicitação',
  //       value: requestTypeDisplay,
  //       width: '40%',
  //     },
  //     { key: 'Projeto', title: 'Projeto', value: project, width: '60%' },
  //     { key: 'Horas', title: 'Horas', value: hours, width: '40%' },
  //     {
  //       key: 'status',
  //       title: 'Status',
  //       value: renderBadge({ status, status_display: statusDisplay }),
  //       width: '60%',
  //     },
  //     {
  //       key: 'Valor',
  //       title: 'Valor',
  //       value: formatPrice(value),
  //       width: '40%',
  //     },
  //     {
  //       key: 'Valor/hora do usuário',
  //       title: 'Valor/hora do usuário',
  //       value: formatPrice(hourlyRate),
  //       width: '60%',
  //     },
  //     {
  //       key: 'Última modificação',
  //       title: 'Última modificação',
  //       value: moment(updatedAt).format('DD/MM/YYYY'),
  //       width: '40%',
  //     },
  //     {
  //       key: 'Data de solicitação',
  //       title: 'Data de solicitação',
  //       value: moment(requestedAt).format('DD/MM/YYYY'),
  //       width: '60%',
  //     },
  //     { key: 'Motivo', title: 'Motivo', value: reason, width: '40%' },
  //     {
  //       key: 'Motivo da reprovação',
  //       title: 'Motivo da reprovação',
  //       value: disapprovalReason,
  //       width: '60%',
  //     },
  //   ];
  // }

  return [
    { key: 'ID', title: 'ID', value: id, width: '40%' },
    { key: name, title: 'Nome', value: name, width: '60%' },
    {
      key: 'Solicitação',
      title: 'Solicitação',
      value: requestTypeDisplay,
      width: '40%',
    },
    { key: 'Projeto', title: 'Projeto', value: project, width: '60%' },
    { key: 'Horas', title: 'Horas', value: hours, width: '40%' },
    {
      key: 'status',
      title: 'Status',
      value: renderBadge({ status, status_display: statusDisplay }),
      width: '60%',
    },
    {
      key: 'Valor',
      title: 'Valor',
      value: formatPrice(value),
      width: '40%',
    },
    {
      key: 'Valor/hora do usuário',
      title: 'Valor/hora do usuário',
      value: formatPrice(hourlyRate),
      width: '60%',
    },
    {
      key: 'Última modificação',
      title: 'Última modificação',
      value: moment(updatedAt).format('DD/MM/YYYY'),
      width: '40%',
    },
    {
      key: 'Data de solicitação',
      title: 'Data de solicitação',
      value: moment(requestedAt).format('DD/MM/YYYY'),
      width: '60%',
    },
    {
      key: 'Motivo',
      title: 'Motivo',
      value: reason,
      width: '100%',
    },
    status === 'aprovado_pelo_financeiro' && {
      key: 'examiner',
      title: 'Responsável pela aprovação',
      value: examiner,
      width: '100%',
    },
    status === 'reprovado_pelo_financeiro' && {
      key: 'examiner',
      title: 'Responsável pela reprovação',
      value: examiner,
      width: '100%',
    },
    status === 'reprovado_pelo_financeiro' && {
      key: 'Motivo da reprovação',
      title: 'Motivo da reprovação',
      value: disapprovalReason,
      width: '100%',
    },
  ].filter(Boolean);
}

export function usePayableRequest(id) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    (async function laodData() {
      try {
        const response = await api.get(PAYABLE_REQUEST_ID(id));

        setData(response.data);
      } catch (e) {
        setError(safelyRead(e, ['response', 'data']));
      }

      setLoading(false);
    })();
  }, [id]);

  return { data, loading, error };
}

export default Details;
