import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from 'antd';

import checkPermission from '~/services/utils/checkPermission';

const { Paragraph } = Typography;

export default function Person({ name_display, onRemove }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Paragraph ellipsis>{name_display}</Paragraph>

      {checkPermission('delete_team') && (
        <Button type="link" onClick={onRemove} disabled={!onRemove}>
          {onRemove ? 'Remover' : 'Equipe Pai'}
        </Button>
      )}
    </div>
  );
}

Person.defaultProps = {
  onRemove: undefined,
};

Person.propTypes = {
  name_display: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};
