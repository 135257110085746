import { message } from 'antd';
import safeRead from 'safely-read';
import Recover from './Recover';
import RequestRecover from './RequestRecover';
import {
  AUTH_PASSWORD_RESET,
  AUTH_PASSWORD_RESET_CONFIRM,
} from '~/constants/endpoints/auth';
import api from '~/services/api';
import history from '~/services/history';

export async function createRecoverToken({ email }, { setSubmitting }) {
  try {
    await api.post(AUTH_PASSWORD_RESET, {
      email,
    });

    message.success(
      'Solicitação feita com sucesso, por favor confira sua caixa de email.'
    );
    setSubmitting(false);
    history.push('/')
  } catch (error) {
    setSubmitting(false);
    message.error('Por favor, confira o email digitado');
  }
}

export async function confirmRecoverPassword(
  { password },
  { setSubmitting, setFieldError }
) {
  try {
    const token = new URLSearchParams(history.location.search).get('token');

    await api.post(AUTH_PASSWORD_RESET_CONFIRM, {
      password,
      token,
    });

    message.success('Senha alterada com sucesso!');
    setSubmitting(false);

    history.push('/autenticacao');
  } catch (error) {
    setSubmitting(false);

    const status = safeRead(error, ['request', 'response'], null, value => {
      const obj = JSON.parse(value);

      return obj.status;
    });

    const passwordError = safeRead(
      error,
      ['request', 'response'],
      null,
      value => {
        const obj = JSON.parse(value);

        return obj.password[0];
      }
    );

    if (status && status === 'notfound') {
      message.error('Token inválido');
    }

    if (passwordError) {
      setFieldError('password', passwordError);
    }
  }
}

export { Recover, RequestRecover };
