import { combineReducers } from 'redux';

import theme from './theme/reducer';
import user from './user/reducer';
import auth from './auth/reducer';
import language from './language/reducer';
import permissions from './permissions/reducer';
import production from './production/reducer';
import groups from './profile_groups/reducer';

export default combineReducers({
  theme,
  user,
  auth,
  groups,
  language,
  permissions,
  production,
});
