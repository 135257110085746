import React from 'react';
import PropTypes from 'prop-types';
import { Form, Icon, Input as AntInput, Tooltip } from 'antd';
import MaskedInput from 'react-text-mask';

const { Item } = Form;

export const INPUT_TYPES = {
  ANY: 'any',
  PASSOWRD: 'password',
  MASKED: 'masked',
  EMAIL: 'email',
  TEXTAREA: 'textarea',
};

function getValidationStatus(field, form) {
  const { errors, touched, submitCount } = form;
  const { name } = field;

  if (touched[name] || submitCount > 0) {
    return errors[name] ? 'error' : 'success';
  }

  return '';
}

function getHelpMessage(field, form) {
  const { errors, touched, submitCount } = form;
  const { name } = field;

  if (touched[name] || submitCount > 0) {
    return errors[name];
  }

  return '';
}

function getInputComponent(inputType) {
  if (inputType === INPUT_TYPES.PASSOWRD) {
    return AntInput.Password;
  }

  if (inputType === INPUT_TYPES.MASKED) {
    return MaskedInput;
  }

  if (inputType === INPUT_TYPES.TEXTAREA) {
    return AntInput.TextArea;
  }

  return AntInput;
}

function getMask(inputType, props) {
  const { mask } = props;

  if (inputType === INPUT_TYPES.MASKED) {
    return {
      mask,
      guide: false,
      className: 'ant-input',
    };
  }

  return {};
}

function getIcons(iconBefore, iconAfter) {
  const icons = {};

  if (iconBefore) {
    icons.prefix = <Icon type={iconBefore} />;
  }

  if (iconAfter) {
    icons.suffix = <Icon type={iconAfter} />;
  }

  return icons;
}

function getExtraInformation(extraInformation) {
  if (extraInformation) {
    return {
      suffix: (
        <Tooltip title={extraInformation}>
          <Icon type="info-circle" />
        </Tooltip>
      ),
    };
  }

  return {};
}

export default function Input({
  id,
  label,
  required,
  field,
  form,
  helpMessage,
  type: inputType,
  placeholder,
  disabled,
  iconBefore,
  iconAfter,
  extraInformation,
  allowClear,
  handleEnter,
  ...props
}) {
  const InputComponent = getInputComponent(inputType);

  return (
    <Item
      validateStatus={getValidationStatus(field, form)}
      label={
        label && (
          <span>
            {label}
            {required && <span style={{ color: 'tomato' }}> *</span>}
          </span>
        )
      }
      hasFeedback={!(inputType === INPUT_TYPES.PASSOWRD || !extraInformation)}
      help={getHelpMessage(field, form)}
      extra={helpMessage}
    >
      <InputComponent
        type={inputType}
        id={id}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        placeholder={placeholder}
        disabled={disabled}
        allowClear={allowClear}
        onPressEnter={handleEnter}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getMask(inputType, props)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getIcons(iconBefore, iconAfter)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getExtraInformation(extraInformation)}
        {...props}
      />
    </Item>
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  helpMessage: PropTypes.string,
  disabled: PropTypes.bool,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  extraInformation: PropTypes.string,
  allowClear: PropTypes.bool,
  handleEnter: PropTypes.func,
};

Input.defaultProps = {
  type: INPUT_TYPES.ANY,
  label: undefined,
  placeholder: '',
  required: false,
  helpMessage: '',
  disabled: false,
  iconBefore: '',
  iconAfter: '',
  extraInformation: '',
  allowClear: false,
  handleEnter: undefined,
};
