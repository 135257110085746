import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import safeRead from 'safely-read';
import ManagePackageModal from './ManagePackageModal';
import renderStatusBadge from '~/services/utils/renderStatusBadge';
import api from '~/services/api';
import { PAYABLE_REQUEST } from '~/constants/endpoints/payable_request';
import formatPrice from '~/services/utils/formatPrice';
import { getQueryParam } from '~/services/history';

export function makeColumns(setDetailModalVisible, setPayableRequestKey) {
  return [
    {
      title: 'Solicitação',
      dataIndex: 'request_type_display',
      key: 'request_type_display',
      ellipsis: true,
    },
    {
      title: 'Duração',
      dataIndex: 'hours_display',
      key: 'hours_display',
      ellipsis: true,
      width: '10%',
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
    },
    {
      title: 'Projeto',
      dataIndex: 'team_display',
      key: 'team_display',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, rawData) =>
        renderStatusBadge({
          status: rawData.status,
          status_display: rawData.status_display,
        }),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, item) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setDetailModalVisible(true);
              setPayableRequestKey(item.key);
            }}
          >
            Detalhes
          </Button>
        </>
      ),
    },
  ];
}

export async function fetchTableData(
  id,
  month,
  year,
  setTableData,
  setLoadingTableData
) {
  if (id !== undefined) {
    setLoadingTableData(true);
    try {
      const { data } = await api.get(PAYABLE_REQUEST, {
        params: { person_id: id, month, year },
      });
      setLoadingTableData(false);
      setTableData(data);
    } catch (error) {
      message.error(
        safeRead(
          error,
          ['response', 'data', 'detail'],
          'Erro ao buscar dados da tabela'
        )
      );
      setLoadingTableData(false);
      setTableData([]);
    }
  }
}

export function useTableData(id, month, year) {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const page = parseInt(getQueryParam('page'), 10);
  const page_size = parseInt(getQueryParam('page_size'), 10) || 5;

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(id, month, year, setTableData, setLoadingTableData, {
        page,
        page_size,
      });
    }

    loadTableData();
  }, [id, month, year, page, page_size]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(id, month, year, setTableData, setLoadingTableData, {
        page,
        page_size,
      }),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      ...item,
      key: safeRead(item, ['id']),
      request_type_display: safeRead(item, ['request_type_display'], '--'),
      hours_display: safeRead(item, ['hours'], '--', value => `${value}h`),
      value: safeRead(item, ['value'], '--', value => formatPrice(value)),
      team_display: safeRead(item, ['team_display'], '--'),
      status: safeRead(item, ['status'], '--'),
      status_display: safeRead(item, ['status_display'], '--'),
      action: safeRead(item, ['id'], '--'),
    }));
  }

  return [];
}

export default ManagePackageModal;
