import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Button, Divider } from 'antd';
import { Formik, Form, Field } from 'formik';

import Transfer from './Transfer';
import { postGroupUser, makeEditState } from './index';
import { ProfileContext } from '../../index';
import { Container, ActionsContainer } from './styles';

const { Title } = Typography;

export default function RegisterOrEditPerson({ visible, setVisible }) {
  const {
    fetchTableData,
    setGroupEditMode,
    setCurrentRow,
    currentUser,
  } = useContext(ProfileContext);

  const groups =
    currentUser && currentUser.groups_display.map(item => `${item.id}`);
  const [targetKeys, setTargetKeys] = useState([]);

  useEffect(() => {
    setTargetKeys(makeEditState(groups));
  }, [setCurrentRow, setGroupEditMode, currentUser, setVisible]);

  return (
    <Modal
      title={<Title level={4}>Cadastrar/Salvar Perfil</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      width={900}
      closable
      destroyOnClose
    >
      <Container>
        <Formik
          initialValues={{
            groups: targetKeys.filter(item => !isNaN(item)),
            name: currentUser && currentUser.username,
          }}
          onSubmit={values =>
            postGroupUser(
              values,
              fetchTableData,
              setVisible,
              currentUser && currentUser.id
            )
          }
        >
          {({ setFieldValue, handleSubmit, values, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Title level={4}>{currentUser && currentUser.username}</Title>

              <Field
                id="input-transfer"
                name="groups"
                labelLeft="Permissões Disponíveis:"
                labelRight={`Perfil para ${currentUser &&
                  currentUser.username}:`}
                component={Transfer}
                targetKeys={targetKeys}
                setTargetKeys={setTargetKeys}
                onChange={value => setFieldValue('groups', value)}
              />

              <ActionsContainer>
                <Button htmlType="submit" onClick={() => setVisible(false)}>
                  Cancelar
                </Button>

                <Divider type="vertical" />

                <Button loading={isSubmitting} htmlType="submit" type="primary">
                  Salvar
                </Button>
              </ActionsContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}

RegisterOrEditPerson.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
