import { defineMessages } from 'react-intl';

export const scope = 'portal.authentication';

export default defineMessages({
  moreSecurity: {
    id: `${scope}.more_security`,
    defaultMessage: 'Mais segurança',
  },
  andAgility: {
    id: `${scope}.and_agility`,
    defaultMessage: 'e agilidade para você',
  },
  copyright: {
    id: `${scope}.copyright`,
    defaultMessage: 'Coopersystem © 2020 - Todos os direitos reservados',
  },
  userLabel: {
    id: `${scope}.user_label`,
    defaultMessage: 'Usuário',
  },
  passwordLabel: {
    id: `${scope}.password_label`,
    defaultMessage: 'Senha',
  },
  keepLoggedLabel: {
    id: `${scope}.keep_logged_label`,
    defaultMessage: 'Manter-me logado',
  },
  signButton: {
    id: `${scope}.sign_button`,
    defaultMessage: 'ENTRAR',
  },
  forgotPassword: {
    id: `${scope}.forgot_password`,
    defaultMessage:
      'Esqueceu sua senha? Entre em contato com o Administrativo - Ramal:',
  },
});
