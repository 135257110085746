import React, { useContext } from 'react';
import {
  Row,
  Col,
  Modal,
  Alert,
  Divider,
  Button,
  Table,
  Checkbox,
  Typography
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import safeRead from 'safely-read';

import Input from '~/components/DataEntry/Input';
import { mountTableData, handleSubmit } from '.';
import { HistoryContext } from '../../index';
import { AntdTable } from './styles';

const { Text } = Typography;

const { Column } = Table;

function AbsenceOvertimeModal({
  absenceOvertimeModalVisible,
  toggleAbsenceOvertimeModal,
  rawData,
  selectedRowItems,
  rowSelection,
  values,
}) {
  const {
    fetchStatisticsData,
    cooperativeMemberId,
    setStatistics,
    setLoadingStatistics,
  } = useContext(HistoryContext);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });

  const [repeatReason, setRepeatReason] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const disableSubmit = repeatReason => {
    const formikValues = safeRead(values, ['reason'], []);

    if (formikValues.length === 0) {
      return true;
    }

    if (repeatReason && formikValues.includes('')) {
      return true;
    }

    if (
      !repeatReason &&
      formikValues.length < rowSelection.selectedRowKeys.length
    ) {
      return true;
    }

    if (
      !repeatReason &&
      formikValues.length === rowSelection.selectedRowKeys.length &&
      formikValues.includes('')
    ) {
      return true;
    }

    return false;
  };

  return (
    <Modal
      visible={absenceOvertimeModalVisible}
      onCancel={toggleAbsenceOvertimeModal}
      title="Solicitar Hora Extra / Ausência para dias passados"
      width={isMobileOrTablet ? '95%' : '60%'}
      destroyOnCLose
      centered
      footer={false}
    >
      <Alert
        message="As suas Ausências e Horas Extras são baseadas na sua produção e de acordo com sua jornada.
        Efetue uma rasura para ajustar a duração das suas solicitações."
        type="info"
        showIcon
      />
      <br />
      <Checkbox
        checked={repeatReason}
        onChange={() => setRepeatReason(inputDisabled => !inputDisabled)}
      >
        Repetir o motivo para todos os dias.
      </Checkbox>
      <br />
      <br />

      <AntdTable
        dataSource={mountTableData(selectedRowItems)}
        pagination={false}
        scroll={{ x: true }}
      >
        <Column dataIndex="day" ellipsis title="Dia" />
        <Column dataIndex="production" ellipsis title="Produção" />
        <Column dataIndex="balance" ellipsis title="Saldo" />
        <Column
          dataIndex="type"
          ellipsis
          title="Solicitação"
          render={(item, row, index) => {
            return item[0] === 'Hora Extra' ? (
              <Text>Hora Extra</Text>
            ) : (
              <Text>Ausência</Text>
            );
          }}
        />
        <Column
          dataIndex="reason"
          ellipsis
          title="Motivo"
          render={(_, row, index) => (
            <Field
              id={repeatReason ? 'reason[0]' : `reason[${index}]`}
              name={repeatReason ? 'reason[0]' : `reason[${index}]`}
              disabled={repeatReason ? index > 0 : false}
              component={Input}
              maxLength={300}
              style={{ margin: '0px !important' }}
            />
          )}
        />
      </AntdTable>

      <br />
      <Row type="flex" justify="end">
        <Col>
          <Button onClick={toggleAbsenceOvertimeModal}>Cancelar</Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={async () => {
              await handleSubmit(
                values,
                setSubmitting,
                toggleAbsenceOvertimeModal,
                repeatReason
              );
              await fetchStatisticsData(
                cooperativeMemberId,
                setStatistics,
                setLoadingStatistics
              );
            }}
            loading={submitting}
            disabled={disableSubmit(repeatReason)}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default withFormik({})(AbsenceOvertimeModal);

AbsenceOvertimeModal.propType = {
  absenceOvertimeModalVisible: PropTypes.bool.isRequired,
  toggleAbsenceOvertimeModal: PropTypes.func.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedRowItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowSelection: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};
