import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

/**
 * @param {Object} searchQueries - an object to build search params url.
 */

export function buildQueriesParamsURLString(searchQueries) {
  const entries = Object.entries(searchQueries);
  const queriesValues = entries.map(entry =>
    entry[1] ? `${entry[0]}=${entry[1]}` : null
  );

  return queriesValues.filter(item => item !== null).join('&');
}

/**
 *
 * @param {string} path - pathname string
 * @param {Object} searchQueries - an object to build search params url
 * @param {Object} [state] - default history state object - optional
 */

export function pushWithQueriesParams(path, searchQueries, state) {
  const queriesString = buildQueriesParamsURLString(searchQueries);

  history.push(`${path}?${queriesString}`, state);
}

/**
 * returns a string value or null
 * @param {string} value - string to search in URL queries
 */

export function getQueryParam(value) {
  const urlSearchParams = new URLSearchParams(history.location.search);

  return urlSearchParams.get(value);
}

/**
 * returns an object with all search query param found in the URL
 */

export function getAllQueriesParams() {
  const searchParamsString = history.location.search.split('?')[1] || null;
  const searchParams = searchParamsString ? searchParamsString.split('&') : [];
  const queries = searchParams.map(query => query.split('='));
  const initialObject = {};

  return queries.reduce((obj, item) => {
    const [key, value] = item;

    obj[key] = value;

    return { ...obj };
  }, initialObject);
}

export default history;
