import React, { useContext } from 'react';
import { Button, Divider } from 'antd';
import PropTypes from 'prop-types';
import PageContainer from '~/components/Layout/PageContainer';
import SearchFields from './SearchForm';
import Table from '~/components/DataDisplay/Table';
import { AbsenceOvertimeContext } from '.';
import DetailModal from './Modals/DetailModal';
import RequestAbsenceOvertime from './Modals/RequestAbsenceOvertime';
import ConfirmRequest from './Modals/ConfirmRequest';
import history, { pushWithQueriesParams } from '~/services/history';

export default function AbsenceOvertime({
  tableColumns,
  page,
  pageSize,
  tableData,
  loadingTableData,
  totalPages,
  id,
  date_start,
  date_end,
  request,
  status,
  ordering,
}) {
  const {
    toggleDetailModalVisible,
    toggleConfirmRequestModal,
    confirmModalVisible,
    toggleRequestModalVisible,
    setItemData,
  } = useContext(AbsenceOvertimeContext);

  return (
    <PageContainer title="Ausência e Hora extra">
      <SearchFields
        pageSize={pageSize}
        id={id}
        date_start={date_start}
        date_end={date_end}
        request={request}
        status={status}
      />
      <Divider />

      <Button type="primary" onClick={toggleRequestModalVisible}>
        Solicitar Ausência / Hora Extra futura
      </Button>
      <br />
      <br />

      <Table
        title=""
        columns={tableColumns(setItemData, toggleDetailModalVisible)}
        dataSource={tableData}
        paginated
        count={totalPages}
        pageSize={pageSize}
        currentPage={page || 1}
        loading={loadingTableData}
        onChange={(pagination, filters, sorter, extra) => {
          pushWithQueriesParams(history.location.pathname, {
            id: id,
            date_start: date_start,
            date_end: date_end,
            request: request,
            status: status,
            page: 1,
            page_size: pageSize,
            ordering:
              sorter.order === 'ascend'
                ? sorter.columnKey
                : `-${sorter.columnKey}`,
          });
        }}
      />
      <DetailModal toggleModal={toggleDetailModalVisible} />
      <ConfirmRequest
        visible={confirmModalVisible}
        toggleModal={toggleConfirmRequestModal}
      />
      <RequestAbsenceOvertime />
    </PageContainer>
  );
}

AbsenceOvertime.propTypes = {
  tableColumns: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  pageSize: PropTypes.number,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loadingTableData: PropTypes.bool.isRequired,
  totalPages: PropTypes.number.isRequired,
  id: PropTypes.string,
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  request: PropTypes.string,
  status: PropTypes.string,
  ordering: PropTypes.string,
};

AbsenceOvertime.defaultProps = {
  pageSize: null,
  page: null,
  id: null,
  date_start: null,
  date_end: null,
  request: null,
  status: null,
  ordering: null,
};
