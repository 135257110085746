export const routePaths = {
  AUTH: '/autenticacao',
  FORGOT_PASSWORD: '/esqueci-minha-senha',
  HOME: '/',
  PROFILE: '/meu-perfil',
  PROD_HISTORY: '/minha-producao',
  ABSENCE_ORVETIME: '/ausencia-hora-extra',
  SETTINGS: '/configuracoes',
  RECTIFICATION: '/rasuras',
  BUDGET: '/verbas',
  PRODUCTION_DAYS: '/dias-de-producao',
  PROFILE_AND_PERMISSIONS: '/grupos-e-permissoes',
  PROFILE_PERMISSIONS: '/perfis',
  ABSENCE_OVERTIME_APPROVAL: '/aprovacao-ausencia-e-hora-extra',
  VACATIONS_ALLOWENCES_APPROVAL: '/aprovacao-ferias-abonos',
  VACATIONS_ALLOWENCES: '/ferias-abonos',
  PROJECT: '/equipes',
  MY_TEAM: '/minha-equipe',
  MY_SQUAD: '/meus-liderados',
  MANAGE_USER: '/gerenciar-usuarios',
  USER_RESGISTRY: '/gerenciar-usuarios',
  HOME_SILVER: '/prata-da-casa',
  HOME_SILVER_USER: '/prata-da-casa-usuario',
  CONSOLIDATE_PRODUCTION: '/consolidar-producao',
  FINANCIAL_REQUESTS: '/solicitacoes-financeiras',
  MY_RPC: '/my-rpc',
  RPC: '/rpc',
  EDESENV: '/edesenv',
  REPORT: '/relatorios',
  PRODUCTION_CONTROL: '/relatorios/controle-de-producao',
  SHUTDOWN: '/acerto-para-desligamento',
  CONSOLIDATE_PRODUCTION_EXECUTIVE: '/consolidar-producao-por-diretoria',
  TEAM_PRODUCTION: '/producao-da-equipe',
  TEAM_LIST: '/listar-equipes',
};

export const breadcrumbEnum = {
  'pt-BR': {
    [routePaths.AUTH]: 'Autenticação',
    [routePaths.HOME]: 'Início',
    [routePaths.PROFILE]: 'Meu Perfil',
    [routePaths.PROD_HISTORY]: 'Minha Produção',
    [routePaths.ABSENCE_ORVETIME]: 'Ausência e Hora extra',
    [routePaths.SETTINGS]: 'Configurações',
    [routePaths.FORGOT_PASSWORD]: 'Esqueci Minha Senha',
    [routePaths.RECTIFICATION]: 'Rasuras',
    [routePaths.BUDGET]: 'Verbas',
    [routePaths.PRODUCTION_DAYS]: 'Dias de Produção',
    [routePaths.PROFILE_AND_PERMISSIONS]: 'Grupos e Permissões',
    [routePaths.PROFILE_PERMISSIONS]: 'Perfis e Permissões',
    [routePaths.ABSENCE_OVERTIME_APPROVAL]: 'Ausência e Hora extra',
    [routePaths.VACATIONS_ALLOWENCES_APPROVAL]: 'Férias e Abonos',
    [routePaths.VACATIONS_ALLOWENCES]: 'Férias e Abonos',
    [routePaths.PROJECT]: 'Gerenciar Equipes',
    [routePaths.MY_TEAM]: 'Minha Equipe',
    [routePaths.MY_SQUAD]: 'Meus liderados',
    [routePaths.MANAGE_USER]: 'Gerenciar Usuários',
    [routePaths.USER_RESGISTRY]: 'Registro de Usuários',
    [routePaths.HOME_SILVER]: 'Prata da Casa',
    [routePaths.HOME_SILVER_USER]: 'Prata da Casa',
    [routePaths.CONSOLIDATE_PRODUCTION]: 'Consolidar Produção',
    [routePaths.FINANCIAL_REQUESTS]: 'Solicitações Financeiras',
    // [routePaths.MY_RPC]: 'Meu RPC',
    // [routePaths.RPC]: 'RPC',
    [routePaths.EDESENV]: 'Histórico Ausência e Hora Extra E-desenv',
    [routePaths.REPORT]: 'Relatórios',
    [routePaths.PRODUCTION_CONTROL]: 'Relatórios de Produção de Cooperados',
    [routePaths.SHUTDOWN]: 'Acerto para Desligamento',
    [routePaths.CONSOLIDATE_PRODUCTION_EXECUTIVE]: 'Consolidar Produção por Diretoria',
    [routePaths.TEAM_PRODUCTION]: 'Produção da Equipe',
    // [routePaths.TEAM_LIST]: 'Lista de equipes',
  },
  'en-US': {
    [routePaths.AUTH]: 'Authentication',
    [routePaths.HOME]: 'Home',
    [routePaths.PROFILE]: 'Profile',
    [routePaths.PROD_HISTORY]: 'My Production',
    [routePaths.ABSENCE_ORVETIME]: 'Absence and Overtime',
    [routePaths.SETTINGS]: 'Settings',
    [routePaths.FORGOT_PASSWORD]: 'Forgot Password',
    [routePaths.RECTIFICATION]: 'Rectification',
    [routePaths.BUDGET]: 'Budget',
    [routePaths.PRODUCTION_DAYS]: 'Production Days',
    [routePaths.PROFILE_AND_PERMISSIONS]: 'Groups and Permissions',
    [routePaths.PROFILE_PERMISSIONS]: 'Profiles and Permissions',
    [routePaths.ABSENCE_OVERTIME_APPROVAL]: 'Absence and Overtime',
    [routePaths.PROJECT]: 'Register Projects',
    [routePaths.VACATIONS_ALLOWENCES_APPROVAL]: 'Vacations and Allowences',
    [routePaths.VACATIONS_ALLOWENCES]: 'Vacations and Allowences',
    [routePaths.MY_TEAM]: 'My Team',
    [routePaths.MANAGE_USER]: 'Manage User',
    [routePaths.USER_RESGISTRY]: 'User Registry',
    [routePaths.HOME_SILVER]: 'Home Silver',
    [routePaths.HOME_SILVER_USER]: 'Home Silver',
    [routePaths.CONSOLIDATE_PRODUCTION]: 'Consolidade Production',
    [routePaths.FINANCIAL_REQUESTS]: 'Financial Requests',
    // [routePaths.MY_RPC]: 'My RPC',
    // [routePaths.RPC]: 'RPC',
    [routePaths.EDESENV]: 'E-desenv Historical Absence and Overtime',
    [routePaths.REPORT]: 'Report',
    [routePaths.PRODUCTION_CONTROL]: 'Cooperative Production Report',
    [routePaths.SHUTDOWN]: 'Hit for Shutdown',
    [routePaths.CONSOLIDATE_PRODUCTION_EXECUTIVE]: 'Consolidate Production by Executive Board',
    [routePaths.TEAM_PRODUCTION]: 'Team Production',
    [routePaths.TEAM_LIST]: 'Team List',
  },
};
