import safeRead from 'safely-read';

import { store } from '~/store';
import globalSuffixes from '~/constants/locales/ordinalSuffixes';

export default function formatOrdinal(number, gender) {
  const { locale } = store.getState().language;
  const pluralRules = new Intl.PluralRules(locale, { type: 'ordinal' });

  return safeRead(
    globalSuffixes,
    [locale, gender, pluralRules.select(number)],
    safeRead(
      globalSuffixes,
      [locale, pluralRules.select(number)],
      null,
      value => `${number}${value}`
    ),
    value => `${number}${value}`
  );
}
