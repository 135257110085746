import styled from 'styled-components';
import { Breadcrumb as AntBreadcrumb } from 'antd';

export const Breadcrumb = styled(AntBreadcrumb)`
  color: ${({ theme }) => theme.textColor};

  .ant-breadcrumb-separator {
    color: ${({ theme }) => theme.textColor};
  }

  a {
    color: ${({ theme }) => theme.textColor} !important;
  }
`;
