import React, { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import safeRead from 'safely-read';

import checkPermission from '~/services/utils/checkPermission';
import ProfileAndPermissions from './ProfileAndPermissions';
import { AUTH_GROUPS, AUTH_GROUPS_ID } from '~/constants/endpoints/auth';
import history, {
  pushWithQueriesParams,
  getQueryParam,
  getAllQueriesParams,
} from '~/services/history';
import api from '~/services/api';

export const ProfileContext = React.createContext();

export function useDefaultQueries() {
  const page = parseInt(getQueryParam('page'), 10) || null;
  const page_size = parseInt(getQueryParam('page_size'), 10) || null;

  useEffect(() => {
    if (!page || !page_size) {
      pushWithQueriesParams(history.location.pathname, {
        page: 1,
        page_size: 10,
      });
    }
  }, [page, page_size]);

  return { page, page_size };
}

export async function getAuthGroups() {
  const defaultResponse = { results: [], count: 0, next: null, previous: null };
  const { page, page_size, ...queries } = getAllQueriesParams();

  try {
    const { data } = await api.get(AUTH_GROUPS, {
      params: { page: page || 1, page_size: page_size || 10, ...queries },
    });

    return data;
  } catch (error) {
    const errorMessage = safeRead(
      error,
      ['response', 'data', 'detail'],
      'Erro ao buscar dados na tabela de Auth Group.'
    );
    message.error(errorMessage);

    if (errorMessage === 'Página inválida.') {
      pushWithQueriesParams(history.location.pathname, {
        page: 1,
        page_size,
        ...queries,
      });
    }
  }

  return defaultResponse;
}

export async function deleteAuthGroup(id) {
  try {
    await api.delete(AUTH_GROUPS_ID(id));

    message.success('Item deletado com sucesso');
  } catch (error) {
    const errorMessage = safeRead(
      error,
      ['response', 'data', 'detail'],
      'Nao foi possivel deletar item'
    );
    message.error(errorMessage);
  }
}

export async function fetchTableData(setLoading, setTableData) {
  setLoading(true);
  const data = await getAuthGroups();
  setTableData(data);
  setLoading(false);
}

export function useTableData() {
  const page = parseInt(getQueryParam('page'), 10) || null;
  const page_size = parseInt(getQueryParam('page_size'), 10) || null;

  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setLoadingTableData, setTableData);
    }

    loadTableData();
  }, [page, page_size]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
  };
}

export function formatTableData(data) {
  console.log('datas', data);
  return data.map(({ id, permissions, ...item }) => ({
    key: id,
    permissions: permissions.map(val => `${val}`),
    ...item,
  }));
}

export function makeColumns(
  updateTableData,
  setAddUserModalVisible,
  setCurrentRow,
  setGroupEditMode,
  setRegisterOrEditModalVisible
) {
  const { confirm } = Modal;
  return [
    {
      title: 'Perfil',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Qtde. Usuários',
      dataIndex: 'amount_users',
      key: 'amount_users',
      ellipsis: true,
      width: 90,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 340,
      render: (_, rowData) => (
        <>
          {checkPermission('change_group') ? (
            <Button
              onClick={() => {
                setCurrentRow(rowData);
                setGroupEditMode(true);
                setRegisterOrEditModalVisible(true);
              }}
              type="link"
            >
              Editar
            </Button>
          ) : null}

          {checkPermission('delete_group') ? (
            <Button
              disabled={!rowData.can_delete}
              onClick={() =>
                confirm({
                  centered: true,
                  title: `Deletar grupo ${rowData.name}?`,
                  async onOk() {
                    await deleteAuthGroup(rowData.key);
                    await updateTableData();
                  },
                  onCancel() {},
                })
              }
              type="link"
            >
              Excluir
            </Button>
          ) : null}
        </>
      ),
    },
  ];
}

export default ProfileAndPermissions;
