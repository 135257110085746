import React, { useContext } from 'react';
import { Button, Row, Col, Typography, Tooltip } from 'antd';
import moment from 'moment';
import { ProductionDaysHolder } from '../style';
import RelocateDayModal from './Modals/RelocateDayModal';
import { ProductionDaysContext } from '../index';

export default function WorkingDays() {
  const { businessDayData } = useContext(ProductionDaysContext);

  const [visible, setVisible] = React.useState(false);

  const year = new Date().getFullYear();
  const months = moment.months();

  const { Title, Text } = Typography;

  return (
    <ProductionDaysHolder>
      <Title level={2}>{`Dias úteis - ${year}`}</Title>
      <Row justify="center" type="flex">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Button type="primary" block onClick={() => setVisible(true)}>
            Remanejar dias
          </Button>
        </Col>
      </Row>

      <br />
      <Row gutter={24} type="flex" justify="center">
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Text strong>Mês</Text>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <Text strong>Quantidade</Text>
        </Col>
      </Row>
      <br />
      {Object.values(businessDayData)
        .map((value, index) => ({
          days: value.days,
          month: months[index],
          history: value.history,
          key: [index + 1],
        }))
        .map(({ month, days, history }) => (
          <Row gutter={[24, 24]} key={month} type="flex" justify="center">
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Text>{month}</Text>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              {history.length > 0 ? (
                <Tooltip
                  style={{ whiteSpace: 'pre' }}
                  placement="right"
                  title={history
                    .map(
                      item =>
                        `${item.transferred_days} dia de ${
                          months[item.from_month - 1]
                        } para ${months[item.to_month - 1]}`
                    )
                    .join('\n')}
                >
                  <Text style={{ cursor: 'pointer' }}>{`${days}*`}</Text>
                </Tooltip>
              ) : (
                <Text>{days}</Text>
              )}
            </Col>
          </Row>
        ))}

      {visible ? (
        <RelocateDayModal visible={visible} setVisible={setVisible} />
      ) : null}
    </ProductionDaysHolder>
  );
}
