import styled from 'styled-components';
import { Layout, Menu as AntMenu } from 'antd';

export const SideMenu = styled(Layout.Sider).attrs({
  width: 250,
  height: '100%',
  collapsible: true,
  collapsedWidth: 0,
  trigger: null,
})`
  background-color: ${({ theme }) => theme.componentBackground};
  box-shadow: ${({ theme }) => theme.boxShadowBase};
  display: ${({ collapsed }) => (collapsed ? 'none' : 'inherit')};

  .ant-timeline-item {
    padding: 0 0 8px;
  }

  .ant-timeline-item-content {
    min-height: 0;
  }

  .ant-menu-sub {
    background-color: transparent;

    a {
      color: ${({ theme }) => theme.textColor};
    }
  }

  .ant-menu-submenu-title {
    color: ${({ theme }) => theme.textColor};
  }
`;

export const Menu = styled(AntMenu).attrs({
  mode: 'inline',
})`
  background-color: ${({ theme }) => theme.componentBackground};
  border: 0;

  .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.borderColorBase} !important;
  }
`;

export const MenuItem = styled(AntMenu.Item)`
  background-color: ${({ theme }) => theme.componentBackground};
  margin: 0;
  color: ${({ theme }) => theme.textColor};
  display: flex;
  align-items: center;
`;

export const VersionTag = styled.span`
  position: absolute;
  bottom: 12px;
  left: 12px;
  color: ${({ theme }) => theme.disabledColor};
  cursor: default;
`;
