import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { Button, Timeline } from 'antd';
import { LogIn, LogOut } from 'react-feather';

export function getBackgroundColor(theme, has_rectification, check_in) {
  if (has_rectification) {
    return 'transparent';
  }
  if (check_in) {
    return theme.primaryColor;
  }
  return theme.errorColor;
}

export const TopHolder = styled.div`
  background-color: ${({ theme }) => theme.componentBackground};
  border-bottom: 1px solid ${({ theme }) => theme.borderColorBase};
  color: ${({ theme }) => theme.textColor};
`;

export const ActionButton = styled(Button).attrs({ block: true })`
  &:disabled {
    background-color: ${({ theme }) => theme.primaryColor} !important;
    border-color: ${({ theme }) => theme.primaryColor} !important;
    color: ${({ theme }) => theme.white} !important;

    :hover {
      background-color: ${({ theme }) =>
        lighten(0.1, theme.primaryColor)} !important;
      border-color: ${({ theme }) =>
        lighten(0.1, theme.primaryColor)} !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const LoginIcon = styled(LogIn).attrs({
  size: 16,
})`
  position: absolute;
  left: 8px;
  bottom: 8px;
`;

export const LogoutIcon = styled(LogOut).attrs({
  size: 16,
})`
  position: absolute;
  left: 8px;
  bottom: 8px;
  transform: rotate(180deg);
`;

export const TimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-head {
    background-color: ${({ theme, has_rectification, check_in }) =>
      getBackgroundColor(theme, has_rectification, check_in)};

    border-color: ${({ check_in, theme }) =>
      check_in ? theme.primaryColor : theme.errorColor};
  }
`;

export const TimelineHolder = styled.div`
  max-height: 200px;
  overflow: auto;
  overflow-x: hidden;
  background: linear-gradient(
      ${({ theme }) => theme.componentBackground} 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
        rgba(255, 255, 255, 0),
        ${({ theme }) => theme.componentBackground} 70%
      )
      0 100%,
    radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(
        50% 100%,
        farthest-side,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background: linear-gradient(
      ${({ theme }) => theme.componentBackground} 30%,
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
        rgba(255, 255, 255, 0),
        ${({ theme }) => theme.componentBackground} 70%
      )
      0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: ${({ theme }) => darken(0.05, theme.componentBackground)};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: ${({ theme }) => darken(0.12, theme.componentBackground)};
  }

  .ant-timeline {
    li {
      max-height: 65px !important;
    }
  }
`;

export const TimelineBox = styled.div`
  width: 100%;
  padding: 0 8px;
  border-radius: ${({ theme }) => theme.borderRadiusBase};
  color: ${({ theme }) => theme.textColor};

  b {
    font-size: 16px;
  }
`;

export const WarningBox = styled.div`
  border-radius: ${({ theme }) => theme.borderRadiusBase};
  background: ${({ theme }) => theme.warningColor};
  color: white;
  border: 1px solid ${({ theme }) => darken(0.1, theme.warningColor)};
  padding: 12px;
  margin-bottom: 12px;
`;
