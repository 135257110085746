import React from 'react';
import Type from './Type';
import Dates from './Dates';
import Finish from './Finish';
import RequestAbsenceOvertime from './RequestAbsenceOvertime';

export function resetValues(setCurrentStep, setRequestType, setSelectedDays) {
  setCurrentStep(0);
  setRequestType(null);
  setSelectedDays([]);
}

export function toggleModal(
  toggleRequestModalVisible,
  setCurrentStep,
  setRequestType,
  setSelectedDays
) {
  resetValues(setCurrentStep, setRequestType, setSelectedDays);
  toggleRequestModalVisible();
}

export function onTypeSubmit(type, setCurrentStep, setRequestType) {
  setCurrentStep(1);
  setRequestType(type);
}

export function onDateSubmit(days, setCurrentStep, setSelectedDays) {
  setCurrentStep(2);
  setSelectedDays(days);
}

export function onDateBack(setCurrentStep) {
  setCurrentStep(0);
}

export function onFinishBack(setCurrentStep) {
  setCurrentStep(1);
}

export function renderSteps(currentStep) {
  if (currentStep === 0) {
    return <Type />;
  }

  if (currentStep === 1) {
    return <Dates />;
  }

  if (currentStep === 2) {
    return <Finish />;
  }

  return <Type />;
}

export default RequestAbsenceOvertime;
