import React, { useContext } from 'react';
import {
  Row,
  Col,
  Typography,
  Select as AntdSelect,
  Button,
  Tooltip,
  Alert,
  DatePicker,
} from 'antd';
import { Field, Formik, Form } from 'formik';
import debounce from 'lodash.debounce';

import safeRead from 'safely-read';
import moment from 'moment';
import { MdHistory } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { InfoHolder, Title } from '../../styles';
import Select from '~/components/DataEntry/Select';
import Input from '~/components/DataEntry/Input';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { UserRegistryContext, useSearchProject } from '../../index';
import { PatchPerson } from './index';
import EditCompensatoryTime from './Modal/EditCompensatoryTime/EditCompensatoryTime';

const { Text } = Typography;

export default function Professional() {
  const {
    userData,
    handleModalVisible,
    setVisible,
    setModalType,
    fetchData,
    setUserData,
    setLoadingUserData,
    compensatoryTime,
  } = useContext(UserRegistryContext);
  const { loadingProjects, projects, setSearch } = useSearchProject();

  const [
    editCompensatoryTimeVisible,
    setEditCompensatoryTimeVisible,
  ] = React.useState(false);

  const { id } = useParams();
  const dateFormat = 'DD/MM/YYYY';

  const status = [
    { id: 'Ativo', value: 'ativo' },
    { id: 'Inativo', value: 'inativo' },
    { id: 'Desligado', value: 'desligado' },
    { id: 'Eliminado', value: 'eliminado' },
    { id: 'Excluido', value: 'excluido' },
  ];

  const nightShift = [
    { id: 'Não', value: false },
    { id: 'Sim', value: true },
  ];

  React.useEffect(() => {
    if (userData.default_team) {
      setSearch(userData.default_team_display);
    }
  }, [userData.default_team, userData.default_team_display, setSearch]);

  const refreshData = () => {
    setTimeout(() => {
      fetchData(setUserData, userData.user, setLoadingUserData);
    }, 500);
  };

  return (
    <>
      <Formik
        initialValues={{
          status: safeRead(userData, ['status'], ''),
          phone_extension: safeRead(userData, ['phone_extension'], ''),
          working_hours: safeRead(userData, ['working_hours'], 0),
          working_day_schedule: safeRead(
            userData,
            ['working_day_schedule', 'start_working_day'],
            ''
          ),
          starting_time: safeRead(
            userData,
            ['starting_time'],
            '',
            starting_time =>
              starting_time
                .split(':')
                .filter((item, index) => index < 2)
                .join(':')
          ),
          default_team: safeRead(userData, ['default_team'], null),
          night_shift: safeRead(userData, ['night_shift'], false),
        }}
        onSubmit={values => {
          setLoadingUserData(true);
          PatchPerson(id, values);
          refreshData();
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col sm={24} md={24} lg={12} xl={12}>
                  <InfoHolder>
                    <Title level={4}>Lotação / Exercício</Title>
                    <br />
                    <Row gutter={24}>
                      <Col sm={8} md={8} lg={8} xl={8} style={{ top: 8 }}>
                        <Text>Status: </Text>
                      </Col>
                      <Col sm={10} md={10} lg={10} xl={10} align="middle">
                        <Field
                          id="status"
                          name="status"
                          placeholder="Selecione"
                          data={status}
                          renderItem={item => (
                            <AntdSelect.Option key={item.id} value={item.value}>
                              {item.id}
                            </AntdSelect.Option>
                          )}
                          component={Select}
                        />
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingTop: 10, cursor: 'pointer' }}
                      >
                        <Tooltip
                          style={{ whiteSpace: 'pre' }}
                          placement="right"
                          title="Histórico de alterações"
                        >
                          <MdHistory
                            size={20}
                            style={{ color: '#1DA57A' }}
                            onClick={() =>
                              handleModalVisible(
                                setVisible,
                                setModalType,
                                'status'
                              )
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                    <Text>
                      Matrícula SENIOR:{' '}
                      <Text strong>{safeRead(userData, [''], ['--'])}</Text>
                    </Text>
                    <br />
                    <br />
                    <Text>
                      Data de adesão:{' '}
                      <Text strong>
                        {moment(userData.affiliation_date).format('DD/MM/YYYY')}
                      </Text>
                    </Text>
                    <br />
                    <br />
                    <Text>
                      Data de início:{' '}
                      <Text strong>
                        {moment(userData.start_date).format('DD/MM/YYYY')}
                      </Text>
                    </Text>
                    <br />
                    <br />
                    <Text>
                      Status SENIOR:{' '}
                      <Text strong> {safeRead(userData, [''], ['--'])}</Text>
                    </Text>
                    <br />
                    <br />
                    <Text>
                      Cargo (CBO):{' '}
                      <Text strong>
                        {safeRead(
                          userData,
                          ['position_display', 'position_display'],
                          ['--']
                        )}
                      </Text>
                    </Text>
                    <br />
                    <br />
                    <Text type="secondary">
                      <Row gutter={24}>
                        <Col sm={8} md={8} lg={8} xl={8} style={{ top: 16 }}>
                          Equipe principal:{' '}
                        </Col>
                        <Col sm={10} md={10} lg={10} xl={10} style={{ top: 8 }}>
                          <Field
                            id="default_team"
                            name="default_team"
                            loading={loadingProjects}
                            component={AutoCompleteSelect}
                            selectSchema={item => (
                              <AntdSelect.Option key={item.id} value={item.id}>
                                {item.team_name}
                              </AntdSelect.Option>
                            )}
                            onChange={e => {
                              if (!e) {
                                setFieldValue('default_team', null);
                              } else {
                                setFieldValue('default_team', e);
                              }

                              setSearch(undefined);
                            }}
                            search={debounce(setSearch, 800)}
                            allowClear
                            data={projects.results}
                          />
                        </Col>
                      </Row>
                    </Text>
                    <br />
                    <Row gutter={24}>
                      <Col sm={8} md={8} lg={8} xl={8} style={{ top: 8 }}>
                        <Text>Início da jornada: </Text>
                      </Col>
                      <Col sm={10} md={10} lg={10} xl={10}>
                        <Field
                          id="starting_time"
                          name="starting_time"
                          allowClear
                          data={Array.from({ length: 24 }, (_, value) => {
                            return {
                              id: value >= 10 ? `${value}:00` : `0${value}:00`,
                              value:
                                value >= 10 ? `${value}:00` : `0${value}:00`,
                            };
                          })}
                          renderItem={item => (
                            <AntdSelect.Option key={item.id} value={item.value}>
                              {item.value}
                            </AntdSelect.Option>
                          )}
                          component={Select}
                        />
                      </Col>
                    </Row>
                  </InfoHolder>
                  <InfoHolder>
                    <Title level={4}> Gerenciar Jornada </Title>
                    <Row gutter={24}>
                      <Col sm={8} md={8} lg={8} xl={8} style={{ top: 8 }}>
                        <Text>Jornada diária: </Text>
                      </Col>
                      <Col sm={10} md={10} lg={10} xl={10}>
                        <Field
                          id="working_hours"
                          name="working_hours"
                          placeholder="Selecione"
                          data={Array.from({ length: 10 }, (_, value) => {
                            return {
                              id:
                                value >= 9
                                  ? `${value + 1}:00`
                                  : `0${value + 1}:00`,
                              value: value + 1,
                            };
                          })}
                          renderItem={item => (
                            <AntdSelect.Option key={item.id} value={item.value}>
                              {item.id}
                            </AntdSelect.Option>
                          )}
                          component={Select}
                        />
                      </Col>

                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingTop: 10, cursor: 'pointer' }}
                      >
                        <Tooltip
                          style={{ whiteSpace: 'pre' }}
                          placement="right"
                          title="Histórico de alterações"
                        >
                          <MdHistory
                            size={20}
                            style={{ color: '#1DA57A' }}
                            onClick={() =>
                              handleModalVisible(
                                setVisible,
                                setModalType,
                                'jornadaDiaria'
                              )
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col sm={8} md={8} lg={8} xl={8} style={{ top: 8 }}>
                        <Text>Vigência: </Text>
                      </Col>
                      <Col sm={10} md={10} lg={10} xl={10}>
                        <Field
                          id="start_working_day"
                          name="start_working_day"
                          format={dateFormat}
                          placeholder="Selecione..."
                          allowClear
                          onChange={item =>
                            setFieldValue(
                              'start_working_day',
                              new Date(item._d).toISOString().split('T')[0]
                            )
                          }
                          component={DatePicker}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      {userData.working_day_schedule && (
                        <Col
                          sm={4}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{ paddingTop: 10, cursor: 'pointer' }}
                        >
                          <Tooltip
                            style={{ whiteSpace: 'pre' }}
                            placement="right"
                            title="Visualizar Agendamento"
                          >
                            <MdHistory
                              size={20}
                              style={{ color: '#1DA57A' }}
                              onClick={() => {
                                handleModalVisible(
                                  setVisible,
                                  setModalType,
                                  'scheduling'
                                );
                              }}
                            />
                          </Tooltip>
                        </Col>
                      )}
                    </Row>
                    <br />
                    <Row gutter={24}>
                      <Col sm={8} md={8} lg={8} xl={8} style={{ top: 8 }}>
                        <Text>Jornada noturna: </Text>
                      </Col>
                      <Col sm={10} md={10} lg={10} xl={10}>
                        <Field
                          id="night_shift"
                          name="night_shift"
                          data={nightShift}
                          renderItem={item => (
                            <AntdSelect.Option key={item.id} value={item.value}>
                              {item.id}
                            </AntdSelect.Option>
                          )}
                          component={Select}
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      {userData.working_day_schedule && (
                        <Alert
                          message={`Agendamento de alteração de jornada para
                          ${safeRead(
                            userData,
                            ['working_day_schedule', 'hour'],
                            ''
                          )}
                          horas começará dia
                          ${moment(
                            userData.working_day_schedule.start_working_day
                          ).format('DD/MM/YYYY')}.`}
                          type="info"
                          showIcon
                        />
                      )}
                    </Row>
                  </InfoHolder>
                </Col>
                <Col sm={24} md={24} lg={12} xl={12}>
                  <InfoHolder>
                    <Title level={4}>Contatos profissionais </Title>

                    <Text>
                      Email corporativo:{' '}
                      <Text strong>
                        {' '}
                        {safeRead(userData, ['corporate_email'], ['--'])}
                      </Text>
                    </Text>
                    <br />
                    <br />

                    <Row gutter={12} type="flex" justify="start">
                      <Col style={{ top: 8 }}>
                        <Text>Ramal corporativo: </Text>
                      </Col>

                      <Col sm={8} md={8} lg={8} xl={8}>
                        <Field
                          id="phone_extension"
                          name="phone_extension"
                          placeholder="0000"
                          component={Input}
                        />
                      </Col>
                    </Row>
                  </InfoHolder>
                </Col>
                <Col sm={24} md={24} lg={12} xl={12}>
                  <InfoHolder>
                    <Title level={4}>Carga de Banco de Horas</Title>
                    <Row>
                      <Col sm={10} md={10} lg={10} xl={10} style={{ top: 8 }}>
                        <Text>Banco de horas aprovadas: </Text>
                      </Col>
                      <Col
                        sm={4}
                        md={4}
                        lg={4}
                        xl={4}
                        align="middle"
                        style={{ top: 8 }}
                      >
                        <Text strong>
                          {safeRead(compensatoryTime, ['balance'], '--')}
                        </Text>
                      </Col>
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingTop: 10, cursor: 'pointer' }}
                      >
                        <Tooltip
                          style={{ whiteSpace: 'pre' }}
                          placement="right"
                          title="Histórico de alterações"
                        >
                          <MdHistory
                            size={20}
                            style={{ color: '#1DA57A' }}
                            onClick={() =>
                              handleModalVisible(
                                setVisible,
                                setModalType,
                                'compensatory'
                              )
                            }
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Button
                        onClick={() => setEditCompensatoryTimeVisible(true)}
                      >
                        Editar Banco de horas aprovadas
                      </Button>
                    </Row>
                    <br />
                    <Row>
                      <Alert
                        message="Ao utilizar esta funcionalidade, todas as solicitações antigas de ausência ou horas extras pendentes não poderão ser computadas."
                        type="info"
                        showIcon
                      />
                    </Row>
                  </InfoHolder>
                </Col>
              </Row>
              <br />
              <Row type="flex" justify="end">
                <Col>
                  <Button type="primary" htmlType="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>

      {editCompensatoryTimeVisible ? (
        <EditCompensatoryTime
          editCompensatoryTimeVisible={editCompensatoryTimeVisible}
          setEditCompensatoryTimeVisible={setEditCompensatoryTimeVisible}
        />
      ) : null}
    </>
  );
}
