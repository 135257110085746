import { message } from 'antd';
import safeRead from 'safely-read';

import AddHomeSilver from './AddHomeSilver';
import { HOME_GROWN } from '~/constants/endpoints/home_grown';
import api from '~/services/api';

export async function postHomeGrown(values) {
  try {
    await api.post(HOME_GROWN, values);
    message.success('Novo bônus registrado com sucesso.');
  } catch (error) {
    message.error(safeRead(error, 'Não foi possível realizar a operação.'));
  }
}

export const dataSelect = [
  { key: 0, value: 6 },
  { key: 1, value: 8 },
];

export const dataSelectTime = [
  { key: 1, value: 2 },
  { key: 2, value: 3 },
  { key: 3, value: 4 },
  { key: 4, value: 5 },
  { key: 5, value: 6 },
  { key: 6, value: 7 },
  { key: 7, value: 8 },
  { key: 8, value: 9 },
  { key: 9, value: 10 },
  { key: 10, value: 11 },
  { key: 11, value: 12 },
  { key: 12, value: 13 },
  { key: 13, value: 14 },
  { key: 14, value: 15 },
  { key: 15, value: 16 },
  { key: 16, value: 17 },
  { key: 17, value: 18 },
  { key: 18, value: 19 },
  { key: 19, value: 20 },
  { key: 20, value: 21 },
  { key: 21, value: 22 },
  { key: 22, value: 23 },
  { key: 23, value: 24 },
  { key: 24, value: 25 },
  { key: 25, value: 26 },
  { key: 26, value: 27 },
  { key: 27, value: 28 },
  { key: 28, value: 29 },
  { key: 29, value: 30 },
];

export default AddHomeSilver;
