import React, { useContext } from 'react';
import { Modal, Typography, Select as AntdSelect, Button, Divider } from 'antd';
import { Formik, Field, Form } from 'formik';
import debounce from 'lodash.debounce';

import badgeStatus from '~/constants/badgesStatus';
import createStatusSelectOptions from '~/services/utils/createStatusSelectOptions';
import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { ProjectContext, useSearchProject } from '../../index';
import { createProject } from './index';
import { ActionsContainer } from './styles';

const { Title } = Typography;

export default function RegisterProject() {
  const { loadingProjects, projects, setSearch } = useSearchProject();

  const { registerVisible, setRegisterVisible, updateTableData } = useContext(
    ProjectContext
  );

  return (
    <Modal
      title={<Title level={4}>Cadastro de Equipe</Title>}
      footer={null}
      visible={registerVisible}
      onCancel={() => setRegisterVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <Formik
        initialValues={{
          team_name: undefined,
          parent_team: undefined,
          status: undefined,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await createProject(values, setRegisterVisible, updateTableData);

          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              id="team_name"
              name="team_name"
              label="Nome da Equipe"
              component={Input}
            />

            <Field
              id="parent_team"
              name="parent_team"
              label="Equipe Pai"
              loading={loadingProjects}
              component={AutoCompleteSelect}
              selectSchema={({ id, team_name }) => (
                <AntdSelect.Option key={id} value={id}>
                  {team_name}
                </AntdSelect.Option>
              )}
              onChange={e => {
                setFieldValue('parent_team', e);
                setSearch(undefined);
              }}
              search={debounce(setSearch, 800)}
              allowClear
              data={projects.results}
            />

            <Field
              id="status"
              name="status"
              label="Status"
              allowClear
              component={Select}
              onChange={formikStatus => {
                setFieldValue('status', formikStatus);
              }}
              data={createStatusSelectOptions([
                badgeStatus.APPROVED,
                badgeStatus.CANCELED,
                badgeStatus.NOT_REQUESTED,
                badgeStatus.DISAPPROVED,
                badgeStatus.IN_ANALYSIS,
                badgeStatus.IN_FINANCIAL_ANALYSIS,
                badgeStatus.APPROVED_BY_FINANCIAL,
                badgeStatus.REPROVED_BY_FINANCIAL,
              ])}
              renderItem={item => (
                <AntdSelect.Option key={item.key} value={item.key}>
                  {item.value}
                </AntdSelect.Option>
              )}
            />

            <ActionsContainer>
              <Button onClick={() => setRegisterVisible(false)}>
                Cancelar
              </Button>

              <Divider type="vertical" />

              <Button
                disabled={!values.team_name || !values.status}
                loading={isSubmitting}
                htmlType="submit"
                type="primary"
              >
                Cadastrar
              </Button>
            </ActionsContainer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
