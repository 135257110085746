import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Spin, Modal, Divider } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

import SearchPersons from '~/components/DataEntry/SearchPersons';
import Person from '../shared/Person';
import Search from '../shared/Search';
import { addManagers, deleteManager } from './index';
import { usePersons } from '../../index';
import { Container } from './styles';

const { Title, Paragraph } = Typography;
const { confirm } = Modal;

export default function Supervisors({ project }) {
  const [pageSize, setPageSize] = useState(5);
  const [name, setName] = useState(undefined);
  const { id, parent_team, parent_team_supervisors_display } = project;
  const { persons, loadingPersons, updatePersons } = usePersons({
    page_size: pageSize,
    page: 1,
    supervised_teams: id,
    name,
  });

  return (
    <Container>
      <Title level={4}>Escolha os responsáveis por esta Equipe</Title>
      <SearchPersons
        actionTitle="Atribuir"
        mode="multiple"
        params={{ supervised_teams__exclude: `${id},${parent_team || ''}` }}
        permissions="add_team"
        onSubmit={async values => {
          await addManagers(id, values);
          await updatePersons();
        }}
      />

      <Paragraph strong>Responsáveis pela Equipe Pai:</Paragraph>

      {parent_team_supervisors_display &&
        parent_team_supervisors_display.map(
          ({ id: personId, name_display }) => (
            <div key={personId}>
              <Divider />

              <Person name_display={name_display} />
            </div>
          )
        )}

      <Divider />

      <Search loading={loadingPersons} setSearch={setName} />

      <InfiniteScroll
        dataLength={persons.results.length}
        next={() => setPageSize(prev => prev + 5)}
        hasMore={persons.next !== null}
        loader={
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        }
        height="154px"
      >
        {persons.results.map(({ id: personId, name_display }) => (
          <Person
            key={personId}
            name_display={name_display}
            onRemove={() =>
              confirm({
                centered: true,
                title: `Remover ${name_display} como supervisor?`,
                onOk: async () => {
                  await deleteManager(id, personId);

                  await updatePersons();
                },
              })
            }
          />
        ))}
      </InfiniteScroll>
    </Container>
  );
}

Supervisors.defaultProps = {
  project: undefined,
};

Supervisors.propTypes = {
  project: PropTypes.objectOf(PropTypes.any),
};
