import React, { useEffect, useState } from 'react';
import { Button, Row, Modal, message } from 'antd';
import safeRead from 'safely-read';
import moment from 'moment';
import Holidays from './Holidays';
import api from '~/services/api';
import { HOLIDAYS_DELETE, HOLIDAYS } from '~/constants/endpoints/holidays';

const { confirm } = Modal;

export async function getHolidays() {
  try {
    const { data } = await api.get(HOLIDAYS);
    return data;
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'detail', [0]],
        `Ocorreu um erro ao tentar carregar histórico de feriados`
      )
    );

    return [];
  }
}

export async function fetchTableData(setLoadingTableData, setTableData) {
  setLoadingTableData(true);
  const data = await getHolidays();
  setTableData(data);
  setLoadingTableData(false);
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    function loadTableData() {
      fetchTableData(setLoadingTableData, setTableData);
    }

    loadTableData();
  }, []);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    getholidays: () => getHolidays(setTableData, setLoadingTableData),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      key: safeRead(item, ['id']),
      date: moment(item.date).format('DD/MM/YYYY'),
      description: safeRead(item, ['description'], '--'),
      weekDay: moment(item.date).format('dddd'),
      action: safeRead(item, ['id'], '--'),
    }));
  }

  return [];
}

export async function handleDelete(id) {
  try {
    await api.delete(HOLIDAYS_DELETE(id));
    message.success('Feriado Deletado com sucesso');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'detail', [0]],
        `Ocorreu um erro ao tentar deletar feriado`
      )
    );
  }
}

export const makeColumns = (setLoadingTableData, setTableData, fetchData) => {
  return [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Feriado',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
    },
    {
      title: 'Dia da semana',
      dataIndex: 'weekDay',
      key: 'weekDay',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, holiday) => (
        <Row>
          <Button
            onClick={() =>
              confirm({
                content: 'Deseja realmente excluir feriado?',
                onOk: async () => {
                  await handleDelete(holiday.key);
                  await fetchTableData(setLoadingTableData, setTableData);
                  await fetchData();
                },
              })
            }
            type="link"
          >
            Excluir
          </Button>
        </Row>
      ),
    },
  ];
};

export async function PostHoliday(values, setVisible) {
  const date = moment(values.date).format('YYYY-MM-DD');
  const description = safeRead(values, ['description']);

  try {
    await api.post(HOLIDAYS, { date, description });
    message.success('Feriado Adicionado com sucesso');
    setVisible(false);
  } catch (error) {
    setVisible(false);
    message.error(
      safeRead(error, ['detail'], 'Não foi possivel adicionar novo feriado')
    );
  }
}

export default Holidays;
