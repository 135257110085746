export const USER_PERMISSIONS_REQUEST = '@portal/USER_PERMISSIONS_REQUEST';
export const USER_PERMISSIONS_SUCCESS = '@portal/USER_PERMISSIONS_SUCCESS';
export const USER_PERMISSIONS_FAILURE = '@portal/USER_PERMISSIONS_FAILURE';
export const GET_USER_DATA_REQUEST = '@portal/GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = '@portal/GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = '@portal/GET_USER_DATA_FAILURE';

export function userPermissionsRequest() {
  return {
    type: USER_PERMISSIONS_REQUEST,
  };
}

export function userPermissionsSuccess(permissions) {
  return {
    type: USER_PERMISSIONS_SUCCESS,
    payload: { permissions },
  };
}

export function userPermissionsFailure() {
  return {
    type: USER_PERMISSIONS_FAILURE,
  };
}

export function getUserDataRequest() {
  return {
    type: GET_USER_DATA_REQUEST,
  };
}

export function getUserDataSuccess(data) {
  return {
    type: GET_USER_DATA_SUCCESS,
    payload: { data },
  };
}

export function getUserDataFailure() {
  return {
    type: GET_USER_DATA_FAILURE,
  };
}
