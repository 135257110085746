/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, Row, Select, Typography } from 'antd';
import { Map, Marker, TileLayer, Popup } from 'react-leaflet';
import safelyRead from 'safely-read';
import L from 'leaflet';
import { useDeepCompareEffect } from 'react-use';
import { useSelector } from 'react-redux';

import PageContainer from '~/components/Layout/PageContainer';
import Statistics from './Statistics/Statistics';
import WorkInfo from './WorkInfo';
import ProductionTable from './ProductionTable';
import history, {
  getQueryParam,
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import { AutoCompleteSelect } from '~/components/DataEntry/Select';
import { fetchTableData, HistoryContext, useCooperativeMember } from '.';
import { useCompensatoryStatistics } from './Statistics';
import { MapContainer } from './styles';
import formatDate from '~/services/utils/formatDate';
import mapMarker from '~/assets/image/map_marker.svg';

const { Title } = Typography;

function History({ values, setFieldValue }) {
  const { data: productionData } = useSelector(state => state.production);
  const [tableData, setTableData] = useState([]);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coordsData, setCoordsData] = useState([]);

  const cooperativeMemberId =
    Number(getQueryParam('cooperative_member_id')) || null;
  const isLeadView = !!cooperativeMemberId;
  const month = getQueryParam('month') || null;
  const year = getQueryParam('year') || null;
  const mesAno = values && values.mesAno;
  const { initialMember, fetchMemberByName } = useCooperativeMember(
    cooperativeMemberId
  );

  const {
    statistics,
    setStatistics,
    fetchStatisticsData,
    loading: loadingStatistics,
    setLoading: setLoadingStatistics,
  } = useCompensatoryStatistics(cooperativeMemberId);

  const contextParams = {
    isLeadView,
    cooperativeMemberId,
    fetchTableData: () =>
      fetchTableData(
        cooperativeMemberId,
        setLoading,
        setTableData,
        setFormattedTableData,
        month,
        year
      ),
    setStatistics,
    fetchStatisticsData,
    statistics,
    loadingStatistics,
    setLoadingStatistics,
  };

  useEffect(() => {
    if (month > 12 || month < 1 || !month || !year) {
      const queries = getAllQueriesParams();

      pushWithQueriesParams(history.location.pathname, {
        year: moment().format('YYYY'),
        month: moment().format('MM'),
        ...queries,
      });
    }
  }, []);

  useEffect(() => {
    if (initialMember.length) {
      setFieldValue('cooperativeField', cooperativeMemberId);
    }
  }, [initialMember]);

  useDeepCompareEffect(() => {
    fetchTableData(
      cooperativeMemberId,
      setLoading,
      setTableData,
      setFormattedTableData,
      month,
      year
    );
  }, [mesAno, cooperativeMemberId, productionData]);

  useEffect(() => {
    const filteredValidCoordinates = tableData
      .map(item =>
        safelyRead(item, ['timeline'], []).filter(
          coord => coord.latitude && coord.longitude
        )
      )
      .filter(item => item.length);

    setCoordsData(filteredValidCoordinates);
  }, [tableData]);

  // Coordenadas de Brasília
  const centerCoords = [-15.7801, -47.9292];

  const iconMarker = new L.Icon({
    iconUrl: mapMarker,
    iconRetinaUrl: mapMarker,
    iconSize: new L.Point(40, 40),
  });

  return (
    <PageContainer
      title={isLeadView ? 'Histórico de Produção' : 'Minha Produção'}
    >
      <HistoryContext.Provider value={contextParams}>
        {isLeadView && (
          <Row>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
              <Field
                id="cooperativeField"
                name="cooperativeField"
                component={AutoCompleteSelect}
                onChange={val => {
                  const queries = getAllQueriesParams();
                  return pushWithQueriesParams(history.location.pathname, {
                    ...queries,
                    cooperative_member_id: val,
                  });
                }}
                label="Cooperado"
                initialData={initialMember}
                renderItem={item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                )}
                onSearch={fetchMemberByName}
                allowClear={false}
              />
            </Col>
          </Row>
        )}
        <Statistics />
        <WorkInfo />
        <ProductionTable
          rawData={tableData}
          formattedData={formattedTableData}
          loading={loading}
        />
        <br />
        <br />
        <Title level={3}>Localização</Title>
        <MapContainer>
          <Map center={centerCoords} zoom={12}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {coordsData.map(mark =>
              mark.map(coord => (
                <Marker
                  key={JSON.stringify(coord)}
                  position={[coord.latitude, coord.longitude]}
                  icon={iconMarker}
                >
                  <Popup>
                    Data: {formatDate(coord.worktime_clock)}
                    <br />
                    Horas: {moment(coord.worktime_clock).format('HH:mm')}
                  </Popup>
                </Marker>
              ))
            )}
          </Map>
        </MapContainer>
      </HistoryContext.Provider>
    </PageContainer>
  );
}

export default History;

History.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
