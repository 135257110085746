import { useQuery } from 'react-query';
import api from '~/services/api';
import { parseResponseData } from '../../../../services/utils/parseResponseData';
import { useGetTeamKey } from './keys';

export const useGetTeam = (name, options) => {
  return useQuery(
    useGetTeamKey(name),
    () =>
      api
        .get(
          `team/?team_name__icontains=${name}&status=ativo&is_directorship=True`
        )
        .then(parseResponseData),
    options
  );
};
