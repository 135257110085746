import prodControl from '~/assets/image/production-control.png';
import calendarSchedule from '~/assets/image/calendar-schedule.png';
// import rpcAccess from '~/assets/image/rpc-access.png';

export default [
  {
    key: 1,
    image: prodControl,
    title: 'Controle sua produção',
    subtitle: `Registre suas entradas e saídas diárias. 
    Esqueceu de registrar? A rasura rápida te ajuda a poupar tempo.`,
  },
  {
    key: 2,
    image: calendarSchedule,
    title: 'Agende suas férias',
    subtitle: `Agendar suas férias não tem que ser um problema. Solicitou e foi
    aprovado? Pronto, já está agendado!`,
  },
  // {
  //   key: 3,
  //   image: rpcAccess,
  //   title: 'Acesse seu RPC',
  //   subtitle: `Mais agilidade para visualizar o seu RPC`,
  // },
];
