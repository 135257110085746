import React, { useContext } from 'react';
import safeRead from 'safely-read';
import Table from '~/components/DataDisplay/Table';
import { useTableData, makeColumns, formatTableData } from './index';
import { getAllQueriesParams } from '~/services/history';
import { HitByShutdownContext } from '../HitByShutdown';

export default function TeamTable() {
  const { tableData, loadingTableData, setTableData } = useTableData();
  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);
  const { page, page_size } = getAllQueriesParams();

  const {
    isLoading,
    setIsLoading,
    setDataDetail,
    setDetailModalVisible,
  } = useContext(HitByShutdownContext);
  return (
    <Table
      dataSource={formatTableData(tableRows)}
      columns={makeColumns(
        setDetailModalVisible,
        setIsLoading,
        setDataDetail,
        isLoading,
        setTableData,
        tableData,
      )}
      loading={loadingTableData}
      paginated
      currentPage={parseInt(page, 10) || 1}
      count={tableCount}
      pageSize={parseInt(page_size, 10) || 10}
    />
  );
}
