import safeRead from 'safely-read';
import { message } from 'antd';
import Professional from './Professional';
import api from '~/services/api';
import { PATCH_PERSON } from '~/constants/endpoints/person';

export async function PatchPerson(id, values) {
  const status = safeRead(values, ['status']);
  const phone_extension = safeRead(values, ['phone_extension']);
  const working_hours = safeRead(values, ['working_hours']);
  const starting_time = safeRead(values, ['starting_time']);
  const default_team = safeRead(values, ['default_team']);
  const start_working_day = safeRead(values, ['start_working_day']);
  const night_shift = safeRead(values, ['night_shift']);

  try {
    await api.patch(PATCH_PERSON(id), {
      status,
      phone_extension,
      working_hours,
      starting_time,
      default_team,
      start_working_day,
      night_shift,
    });
    message.success('Cadastro alterado com sucesso.');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'start_working_day'],
        'Não foi possível realizar a operação.'
      )
    );
  }
}
export default Professional;
