import React, { useContext } from 'react';
// import { Button } from 'antd';

import badgeStatus from '~/constants/badgesStatus';
import checkPermission from '~/services/utils/checkPermission';
import MultipleActionButton from '~/components/MultipleActionButton';
import { TableActionsContainer } from '../styles';
import { AbsenceOvertimeContext } from '../index';

export default function TableActions() {
  const {
    setConfirmationModalVisible,
    setConfirmationType,
    setItemsToUpdateStatus,
    selectedRowKeys,
    setFrom,
  } = useContext(AbsenceOvertimeContext);

  return (
    <>
      {checkPermission('change_request') ? (
        <TableActionsContainer>
          <MultipleActionButton
            actions={[
              {
                title: 'Aprovar',
                action: () => {
                  setFrom('tableActions');
                  setItemsToUpdateStatus(selectedRowKeys);
                  setConfirmationType(badgeStatus.APPROVED);
                  setConfirmationModalVisible(true);
                },
              },
              {
                title: 'Reprovar',
                action: () => {
                  setFrom('tableActions');
                  setItemsToUpdateStatus(selectedRowKeys);
                  setConfirmationType(badgeStatus.DISAPPROVED);
                  setConfirmationModalVisible(true);
                },
              },
              // {
              //   title: 'Encaminhar ao financeiro',
              //   action: () => {
              //     console.log('Encaminhar ao financeiro');
              //   },
              // },
              // {
              //   title: 'Aprovar (Financeiro)',
              //   action: () => {
              //     console.log('Aprovar (Financeiro)');
              //   },
              // },
              // {
              //   title: 'Reprovar (Financeiro)',
              //   action: () => {
              //     console.log('Reprovar (Financeiro)');
              //   },
              // },
            ]}
          >
            Ações
          </MultipleActionButton>

          {/* <Button type="primary">Incluir Pacote</Button> */}
        </TableActionsContainer>
      ) : null}
    </>
  );
}
