import styled from 'styled-components';
import Table from '~/components/DataDisplay/Table';

export const HistoricalTable = styled(Table)`
  .ant-table-body {
    cursor: grab;
  }

  .ant-table-body.on {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
`;
