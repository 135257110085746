import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.production_history';

export default defineMessages({
  pageTitle: {
    id: `${scope}.page_title`,
    defaultMessage: 'Minha Produção',
  },
  compTimeApproved: {
    id: `${scope}.comptime_approved`,
    defaultMessage: 'Banco de horas aprovadas',
  },
  compTimeInAnalisys: {
    id: `${scope}.comptime_in_analisys`,
    defaultMessage: 'Banco de horas em análise',
  },
  compTimeNotRequested: {
    id: `${scope}.comptime_not_requested`,
    defaultMessage: 'Banco de horas não solicitadas',
  },
  monthYear: {
    id: `${scope}.month_year`,
    defaultMessage: 'Mês/Ano',
  },
  workingHours: {
    id: `${scope}.workingHours`,
    defaultMessage: 'Jornada',
  },
  workingDays: {
    id: `${scope}.working_days`,
    defaultMessage: 'Dias úteis deste mês',
  },
  expectedHoursMonth: {
    id: `${scope}.expected_hours_month`,
    defaultMessage: 'Horas previstas para este mês',
  },
  hoursLeftProduce: {
    id: `${scope}.hours_left_produce`,
    defaultMessage: 'Horas restantes a produzir',
  },
  workedHours: {
    id: `${scope}.workedHours`,
    defaultMessage: 'Horas produzidas',
  },
  absence_analyze: {
    id: `${scope}.absence_analyze`,
    defaultMessage: 'Horas abonadas',
  },
  empty: {
    id: 'portal.pages.rectification.empty',
    defaultMessage: 'vazio',
  },
  columnDay: {
    id: `${scope}.column_day`,
    defaultMessage: 'Dia',
  },
  columnProduction: {
    id: `${scope}.column_production`,
    defaultMessage: 'Produção',
  },
  columnBalance: {
    id: `${scope}.column_balance`,
    defaultMessage: 'Saldo',
  },
  columnAbsenceOvertime: {
    id: `${scope}.column_absence_overtime`,
    defaultMessage: 'Ausências e Horas Extras',
  },
  columnCheckIn: {
    id: `${scope}.column_checkin`,
    defaultMessage: 'Entrada',
  },
  columnCheckOut: {
    id: `${scope}.column_checkout`,
    defaultMessage: 'Saída',
  },
  reason: {
    id: `${scope}.reason`,
    defaultMessage: 'Motivo (opcional)',
  },
  requestRectification: {
    id: `${scope}.request_rectification`,
    defaultMessage: 'Solicitar Rasura',
  },
  cancelButton: {
    id: 'portal.sider.worktime.modal.rectification.cancel',
    defaultMessage: 'Cancelar',
  },
  sendButton: {
    id: 'portal.authentication.recover.send',
    defaultMessage: 'Enviar',
  },
  rectificaitonButton: {
    id: `${scope}.rectification`,
    defaultMessage: 'Rasurar',
  },
});
