import React, { useContext } from 'react';
import { Row, Col, Typography } from 'antd';
import safeRead from 'safely-read';
import { InfoHolder, Title } from '../../styles';
import { UserRegistryContext } from '../../index';
import moment from 'moment';

const { Text } = Typography;

export default function PersonalData() {
  const { userData } = useContext(UserRegistryContext);

  return (
    <Row gutter={24}>
      <Col sm={24} md={24} lg={12} xl={12}>
        <InfoHolder>
          <Title level={4}>Identificação</Title>
          <br />
          <br />
          <Text>Nome Completo: </Text>
          <Text strong>{safeRead(userData, ['name_display'], ['--'])}</Text>
          <br />
          <br />
          <Text>Nacionalidade: </Text>
          <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          <br />
          <br />
          <Text>Data de nascimento: </Text>
          <Text strong>
            {safeRead(userData, ['date_of_birth'], ['--'], value =>
              moment(value).format('DD/MM/YYYY')
            )}
          </Text>
          <br />
          <br />
          <Text>Sexo: </Text>
          <Text strong>{safeRead(userData, ['sex'], ['--'])}</Text>
          <br />
          <br />
          <Text>Estado Civil: </Text>
          <Text strong>{safeRead(userData, ['marital_status'], ['--'])}</Text>
          <br />
          <br />
          <Text>
            Escolaridade:{' '}
            <Text strong>
              {safeRead(userData, ['schooling_level'], ['--'])}
            </Text>
          </Text>
        </InfoHolder>
      </Col>
      <Col sm={24} md={24} lg={12} xl={12}>
        <InfoHolder>
          <Title level={4}>Endereço residencial</Title>
          <br />
          <br />
          <Text>
            CEP: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Estado: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Cidade: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Logradouro: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Número: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Bairro: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
        </InfoHolder>
      </Col>
      <Col sm={24} md={24} lg={12} xl={12}>
        <InfoHolder>
          <Title level={4}>Documentos de identificação</Title>
          <br />
          <br />
          <Text>
            CPF: <Text strong>{safeRead(userData, ['cpf'], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            RG: <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Órgão Emissor/Estado:{' '}
            <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            PIS (INSS): <Text strong>{safeRead(userData, [''], ['--'])}</Text>
          </Text>
          <br />
          <br />
        </InfoHolder>
      </Col>
      <Col sm={24} md={24} lg={12} xl={12}>
        <InfoHolder>
          <Title level={4}>Contato pessoal</Title>
          <br />
          <br />
          <Text>
            E-mail:{' '}
            <Text strong>{safeRead(userData, ['personal_email'], ['--'])}</Text>
          </Text>
          <br />
          <br />
          <Text>
            Telefone:{' '}
            <Text strong>
              {safeRead(userData, ['primary_phone_number'], ['--'])}
            </Text>
          </Text>
        </InfoHolder>
      </Col>
    </Row>
  );
}
