import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GenericLayout from '../Layout/GenericLayout';
import DashboardLayout from '../Layout/DashboardLayout';
import Loading from '~/pages/Loading';
import checkPermission from '~/services/utils/checkPermission';

export default function RouteWrapper({
  notFound,
  component: Component,
  isPrivate = false,
  cooperative_member,
  permissions,
  operation,
  ...rest
}) {
  const { signed, refreshingToken } = useSelector(state => state.auth);
  const { profile, loading: loadingProfile } = useSelector(state => state.user);
  const { loading: loadingPermissions } = useSelector(
    state => state.permissions
  );

  if (permissions && !loadingPermissions && !loadingProfile && profile.id) {
    if (!checkPermission(permissions, operation)) {
      return <Redirect to="/404" />;
    }
  }

  if (
    !loadingProfile &&
    cooperative_member &&
    !profile.is_cooperative_member &&
    profile.id
  ) {
    return <Redirect to="/404" />;
  }

  if (!signed && isPrivate && !refreshingToken) {
    return <Redirect to="/autenticacao" />;
  }

  if (signed && !isPrivate && !notFound) {
    return <Redirect to="/" />;
  }

  const Layout = signed ? DashboardLayout : GenericLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <>
          {refreshingToken ||
          loadingProfile ||
          loadingPermissions ||
          (!profile.id && loadingProfile) ? (
            <Loading />
          ) : (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        </>
      )}
    />
  );
}

RouteWrapper.defaultProps = {
  isPrivate: undefined,
  notFound: undefined,
  cooperative_member: undefined,
  permissions: undefined,
  operation: undefined,
};

RouteWrapper.propTypes = {
  component: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool,
  notFound: PropTypes.bool,
  cooperative_member: PropTypes.bool,
  operation: PropTypes.string,
  permissions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};
