/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { Modal, Button, Divider, Typography, Select as AntdSelect } from 'antd';
import Select from '~/components/DataEntry/Select';

import { fieldType } from './index';
import { ButtonGroup } from './styles';

import PaidLeave from './PaidLeave';
import DayOff from './DayOff';
import formatDate from '~/services/utils/formatDate';
import { VacationsAllowenceContext } from '../ManageVacationsAllowences';

const { Title } = Typography;

function RequestDayOff({
  visible,
  setVisible,
  referencePeriods,
  selectedPeriod,
  setSelectedPeriod,
  selectedUser,
  setFieldValue,
  fetchData,
  fetchTableData,
  values,
  setSubmitting,
  isSubmitting,
}) {
  const { reference_period } = values;

  const { handleSetReferencePeriodQuery } = useContext(
    VacationsAllowenceContext
  );

  const [type, setType] = useState(fieldType.paidLeave);
  const [paidLeaveInfoLoading, setPaidLeaveInfoLoading] = useState(false);
  const [paidDayOffInfoLoading, setPaidDayOffInfoLoading] = useState(false);
  const [lastDayOfVacationsOfDayOff, setLastDayOfVacationsOfDayOff] = useState(
    {}
  );
  const [
    lastDayOfVacationsOfDayOffLoading,
    setLastDayOfVacationsOfDayOffLoading,
  ] = useState(false);

  useEffect(() => {
    setFieldValue('reference_period', selectedPeriod);
  }, []);

  useEffect(() => {
    if (reference_period) {
      const fetchInfo = async () => {
        setPaidLeaveInfoLoading(true);
        await handleSetReferencePeriodQuery(reference_period);
        setPaidLeaveInfoLoading(false);
      };

      fetchInfo();
    }
  }, [reference_period]);

  return (
    <Modal
      title={<Title level={4}>Solicitar Descanso</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <ButtonGroup>
        <Button
          onClick={() => setType(fieldType.paidLeave)}
          type={type === fieldType.paidLeave ? 'primary' : 'default'}
        >
          Férias
        </Button>
        <Button
          onClick={() => setType(fieldType.dayOff)}
          type={type === fieldType.paidLeave ? 'default' : 'primary'}
        >
          Abono
        </Button>
      </ButtonGroup>

      <Divider />

      <Field
        id="year-field"
        name="reference_period"
        label="Período aquisitivo"
        component={Select}
        style={{ width: '100%', maxWidth: 320 }}
        data={referencePeriods}
        onChange={e => {
          setSelectedPeriod(e);
          setFieldValue('total_days', undefined);
        }}
        renderItem={period => (
          <AntdSelect.Option key={period[0]} value={period[0]}>
            {formatDate(period[0])} - {formatDate(period[1])}
          </AntdSelect.Option>
        )}
      />

      {type === fieldType.paidLeave ? (
        <PaidLeave
          type={type}
          visible={visible}
          setVisible={setVisible}
          referencePeriods={referencePeriods}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          selectedUser={selectedUser}
          setFieldValue={setFieldValue}
          fetchData={fetchData}
          fetchTableData={fetchTableData}
          values={values}
          setSubmitting={setSubmitting}
          isSubmitting={isSubmitting}
          paidLeaveInfoLoading={paidLeaveInfoLoading}
          setPaidLeaveInfoLoading={setPaidLeaveInfoLoading}
          lastDayOfVacationsOfDayOff={lastDayOfVacationsOfDayOff}
          setLastDayOfVacationsOfDayOff={setLastDayOfVacationsOfDayOff}
          lastDayOfVacationsOfDayOffLoading={lastDayOfVacationsOfDayOffLoading}
          setLastDayOfVacationsOfDayOffLoading={
            setLastDayOfVacationsOfDayOffLoading
          }
        />
      ) : (
        <DayOff
          type={type}
          visible={visible}
          setVisible={setVisible}
          referencePeriods={referencePeriods}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          selectedUser={selectedUser}
          setFieldValue={setFieldValue}
          fetchData={fetchData}
          fetchTableData={fetchTableData}
          values={values}
          setSubmitting={setSubmitting}
          isSubmitting={isSubmitting}
          paidDayOffInfoLoading={paidDayOffInfoLoading}
          setPaidDayOffInfoLoading={setPaidDayOffInfoLoading}
          lastDayOfVacationsOfDayOff={lastDayOfVacationsOfDayOff}
          setLastDayOfVacationsOfDayOff={setLastDayOfVacationsOfDayOff}
          lastDayOfVacationsOfDayOffLoading={lastDayOfVacationsOfDayOffLoading}
          setLastDayOfVacationsOfDayOffLoading={
            setLastDayOfVacationsOfDayOffLoading
          }
        />
      )}
    </Modal>
  );
}

RequestDayOff.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  referencePeriods: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setSubmitting: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  setSelectedPeriod: PropTypes.func.isRequired,
  selectedUser: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchTableData: PropTypes.func.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    reference_period: undefined,
    total_days: undefined,
    begin_date: undefined,
    receive_advance_money: false,
  }),
})(RequestDayOff);
