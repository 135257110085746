import produce from 'immer';
import { CHANGE_LOCALE } from './actions';

const INITIAL_STATE = {
  locale: 'pt-BR',
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case CHANGE_LOCALE: {
        draft.locale = action.payload.languageLocale;
        break;
      }
      default:
    }
  });
}
