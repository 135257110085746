import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import safeRead from 'safely-read';
import AbsenceOvertime from './AbsenceOvertime';
import { getQueryParam } from '~/services/history';
import { REQUEST_ABSENCE_OVERTIME } from '~/constants/endpoints/request';
import renderStatusBadges from '~/services/utils/renderStatusBadge';
import api from '~/services/api';

export function renderAction(item, setItemData, toggleModal) {
  return (
    <Button
      type="link"
      onClick={() => {
        setItemData(item);
        toggleModal();
      }}
    >
      {item.status === 'nao_solicitado'
        ? 'Confirmar solicitação  '
        : 'Detalhes'}
    </Button>
  );
}

export const renderColumns = (setItemData, toggleModal) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    ellipsis: true,
    width: 80,
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    title: 'Data',
    dataIndex: 'date',
    key: 'date',
    ellipsis: true,
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    title: 'Solicitação',
    dataIndex: 'request',
    key: 'request',
    ellipsis: true,
  },
  {
    title: 'Duração',
    dataIndex: 'duration',
    key: 'duration',
    ellipsis: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    ellipsis: true,
    render: renderStatusBadges,
  },
  {
    title: '',
    dataIndex: 'action',
    key: 'action',
    ellipsis: true,
    render: (id, row) => {
      const selectedItem = row.responseData.filter(
        filtered => filtered.id === id
      );

      return renderAction(
        selectedItem && selectedItem[0],
        setItemData,
        toggleModal
      );
    },
  },
];

export async function fetchTableData(
  setTableData,
  setLoadingTableData,
  setTotalPages,
  queryParams
) {
  const {
    id,
    date_start,
    date_end,
    request,
    status,
    page,
    page_size,
    ordering,
  } = queryParams;

  setLoadingTableData(true);

  try {
    const { data } = await api.get(REQUEST_ABSENCE_OVERTIME, {
      params: {
        id,
        date_start,
        date_end,
        request_type: request,
        status,
        page,
        page_size,
        ordering,
      },
    });

    const response = safeRead(data, ['results'], [], item =>
      item.map(req => {
        let duration = req.hours;
        if (moment(req.hours, 'HH:mm:ss').isValid()) {
          duration = moment(req.hours, 'HH:mm:ss').format('HH:mm');
        } else {
          duration = (duration + '')
            .split(':')
            .slice(0, -1)
            .join(':');
        }
        return {
          key: req.id,
          id: req.id,
          requestDate: moment(req.created_at).format('DD/MM/YYYY'),
          request: req.request_type_display,
          date: moment(req.date).format('DD/MM/YYYY'),
          duration,
          status: { status: req.status, status_display: req.status_display },
          action: req.id,
          responseData: data && data.results,
        };
      })
    );

    setTableData(response);
    setTotalPages(safeRead(data, ['count'], 0));
  } catch (error) {
    setTableData([]);
  }

  setLoadingTableData(false);
}

export const AbsenceOvertimeContext = React.createContext({});

export default function Index() {
  const [tableData, setTableData] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [confirmRequestModalVisible, setConfirmRequestModalVisible] = useState(
    false
  );
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [itemData, setItemData] = useState(null);

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const pageSize = parseInt(getQueryParam('page_size'), 10) || 10;
  const id = getQueryParam('id');
  const date_start = getQueryParam('date_start');
  const date_end = getQueryParam('date_end');
  const request = getQueryParam('request');
  const status = getQueryParam('status');
  const ordering = getQueryParam('ordering');

  useEffect(() => {
    const params = {
      id,
      date_start,
      date_end,
      request,
      status,
      page,
      page_size: pageSize,
      ordering,
    };

    fetchTableData(setTableData, setLoadingTableData, setTotalPages, params);
  }, [page, pageSize, status, id, date_start, date_end, request, ordering]);

  const contextValues = {
    detailModalVisible,
    toggleDetailModalVisible: () => setDetailModalVisible(prev => !prev),
    confirmRequestModalVisible,
    toggleConfirmRequestModal: () =>
      setConfirmRequestModalVisible(prev => !prev),
    requestModalVisible,
    toggleRequestModalVisible: () => setRequestModalVisible(prev => !prev),
    itemData,
    setItemData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, setTotalPages, {
        id,
        date_start,
        date_end,
        request,
        status,
        page,
        page_size: pageSize,
        ordering,
      }),
  };

  return (
    <AbsenceOvertimeContext.Provider value={contextValues}>
      <AbsenceOvertime
        tableColumns={renderColumns}
        tableData={tableData}
        setTableData={setTableData}
        loadingTableData={loadingTableData}
        setLoadingTableData={setLoadingTableData}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        pageSize={pageSize}
        page={page}
        id={id}
        date_start={date_start}
        date_end={date_end}
        request={request}
        status={status}
        ordering={ordering}
      />
    </AbsenceOvertimeContext.Provider>
  );
}
