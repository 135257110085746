/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Divider, Typography, Select as AntdSelect } from 'antd';

import { withFormik, Field } from 'formik';
import moment from 'moment';
import history, {
  pushWithQueriesParams,
  getQueryParam,
} from '~/services/history';
import { fieldType } from './index';

import Select from '~/components/DataEntry/Select';

import DayOff from './DayOff/DayOff';
import PaidLeave from './PaidLeave/PaidLeave';

import { ButtonGroup } from './styles';

const { Title } = Typography;

function RequestDayOff({
  referencePeriods,
  visible,
  setVisible,
  setFieldValue,
  values,
}) {
  const referencePeriod = getQueryParam('reference_period');

  const [type, setType] = useState(fieldType.paidLeave);
  const [lastDayOfVacationsOfDayOff, setLastDayOfVacationsOfDayOff] = useState(
    {}
  );
  const [
    lastDayOfVacationsOfDayOffLoading,
    setLastDayOfVacationsOfDayOffLoading,
  ] = useState(false);

  useEffect(() => {
    setFieldValue('reference_period', referencePeriod);
  }, []);

  return (
    <Modal
      title={<Title level={4}>Solicitar Descanso</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <ButtonGroup>
        <Button
          onClick={() => setType(fieldType.paidLeave)}
          type={type === fieldType.paidLeave ? 'primary' : 'default'}
        >
          Férias
        </Button>
        <Button
          onClick={() => setType(fieldType.dayOff)}
          type={type === fieldType.paidLeave ? 'default' : 'primary'}
        >
          Abono
        </Button>
      </ButtonGroup>

      <Divider />

      <Field
        id="year-field"
        name="reference_period"
        label="Período aquisitivo"
        component={Select}
        style={{ width: '100%', maxWidth: 320 }}
        data={referencePeriods}
        onChange={item => {
          pushWithQueriesParams(history.location.pathname, {
            reference_period: item,
          });
          setFieldValue('reference_period', item);
          setFieldValue('total_days', undefined);
        }}
        renderItem={({ start, end }) => (
          <AntdSelect.Option key={`${start}*${end}`} value={`${start}`}>
            {`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format(
              'DD/MM/YYYY'
            )}`}
          </AntdSelect.Option>
        )}
      />

      {type === fieldType.paidLeave && (
        <PaidLeave
          type={type}
          lastDayOfVacationsOfDayOff={lastDayOfVacationsOfDayOff}
          setLastDayOfVacationsOfDayOff={setLastDayOfVacationsOfDayOff}
          lastDayOfVacationsOfDayOffLoading={lastDayOfVacationsOfDayOffLoading}
          setLastDayOfVacationsOfDayOffLoading={
            setLastDayOfVacationsOfDayOffLoading
          }
          referencePeriods={referencePeriods}
          setVisible={setVisible}
          referencePeriod={values.reference_period}
        />
      )}

      {type === fieldType.dayOff && (
        <DayOff
          type={type}
          lastDayOfVacationsOfDayOff={lastDayOfVacationsOfDayOff}
          setLastDayOfVacationsOfDayOff={setLastDayOfVacationsOfDayOff}
          lastDayOfVacationsOfDayOffLoading={lastDayOfVacationsOfDayOffLoading}
          setLastDayOfVacationsOfDayOffLoading={
            setLastDayOfVacationsOfDayOffLoading
          }
          referencePeriods={referencePeriods}
          setVisible={setVisible}
          referencePeriod={values.reference_period}
        />
      )}
    </Modal>
  );
}

RequestDayOff.propTypes = {
  referencePeriods: PropTypes.arrayOf(PropTypes.any).isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    reference_period: undefined,
  }),
})(RequestDayOff);
