import styled from 'styled-components';

export const Container = styled.button`
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.borderColorBase};
  border-radius: 4px;
  cursor: pointer;
  background: transparent;

  &:hover {
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;
export const Title = styled.h2`
  font-size: 17px;
  font-weight: bold;
  color: ${({ theme }) => theme.textColor};
`;
export const Description = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.textColor};
`;
