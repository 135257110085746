import React, { useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Select, Button } from 'antd';
import debounce from 'lodash.debounce';

import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { useSearchUsers, postUsersToGroup } from './index';
import { AddUserContext } from '../index';
import { AddUserSearchContainer } from '../styles';

export default function SearchForm() {
  const { updateUsers, profile_id, fetchTableData } = useContext(AddUserContext);

  const { usersResponse, setSearch, loading } = useSearchUsers(profile_id);

  return (
    <Formik
      initialValues={{
        users: [],
      }}
      onSubmit={({ users }, { resetForm }) => {
        postUsersToGroup(profile_id, users, updateUsers, fetchTableData);

        resetForm();
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <AddUserSearchContainer>
            <Field
              id="users-input-select"
              name="users"
              label="Usuários"
              mode="multiple"
              loading={loading}
              component={AutoCompleteSelect}
              selectSchema={({ id, name_display, groups }) => (
                <Select.Option key={id} value={id} groups={groups}>
                  {name_display}
                </Select.Option>
              )}
              onChange={e => {
                setFieldValue('users', e);
                setSearch(undefined);
              }}
              search={debounce(setSearch, 800)}
              allowClear
              data={usersResponse}
            />

            <div style={{ width: 20 }} />

            <Button
              type="primary"
              htmlType="submit"
              disabled={values.users.length === 0}
            >
              Atribuir
            </Button>
          </AddUserSearchContainer>
        </Form>
      )}
    </Formik>
  );
}
