import styled from 'styled-components';
import { tabletWidth, mobileWidth } from '~/constants/metrics';

export const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;

  width: 100%;
`;

export const Item = styled.div`
  flex-grow: 0;
  width: ${({ width }) => width || '33%'};
  margin-bottom: 40px;

  @media (max-width: ${tabletWidth}) {
    width: ${({ width }) => (width && width === '100%' ? width : '50%')};
  }

  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;
