import styled from 'styled-components';
import { darken } from 'polished';
import { desktopWidth } from '~/constants/metrics';

export const Table = styled.table`
  td {
    border-bottom: 0 !important;
    padding: 8px;
    width: 150px;
  }

  .tb-header {
    text-align: center;
  }

  .tb-description {
    text-align: right;
  }

  .tb-vacation-cell {
    background-color: seagreen !important;
    text-align: center;
    color: white;
  }

  .tb-vacation-middle {
    border-top: 1px solid ${darken(0.05, 'seagreen')} !important;
    border-bottom: 1px solid ${darken(0.05, 'seagreen')} !important;
  }

  .tb-first {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .tb-first-left {
    border-top-left-radius: 8px;
  }

  .tb-last {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .tb-last-left {
    border-bottom-left-radius: 8px;
    background-color: ${darken(0.05, 'seagreen')} !important;
    font-weight: bold;
  }

  .tb-description-cell {
    text-align: right;
    width: 5%;
  }

  .tb-allowence-cell {
    background-color: steelblue !important;
    text-align: center;
    color: white;
  }

  .tb-allowence-middle {
    border-top: 1px solid ${darken(0.05, 'steelblue')} !important;
    border-bottom: 1px solid ${darken(0.05, 'steelblue')} !important;
  }

  .tb-first-right {
    border-top-right-radius: 8px;
  }

  .tb-last-right {
    border-bottom-right-radius: 8px;
    background-color: ${darken(0.05, 'steelblue')} !important;
    font-weight: bold;
  }

  @media (max-width: ${desktopWidth}) {
    align-self: center;
  }
`;
