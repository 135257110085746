import styled from 'styled-components';
import { darken } from 'polished';
import { Button } from 'antd';

export const ButtonGroup = styled(Button.Group)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    width: 100%;
  }
`;

export const BackgroundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ col }) => (col ? 'column' : 'row')};

  border: 2px solid ${({ theme }) => darken(0.02, theme.componentBackground)};
  background-color: ${({ theme }) => darken(0.01, theme.componentBackground)};
  border-radius: 4px;
  padding: 10px 14px;
  margin-bottom: 24px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;
