import React, { useState, useMemo, useContext, useEffect } from 'react';
import PageContainer from '~/components/Layout/PageContainer';
import {
  Divider,
  Alert,
  Typography,
  Select,
  Button,
  Input,
  message,
} from 'antd';
import { TableView } from './components/TableView';
import { CreateNewTeamModal } from './components/CreateNewTeamModal';
import { DeleteTeamModal } from './components/DeleteTeamModal';
import * as S from './styles';
import { useDebounce } from '../../hooks/useDebounce';
import { useGetPerson } from './services/useGetPerson';
import { useGetTeam } from './services/useGetTeam';
import { useGetTeamList } from './services/useGetTeamList';
import { getQueryParam } from '~/services/history';
import { store } from '~/store';

const { Option } = Select;
export const TeamList = () => {
  // const {
  //   currentRow
  // } = useContext(ProfileContext);

  const userPermission = store.getState()?.user?.profile?.permissions;

  console.log('filteredPermissions', userPermission);
  // Input when states are salved
  const [teamName, setTeamName] = useState(undefined);
  const [nomeDiretor, setNomeDiretor] = useState(undefined);
  const [member, setMember] = useState(undefined);
  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  console.log(page, page_size);
  // Input when states are salved

  // search by terms an call api
  const [searchDiretoria, setSearchDiretoria] = useState('');
  const searchDiretoriaKey = useDebounce(searchDiretoria);
  const [searchPerson, setSearchPerson] = useState('');
  const searchPersonKey = useDebounce(searchPerson);
  // search by terms an call api

  const [editEquipe, setEditEquipe] = useState(null);
  const [deleteEquipe, setDeleteEquipe] = useState(null);

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { data: teamData, isLoading: teamLoading } = useGetTeam(
    searchDiretoriaKey
  );
  const { data: personData, isLoading: personLoading } = useGetPerson(
    searchPersonKey
  );
  const { data: teamList, isLoading: teamListLoading } = useGetTeamList(
    {
      name: teamName ?? '',
      directorship: nomeDiretor ?? '',
      member: member ?? '',
      page: page,
      pageSize: page_size,
    },
    {
      onError: () => {
        message.error('Erro ao listar equipes');
      },
    }
  );

  const renderTableData = () => {
    if (teamList?.results?.length) {
      const formattedList = teamList?.results.map(val => ({
        ...val,
        key: val.id,
        nome: val.name,
        diretor: val.director.name_display,
        coordenador: val.coordinator.name_display,
        leaderName: val.leader.name_display,
        status: val.status,
      }));
      return formattedList;
    }

    return [];
  };

  const renderDiretorOptions = useMemo(() => {
    if (teamData && teamData.results.length) {
      return teamData.results.map(diretores => (
        <Option key={diretores.id} value={diretores.id}>
          {diretores.team_name}
        </Option>
      ));
    }
    return null;
  }, [teamData]);

  const renderPersonsOptions = useMemo(() => {
    if (personData && personData.results.length) {
      return personData.results.map(cooperados => (
        <Option key={cooperados.id} value={cooperados.id}>
          {cooperados.name_display}
        </Option>
      ));
    }
    return null;
  }, [personData]);

  useEffect(() => {
    if(!open){
      setEditEquipe(null)
    }
  },[open])
  useEffect(() => {
    if(!deleteEquipe){
      setDeleteEquipe(null)
    }
  },[deleteEquipe])

  function onSearchDiretoria(val) {
    console.log('onSearchDiretoria:', val);
    setSearchDiretoria(val);
  }
  function onSearchPerson(val) {
    console.log('onSearchDiretoria:', val);
    setSearchPerson(val);
  }

  const onCleanFilters = () => {
    setTeamName(undefined);
    setNomeDiretor(undefined);
    setMember(undefined);
  };

  const onActionTable = teamItem => {
    if (teamItem?.key === 'EDIT') {
      console.log('edit', teamItem);
      setEditEquipe(teamItem.data);
      setOpen(true);
    } else {
      setDeleteEquipe(teamItem.data);
      setOpenDeleteModal(true);
    }
  };

  return (
    <PageContainer title="Listar equipe">
      <S.ContainerFilters>
        <S.ContainerInputs>
          <Input
            size="large"
            placeholder="Nome da equipe"
            onChange={e => setTeamName(e.target.value)}
            value={teamName}
          />
          <Select
            style={{ width: '100%' }}
            size="large"
            showSearch
            value={nomeDiretor}
            placeholder="Diretoria"
            optionFilterProp="children"
            onChange={val => setNomeDiretor(val)}
            onSearch={onSearchDiretoria}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderDiretorOptions}
          </Select>
          <Select
            style={{ width: '100%' }}
            size="large"
            showSearch
            value={member}
            placeholder="Integrante"
            optionFilterProp="children"
            onChange={val => setMember(val)}
            onSearch={onSearchPerson}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {renderPersonsOptions}
          </Select>
        </S.ContainerInputs>
        {userPermission.includes('add_squad') && (
          <S.ContainerButtons>
            <Button onClick={() => setOpen(true)} type="primary">
              Criar equipe
            </Button>
            <Button onClick={onCleanFilters}>Limpar filtros</Button>
          </S.ContainerButtons>
        )}
      </S.ContainerFilters>
      <Divider />
      <TableView
        teamListData={renderTableData()}
        count={teamList?.count}
        isLoading={teamListLoading}
        onActionTable={onActionTable}
      />
      <CreateNewTeamModal
        open={open}
        setOpen={setOpen}
        teamData={teamData}
        personData={personData}
        editTeam={editEquipe}
      />
      <DeleteTeamModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={deleteEquipe}
      />
    </PageContainer>
  );
};
