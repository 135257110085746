import { useQuery } from 'react-query';
import api from '~/services/api';
import { parseResponseData } from '../../../../services/utils/parseResponseData';
import { useGetPersonKey } from './keys';

export const useGetPerson = (name, options) => {
  return useQuery(
    useGetPersonKey(name),
    () => api.get(`person/?name=${name}`).then(parseResponseData),
    options
  );
};
