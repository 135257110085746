import React from 'react';
import moment from 'moment';
import { Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import {
  Container,
  TimelineContainer,
  TimelineItem,
  TimelineItemTitle,
  TimelineItemContent,
} from './styles';

const { Title } = Typography;

export default function VacationsAllowencesTimeline({ items }) {
  return (
    <>
      {items.months_proportions !== null && (
        <Container>
          <>
            <Title level={4}>Linha do tempo</Title>

            <TimelineContainer>
              {items &&
                (items.months_proportions || []).map((item, index, arr) => (
                  <div
                    key={index.toString()}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TimelineItem last={index === 11}>
                      <TimelineItemTitle>
                        {item.month.month_display.substring(0, 3)}
                      </TimelineItemTitle>

                      {item.obteined !== null ? (
                        <Tooltip
                          title={
                            item.propotion !== '0.0'
                              ? `${item.propotion.split('.')[0]} %`
                              : ''
                          }
                        >
                          <TimelineItemContent
                            position={index}
                            days={item.obteined}
                            first={index === 0}
                            last={
                              index === 11 ||
                              (arr[index + 1]
                                ? arr[index + 1] === null
                                : false) ||
                              arr[index + 1].obteined === null
                            }
                          >
                            {`${
                              item.obteined === 0
                                ? item.obteined
                                : `+ ${item.obteined}`
                            } dia(s)`}
                          </TimelineItemContent>
                        </Tooltip>
                      ) : (
                        <TimelineItemContent> </TimelineItemContent>
                      )}
                    </TimelineItem>

                    <div style={{ margin: '20px 0 0 0' }}>
                      {index === 0
                        ? moment(items.start_date).format('DD/MM/YYYY')
                        : null}

                      {index === arr.length - 1
                        ? moment(items.end_date).format('DD/MM/YYYY')
                        : null}
                    </div>
                  </div>
                ))}
            </TimelineContainer>
          </>
        </Container>
      )}
    </>
  );
}

VacationsAllowencesTimeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

VacationsAllowencesTimeline.defaultProps = {
  items: [],
};
