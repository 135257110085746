import produce from 'immer';
import { TOGGLE_THEME } from './actions';

const INITIAL_STATE = {
  colorScheme: 'light',
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_THEME:
      return produce(state, draft => {
        draft.colorScheme = state.colorScheme === 'light' ? 'dark' : 'light';
      });
    default:
      return state;
  }
}
