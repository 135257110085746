import { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';

import api from '~/services/api';
import { GET_PERSON } from '~/constants/endpoints/person';
import SearchPersons from './SearchPersons';

export async function fetchPersons(search, params) {
  let response = {
    count: 0,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(GET_PERSON, {
      params: { name: search, ...params },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['details'], 'Erro ao buscar persons.'));
  }

  return response;
}

export function useSearchPersons({
  supervised_teams__exclude,
  teams__exclude,
}) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [persons, setPersons] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadPersons() {
      setLoading(true);

      const data = await fetchPersons(search, {
        supervised_teams__exclude,
        teams__exclude,
      });

      setPersons(data);

      setLoading(false);
    }

    loadPersons();
  }, [supervised_teams__exclude, teams__exclude, search]);

  return {
    loading,
    setLoading,
    persons,
    setPersons,
    search,
    setSearch,
  };
}

export default SearchPersons;
