import styled from 'styled-components';
import { mobileWidth } from '~/constants/metrics';

export function makeStatusColor(status, theme) {
  return {
    em_analise: theme.warningColor,
    aprovado: theme.successColor,
    reprovado: theme.errorColor,
  }[status];
}

export const CircleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StatusCircle = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 5px;

  background-color: ${({ status, theme }) => makeStatusColor(status, theme)};
`;

export const ModalItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: 18px;

  @media (max-width: ${mobileWidth}) {
    flex-direction: column;
  }
`;
