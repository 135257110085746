import React from 'react';

import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm';
import UserTable from './UserTable';

export default function ManageUser() {
  return (
    <PageContainer title="Gerenciar Usuários">
      <SearchForm />
      <UserTable />
    </PageContainer>
  );
}
