import { useState, useEffect } from 'react';
import safeRead from 'safely-read';
import DateFilter from './DateFilter';
import { message } from 'antd';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import api from '~/services/api/index';
import { POSITION_ROLE } from '~/constants/endpoints/position';
import { TEAM } from '~/constants/endpoints/team';

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export function handleSearch(values, positionID) {
  const { ...queries } = getAllQueriesParams();
  const name = safeRead(values, ['nameField']);
  const team = safeRead(values, ['teamField']);
  const position = positionID;
  const page = 1;

  return pushWithQueriesParams(history.location.pathname, {
    ...queries,
    page,
    person_name: name,
    team_name: team,
    position: position,
  });
}

export function getInitialValues() {
  const { person_name, team_name, position } = getAllQueriesParams();

  return {
    nameField: person_name,
    teamField: team_name,
    position,
  };
}

export function clearFields(setValues) {
  setValues({
    nameField: null,
    teamField: null,
    position: null,
  });
  pushWithQueriesParams(history.location.pathname, {});
}

export async function fetchRole(setDisableField, setRoles) {
  try {
    const { data } = await api.get(POSITION_ROLE);
    setDisableField(false);
    setRoles(data);
  } catch (error) {
    setDisableField(true);
    setRoles([]);
  }
}

export default DateFilter;
