import React, { useContext } from 'react';
import {
  Row,
  Col,
  Typography,
  Select as AntdSelect,
  Button,
  Tooltip,
} from 'antd';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Field, Formik, Form } from 'formik';
import safeRead from 'safely-read';
import { useParams } from 'react-router-dom';
import { InfoHolder, Title } from '../../styles';
import Select from '~/components/DataEntry/Select';
import { UserRegistryContext } from '../../index';
import formatPrice from '~/services/utils/formatPrice';
import {
  PatchPersonBudgetLeader,
  PatchPersonBudgetPrepository,
  readBudgets,
} from './index';

const { Text } = Typography;

export default function Financial() {
  const status = [
    { id: 'Sim', value: 'true' },
    { id: 'Não', value: 'false' },
  ];

  const { id } = useParams();

  const {
    userData,
    setUserData,
    fetchData,
    setLoadingUserData,
    budgetLeader,
    budgetPrepository,
    tooltipVisible,
  } = useContext(UserRegistryContext);

  const formattedVerbaObject = readBudgets(userData.budgets_display);

  return (
    <>
      <Formik
        initialValues={{
          verba_preposto: safeRead(
            formattedVerbaObject,
            ['verba_preposto'],
            'false',
            value => (value.budget_kind === 'verba_preposto' ? 'true' : 'false')
          ),
          verba_lider: safeRead(
            formattedVerbaObject,
            ['verba_lider'],
            'false',
            value => (value.budget_kind === 'verba_lider' ? 'true' : 'false')
          ),
        }}
        onSubmit={values => {
          PatchPersonBudgetLeader(id, values);
          PatchPersonBudgetPrepository(id, values);
          fetchData(setUserData, id, setLoadingUserData);
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={24}>
              <Col sm={24} md={24} lg={12} xl={12}>
                <InfoHolder>
                  <Title level={4}>Dados bancários </Title>
                  <br />
                  <br />
                  <Text>
                    Tipo: <Text strong>{safeRead(userData, [''], '--')}</Text>
                  </Text>
                  <br />
                  <br />
                  <Text>
                    Banco: <Text strong>{safeRead(userData, [''], '--')}</Text>
                  </Text>
                  <br />
                  <br />
                  <Text>
                    Agência:{' '}
                    <Text strong>{safeRead(userData, [''], '--')}</Text>
                  </Text>
                  <br />
                  <br />
                  <Text>
                    DV: (Agência){' '}
                    <Text strong>{safeRead(userData, [''], '--')}</Text>
                  </Text>
                  <br />
                  <br />
                  <Text>
                    Conta: <Text strong>{safeRead(userData, [''], '--')}</Text>
                  </Text>
                  <br />
                  <br />
                  <Text>
                    DV (Conta):{' '}
                    <Text strong>{safeRead(userData, [''], '--')}</Text>
                  </Text>
                  <br />
                  <br />
                </InfoHolder>
              </Col>

              <Col sm={24} md={24} lg={12} xl={12}>
                <InfoHolder>
                  <Title level={4}>Valores e Verbas </Title>
                  <br />
                  <br />
                  <Text>
                    Valor/hora base:
                    <Text strong>
                      {safeRead(
                        userData,
                        [''],
                        ' R$ --,--',
                        amount => ` ${formatPrice(amount)}`
                      )}
                    </Text>
                  </Text>
                  <br />
                  <br />
                  <Text>
                    Valor/hora:
                    <Text strong>
                      {safeRead(
                        userData,
                        ['hourly_rate'],
                        ' R$ --,--',
                        amount => ` ${formatPrice(amount)}`
                      )}
                    </Text>
                  </Text>
                  <br />
                  <br />

                  <Row gutter={24}>
                    <Col sm={10} md={10} lg={10} xl={10} style={{ top: 8 }}>
                      <Text>Possui Verba Líder?</Text>
                    </Col>
                    <Col sm={6} md={6} lg={6} xl={6} align="middle">
                      <Field
                        id="verba_lider"
                        name="verba_lider"
                        placeholder="Selecione"
                        data={status}
                        renderItem={item => (
                          <AntdSelect.Option key={item.id} value={item.value}>
                            {item.id}
                          </AntdSelect.Option>
                        )}
                        component={Select}
                      />
                    </Col>
                    {tooltipVisible ? (
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingTop: 10, cursor: 'pointer' }}
                      >
                        <Tooltip
                          style={{ whiteSpace: 'pre' }}
                          placement="right"
                          title={`Valor atual: ${safeRead(
                            budgetLeader,
                            ['amount'],
                            '',
                            value => formatPrice(value)
                          )}`}
                        >
                          <AiOutlineQuestionCircle
                            size={20}
                            style={{ color: '#1DA57A' }}
                          />
                        </Tooltip>
                      </Col>
                    ) : null}
                  </Row>
                  <Row gutter={24}>
                    <Col sm={10} md={10} lg={10} xl={10} style={{ top: 8 }}>
                      <Text>Possui Verba Preposto?</Text>
                    </Col>

                    <Col sm={6} md={6} lg={6} xl={6}>
                      <Field
                        id="verba_preposto"
                        name="verba_preposto"
                        placeholder="Selecione"
                        data={status}
                        renderItem={item => (
                          <AntdSelect.Option key={item.id} value={item.value}>
                            {item.id}
                          </AntdSelect.Option>
                        )}
                        component={Select}
                      />
                    </Col>
                    {tooltipVisible ? (
                      <Col
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingTop: 10, cursor: 'pointer' }}
                      >
                        <Tooltip
                          style={{ whiteSpace: 'pre' }}
                          placement="right"
                          title={`Valor atual: ${safeRead(
                            budgetPrepository,
                            ['amount'],
                            '',
                            value => formatPrice(value)
                          )}`}
                        >
                          <AiOutlineQuestionCircle
                            size={20}
                            style={{ color: '#1DA57A' }}
                          />
                        </Tooltip>
                      </Col>
                    ) : null}
                  </Row>
                </InfoHolder>
              </Col>
            </Row>
            <br />
            <br />
            <Row type="flex" justify="end">
              <Col>
                <Button type="primary" htmlType="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
}
