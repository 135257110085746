/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect } from 'antd';
import { Field, withFormik } from 'formik';
import moment from 'moment';

import Select from '~/components/DataEntry/Select';
import history, {
  pushWithQueriesParams,
  getQueryParam,
} from '~/services/history';

function PeriodForm({ referencePeriods, setFieldValue }) {
  const cooperative_member_id = getQueryParam('cooperative_member_id');
  const reference_period = getQueryParam('reference_period');

  useEffect(() => {
    if (reference_period) {
      setFieldValue('period', reference_period);
    } else if (referencePeriods.length > 0) {
      pushWithQueriesParams(history.location.pathname, {
        reference_period: referencePeriods[0].start,
        cooperative_member_id,
      });

      setFieldValue('period', referencePeriods[0].start);
    }
  }, [reference_period]);

  return (
    <Field
      id="year-field"
      name="period"
      label="Período aquisitivo"
      component={Select}
      style={{ width: '100%', maxWidth: 320 }}
      data={referencePeriods}
      onChange={item => {
        const value = item.split(' - ');
        pushWithQueriesParams(history.location.pathname, {
          reference_period: value[0],
          cooperative_member_id,
        });
        setFieldValue('period', item);
      }}
      renderItem={({ start, end }) => (
        <AntdSelect.Option key={`${start} - ${end}`} value={`${start}`}>
          {`${moment(start).format('DD/MM/YYYY')} - ${moment(end).format(
            'DD/MM/YYYY'
          )}`}
        </AntdSelect.Option>
      )}
    />
  );
}

PeriodForm.propTypes = {
  referencePeriods: PropTypes.arrayOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default withFormik({})(PeriodForm);
