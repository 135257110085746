export default {
  'en-US': {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
  'pt-BR': {
    male: { other: 'º' },
    female: { other: 'ª' },
  },
};
