import { useEffect, useState } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import {
  PAYABLE_REQUEST,
  PAYABLE_REQUEST_ID,
} from '~/constants/endpoints/payable_request';
import DetailModal from './DetailModal';

export async function fetchDetailData(id, month, year, setDetailData) {
  try {
    const { data } = await api.get(PAYABLE_REQUEST, {
      params: { id, month, year },
    });
    setDetailData(data.results);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Houve um erro em listar os dados')
    );
    setDetailData([]);
  }
}

export function useDetailData(id, month, year) {
  const [detailData, setDetailData] = useState({
    results: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadDetailData() {
      setLoading(true);
      await fetchDetailData(id, month, year, setDetailData);
      setLoading(false);
    }

    loadDetailData();
  }, [id, month, year]);

  return {
    loading,
    detailData,
    setDetailData,
  };
}

export async function PatchPayableRequest(id) {
  try {
    await api.patch(PAYABLE_REQUEST_ID(id), {
      status: 'cancelado',
    });
    message.success('Solicitação cancelada com sucesso.');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Houve um erro em cancelar a solicitação'
      )
    );
  }
}

export default DetailModal;
