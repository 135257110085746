import React, { useContext } from 'react';
import { Row, Col, Button, Modal, Typography, Popover } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AiFillPlusCircle } from 'react-icons/ai';
import moment from 'moment';
import { Container, AntdTable } from './styles';
import messages from '../messages';

import { HistoryContext } from '../index';
import RectificationModal from '../Modals/RectificationModal';
import AbsenceOvertimeModal from '../Modals/AbsenseOvertimeModal/AbsenceOvertimeModal';
import history from '~/services/history';
import { routePaths } from '~/components/routes/paths';

const { Column } = AntdTable;
const { Text } = Typography;

function ScrollableTable() {
  const slide = document.querySelector('.ant-table-body');
  let isDown = false;
  let startX;
  let scrollLeft;

  slide.addEventListener('mousedown', e => {
    isDown = true;
    slide.classList.add('on');
    startX = e.pageX - slide.offsetLeft;
    scrollLeft = slide.scrollLeft;
  });

  slide.addEventListener('mouseleave', () => {
    isDown = false;
    slide.classList.remove('on');
  });

  slide.addEventListener('mouseup', () => {
    isDown = false;
    slide.classList.remove('on');
  });

  slide.addEventListener('mousemove', e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slide.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    slide.scrollLeft = scrollLeft - walk;
  });
}

const setRowClassName = record => {
  return record.key === moment().format('YYYY-MM-DD') ? 'markedRow' : '';
};

export default function ProductionTable({
  rawData,
  formattedData,
  renderColumns,
  renderColumnBalance,
  renderColumnProduction,
  toggleRectficationModal,
  toggleAbsenceOvertimeModal,
  selectedRowItems,
  rowSelection,
  rectificationModalVisible,
  absenceOvertimeModalVisible,
  loading,
}) {
  const { isLeadView } = useContext(HistoryContext);

  // const [popoverVisible, setPopoverVisible] = React.useState(false);

  // window.document.body.addEventListener('click', () =>
  //   setPopoverVisible(false)
  // );

  React.useEffect(() => {
    ScrollableTable();
  }, []);

  /* The function below ensures that only days with positive and negative production enable the
  button to request absence or overtime
*/
  const buttonDisabled = selectedRowItems => {
    const bool = [];
    let disabled;

    if (selectedRowItems.length === 0) {
      return (disabled = true);
    }

    selectedRowItems.map(item => {
      if (item.saldo === '--' || item.saldo === '00:00') {
        bool.push(false);
      } else {
        bool.push(true);
      }
      return bool;
    });

    disabled = bool.every(item => item);

    return !disabled;
  };

  return (
    <Container>
      <br />
      <Row type="flex" justify="start" gutter={[12, 12]}>
        <Col sm={24} md={5} lg={5} xl={3}>
          <Popover
            content={
              <Text>
                Selecione algum registro na tabela para efetuar a solicitação de
                rasura.
              </Text>
            }
            title={null}
            trigger="hover"
            placement="topLeft"
            // visible={popoverVisible && selectedRowItems.length === 0}
            // onMouseLeave={() => setPopoverVisible(false)}
            // onMouseEnter={() => setPopoverVisible(true)}
          >
            <Button
              type="primary"
              icon="edit"
              onClick={toggleRectficationModal}
              disabled={selectedRowItems.length === 0}
              block
            >
              {' '}
              <FormattedMessage {...messages.rectificaitonButton} />
            </Button>
          </Popover>
        </Col>
        {!isLeadView && (
          <Col sm={24} md={10} lg={9} xl={5}>
            <Popover
              content={
                <>
                  <Text>
                    Selecione um dia para solicitar Ausência ou Hora Extra de
                    acordo com a sua produção. Para solicitações futuras,
                  </Text>{' '}
                  <Button
                    type="link"
                    onClick={() => history.push(routePaths.ABSENCE_ORVETIME)}
                    style={{ padding: 0 }}
                  >
                    clique aqui
                  </Button>
                  <Text>.</Text>
                </>
              }
              title={null}
              trigger="hover"
              placement="topLeft"
            >
              <Button
                type="primary"
                onClick={toggleAbsenceOvertimeModal}
                disabled={buttonDisabled(selectedRowItems)}
                block
              >
                <Row type="flex" justify="center" align="middle" gutter={8}>
                  <Col style={{ fontSize: 17, paddingTop: 2 }}>
                    <AiFillPlusCircle />
                  </Col>
                  <Col> H. Extra / Ausência</Col>
                </Row>
              </Button>
            </Popover>
          </Col>
        )}
      </Row>
      <br />
      <AntdTable
        dataSource={formattedData}
        scroll={{ x: 'calc(1400px + 0.01%)' }}
        pagination={false}
        rowSelection={rowSelection}
        loading={loading}
        rowClassName={setRowClassName}
      >
        <Column
          title={<FormattedMessage {...messages.columnDay} />}
          dataIndex="dia"
          key="dia"
          fixed="left"
          width={130}
        />

        {renderColumns()}
        {renderColumnProduction()}
        {renderColumnBalance()}
      </AntdTable>
      {rectificationModalVisible ? (
        <Modal
          visible={rectificationModalVisible}
          onCancel={toggleRectficationModal}
          testId="rectification-modal"
          title={<FormattedMessage {...messages.requestRectification} />}
          width="90%"
          okText="Salvar"
          centered
          destroyOnClose
          footer={false}
        >
          <RectificationModal
            data={rawData}
            selectedRowItems={selectedRowItems}
            toggleModal={toggleRectficationModal}
          />
        </Modal>
      ) : null}

      {absenceOvertimeModalVisible ? (
        <AbsenceOvertimeModal
          absenceOvertimeModalVisible={absenceOvertimeModalVisible}
          toggleAbsenceOvertimeModal={toggleAbsenceOvertimeModal}
          rawData={rawData}
          selectedRowItems={selectedRowItems}
          rowSelection={rowSelection}
        />
      ) : null}
    </Container>
  );
}

ProductionTable.propTypes = {
  rawData: PropTypes.arrayOf(PropTypes.any).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.any).isRequired,
  renderColumns: PropTypes.func.isRequired,
  renderColumnBalance: PropTypes.func.isRequired,
  renderColumnProduction: PropTypes.func.isRequired,
  toggleRectficationModal: PropTypes.func.isRequired,
  toggleAbsenceOvertimeModal: PropTypes.func.isRequired,
  selectedRowItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  rowSelection: PropTypes.objectOf(PropTypes.any).isRequired,
  rectificationModalVisible: PropTypes.bool.isRequired,
  absenceOvertimeModalVisible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};
