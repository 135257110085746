import { message } from 'antd';
import safeRead from 'safely-read';

import RequestDayOff from './RequestDayOff';
import api from '~/services/api';
import {
  PAID_LEAVE_MANAGE,
  CALCULATE_LAST_DAY,
} from '~/constants/endpoints/paid_leave';
import { setError } from '../ManageVacationsAllowences/index';

export const fieldType = {
  paidLeave: 'annual_leaves',
  dayOff: 'days_off',
};

export async function postPaidLeaveRequest(
  userId,
  values,
  setVisible,
  selectedPeriod
) {
  const { begin_date, total_days, receive_advance_money } = values;

  try {
    await api.post(PAID_LEAVE_MANAGE(userId), {
      paid_leave_type: 'ferias',
      reference_period_start: selectedPeriod,
      begin_date,
      total_days,
      receive_advance_money,
    });

    message.success('Solicitação feita com sucesso!');
    setVisible(false);
  } catch (error) {
    const { detail, error_code } = safeRead(
      error,
      ['response', 'data'],
      'Erro ao solicitar férias.'
    );
    message.error(detail);

    if (error_code === 'NEED_A_DEFAULT_TEAM') {
      setError('NEED_A_DEFAULT_TEAM');
    }
  }
}

export async function postDayoffRequest(
  userId,
  values,
  setVisible,
  selectedPeriod
) {
  const { begin_date, total_days, receive_advance_money } = values;

  try {
    await api.post(PAID_LEAVE_MANAGE(userId), {
      paid_leave_type: 'abono',
      reference_period_start: selectedPeriod,
      begin_date,
      total_days,
      receive_advance_money,
    });

    message.success('Solicitação feita com sucesso!');
    setVisible(false);
  } catch (error) {
    const { detail, error_code } = safeRead(
      error,
      ['response', 'data'],
      'Erro ao solicitar férias.'
    );
    message.error(detail);

    if (error_code === 'NEED_A_DEFAULT_TEAM') {
      setError('NEED_A_DEFAULT_TEAM');
    }
  }
}

export function getTotalBalance(paidLeave, type) {
  const { end_date } = safeRead(
    paidLeave,
    ['timeline'],
    0
  );
  const { expired } = safeRead(
    paidLeave,
    ['timeline', 'balance', [0], type],
    0
  );
  const endDate = new Date(end_date);
  const limitExpirationDate = new Date('01-01-2020');

  if (endDate.getFullYear() + 2 >= limitExpirationDate.getFullYear() && expired > 0) {
    return safeRead(
      paidLeave,
      ['timeline', 'balance', [0], type, 'expired'],
      0
    );
  }
  return safeRead(
    paidLeave,
    ['timeline', 'balance', [0], type, 'remaining'],
    0
  );
}

export async function calculateLastDayOffOrVacations(
  startDate,
  totalDay,
  setLastDayOfVacationsOfDayOff,
  setLastDayOfVacationsOfDayOffLoading
) {
  setLastDayOfVacationsOfDayOffLoading(true);
  try {
    const { data } = await api.get(CALCULATE_LAST_DAY(startDate, totalDay));
    setLastDayOfVacationsOfDayOff(data);
  } catch (error) {
    setLastDayOfVacationsOfDayOff({});
  }
  setLastDayOfVacationsOfDayOffLoading(false);
}

export default RequestDayOff;
