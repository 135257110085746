import decodeJWT from 'jwt-decode';
import { store } from '~/store';
import {
  refreshTokenInterceptorRequest,
  signOut,
} from '~/store/modules/auth/actions';
import { AUTH_REFRESH } from '~/constants/endpoints/auth';
import { REFRESH_TOKEN } from '~/constants/localstorage';
import api from '../api';
import { message } from 'antd';
import safeRead from 'safely-read';

export async function refreshExpiredToken(config) {
  const { headers, url } = config;

  if (url !== AUTH_REFRESH) {
    const { Authorization } = headers;

    if (Authorization) {
      const accessToken = Authorization.split(' ')[1];
      const now = Math.round(Date.now() / 1000);
      const { exp } = decodeJWT(accessToken);
      const isTokenExpired = exp <= now;
      let refreshToken = localStorage.getItem(REFRESH_TOKEN);

      if (!refreshToken) {
        refreshToken = sessionStorage.getItem(REFRESH_TOKEN);
      }

      if (isTokenExpired) {
        try {
          const { data } = await api.post(AUTH_REFRESH, {
            refresh: refreshToken,
          });

          const { access } = data;

          config.headers.Authorization = `Bearer ${access}`;

          store.dispatch(refreshTokenInterceptorRequest(access));
        } catch (error) {
          store.dispatch(signOut());
          message.error(
            safeRead(
              error,
              ['response', 'data', 'detail'],
              'Não foi possível validar o seu login, faça o login novamente'
            )
          );
        }
      }
    }
  }

  return config;
}

export function setNewAccessToken(config) {
  const { access } = store.getState().auth.token;

  if (access) {
    config.headers.Authorization = `Bearer ${access}`;
  }

  return config;
}
