import badgeStatus from '~/constants/badgesStatus';

/**
 *
 * @param {string[]} exclude array of strings to be filtered
 */
export default function createStatusSelectOptions(exclude = []) {
  const statuses = Object.values(badgeStatus).filter(
    status => !exclude.includes(status)
  );

  return statuses.map(value => {
    const str = value.split('_').join(' ');
    let formatted;

    if (str.includes('nao')) {
      formatted = str.replace('nao', 'não');
    } else if (str.includes('analise')) {
      formatted = str.replace('analise', 'análise');
    } else {
      formatted = str;
    }

    return {
      key: value,
      value: formatted.charAt(0).toUpperCase() + formatted.slice(1),
    };
  });
}
