import styled from 'styled-components';
import { darken } from 'polished';

export const PickerHolder = styled.div`
  background-color: ${({ theme }) => darken(0.01, theme.componentBackground)};
  border: 2px solid ${({ theme }) => theme.borderColorBase};
  border-radius: 4px;
`;

export const WarningHolder = styled.div`
  text-align: center;
`;
