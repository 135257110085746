import React, { useState, useContext } from 'react';
import { Modal, Typography, Steps } from 'antd';
import { StepHolder } from './styles';
import {
  onTypeSubmit,
  onDateSubmit,
  onDateBack,
  onFinishBack,
  resetValues,
  toggleModal,
  renderSteps,
} from '.';
import { AbsenceOvertimeContext } from '../..';

const { Title } = Typography;
const { Step } = Steps;

export const RequestAbsenceOvertimeContext = React.createContext();

export default function RequestAbsenceOvertime() {
  const [currentStep, setCurrentStep] = useState(0);
  const [requestType, setRequestType] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const { requestModalVisible, toggleRequestModalVisible } = useContext(
    AbsenceOvertimeContext
  );

  const contextParams = {
    step1: requestType,
    step2: selectedDays,
    step3: [],
    onTypeSubmit: type => onTypeSubmit(type, setCurrentStep, setRequestType),
    onDateSubmit: days => onDateSubmit(days, setCurrentStep, setSelectedDays),
    onDateBack: () => onDateBack(setCurrentStep),
    onFinishBack: () => onFinishBack(setCurrentStep),
    resetValues: () =>
      resetValues(setCurrentStep, setRequestType, setSelectedDays),
    toggleModal: () =>
      toggleModal(
        toggleRequestModalVisible,
        setCurrentStep,
        setRequestType,
        setSelectedDays
      ),
  };

  return (
    <Modal
      title={<Title level={4}>Solicitar Ausência/Hora Extra</Title>}
      footer={null}
      visible={requestModalVisible}
      width="60%"
      onCancel={contextParams.toggleModal}
      closable
      destroyOnClose
      centered
    >
      <RequestAbsenceOvertimeContext.Provider value={contextParams}>
        <StepHolder>
          <Steps current={currentStep}>
            <Step title="Tipo" />
            <Step title="Datas" />
            <Step title="Finalizar" />
          </Steps>
        </StepHolder>
        {renderSteps(currentStep)}
      </RequestAbsenceOvertimeContext.Provider>
    </Modal>
  );
}
