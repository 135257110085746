import styled from 'styled-components';

export const ContainerFilters = styled.div`
  display: grid;
  gap: 10px;
`;
export const ContainerInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 10px;
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 8px;
  }
`;
