import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Row, Col } from 'antd';
import PageContainer from '~/components/Layout/PageContainer';
import ProductionChart from './ProductionChart';
import Button from './Button';

const { Title } = Typography;

export default function Report() {
  return (
    <PageContainer title="Relatórios">
      <Title level={3}>Relatórios disponíveis</Title>
      <Row gutter={24}>
        <Col span={8}>
          <Link to="/relatorios/controle-de-producao">
            <Button
              title="Relatórios de Produção de Cooperados"
              description="Visualize informações relacionadas à produção, banco de horas, faturamento e descontos."
            />
          </Link>
        </Col>
      </Row>
      <br />
      <br />
      <Title level={3}>Gráficos</Title>
      <ProductionChart />
    </PageContainer>
  );
}
