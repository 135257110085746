import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Col, Button, Divider } from 'antd';
import PropTypes from 'prop-types';
import Input from '~/components/DataEntry/Input';
import { RangePicker } from '~/components/DataEntry/DatePicker';
import Select from '~/components/DataEntry/Select';
import { buildDateRange } from '~/services/utils/dateRangeUtils';
import {
  handleSearch,
  selectOptions,
  handleClearFilters,
  handleDisableSearch,
} from '.';

export default function SearchForm(props) {
  const { id, date_start, date_end, request, status } = props;

  return (
    <Formik
      initialValues={{
        idInput: id,
        dateInput: buildDateRange(date_start, date_end),
        requestInput: request,
        statusInput: status,
      }}
      onSubmit={values => handleSearch(values, props)}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form>
          <Row gutter={24}>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Field
                id="id-input"
                name="idInput"
                label="ID"
                component={Input}
              />
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Field
                id="date-input"
                name="dateInput"
                label="Data"
                component={RangePicker}
              />
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Field
                id="request-input"
                name="requestInput"
                label="Solicitação"
                component={Select}
                data={selectOptions.requestOptions}
                renderItem={selectOptions.render}
                allowClear
              />
            </Col>
            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
              <Field
                id="status-input"
                name="statusInput"
                label="Status"
                component={Select}
                data={selectOptions.statusOptions}
                renderItem={selectOptions.render}
                allowClear
              />
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              <Button onClick={() => handleClearFilters(setFieldValue)}>
                Limpar
              </Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                icon="search"
                onClick={handleSubmit}
                disabled={handleDisableSearch(values)}
              >
                {' '}
                Pesquisar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

SearchForm.propTypes = {
  id: PropTypes.string,
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  request: PropTypes.string,
  status: PropTypes.string,
};

SearchForm.defaultProps = {
  id: undefined,
  date_start: undefined,
  date_end: undefined,
  request: undefined,
  status: undefined,
};
