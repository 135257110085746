import styled from 'styled-components';
import { Typography } from 'antd';
import { tabletWidth } from '~/constants/metrics';
import THEME from '~/constants/theme';

const { Title } = Typography;

export const CarouselContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;
  background-color: ${({ theme }) => theme.bodyBackground};

  p {
    position: absolute;
    bottom: 5px;
    color: ${({ theme }) => theme.textColor};
  }

  @media (max-width: ${tabletWidth}) {
    display: none;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30%;
  background-color: ${({ theme }) => theme.componentBackground};
  color: ${({ theme }) => theme.textColor} !important;
  box-shadow: ${({ theme }) => theme.boxShadowBase};
  padding: 0 30px;

  h3 {
    text-align: center;
    margin-bottom: 30px;
  }

  @media (max-width: ${tabletWidth}) {
    width: 100%;

    #login-title {
      display: none;
    }
  }
`;

export const PageTitle = styled(Title)`
  width: 100%;
  color: ${({ theme }) => theme.headingColor} !important;
`;

export const LogoImg = styled.img`
  bottom: 18px;
  height: 50px;
  width: 150px;

  @media (max-width: ${tabletWidth}) {
    display: none;
  }
`;

export const LogoImgTop = styled.img`
  height: 80px;
  width: 230px;
  margin-bottom: 35px;

  @media (min-width: ${tabletWidth}) {
    display: none;
  }
`;

export const CarouselHolder = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 100px;

  .ant-carousel {
    height: auto !important;
  }

  .slick-dots-bottom {
    li {
      button {
        border-radius: 50% !important;
        width: 10px;
        height: 10px;
        background-color: gray;
        &:not(last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .ant-carousel .slick-dots li.slick-active button {
    width: 10px !important;
    height: 10px !important;
    background-color: ${THEME.light.primaryColor};
  }
`;

export const CarouselPage = styled.div`
  display: flex !important;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  padding: 30px;
`;

export const CarouselPageSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CarouselImgs = styled.img`
  max-width: 70%;
  height: auto;
`;
