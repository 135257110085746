import styled from 'styled-components';
import { largeDesktopWidth } from '~/constants/metrics';
import { darken } from 'polished';

export const Container = styled.div`
  color: ${({ theme }) => theme.textColor};

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const HistoryCountContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${largeDesktopWidth}) {
    flex-direction: row;
  }
`;

export const HistoryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: 8px;
`;

export const CountTableContainer = styled.div`
  margin-left: 18px;
  @media (max-width: ${largeDesktopWidth}) {
    margin-left: 0;
    margin-top: 20px;
    width: 50%;
  }
`;

export const LoadingContainer = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: ${({ theme }) => darken(0.05, theme.componentBackground)};
  border-radius: 4px;
`;
