import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Modal, Divider } from 'antd';
import { Formik, Field, Form } from 'formik';

import { denyRectificationSchema } from '~/constants/formSchemas/rectificationApproval';
import Input from '~/components/DataEntry/Input';
import {
  handleRectificationsApproval,
  fetchRectificationApproval,
  from as fromPage,
} from '../../index';

export default function DetailModal({
  visible,
  setVisible,
  setTotalPages,
  setLoadingTableData,
  setTableData,
  actionType,
  approvalItems,
  setDetailModalVisible,
  setSelectedRowKeys,
  from,
}) {
  const [loading, setLoading] = useState(false);
  const { Title } = Typography;

  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <Title level={4}>
        {actionType === 'aprovar'
          ? 'Aprovar rasuras selecionadas?'
          : 'Reprovar rasuras selecionas?'}
      </Title>

      {actionType !== 'aprovar' ? (
        <Formik
          validationSchema={denyRectificationSchema}
          validateOnChange
          validateOnBlur={false}
          initialValues={{ reason: undefined }}
          onSubmit={async ({ reason }) => {
            setLoading(true);
            await handleRectificationsApproval(
              approvalItems,
              'reprovado',
              reason
            );
            const data = await fetchRectificationApproval({
              setData: setTableData,
              setLoading: setLoadingTableData,
            });

            const { count } = data;

            if (from === fromPage.table) {
              setSelectedRowKeys([]);
            }

            setTotalPages(count);
            setLoading(false);
            setVisible(false);

            if (from === fromPage.detail) {
              setDetailModalVisible(false);
            }
          }}
        >
          {({ handleSubmit, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                id="reason-input"
                name="reason"
                label="Motivo*"
                type="textarea"
                component={Input}
              />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginTop: 40,
                }}
              >
                <Button onClick={() => setVisible(false)}>Cancelar</Button>
                <Divider type="vertical" />
                <Button
                  disabled={!values.reason || errors.reason}
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                >
                  Ok
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 40,
          }}
        >
          <Button onClick={() => setVisible(false)}>Cancelar</Button>
          <Divider type="vertical" />
          <Button
            onClick={async () => {
              setLoading(true);
              await handleRectificationsApproval(approvalItems, 'aprovado');
              const data = await fetchRectificationApproval({
                setData: setTableData,
                setLoading: setLoadingTableData,
              });

              const { count } = data;

              if (from === fromPage.table) {
                setSelectedRowKeys([]);
              }

              setTotalPages(count);
              setLoading(false);
              setVisible(false);

              if (from === fromPage.detail) {
                setDetailModalVisible(false);
              }
            }}
            loading={loading}
            type="primary"
          >
            Ok
          </Button>
        </div>
      )}
    </Modal>
  );
}

DetailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setLoadingTableData: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  setTotalPages: PropTypes.func.isRequired,
  approvalItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  setDetailModalVisible: PropTypes.func.isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
};
