import { message } from 'antd';
import safeRead from 'safely-read';

import api from '~/services/api';
import { PAID_LEAVE } from '~/constants/endpoints/paid_leave';
import ResignationRequest from './ResignationRequest';

export async function resignationRequest(referencePeriods) {
  const { length } = referencePeriods;

  const lastIndex = length > 1 ? length - 1 : 0;

  try {
    await api.post(PAID_LEAVE, {
      paid_leave_type: 'acerto_por_desligamento',
      reference_period_start: safeRead(
        referencePeriods[lastIndex],
        ['start'],
        null
      ),
    });

    message.success(
      'Solicitação de acerto por desligamento feita com sucesso.'
    );
  } catch (error) {
    message.error('Ocorreu um erro ao solicitar acerto por desligamento.');
  }
}

export default ResignationRequest;
