import React from 'react';
import PageContainer from '~/components/Layout/PageContainer';
import EdesenvHistoricalTable from './EdesenvHistoricalTable/EdesenvHistoricalTable';
import SearchForm from './SearchForm/SearchForm';

export default function EdesenvHistorical() {
  return (
    <PageContainer title="Histórico de Ausência e Hora extra E-desenv">
      <SearchForm />
      <EdesenvHistoricalTable />
    </PageContainer>
  );
}
