import React, { useState, useEffect } from 'react';
import { Select as AntSelect, Form } from 'antd';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

const { Item } = Form;

export function AutoCompleteSelect(props) {
  const {
    label,
    extra,
    onSearch,
    renderItem,
    debounceTime,
    allowClear,
    initialData,
    ...restProps
  } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const fetchData = async value => {
    setLoading(true);

    try {
      const result = await onSearch(value);

      setData(result);
    } catch (error) {
      setData([]);
    }

    setLoading(false);
  };

  return (
    <Select
      label={label}
      extra={extra}
      loading={loading}
      onSearch={debounce(fetchData, debounceTime || 1000)}
      showSearch={!!onSearch}
      showArrow={!onSearch}
      allowClear={allowClear}
      data={data}
      renderItem={renderItem}
      filterOption={false}
      {...restProps}
    />
  );
}

AutoCompleteSelect.propTypes = {
  label: PropTypes.string,
  extra: PropTypes.string,
  debounceTime: PropTypes.number,
  onSearch: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  allowClear: PropTypes.bool,
  initialData: PropTypes.arrayOf(PropTypes.any),
};

AutoCompleteSelect.defaultProps = {
  label: undefined,
  extra: undefined,
  allowClear: true,
  debounceTime: 1000,
  initialData: undefined,
};

export default function Select(props) {
  const {
    label,
    extra,
    onChange,
    onBlur,
    form,
    field,
    data,
    renderItem,
    ...restProps
  } = props;
  const { name, value } = field;
  const { setFieldValue, setFieldTouched, errors, touched } = form;

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      <AntSelect
        onChange={(val, option) => {
          setFieldValue(name, val);
          return onChange && onChange(val, option);
        }}
        onBlur={val => {
          setFieldTouched(name);
          return onBlur && onBlur(val);
        }}
        value={value}
        style={{ width: '100%' }}
        showArrow
        {...restProps}
      >
        {(data || []).map((item, index) => renderItem(item, index))}
      </AntSelect>
    </Item>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  extra: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderItem: PropTypes.func.isRequired,
};

Select.defaultProps = {
  label: undefined,
  extra: undefined,
  onChange: undefined,
  onBlur: undefined,
};
