import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { Typography, Icon, Row, Col, Button, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import Input from '~/components/DataEntry/Input';
import { ModalContainer } from './styles';
import profileMsgs from '../messages';
import modalMsgs from './messages';
import { confirmChangePassword } from './RequestChangePassword';
import { confirmPassword } from '~/constants/formSchemas/changePassword';

export default function ChangePassword({ visible, onCancel }) {
  const { Text } = Typography;

  return (
    <ModalContainer
      visible={visible}
      title={<FormattedMessage {...profileMsgs.resetPassword} />}
      footer={false}
      onCancel={onCancel}
    >
      <Formik
        onSubmit={confirmChangePassword}
        validationSchema={confirmPassword}
        validateOnChange
        validateOnBlur={true}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
      >
        {({ handleSubmit, values, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              id="current-password-input"
              name="currentPassword"
              component={Input}
              type="password"
              label={<FormattedMessage {...modalMsgs.currentPassword} />}
            />
            <Field
              id="new-password-input"
              name="newPassword"
              component={Input}
              type="password"
              label={<FormattedMessage {...modalMsgs.newPassword} />}
            />
            <Field
              id="confirm-password-input"
              name="confirmPassword"
              component={Input}
              type="password"
              label={<FormattedMessage {...modalMsgs.confirmPassword} />}
            />
            <Text>
              <Text type="warning">
                <Icon type="warning" style={{ fontSize: 18 }} />
              </Text>{' '}
              <FormattedMessage {...modalMsgs.warnNormal} />{' '}
              <b>
                <FormattedMessage {...modalMsgs.warnBold} />
              </b>
            </Text>
            <Row type="flex" justify="end">
              <Col>
                <Button onClick={onCancel}>
                  <FormattedMessage {...modalMsgs.cancelButton} />
                </Button>
                <Divider type="vertical" />
                <Button 
                  type="primary"
                  onClick={handleSubmit}
                  disabled={
                    !values.currentPassword || 
                    !values.newPassword || 
                    !values.confirmPassword || 
                    errors.newPassword || 
                    errors.confirmPassword
                  }
                >
                  <FormattedMessage {...modalMsgs.sendButton} />
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}

ChangePassword.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
