import React, { useContext } from 'react';
import { Modal, Button, Typography, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import safeRead from 'safely-read';
import { Item, ItemContainer } from '../ManageAbsenceOvertimeModal/styles';
import renderStatusBadge from '~/services/utils/renderStatusBadge';
import formatPrice from '~/services/utils/formatPrice';
import { useDetailData, PatchPayableRequest } from './index';
import { CollapsePanelContext } from '../../CollapseList';

const { confirm } = Modal;

export default function DetailModal({
  detailModalVisible,
  setDetailModalVisible,
  payableRequestKey,
}) {
  const {
    user,
    key,
    year,
    month,
    setConsolidatedProduction,
    fetchConsolidatedProduction,
    compensatoryTime,
    setCompensatoryTime,
    fetchCompensatoryTime,
    fetchTableData,
    setTableData,
    setLoadingTableData,
  } = useContext(CollapsePanelContext);

  const { detailData, loading } = useDetailData(payableRequestKey, month, year);

  const { Text, Title } = Typography;
  return (
    <Modal
      visible={detailModalVisible}
      onCancel={() => setDetailModalVisible(false)}
      footer={null}
      closable
      destroyOnClose
      width={450}
      title={<Title level={4}>Detalhamento de solicitação </Title>}
    >
      <ItemContainer>
        <Item width="60%">
          <Text strong style={{ display: 'block' }}>
            Usuário
          </Text>
          <Text type="secondary" style={{ display: 'block' }} id={key}>
            {safeRead(user, ['person_display', 'name_display'])}
          </Text>
        </Item>

        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Mês/Ano
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {`${moment(`${year}-${month}`).format('MMMM/YYYY')}`}
          </Text>
        </Item>

        <Item width="60%">
          <Text strong style={{ display: 'block' }}>
            Banco de Horas aprovadas
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(compensatoryTime, ['balance'], '--')}
          </Text>
        </Item>

        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Valor/Hora
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['general', 'hourly_rate'], '--', rate =>
              formatPrice(rate)
            )}
          </Text>
        </Item>

        <Item width="60%">
          <Text strong style={{ display: 'block' }}>
            Projeto
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(detailData, [[0], 'team_display'], '--')}
          </Text>
        </Item>

        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Solicitação
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(detailData, [[0], 'request_type_display'], '--')}
          </Text>
        </Item>

        <Item width="60%">
          <Text strong style={{ display: 'block' }}>
            Situação
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {renderStatusBadge({
              status: safeRead(detailData, [[0], 'status'], '--'),
              status_display: safeRead(
                detailData,
                [[0], 'status_display'],
                '--'
              ),
            })}
          </Text>
        </Item>

        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Valor
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(detailData, [[0], 'value'], '--', value =>
              formatPrice(value)
            )}
          </Text>
        </Item>

        {safeRead(detailData, [[0], 'hours_display']) === null ? null : (
          <Item width="60%">
            <Text strong style={{ display: 'block' }}>
              Quantidade de Horas
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {safeRead(
                detailData,
                [[0], 'hours_display'],
                '--',
                value => `${value}h`
              )}
            </Text>
          </Item>
        )}

        <Item width="60%">
          <Text strong style={{ display: 'block' }}>
            Motivo
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(detailData, [[0], 'reason'], '--')}
          </Text>
        </Item>
      </ItemContainer>
      <br />
      {safeRead(detailData, [[0], 'status']) !== 'cancelado' && !loading ? (
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="danger"
              onClick={() =>
                confirm({
                  content: 'Deseja realmente cancelar solicitação?',
                  onOk: async () => {
                    setDetailModalVisible(false);
                    await PatchPayableRequest(payableRequestKey);
                    fetchCompensatoryTime(key, setCompensatoryTime);
                    fetchTableData(
                      key,
                      month,
                      year,
                      setTableData,
                      setLoadingTableData
                    );
                    fetchConsolidatedProduction(
                      year,
                      month,
                      setConsolidatedProduction
                    );
                  },
                })
              }
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      ) : null}
    </Modal>
  );
}

DetailModal.propTypes = {
  detailModalVisible: PropTypes.bool.isRequired,
  setDetailModalVisible: PropTypes.func.isRequired,
  payableRequestKey: PropTypes.number.isRequired,
};
