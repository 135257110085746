import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { string, func, number, bool, shape, node } from 'prop-types';
import { Input, Form } from 'antd';

import formatCurrency from './format-currency';

const { Item } = Form;

const defaultConfig = {
  locale: 'pt-br',
  formats: {
    number: {
      USD: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const InputCurrency = ({
  component: InputComponent,
  value,
  defaultValue,
  config,
  currency,
  max,
  autoFocus,
  autoSelect,
  autoReset,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  label,
  extra,
  form,
  field,
  ...otherProps
}) => {
  const inputRef = useCallback(
    node => {
      const isActive = node === document.activeElement;

      if (node && autoFocus && !isActive) {
        node.focus();
      }
    },
    [autoFocus]
  );

  const [maskedValue, setMaskedValue] = useState('0');

  // to prevent a malformed config object
  const safeConfig = useMemo(
    () => () => {
      const {
        formats: {
          number: {
            [currency]: { maximumFractionDigits },
          },
        },
      } = config;

      const finalConfig = {
        ...defaultConfig,
        ...config,
      };

      // at the moment this prevents problems when converting numbers
      // with zeroes in-between, otherwise 205 would convert to 25.
      finalConfig.formats.number[
        currency
      ].minimumFractionDigits = maximumFractionDigits;

      return finalConfig;
    },
    [config, currency]
  );

  const clean = number => {
    if (typeof number === 'number') {
      return number;
    }

    // strips everything that is not a number (positive or negative)
    return Number(number.toString().replace(/[^0-9-]/g, ''));
  };

  const normalizeValue = number => {
    const {
      formats: {
        number: {
          [currency]: { maximumFractionDigits },
        },
      },
    } = safeConfig();
    let safeNumber = number;

    if (typeof number === 'string') {
      safeNumber = clean(number);

      if (safeNumber % 1 !== 0) {
        safeNumber = safeNumber.toFixed(maximumFractionDigits);
      }
    } else {
      // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
      safeNumber = Number.isInteger(number)
        ? Number(number) * 10 ** maximumFractionDigits
        : number.toFixed(maximumFractionDigits);
    }

    // divide it by 10 power the maximum fraction digits.
    return clean(safeNumber) / 10 ** maximumFractionDigits;
  };

  const calculateValues = inputFieldValue => {
    const value = normalizeValue(inputFieldValue);
    const maskedValue = formatCurrency(value, safeConfig(), currency);

    return [value, maskedValue];
  };

  const updateValues = value => {
    const [calculatedValue, calculatedMaskedValue] = calculateValues(value);

    if (!max || calculatedValue <= max) {
      setMaskedValue(calculatedMaskedValue);

      return [calculatedValue, calculatedMaskedValue];
    } else {
      return [normalizeValue(maskedValue), maskedValue];
    }
  };

  const handleChange = event => {
    event.preventDefault();

    const [value, maskedValue] = updateValues(event.target.value);

    if (maskedValue) {
      onChange(event, value, maskedValue);
    }
  };

  const handleBlur = event => {
    const [value, maskedValue] = updateValues(event.target.value);

    if (autoReset) {
      calculateValues(0);
    }

    if (maskedValue) {
      onBlur(event, value, maskedValue);
    }
  };

  const handleFocus = event => {
    if (autoSelect) {
      event.target.select();
    }

    const [value, maskedValue] = updateValues(event.target.value);

    if (maskedValue) {
      onFocus(event, value, maskedValue);
    }
  };

  const handleKeyUp = event => onKeyPress(event, event.key, event.keyCode);

  useEffect(() => {
    const currentValue = value || defaultValue || 0;
    const [, maskedValue] = calculateValues(currentValue);

    setMaskedValue(maskedValue);
    // eslint-disable-next-line
  }, [currency, value, defaultValue, config]);

  const { name } = field;
  const { errors, touched } = form;

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      <Input
        ref={inputRef}
        value={maskedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
        {...otherProps}
      />
    </Item>
  );
};

InputCurrency.propTypes = {
  defaultValue: number,
  value: number,
  max: number,
  component: node.isRequired,
  currency: string.isRequired,
  config: shape().isRequired,
  autoFocus: bool.isRequired,
  autoSelect: bool.isRequired,
  autoReset: bool.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onKeyPress: func.isRequired,
};

InputCurrency.defaultProps = {
  component: 'input',
  currency: 'USD',
  value: 0,
  config: defaultConfig,
  autoFocus: false,
  autoSelect: false,
  autoReset: false,
  onChange: f => f,
  onBlur: f => f,
  onFocus: f => f,
  onKeyPress: f => f,
};

export default InputCurrency;
