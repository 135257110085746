import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.not_found';

export default defineMessages({
  pageNotFound: {
    id: `${scope}.page_not_found`,
    defaultMessage: 'Esta página não existe',
  },
  brokenOrRemoved: {
    id: `${scope}.broken_or_removed`,
    defaultMessage:
      'Este link deve estar quebrado ou a página deve ter sido removida.',
  },
  checkLink: {
    id: `${scope}.check_link`,
    defaultMessage:
      'Verifique se o link que você está tentando acessar está correto.',
  },
  goHome: {
    id: `${scope}.go_home`,
    defaultMessage: 'Ir para tela de início',
  },
  goBack: {
    id: `${scope}.go_back`,
    defaultMessage: 'Voltar para página anterior',
  },
});
