/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useDeepCompareEffect } from 'react-use';
import {
  Table,
  Icon,
  message,
  Button,
  Row,
  Col,
  Divider,
  // Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import { Field, FieldArray, withFormik } from 'formik';
import safeRead from 'safely-read';
import moment from 'moment';
import shortid from 'shortid';
import { FormattedMessage } from 'react-intl';

import Input from '~/components/DataEntry/Input';
import TimePicker from '~/components/DataEntry/TimePicker';
import { TableRowItem } from './styles';
import api from '~/services/api';
import {
  RECTIFICATION_REQUEST,
  RECTIFICATION_REQUEST_BY_ID,
} from '~/constants/endpoints/rectification_request';
import rectificationCheckinsCheckouts from '~/constants/rectificationcheckinsCheckouts';
import formatOrdinal from '~/services/utils/formatOrdinal';
import messages from '../../messages';
import { HistoryContext } from '../..';

const { Column } = Table;

function msToTime(s) {
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const mins = s % 60;
  const hrs = (s - mins) / 60;

  if (s < 0) {
    return `${hrs * -1 < 10 ? `-0${hrs * -1}` : hrs}:${
      mins * -1 < 10 ? `0${mins * -1}` : mins * -1
    }`;
  }

  return `${hrs < 10 ? `0${hrs}` : hrs}:${mins < 10 ? `0${mins}` : mins}`;
}

function RectificationModal({
  data,
  selectedRowItems,
  values,
  setFieldValue,
  toggleModal,
}) {
  const [rows, setRows] = useState([]);
  const [formattedRows, setFormattedRows] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [repeatHour] = useState(false);
  const { fetchTableData, isLeadView, cooperativeMemberId } = useContext(
    HistoryContext
  );

  useEffect(() => {
    (selectedRowItems || []).map(item =>
      data.forEach(filter =>
        filter.date === item.key ? setRows(prev => [...prev, filter]) : null
      )
    );
    console.log(selectedRowItems)
  }, []);

  useDeepCompareEffect(() => {
    const raw = (rows || []).map((item, idx) => {
      const mappedPositions = {};

      rectificationCheckinsCheckouts.map((position, index) => {
        mappedPositions[`${position.pos}${position.type}`] = safeRead(
          item,
          ['timeline', [index]],
          {
            position: position.pos,
            date: moment(item.date),
            check_in: position.type === 'entrada',
          },
          rowItem => ({ ...rowItem, date: moment(rowItem.worktime_clock) })
        );

        return true;
      });

      return {
        id: item.id,
        key: item.id || shortid.generate(),
        day: moment(item.date),
        ...mappedPositions,
        total: msToTime(
          Object.values(values['table-input'][idx]).reduce(
            (accumulator, currentValue, index, src) => {
              if (
                index > 0 &&
                currentValue &&
                index % 2 !== 0 &&
                Boolean(src[index - 1])
              ) {
                accumulator += currentValue.diff(src[index - 1], 'mileseconds');
              }
              return accumulator;
            },
            0
          )
        ),
      };
    });

    setFormattedRows(raw);
  }, [rows, values]);

  const handleSubmit = async () => {
    setSubmitLoading(true);

    try {
      const rectificationsFields = safeRead(values, ['table-input'], []);
      const motivo = safeRead(values, ['motivo']);

      const payload = selectedRowItems.map((row, index) => {
        const labels = Object.keys(rectificationsFields[index]);
        const rectificationList = labels.map(rect => ({
          position: rect.includes('entrada')
            ? rect.replace('entrada', '')
            : rect.replace('saida', ''),
          check_in: rect.includes('entrada'),
          worktime_clock: safeRead(
            rectificationsFields[index],
            [rect],
            null,
            worktime =>
              `${
                moment(row.key)
                  .format('YYYY-MM-DDTHH:mm')
                  .split('T')[0]
              }T${
                moment(worktime)
                  .format('YYYY-MM-DDTHH:mm')
                  .split('T')[1]
              }`
          ),
        }));

        return {
          date: row.key,
          rectifications:
            rectificationList &&
            rectificationList.filter(f => f.worktime_clock !== null),
          reason: motivo,
        };
      });

      await api.post(
        isLeadView
          ? RECTIFICATION_REQUEST_BY_ID(cooperativeMemberId)
          : RECTIFICATION_REQUEST,
        payload
      );

      message.success('Rasuras realizadas com sucesso!');
      window.location.reload(false);
      toggleModal();
      fetchTableData();
    } catch (error) {
      const errorMsg = safeRead(
        error,
        ['response', 'data', 'detail'],
        'Ocorreu um erro ao solicitar rasuras.'
      );
      message.error(errorMsg);
    }

    setSubmitLoading(false);
  };

  const renderTableInput = (item, row, index) => (
    <TableRowItem>
      <Field
        id={`table-input[${index}].${item.position}${
          item.check_in ? 'entrada' : 'saida'
        }`}
        name={`table-input[${index}].${item.position}${
          item.check_in ? 'entrada' : 'saida'
        }`}
        component={TimePicker}
        format="HH:mm"
        suffix={null}
        suffixIcon={<Icon type="toBeNull" />}
        placeholder={null}
        disabled={repeatHour && index > 0}
        onChange={newDate => {
          if (repeatHour) {
            formattedRows.map((i, ind) => {
              if (ind > 0) {
                setFieldValue(
                  `table-input[${ind}].${item.position}${
                    item.check_in ? 'entrada' : 'saida'
                  }`,
                  newDate
                );
              }

              return true;
            });
          }
        }}
      />
    </TableRowItem>
  );

  const renderTableColumns = () => {
    const renderLabel = label => (
      <>
        {formatOrdinal(label.pos, 'female')}{' '}
        {label.type === 'entrada' ? (
          <FormattedMessage {...messages.columnCheckIn} />
        ) : (
          <FormattedMessage {...messages.columnCheckOut} />
        )}
      </>
    );

    return rectificationCheckinsCheckouts.map(item => {
      if (item.type === 'total') {
        return (
          <Column
            className="group-column"
            title="Total"
            dataIndex="total"
            key={`total${item.pos}${item.type}`}
          />
        );
      }

      return (
        <Column
          className="group-column"
          title={renderLabel(item)}
          dataIndex={`${item.pos}${item.type}`}
          key={`${item.pos}${item.type}`}
          render={renderTableInput}
        />
      );
    });
  };

  return (
    <div>
      <>
        {/* <Row>
          <Checkbox
            checked={repeatHour}
            onChange={e => setRepeatHour(e.target.checked)}
          />
          <span style={{ marginLeft: 8 }}>Repetir horários</span>
        </Row> */}
        <br />
        <FieldArray
          name="table-input"
          render={() => (
            <Table
              dataSource={formattedRows}
              pagination={false}
              scroll={{ x: true }}
              loading={submitLoading}
            >
              <Column
                title={<FormattedMessage {...messages.columnDay} />}
                key="day"
                dataIndex="day"
                fixed="left"
                render={item => item && item.format('DD/MM - ddd')}
              />

              {renderTableColumns()}
            </Table>
          )}
        />
        <br />
        <br />
        <Field
          id="input-motivo"
          name="motivo"
          component={Input}
          type="textarea"
          label={<FormattedMessage {...messages.reason} />}
        />
      </>
      <br />
      <Row type="flex" justify="end">
        <Col>
          <Button onClick={() => toggleModal(false)}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={handleSubmit}>
            <FormattedMessage {...messages.sendButton} />
          </Button>
        </Col>
      </Row>
    </div>
  );
}

const mapPropsToValues = props => {
  const tableList = [];
  const { selectedRowItems } = props;

  selectedRowItems.map((row, index) => {
    const labelList = Object.keys(row).filter(
      item => item.includes('entrada') || item.includes('saida')
    );

    return labelList.map(item => {
      const currentVal = row[item];
      let value;
      let today;

      if (currentVal.worktime_clock) {
        value = currentVal.worktime_clock.split('T')[1];
        today = moment().format('YYYY-MM-DD');
      }

      const prevValue =
        typeof currentVal === 'object' ? moment(`${today}T${value}`) : null;

      return (tableList[index] = { ...tableList[index], [item]: prevValue });
    });
  });

  return {
    motivo: null,
    'table-input': tableList,
  };
};

export default withFormik({
  mapPropsToValues,
})(RectificationModal);

RectificationModal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModal: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
