import styled from 'styled-components';
import { Button } from 'antd';
import { tabletWidth } from '~/constants/metrics';

export const ListItem = styled.div`
  padding: 12px;

  b {
    margin-right: 8px;
  }

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.borderColorBase};
  }

  @media (max-width: ${tabletWidth}) {
    &:first-child {
      margin-top: 40px;
    }
  }
`;

export const AvatarHolder = styled.span`
  position: relative;

  &:hover {
    #avatar-photo {
      animation: borderanim 0.1s ease-in forwards;
    }

    @keyframes borderanim {
      0% {
        border: 0px solid ${({ theme }) => theme.primaryColor};
      }

      50% {
        border: 3px solid ${({ theme }) => theme.primaryColor};
      }

      100% {
        border: 5px solid ${({ theme }) => theme.primaryColor};
      }
    }

    #edit-button {
      opacity: 100%;
    }
  }
`;

export const EditPhoto = styled(Button).attrs({
  icon: 'edit',
  shape: 'circle',
  type: 'primary',
})`
  position: absolute;
  opacity: 0%;
  top: -40px;
  right: 0;
  transition: 0.3s;

  @media (max-width: ${tabletWidth}) {
    opacity: 100%;
  }
`;
