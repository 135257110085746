import React, { useState, useEffect } from 'react';
import safeRead from 'safely-read';
import { Button, Alert } from 'antd';

import { AntdTable } from './styles';
import {
  useTableData,
  makeColumns,
  formatTableData,
  productionControlSheet,
} from './index';
import { getAllQueriesParams } from '~/services/history';

function ScrollableTable() {
  const slide = document.querySelector('.ant-table-body');
  let isDown = false;
  let startX;
  let scrollLeft;

  slide.addEventListener('mousedown', e => {
    isDown = true;
    slide.classList.add('on');
    startX = e.pageX - slide.offsetLeft;
    scrollLeft = slide.scrollLeft;
  });

  slide.addEventListener('mouseleave', () => {
    isDown = false;
    slide.classList.remove('on');
  });

  slide.addEventListener('mouseup', () => {
    isDown = false;
    slide.classList.remove('on');
  });

  slide.addEventListener('mousemove', e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slide.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    slide.scrollLeft = scrollLeft - walk;
  });
}

export default function ProductionControlTable() {
  const [loading, setLoading] = useState(false);
  const { tableData, loadingTableData } = useTableData();
  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);

  const {
    page,
    page_size,
    full_name,
    begin_date,
    end_date,
    team_id,
    csys_registration_number,
  } = getAllQueriesParams();

  useEffect(() => {
    ScrollableTable();
  }, []);

  return (
    <>
      <Button
        type="primary"
        onClick={() =>
          productionControlSheet(
            full_name,
            begin_date,
            end_date,
            team_id,
            csys_registration_number,
            setLoading
          )
        }
        download
        loading={loading}
      >
        Exportar Dados
      </Button>
      <br />
      <br />
      <Alert
        message={
          <>
            As informações exibidas nesta tabela virão de acordo com o{' '}
            <strong>Período selecionado</strong>
          </>
        }
        showIcon
      />
      <br />

      <AntdTable
        dataSource={formatTableData(tableRows)}
        columns={makeColumns}
        loading={loadingTableData}
        paginated
        currentPage={parseInt(page, 10) || 1}
        count={tableCount}
        pageSize={parseInt(page_size, 10) || 10}
        scroll={{ x: 1500 }}
      />
    </>
  );
}
