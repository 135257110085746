import styled from 'styled-components';
import { Typography } from 'antd';
import { tabletWidth } from '~/constants/metrics';

const { Title } = Typography;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  height: 100%;
  flex: 1;
`;

export const PasswordImage = styled.img`
  margin-top: 100px;
`;

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 60px;
  width: 70%;
  background-color: ${({ theme }) => theme.bodyBackground};

  @media (max-width: ${tabletWidth}) {
    display: none;
  }
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
  background-color: ${({ theme }) => theme.componentBackground};
  color: ${({ theme }) => theme.textColor} !important;
  box-shadow: ${({ theme }) => theme.boxShadowBase};
  padding: 0 30px;

  @media (max-width: ${tabletWidth}) {
    width: 100%;
  }
`;

export const PageTitle = styled(Title)`
  width: 100%;
  color: ${({ theme }) => theme.headingColor} !important;
`;

export const LogoImg = styled.img`
  position: absolute;
  bottom: 18px;
  height: 50px;
  width: 150px;

  @media (max-width: ${tabletWidth}) {
    position: inherit;
  }
`;
