import React from 'react';
import { Tabs, Typography, Row, Col } from 'antd';
import safeRead from 'safely-read';
import { useParams } from 'react-router-dom';
import { InfoHolder, TabHolder } from './styles';

import Access from './Tabs/Access/Access';
import PersonalData from './Tabs/PersonalData/PersonalData';
import Financial from './Tabs/Financial/Financial';
import Parents from './Tabs/Parents';
import Professional from './Tabs/Professional/Professional';
import HistoryModal from './Modal/HistoryModal/index';
import {
  UserRegistryContext,
  useUserData,
  handleModalVisible,
  useCompensatoryTime,
  useCompensatoryTimeHistory,
  useBudget,
} from '.';

const { TabPane } = Tabs;
const { Text } = Typography;

export default function UserRegistry() {
  const [visible, setVisible] = React.useState(false);
  const [modalType, setModalType] = React.useState(undefined);

  const { id } = useParams();
  const {
    userData,
    loadingUserData,
    setUserData,
    fetchData,
    setLoadingUserData,
  } = useUserData(id);
  const {
    compensatoryTime,
    setCompensatoryTime,
    fetchCompensatoryTime,
  } = useCompensatoryTime(id);
  const {
    compensatoryTimeHistory,
    setCompensatoryTimeHistory,
    fetchCompensatoryTimeHistory,
  } = useCompensatoryTimeHistory(id);

  const { budgetLeader, budgetPrepository, tooltipVisible } = useBudget();

  return (
    <>
      <UserRegistryContext.Provider
        value={{
          userData,
          setUserData,
          loadingUserData,
          handleModalVisible,
          modalType,
          setModalType,
          visible,
          setVisible,
          fetchData,
          setLoadingUserData,
          compensatoryTime,
          setCompensatoryTime,
          fetchCompensatoryTime,
          compensatoryTimeHistory,
          setCompensatoryTimeHistory,
          fetchCompensatoryTimeHistory,
          budgetLeader,
          budgetPrepository,
          tooltipVisible,
        }}
      >
        <InfoHolder>
          <Row gutter={24} type="flex" justify="center">
            <Col sm={24} md={24} lg={12} xl={8}>
              <Text>Nome: </Text>
              <Text strong>{safeRead(userData, ['name_display'], ['--'])}</Text>
            </Col>
            <Col sm={24} md={24} lg={12} xl={5}>
              <Text>Matrícula SENIOR: </Text>
              <Text strong>{safeRead(userData, [''], '--')}</Text>
            </Col>
            <Col sm={24} md={24} lg={12} xl={5}>
              <Text>Matrícula Csys: </Text>
              <Text strong>
                {safeRead(userData, ['csys_registration_number'], ['--'])}
              </Text>
            </Col>
            <Col sm={24} md={24} lg={12} xl={4}>
              <Text>CPF: </Text>
              <Text strong>{safeRead(userData, ['cpf'], ['--'])}</Text>
            </Col>
          </Row>
        </InfoHolder>

        <TabHolder>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Acesso" key="1">
              {!loadingUserData && <Access />}
            </TabPane>
            <TabPane tab="Dados Pessoais" key="2">
              {!loadingUserData && <PersonalData />}
            </TabPane>
            <TabPane tab="Parentes" key="3">
              {!loadingUserData && <Parents />}
            </TabPane>
            <TabPane tab="Profissional" key="4">
              {!loadingUserData && <Professional />}
            </TabPane>
            <TabPane tab="Financeiro" key="5">
              {!loadingUserData && <Financial />}
            </TabPane>
          </Tabs>
        </TabHolder>

        {visible ? (
          <HistoryModal visible={visible} setVisible={setVisible} />
        ) : null}
      </UserRegistryContext.Provider>
    </>
  );
}
