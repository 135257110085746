import React from 'react';
import { Row, Col, Typography, Button, Divider, Modal } from 'antd';
import safeRead from 'safely-read';
import { GrossPaymentHolder, WarningButton } from './style';
import formatPrice from '~/services/utils/formatPrice';
import checkPermission from '~/services/utils/checkPermission';
import { patchStatus, CollapsePanelContext } from '../index';

export default function GrossPayment() {
  const {
    user,
    key,
    month,
    year,
    fetchConsolidatedProduction,
    setConsolidatedProduction,
    setLoading,
  } = React.useContext(CollapsePanelContext);
  const { Text } = Typography;
  const { confirm } = Modal;
  return (
    <>
      <GrossPaymentHolder>
        <Text>
          <Row gutter={24} type="flex" justify="space-between">
            <Col>
              <Text>Verbas e Pacotes</Text>
              <br />
              <br />
              <Text>Férias (D.A.R.)</Text>
              <br />
              <br />
              <Text>Abonos</Text>
              <br />
              <br />
              <Text>Produção do mês</Text>
              <br />
              <br />
              <Text>Horas extras</Text>
              <br />
              <br />
              <Text>Ausências</Text>
            </Col>

            <Col>
              <Text strong>
                {safeRead(
                  user,
                  ['general', 'gross_payroll', 'budgets_and_service_bundle'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}
              </Text>
              <br />
              <br />
              <Text strong>
                {safeRead(
                  user,
                  ['general', 'gross_payroll', 'annual_leaves'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}
              </Text>
              <br />
              <br />
              <Text strong>
                {safeRead(
                  user,
                  ['general', 'gross_payroll', 'days_off'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}
              </Text>
              <br />
              <br />
              <Text strong>
                {safeRead(
                  user,
                  ['general', 'gross_payroll', 'month_production'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}
              </Text>
              <br />
              <br />
              <Text strong>
                {safeRead(
                  user,
                  ['general', 'gross_payroll', 'overtime'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}
              </Text>
              <br />
              <br />
              <Text strong>
                {safeRead(
                  user,
                  ['general', 'gross_payroll', 'absence'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}
              </Text>
            </Col>
          </Row>
        </Text>

        <Divider type="horizontal" className="ant-divider-horizontal-text" />
        <Row type="flex" justify="space-between">
          <Col>
            <Text strong>Bruto a pagar:</Text>
          </Col>
          <Col>
            <span style={{ color: '#1DA57A', fontWeight: 'bold' }}>
              {safeRead(
                user,
                ['general', 'gross_payroll', 'gross_total'],
                'R$ 0,00',
                value => formatPrice(value)
              )}
            </span>
          </Col>
        </Row>
      </GrossPaymentHolder>
      {user.status !== null && user.status.status === 'em_analise' && (
        <>
          {checkPermission('change_consolidatedproduction') && (
            <Button
              type="primary"
              block
              onClick={() =>
                confirm({
                  content: 'Deseja realmente aprovar a produção do cooperado?',
                  onOk: async () => {
                    await patchStatus(key, year, month, 'aprovado');
                    fetchConsolidatedProduction(
                      year,
                      month,
                      setConsolidatedProduction,
                      setLoading
                    );
                  },
                })
              }
            >
              Aprovar
            </Button>
          )}
        </>
      )}
      {user.status !== null && user.status.status === 'aprovado' && (
        <>
          {checkPermission('change_consolidatedproduction') && (
            <WarningButton
              block
              onClick={() =>
                confirm({
                  content:
                    'Deseja realmente deixar a produção do cooperado em análise?',
                  onOk: async () => {
                    await patchStatus(key, year, month, 'em_analise');
                    fetchConsolidatedProduction(
                      year,
                      month,
                      setConsolidatedProduction,
                      setLoading
                    );
                  },
                })
              }
            >
              Deixar em análise
            </WarningButton>
          )}
        </>
      )}
    </>
  );
}
