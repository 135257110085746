import React from 'react';
import { Select as AntSelect } from 'antd';
import safeRead from 'safely-read';
import history, { pushWithQueriesParams } from '~/services/history';
import { buildDateRangeStringPair } from '~/services/utils/dateRangeUtils';
import SearchForm from './SearchForm';

export const selectOptions = {
  requestOptions: [
    { id: 'ausencia', value: 'Ausência' },
    { id: 'hora_extra', value: 'Hora extra' },
  ],
  statusOptions: [
    { id: 'aprovado', value: 'Aprovado' },
    { id: 'em_analise', value: 'Em análise' },
    { id: 'reprovado', value: 'Reprovado' },
    { id: 'cancelado', value: 'Cancelado' },
  ],
  render: item => (
    <AntSelect.Option key={item.id} value={item.id}>
      {item.value}
    </AntSelect.Option>
  ),
};

export function handleSearch(values, componentProps) {
  const { idInput, dateInput, requestInput, statusInput } = values;
  const { pageSize } = componentProps;
  const dateStringPair = buildDateRangeStringPair(dateInput);

  pushWithQueriesParams(history.location.pathname, {
    id: idInput,
    date_start: dateStringPair && dateStringPair[0],
    date_end: dateStringPair && dateStringPair[1],
    request: requestInput,
    status: statusInput,
    page: 1,
    page_size: pageSize,
  });
}

export function handleClearFilters(setFieldValue) {
  setFieldValue('idInput', null);
  setFieldValue('dateInput', null);
  setFieldValue('requestInput', null);
  setFieldValue('statusInput', null);

  pushWithQueriesParams(history.location.pathname, {
    page: 1,
    page_size: 10,
  });
}

export function handleDisableSearch(fieldValues) {
  const id = safeRead(fieldValues, ['idInput']);
  const date = safeRead(fieldValues, ['dateInput'], []);
  const request = safeRead(fieldValues, ['requestInput']);
  const status = safeRead(fieldValues, ['statusInput']);

  if (id || date.length || request || status) {
    return false;
  }

  return true;
}

export default SearchForm;
