import React, { useContext, useEffect, useState } from 'react';
import { Divider, Button, Typography } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';

import {
  Container,
  ItemContainer,
  LeadingContainer,
  ContentContainer,
  TrailingContainer,
  VacationAllowenceBadge,
  Title,
} from './styles';
import { VacationsContext } from '../index';
import renderBadge from '~/services/utils/renderStatusBadge';
import { formatDetails } from './index';

const { Text } = Typography;

export default function RequestHistory() {
  const { paidLeave, setDetailModalVisible, setDetail } = useContext(
    VacationsContext
  );
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (paidLeave) {
      setHistory(safeRead(paidLeave, ['timeline', 'history']));
    }
  }, [paidLeave]);

  return (
    <Container>
      {history && history.length > 0 ? (
        <>
          {(history || []).map(
            (
              {
                status,
                status_display,
                paid_leave_type_display,
                begin_date,
                created_at,
                last_date,
                paid_leave_type,
              },
              index
            ) => (
              <ItemContainer key={index.toString()}>
                <LeadingContainer>
                  <VacationAllowenceBadge
                    vacation={paid_leave_type === 'ferias'}
                  >
                    {paid_leave_type_display}
                  </VacationAllowenceBadge>
                  {created_at && (
                    <p>{moment(created_at).format('DD/MM/YYYY')}</p>
                  )}
                </LeadingContainer>

                <Divider type="vertical" />

                <ContentContainer>
                  <Title>
                    {`${moment(begin_date).format('DD/MM/YYYY')}`}{' '}
                    {last_date &&
                      ` - ${moment(last_date).format('DD/MM/YYYY')}`}
                  </Title>
                  {renderBadge({ status, status_display })}
                </ContentContainer>

                <Divider type="vertical" />

                <TrailingContainer>
                  <Button
                    onClick={() => {
                      setDetailModalVisible(true);
                      setDetail(formatDetails(history[index]));
                    }}
                    type="link"
                  >
                    Detalhes
                  </Button>
                </TrailingContainer>
              </ItemContainer>
            )
          )}
        </>
      ) : (
        <Text level={4} style={{ display: 'block', marginRight: 'auto' }}>
          Você não tem solicitações para este período aquisitivo.
        </Text>
      )}
    </Container>
  );
}
