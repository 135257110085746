import React from 'react';

import { makeColumns } from './index';
import Table from '~/components/DataDisplay/Table';

export default function Parents() {
  return (
    <>
      <Table columns={makeColumns()} dataSource={[]} />{' '}
    </>
  );
}
