import React from 'react';
import { Formik, Field } from 'formik';
import { Row, Col, Select as AntdSelect, Button, Form, Divider } from 'antd';
import debounce from 'lodash.debounce';

import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import {
  clearFields,
  handleSearch,
  getInitialValues,
  fetchRole,
  useSearchProject,
} from '.';

export default function SearchForm() {
  const { loadingProjects, projects, setSearch } = useSearchProject();
  const [roles, setRoles] = React.useState([]);
  const [disableField, setDisableField] = React.useState(true);

  const [positionId, setPositionId] = React.useState(null);

  const status = [
    { id: 'Ativo', value: 'true' },
    { id: 'Inativo', value: 'false' },
  ];

  React.useEffect(() => {
    fetchRole(setDisableField, setRoles);
  }, []);

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={values => handleSearch(values, positionId)}
      >
        {({ handleSubmit, values, setValues, setFieldValue }) => (
          <Form>
            <Row gutter={24} type="flex" justify="start">
              <Col xs={24} sm={24} md={4} lg={4} xl={7}>
                <Field
                  id="csysField"
                  name="csysField"
                  label="Matrícula Csys"
                  component={Input}
                />
              </Col>

              <Col xs={24} sm={24} md={5} lg={5} xl={8}>
                <Field
                  id="nameField"
                  name="nameField"
                  label="Nome"
                  component={Input}
                />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={8}>
                <Field
                  id="teamField"
                  name="teamField"
                  label="Equipe"
                  loading={loadingProjects}
                  component={AutoCompleteSelect}
                  placeholder=" "
                  selectSchema={item => (
                    <AntdSelect.Option key={item.id} value={item.id}>
                      {item.team_name}
                    </AntdSelect.Option>
                  )}
                  onChange={e => {
                    if (!e) {
                      setFieldValue('teamField', null);
                    } else {
                      setFieldValue('teamField', e);
                    }

                    setSearch(undefined);
                  }}
                  search={debounce(setSearch, 600)}
                  allowClear
                  data={projects.results}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Col>
                <Button onClick={() => clearFields(setValues)}>Limpar</Button>
                <Divider type="vertical" />
                <Button
                  onClick={handleSubmit}
                  disabled={
                    !values.nameField &&
                    !values.accessField &&
                    !values.roleField &&
                    !values.teamField &&
                    !values.csysField
                  }
                  type="primary"
                  icon="search"
                  htmlType="submit"
                >
                  Pesquisar
                </Button>
              </Col>
            </Row>
            <br />
          </Form>
        )}
      </Formik>
      <Divider />
    </>
  );
}
