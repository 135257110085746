import { defineMessages } from 'react-intl';
import { scope as rectificationScope } from '../../messages';

export const scope = 'portal.sider.worktime.modal.rectification';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Rasura rápida',
  },
  checkIn: {
    id: `${rectificationScope}.check_in`,
    defaultMessage: 'Entrada',
  },
  checkOut: {
    id: `${rectificationScope}.check_out`,
    defaultMessage: 'Saída',
  },
  makingAction: {
    id: `${scope}.makingAction`,
    defaultMessage: 'Você está efetuando uma',
  },
  withoutPrevious: {
    id: `${scope}.withoutPrevious`,
    defaultMessage: 'sem uma prévia',
  },
  fillRectification: {
    id: `${scope}.fillRectification`,
    defaultMessage: 'Informe o horário desta',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Selecione',
  },
  rectification: {
    id: `${scope}.rectification`,
    defaultMessage: 'Rasura',
  },
  reason: {
    id: `${scope}.reason`,
    defaultMessage: 'Motivo',
  },
  optional: {
    id: `${scope}.optional`,
    defaultMessage: 'Opcional',
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage: 'Atenção',
  },
  warningDescription: {
    id: `${scope}.warningDescription`,
    defaultMessage:
      'Após rasurar este registro passado o seu registro atual será computado',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancelar',
  },
  submitRectification: {
    id: `${scope}.submitRectification`,
    defaultMessage: 'Submeter Rasura',
  },
});
