import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import { VacationsContext } from '../index';
import { cancelRequest } from '../RequestHistory';

export default function CooperativeActions({ detail }) {
  const { updatePaidLeave, setDetailModalVisible } = useContext(
    VacationsContext
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {detail.find(item => item.key === 'status').value.props.text ===
        'Em análise' && (
        <Button
          onClick={async () => {
            cancelRequest(
              detail.find(item => item.key === 'id').value,
              updatePaidLeave,
              setDetailModalVisible
            );
          }}
          type="danger"
        >
          Cancelar solicitação
        </Button>
      )}
    </div>
  );
}

CooperativeActions.propTypes = {
  detail: PropTypes.arrayOf(PropTypes.any).isRequired,
};
