import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import debounce from 'lodash.debounce';

import Select from '~/components/DataEntry/Select';
import Input from '~/components/DataEntry/Input';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { getAllQueriesParams } from '~/services/history';
import {
  handleSearch,
  clearFields,
  getInitialValues,
  fetchRole,
  useSearchProject,
} from './index';

export default function SearchForm() {
  const { loadingProjects, projects, setSearch } = useSearchProject();
  const [roles, setRoles] = React.useState([]);
  const [disableField, setDisableField] = React.useState(true);

  const [positionId, setPositionId] = React.useState(null);

  const { team_name } = getAllQueriesParams();

  React.useEffect(() => {
    fetchRole(setDisableField, setRoles);
  }, []);

  React.useEffect(() => {
    if (team_name) {
      setSearch(team_name);
    }
  }, [team_name, setSearch]);

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={values => handleSearch(values, positionId)}
    >
      {({ handleSubmit, values, setFieldValue, setValues }) => (
        <Form>
          <Row gutter={24} type="flex" justify="start">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Field
                id="nameField"
                name="nameField"
                label="Nome"
                component={Input}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Field
                id="teamField"
                name="teamField"
                label="Equipes"
                loading={loadingProjects}
                component={AutoCompleteSelect}
                placeholder=" "
                selectSchema={item => (
                  <AntdSelect.Option key={item.id} value={item.id}>
                    {item.name}
                  </AntdSelect.Option>
                )}
                onChange={e => {
                  if (!e) {
                    setFieldValue('teamField', null);
                  } else {
                    setFieldValue('teamField', e);
                  }

                  setSearch(undefined);
                }}
                search={debounce(setSearch, 800)}
                allowClear
                data={projects.results}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Field
                showSearch
                id="position"
                name="position"
                label="Cargo"
                data={roles}
                disabled={disableField}
                renderItem={item => (
                  <AntdSelect.Option
                    key={item.id}
                    value={item.position_display}
                  >
                    {item.position_display}
                  </AntdSelect.Option>
                )}
                onChange={(e, a) => {
                  setPositionId(a.key);
                }}
                component={Select}
              />
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              <Button onClick={() => clearFields(setValues)}>Limpar</Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                icon="search"
                htmlType="submit"
                disabled={
                  !values.nameField && !values.teamField && !values.position
                }
              >
                Pesquisar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
