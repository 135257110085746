import { useQuery } from 'react-query';
import api from '~/services/api';
import { parseResponseData } from '../../../../services/utils/parseResponseData';
import { useGetTeamListKey } from './key';

export const useGetTeamList = (filters, options) => {
  return useQuery(
    useGetTeamListKey(
      filters.name,
      filters.member,
      filters.directorship,
      filters.page,
      filters.pageSize
    ),
    () =>
      api
        .get(
          `/squad/?name=${filters.name}&member=${filters.member}&directorship=${filters.directorship}&page=${filters.page}&page_size=${filters.pageSize}`
        )
        .then(parseResponseData),
    options
  );
};
