import styled from 'styled-components';
import { Row, Col } from 'antd';
import { shade } from 'polished';

export const Container = styled(Row).attrs({
  gutter: 28,
})`
  padding: 12px;
  margin: 16px 0 !important;
  background-color: ${({ theme }) => shade(0.04, theme.componentBackground)};
  border-radius: ${({ theme }) => theme.borderRadiusBase};

  .info-title {
    color: ${({ theme }) => theme.textColor};
  }

  .info-description {
    color: ${({ theme }) => theme.textColor};
    font-size: 28px;
    font-weight: bold;
  }
`;

export const InfoItemHolder = styled(Col).attrs({
  sm: 24,
  md: 4,
  lg: 4,
  xl: 4,
})``;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;

export const ItemLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: white;
  margin: 12px 0;
`;
