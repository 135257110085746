import { message } from 'antd';
import safeRead from 'safely-read';

import Users from './Users';
import api from '~/services/api';
import { TEAM_MANAGE_ID, DELETE_TEAM_PERSON } from '~/constants/endpoints/team';

export async function deletePerson(team_id, person) {
  try {
    await api.delete(DELETE_TEAM_PERSON(team_id, person));

    message.success('Usuário deletado com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao deletar usuário.'
      )
    );
  }
}

export async function addPersons(team_id, persons) {
  try {
    await api.post(TEAM_MANAGE_ID(team_id), { persons });

    message.success('Usuários atribuídos com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao atribuir usuários.'
      )
    );
  }
}

export default Users;
