import React, { useContext, useState, useEffect } from 'react';
import { Typography, Tooltip } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import { VacationsContext } from '../index';
import {
  Container,
  TimelineContainer,
  TimelineItem,
  TimelineItemTitle,
  TimelineItemContent,
} from './styles';

const { Title } = Typography;

export default function Timeline() {
  const { paidLeave } = useContext(VacationsContext);
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    if (paidLeave) {
      setTimeline(safeRead(paidLeave, ['timeline', 'months_proportions']));
    }
  }, [paidLeave]);

  return (
    <>
      {timeline && (
        <Container>
          <>
            <Title level={4}>Linha do tempo</Title>

            <TimelineContainer>
              {timeline &&
                (timeline || []).map((item, index, arr) => (
                  <div
                    key={item.month.month_display}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TimelineItem last={index === 11}>
                      <TimelineItemTitle>
                        {item.month.month_display.substring(0, 3)}
                      </TimelineItemTitle>

                      {item.obteined !== null ? (
                        <Tooltip
                          title={
                            item.obteined !== 0
                              ? `${item.propotion.split('.')[0]} %`
                              : ''
                          }
                        >
                          <TimelineItemContent
                            position={index}
                            days={item.obteined}
                            first={index === 0}
                            last={
                              index === 11 ||
                              (arr[index + 1]
                                ? arr[index + 1] === null
                                : false) ||
                              arr[index + 1].obteined === null
                            }
                          >
                            {`${
                              item.obteined === 0
                                ? item.obteined
                                : `+${item.obteined}`
                            } dia(s)`}
                          </TimelineItemContent>
                        </Tooltip>
                      ) : (
                        <TimelineItemContent> </TimelineItemContent>
                      )}
                    </TimelineItem>

                    <div style={{ margin: '20px 0 0 0' }}>
                      {index === 0
                        ? moment(paidLeave.timeline.start_date).format(
                            'DD/MM/YYYY'
                          )
                        : null}

                      {index === arr.length - 1
                        ? moment(paidLeave.timeline.end_date).format(
                            'DD/MM/YYYY'
                          )
                        : null}
                    </div>
                  </div>
                ))}
              {paidLeave !== null && paidLeave.start_date === '' ? (
                <div
                  style={{
                    width: `100%`,
                    height: `100%`,
                    position: `absolute`,
                    background: `rgba(0,0,0,0.7)`,
                    top: 0,
                    left: 0,
                    zIndex: 9,
                  }}
                >
                  <div
                    style={{
                      paddingTop: 30,
                      zIndex: 10,
                      textAlign: 'center',
                      color: '#fff',
                      fontSize: 20,
                    }}
                  >
                    <span>
                      Você não possui saldo de Férias ou Abonos para esse
                      período selecionado.
                    </span>
                    <br />
                    <strong>Por favor volte no mês seguinte.</strong>
                  </div>
                </div>
              ) : null}
            </TimelineContainer>
          </>
        </Container>
      )}
    </>
  );
}
