import safeRead from 'safely-read';
import { message } from 'antd';

import moment from 'moment';
import api from '~services/api';
import AbsenceOvertimeModal from './AbsenceOvertimeModal';
import { REQUEST_PAST_ABSENCE_OVERTIME } from '~/constants/endpoints/request';

export function mountTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      key: safeRead(item, ['key']),
      day: safeRead(item, ['dia']),
      production: safeRead(item, ['producao']),
      balance: safeRead(item, ['saldo']),
      type: safeRead(item, ['saldo'], '--', item => {
        const saldo = item.split([]);
        return saldo[0] === '+' ? ['Hora Extra'] : [''];
      }),
      reason: safeRead(item, ['']),
    }));
  }

  return [];
}

export async function handleSubmit(
  values,
  setSubmitting,
  toggleAbsenceOvertimeModal,
  repeatReason
) {
  setSubmitting(true);

  const payload = [];

  (values.rowSelection.selectedRowKeys || []).map((item, index) => {
    if ((values.type || []).length > 0) {
      return payload.push({
        date: moment(item).format('YYYY-MM-DD'),
        reason: repeatReason
          ? safeRead(values, ['reason', [0]])
          : safeRead(values, ['reason', [index]]),
      });
    }

    return payload.push({
      date: moment(item).format('YYYY-MM-DD'),
      reason: repeatReason
        ? safeRead(values, ['reason', [0]])
        : safeRead(values, ['reason', [index]]),
    });
  });

  const promises = payload.map((item, index) => {
    async function request() {
      let payloadRequest = {};
      if (item.request_type !== '') {
        payloadRequest = {
          reason: item.reason,
          request_type: item.request_type,
        };
      } else {
        payloadRequest = {
          reason: item.reason,
        };
      }
      try {
        await api.patch(
          REQUEST_PAST_ABSENCE_OVERTIME(item.date),
          payloadRequest
        );
        window.location.reload(false);
        message.success({
          key: 'success',
          content: 'Solicitação enviada com sucesso.',
        });
      } catch (error) {
        message.error({
          key: index + 1,
          content: safeRead(
            error,
            ['response', 'data', 'detail'],
            `Ocorreu um erro na solicitação da data: ${moment(item.date).format(
              'DD/MM/YYYY dddd'
            )} .`
          ),
        });
      }
    }
    return request();
  });

  await Promise.all(promises);
  toggleAbsenceOvertimeModal();
  setSubmitting(false);
}

export default AbsenceOvertimeModal;
