import { useState, useEffect } from 'react';
import safeRead from 'safely-read';
import SearchForm from './SearchForm';
import { message } from 'antd';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import api from '~/services/api';
import { POSITION_ROLE } from '~/constants/endpoints/position';
import { TEAM } from '~/constants/endpoints/team';

export function handleSearch(values, positionID) {
  const { page_size } = getAllQueriesParams();
  const name = safeRead(values, ['nameField']);
  const is_active = safeRead(values, ['accessField']);
  const team = safeRead(values, ['teamField']);
  const csys_registration_number = safeRead(values, ['csysField']);
  const position = positionID;
  const page = 1;

  return pushWithQueriesParams(history.location.pathname, {
    page,
    page_size,
    name,
    is_active,
    position,
    team,
    csys_registration_number,
  });
}

export async function getInitialValues() {
  const {
    name,
    is_active,
    position,
    team,
    csys_registration_number,
  } = getAllQueriesParams();

  return {
    nameField: name,
    accessField: is_active,
    roleField: position,
    teamField: team,
    csysField: csys_registration_number,
  };
}

export function clearFields(setValues) {
  setValues({
    nameField: null,
    accessField: null,
    roleField: null,
    teamField: null,
    csys_registration_number: null,
  });
  pushWithQueriesParams(history.location.pathname, {});
}

export async function fetchRole(setDisableField, setRoles) {
  try {
    const { data } = await api.get(POSITION_ROLE);
    setDisableField(false);
    setRoles(data);
  } catch (error) {
    setDisableField(true);
    setRoles([]);
  }
}

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export default SearchForm;
