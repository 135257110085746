import { defineMessages } from 'react-intl';

export const scope = 'portal.header.menu';

export default defineMessages({
  myProfile: {
    id: `${scope}.my_profile`,
    defaultMessage: 'Meu Perfil',
  },
  switchProfile: {
    id: `${scope}.switch_profile`,
    defaultMessage: 'Trocar Perfil',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Configurações',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Sair',
  },
});
