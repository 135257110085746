import { store } from '~/store';

export default function formatPrice(value) {
  const { locale } = store.getState().language;

  const { format } = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'BRL',
  });

  return format(value);
}
