import React, { useState, useEffect } from 'react';
import { message } from 'antd';

import api from '~/services/api';
import AddUser from './AddUser';
import { DELETE_USER_AUTH_GROUP, AUTH_USERS } from '~/constants/endpoints/auth';

export const AddUserContext = React.createContext();

export async function getUsersOnThisProfile(group_id, page_size = 5, name) {
  let result = [];

  try {
    const { data } = await api.get(AUTH_USERS, {
      params: { group_id, page_size, page: 1, name },
    });

    result = data;
  } catch (error) {
    message.error('Erro ao buscar users');
  }

  return result;
}

export async function updateUsers(group_id, setUsers) {
  const data = await getUsersOnThisProfile(group_id);

  setUsers(data);
}

export async function deleteUser(group_id, user_id) {
  try {
    await api.delete(DELETE_USER_AUTH_GROUP(group_id, user_id));

    message.success('Usuário deletado com sucesso');
  } catch (error) {
    message.error('Erro ao deletar usuário.');
  }
}

export function useUsersOnThisProfile(group_id) {
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadUsers() {
      setLoading(true);
      const results = await getUsersOnThisProfile(group_id, pageSize, search);

      setUsers(results);

      setLoading(false);
    }

    loadUsers();
  }, [group_id, pageSize, search]);

  return {
    users,
    setUsers,
    loading,
    pageSize,
    setPageSize,
    search,
    setSearch,
  };
}

export default AddUser;
