import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

function CountdownCounter({ countdown, setCountdown, timelineToCountDown }) {
  const [countMinute, setCountMinute] = useState(0);
  const [countSecond, setCountSecond] = useState(0);

  const [tabVisibility, setTabVisibility] = useState(true);

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      setTabVisibility(false);
    } else {
      setTabVisibility(true);
    }
  });

  useEffect(() => {
    if (timelineToCountDown.length > 0) {
      if (
        timelineToCountDown[0][0].check_in === false &&
        timelineToCountDown[0][0].minimum_break
      ) {
        const initialTime = moment(timelineToCountDown[0][0].worktime_clock)
          .format('HH:mm:ss')
          .split(':');
        const finalTime = moment()
          .format('HH:mm:ss')
          .split(':');
        let totalHours =
          parseInt(finalTime[0], 10) - parseInt(initialTime[0], 10);
        let totalMinutes =
          parseInt(finalTime[1], 10) - parseInt(initialTime[1], 10);
        let totalSeconds =
          parseInt(finalTime[2], 10) - parseInt(initialTime[2], 10);

        if (totalSeconds < 0) {
          totalSeconds += 60;
          totalMinutes -= 1;
        }
        if (totalMinutes < 0) {
          totalMinutes += 60;
          totalHours -= 1;
        }

        if (totalHours === 0 && totalMinutes <= 30 && totalMinutes >= 0) {
          if (totalMinutes >= 30) {
            setCountMinute(30 - totalMinutes);
            setCountSecond(0);
          } else {
            setCountMinute(30 - totalMinutes - 1);
            setCountSecond(60 - totalSeconds);
          }
        }
      }
    }
  }, [timelineToCountDown, tabVisibility]);

  useEffect(() => {
    let timer;
    if (timelineToCountDown.length > 0) {
      if (
        timelineToCountDown[0][0].check_in === false &&
        timelineToCountDown[0][0].minimum_break
      ) {
        let checkout_timer = moment(timelineToCountDown[0][0].worktime_clock);

        let checkin_timer = checkout_timer.clone();
        checkin_timer.add(countMinute, 'minutes');
        checkin_timer.add(countSecond, 'seconds');

        timer = setInterval(() => {
          checkin_timer.subtract(1000);
          let timer_diff = checkin_timer.diff(checkout_timer);
          if (timer_diff <= 0) {
            clearInterval(timer);
          }

          setCountdown(moment.utc(timer_diff).format('mm:ss'));
        }, 1000);
      }
    }
    return () => {
      clearTimeout(timer);
    };
  }, [
    timelineToCountDown,
    setCountdown,
    countMinute,
    countSecond,
    tabVisibility,
  ]);

  return (
    <>
      {countdown !== '00:00' && countdown !== '59:59'
        ? ` - ${countdown}`
        : null}
    </>
  );
}

export default CountdownCounter;

CountdownCounter.propTypes = {
  countdown: PropTypes.string.isRequired,
  setCountdown: PropTypes.func.isRequired,
  timelineToCountDown: PropTypes.array.isRequired,
};
