import React from 'react';

import SearchForm from './SearchForm';
import Chart from './Chart';

import { ProductionChartContext, useChartData } from './index';

export default function ProductionChart() {
  const {
    team,
    setTeam,
    dateRange,
    setDateRange,
    chartData,
    setChartData,
  } = useChartData();

  return (
    <ProductionChartContext.Provider
      value={{
        team,
        setTeam,
        dateRange,
        setDateRange,
        chartData,
        setChartData,
      }}
    >
      <SearchForm />
      <Chart />
    </ProductionChartContext.Provider>
  );
}
