import React, { useState } from 'react';
import { Divider } from 'antd';

import AbsenceOvertimeTable from './AbsenceOvertimeTable';
import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm';
import TableActions from './TableActions';
import DetailModal from './Modals/DetailModal';
import ConfirmationModal from './Modals/ConfirmationModal';

import {
  useTableData,
  AbsenceOvertimeContext,
  fetchTableData,
  patchRequestApproval,
} from './index';

export default function AbsenceOvertimeApproval() {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowRequestTypes, setSelectedRowRequestTypes] = useState([]);
  const [itemsToUpdateStatus, setItemsToUpdateStatus] = useState([]);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [from, setFrom] = useState('');
  const [confirmationType, setConfirmationType] = useState('');
  const [detailData, setDetailData] = useState(null);
  const {
    loadingTableData,
    setLoadingTableData,
    setTableData,
    tableData,
  } = useTableData();

  return (
    <PageContainer title="Ausência e Hora extra">
      <AbsenceOvertimeContext.Provider
        value={{
          fetchTableData: () =>
            fetchTableData(setLoadingTableData, setTableData),
          patchRequestApproval,
          setSelectedRowKeys,
          selectedRowKeys,
          tableData,
          loadingTableData,
          setDetailModalVisible,
          setDetailData,
          detailData,
          selectedRowRequestTypes,
          setSelectedRowRequestTypes,
          itemsToUpdateStatus,
          setItemsToUpdateStatus,
          confirmationType,
          setConfirmationType,
          setConfirmationModalVisible,
          confirmationModalVisible,
          from,
          setFrom,
        }}
      >
        <SearchForm />

        <Divider />

        <TableActions />

        <AbsenceOvertimeTable />

        {detailModalVisible ? (
          <DetailModal
            setVisible={setDetailModalVisible}
            visible={detailModalVisible}
          />
        ) : null}

        {confirmationModalVisible ? (
          <ConfirmationModal
            visible={confirmationModalVisible}
            setVisible={setConfirmationModalVisible}
          />
        ) : null}
      </AbsenceOvertimeContext.Provider>
    </PageContainer>
  );
}
