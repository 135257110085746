import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import debounce from 'lodash.debounce';

export default function Search({ loading, setSearch }) {
  const debouncedUpdate = debounce(value => setSearch(value), 800);

  return (
    <>
      <Input.Search
        loading={loading}
        onChange={({ target: { value } }) => debouncedUpdate(value)}
      />
    </>
  );
}

Search.propTypes = {
  loading: PropTypes.bool.isRequired,
  setSearch: PropTypes.func.isRequired,
};
