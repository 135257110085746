import { useState, useEffect } from 'react';
import moment from 'moment';
import safeRead from 'safely-read';
import { message } from 'antd';
import SearchForm from './SearchForm';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import api from '~/services/api/index';
import { TEAM } from '~/constants/endpoints/team';

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export function buildDateRange(date_start, date_end) {
  const date_range = [];

  if (!date_start && date_end) {
    date_range.push(null);
    date_range.push(moment(date_end));

    return date_range;
  }

  if (date_start) date_range.push(moment(date_start));
  if (date_end) date_range.push(moment(date_end));

  return date_range;
}

export function buildDateRangeStringPair(datePair) {
  return datePair
    ? datePair.reduce((result, value) => {
        const date = new Date(value._d).toISOString().split('T')[0];

        result.push(date);

        return result;
      }, [])
    : null;
}

export function handleSearch(values) {
  const { ...queries } = getAllQueriesParams();
  const full_name = safeRead(values, ['nameField']);
  const team_id = safeRead(values, ['teamField']);
  const csys_registration_number = safeRead(values, ['csysField']);
  const dateStringPair = buildDateRangeStringPair(
    safeRead(values, ['formikDateRange'])
  );
  const page = 1;

  return pushWithQueriesParams(history.location.pathname, {
    ...queries,
    csys_registration_number,
    page,
    full_name,
    team_id,
    begin_date: dateStringPair[0],
    end_date: dateStringPair[1],
  });
}

export function getInitialValues() {
  const { full_name, team, csys_registration_number } = getAllQueriesParams();

  return {
    nameField: full_name,
    teamField: team,
    csysField: csys_registration_number,
    formikDateRange: [],
  };
}

export function clearFields(setValues) {
  setValues({
    nameField: null,
    teamField: null,
    csys_registration_number: null,
    formikDateRange: [],
  });
  pushWithQueriesParams(history.location.pathname, {});
}

export default SearchForm;
