import styled from 'styled-components';
import { tabletWidth } from '~/constants/metrics';

export const StatisticHolder = styled.div`
  padding: 12px;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.borderColorBase};
  border-radius: ${({ theme }) => theme.borderRadiusBase};

  @media (max-width: ${tabletWidth}) {
    margin-top: 26px;
  }

  .stats-title {
    color: ${({ theme }) => theme.textColorSecondary};
  }

  .stats-description {
    color: ${({ theme }) => theme.textColor};
    font-size: 28px;
    font-weight: bold;
  }
`;

export const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`;
