import React, { useState, useEffect, useContext } from 'react';
import { Tooltip } from 'antd';
import safeRead from 'safely-read';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { Table, TooltipWrapper } from './styles';
import { VacationsContext } from '../index';

export default function CountTable() {
  const [balance, setBalance] = useState(undefined);
  const { paidLeave } = useContext(VacationsContext);

  useEffect(() => {
    if (paidLeave) {
      setBalance(safeRead(paidLeave, ['timeline', 'balance', [0]]));
    }
  }, [paidLeave]);

  return (
    <>
      {balance && balance.days_off === null ? (
        <>
          {balance && (
            <Table border>
              <tbody>
                <tr>
                  <td />
                  <td className="tb-header">Férias</td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Adquirido</td>
                  <td className="tb-vacation-cell tb-first">
                    {balance.annual_leaves.obtained} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Utilizado</td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {balance.annual_leaves.approved} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Expirado 
                    <Tooltip
                      title={
                        <TooltipWrapper>
                          Caso o período expirado for após 01/01/2020 contate o seu coordenador
                        </TooltipWrapper>
                      }
                    >
                      <AiOutlineInfoCircle
                        size={12}
                      />
                    </Tooltip>
                  </td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {balance.annual_leaves.expired} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">
                    <b>Restante</b>
                  </td>
                  <td className="tb-vacation-cell tb-last">
                    {balance.annual_leaves.remaining} dia(s)
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      ) : (
        <>
          {balance && (
            <Table>
              <tbody>
                <tr>
                  <td />
                  <td className="tb-header">Férias</td>
                  <td className="tb-header">Abonos</td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Adquirido</td>
                  <td className="tb-vacation-cell tb-first-left">
                    {balance.annual_leaves.obtained} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-first-right">
                    {balance.days_off.obtained} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Utilizado</td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {balance.annual_leaves.approved} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-allowence-middle">
                    {balance.days_off.approved} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Expirado 
                    <Tooltip
                      title={
                        <TooltipWrapper>
                          Caso o período expirado for após 01/01/2020 contate o seu coordenador
                        </TooltipWrapper>
                      }
                    >
                      <AiOutlineInfoCircle
                        style={{ marginLeft: '2px' }}
                        size={12}
                      />
                    </Tooltip>
                  </td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {balance.annual_leaves.expired || 0} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-allowence-middle">
                    {balance.days_off.expired || 0} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">
                    <b>Restante</b>
                  </td>
                  <td className="tb-vacation-cell tb-last-left">
                    {balance.annual_leaves.remaining} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-last-right">
                    {balance.days_off.remaining} dia(s)
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  );
}
