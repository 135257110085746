import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
  Row,
  Button,
  Divider,
  Col,
  Typography,
  Table,
  Checkbox,
  message,
} from 'antd';
import { Field, withFormik } from 'formik';
import moment from 'moment';
import safeRead from 'safely-read';
import PropTypes from 'prop-types';
import { RequestAbsenceOvertimeContext } from '../RequestAbsenceOvertime';
import TimePicker from '~/components/DataEntry/TimePicker';
import Input from '~/components/DataEntry/Input';
import api from '~/services/api';
import { REQUEST_ABSENCE_OVERTIME } from '~/constants/endpoints/request';
import { AbsenceOvertimeContext } from '../../..';
import { FieldHolder } from './styles';

const { Title } = Typography;
const { Column } = Table;

export function renderHourInput(
  item,
  row,
  index,
  repeatFlag,
  absenceOvertimeType
) {
  const commonProps = {
    name: repeatFlag ? 'duration[0]' : `duration[${index}]`,
    id: repeatFlag ? 'duration[0]' : `duration[${index}]`,
    format: 'HH:mm',
    placeholder: '',
    disabled: repeatFlag ? index > 0 : false,
    minuteStep: absenceOvertimeType === 2 ? undefined : 15,
    disabledMinutes: hour => {
      if (hour === 0 && absenceOvertimeType === 2) {
        return Array.from({ length: 16 }, (i, ind) => (ind < 16 ? ind : null));
      }

      return [];
    },
    hideDisabledOptions: true,
    allowClear: false,
  };

  return (
    <FieldHolder>
      <Field {...commonProps} component={TimePicker} />
    </FieldHolder>
  );
}

export function renderReasonInput(item, row, index, repeatFlag) {
  const commonProps = {
    name: repeatFlag ? 'reason[0]' : `reason[${index}]`,
    id: repeatFlag ? 'reason[0]' : `reason[${index}]`,
    disabled: repeatFlag ? index > 0 : undefined,
  };

  return (
    <FieldHolder>
      <Field {...commonProps} component={Input} />
    </FieldHolder>
  );
}

function Finish({ values, setFieldValue }) {
  const [repeatDuration, setRepeatDuration] = useState(false);
  const [repeatReason, setRepeatReason] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { toggleModal, onFinishBack, step1, step2 } = useContext(
    RequestAbsenceOvertimeContext
  );
  const { fetchTableData } = useContext(AbsenceOvertimeContext);

  const dateList = step2.sort((a, b) => a - b);

  const requestStep = useMemo(() => {
    switch (step1) {
      case 'hora_extra':
        return 'Hora Extra';
      case 'ausencia_a_abonar':
        return 'Ausência a Abonar';

      default:
        return 'Ausência';
    }
  }, [step1]);

  const tableData = dateList.map(item => ({
    key: item,
    day: moment(item).format('DD/MM - ddd'),
    request: requestStep,
  }));

  const handleSubmit = async () => {
    setSubmitting(true);

    const payload = [];

    (dateList || []).map((item, index) =>
      payload.push({
        reason: repeatReason
          ? safeRead(values, ['reason', [0]])
          : safeRead(values, ['reason', [index]]),
        date: moment(item).format('YYYY-MM-DD'),
        hours: repeatDuration
          ? safeRead(values, ['duration', [0]], null, hour =>
              moment(hour).format('HH:mm:00')
            )
          : safeRead(values, ['duration', [index]], null, hour =>
              moment(hour).format('HH:mm:00')
            ),
        request_type: step1,
      })
    );

    try {
      await api.post(REQUEST_ABSENCE_OVERTIME, payload);

      message.success('Solicitação enviada com sucesso.');
      fetchTableData();
      toggleModal();
    } catch (error) {
      message.error(
        safeRead(error, ['detail'], 'Ocorreu um erro na solicitação.')
      );
    }

    setSubmitting(false);
  };

  const disableSubmit = repeatFlag => {
    const formikValues = safeRead(values, ['reason'], []);

    if (repeatFlag && formikValues.includes('')) {
      return true;
    }

    if (!repeatFlag && formikValues.length < dateList.length) {
      return true;
    }

    if (
      !repeatFlag &&
      formikValues.length === dateList.length &&
      formikValues.includes('')
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    dateList.map((date, index) =>
      setFieldValue(`duration[${index}]`, moment('00:00', 'HH:mm'))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <br />
      <Row>
        <Title level={4}>Finalize sua solicitação</Title>
      </Row>
      <br />
      <Checkbox
        checked={repeatDuration}
        onChange={e => setRepeatDuration(e.target.checked)}
      >
        Repetir a duração para todos os dias
      </Checkbox>
      <br />
      <Checkbox
        checked={repeatReason}
        onChange={e => setRepeatReason(e.target.checked)}
      >
        Repetir o motivo para todos os dias
      </Checkbox>
      <br />
      <br />
      <Table pagination={false} dataSource={tableData}>
        <Column dataIndex="day" ellipsis title="Dia" />
        <Column dataIndex="request" ellipsis title="Solicitação" />
        <Column
          dataIndex="duration"
          ellipsis
          title="Duração"
          render={(item, row, index) =>
            renderHourInput(item, row, index, repeatDuration, step1)
          }
        />
        <Column
          dataIndex="reason"
          ellipsis
          title="Motivo"
          render={(item, row, index) =>
            renderReasonInput(item, row, index, repeatReason)
          }
        />
      </Table>
      <br />
      <Row type="flex" justify="space-between">
        <Button ghost type="primary" onClick={onFinishBack}>
          Voltar
        </Button>
        <Col>
          <Button type="link" onClick={toggleModal}>
            Cancelar
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={submitting}
            disabled={disableSubmit(repeatReason)}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default withFormik({})(Finish);

Finish.propTypes = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
