import React from 'react';
import { InputNumber as AntInputNumber, Form } from 'antd';
import PropTypes from 'prop-types';

const { Item } = Form;

export default function InputNumber(props) {
  const {
    label,
    extra,
    form,
    field,
    onChange,
    onBlur,
    defaultValue,
    ...restProps
  } = props;
  const { name, value } = field;
  const { errors, touched, setFieldValue } = form;

  return (
    <Item
      label={label}
      validateStatus={errors[name] && touched[name] ? 'error' : 'success'}
      help={errors[name] && touched[name] ? errors[name] : ''}
      extra={extra}
    >
      <AntInputNumber
        value={value || defaultValue}
        onChange={val => {
          setFieldValue(name, val);
          return onChange && onChange(val);
        }}
        onBlur={event => onBlur && onBlur(event)}
        style={{ width: 'auto' }}
        {...restProps}
      />
    </Item>
  );
}

InputNumber.propTypes = {
  label: PropTypes.string,
  extra: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
};

InputNumber.defaultProps = {
  label: undefined,
  extra: undefined,
  onChange: undefined,
  onBlur: undefined,
  defaultValue: '',
};
