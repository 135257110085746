import React, { useState, useEffect } from 'react';
import PropType from 'prop-types';
import { Slider, Select, Form, Typography } from 'antd';

const { Text } = Typography;

export default function MultipleSliders({
  maxValue,
  data,
  onChange,
  selectSchema,
  allowClear,
  totalHours,
  setTotalHours,
  teamHours,
  teamDefault,
}) {
  let defaultSelectOptions = [];
  let defaultSliderValues = {};

  const defaultTeam = JSON.stringify({
    id: teamDefault.id,
    team_name: teamDefault.display_default_team,
  });

  if (teamHours !== null) {
    defaultSelectOptions = teamHours.map(({ team, team_display }) =>
      JSON.stringify({ id: team, team_name: team_display })
    );

    const defaultValues = teamHours.map(({ hours }) => hours);

    defaultSliderValues = defaultSelectOptions.reduce(
      (obj, item, index) => ({
        ...obj,
        [item]: defaultValues[index],
      }),
      {}
    );
  }
  const [teams, setTeams] = useState(defaultSelectOptions);
  const [sliderValues, setSliderValues] = useState(defaultSliderValues);

  useEffect(() => {
    setTotalHours(
      Object.values(sliderValues).reduce((acc, val) => acc - val, maxValue)
    );
  }, [maxValue, setTotalHours, sliderValues]);

  useEffect(() => {
    onChange(sliderValues);
    // eslint-disable-next-line
  }, [JSON.stringify(sliderValues)]);

  return (
    <div>
      <Text>Equipe principal</Text>
      <Form.Item key={teamDefault.id} label={teamDefault.display_default_team}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Slider
            style={{ width: '100%' }}
            id={defaultTeam}
            name={defaultTeam}
            onAfterChange={e => {
              if (totalHours < 0) {
                setSliderValues(prev => ({
                  ...prev,
                  [defaultTeam]: e + totalHours,
                }));
              }
            }}
            onChange={e => {
              if (e < sliderValues[defaultTeam]) {
                setSliderValues(prev => ({
                  ...prev,
                  [defaultTeam]: e,
                }));
              } else {
                setSliderValues(prev => ({
                  ...prev,
                  [defaultTeam]: e,
                }));
              }
            }}
            value={sliderValues[defaultTeam] || 0}
            min={0}
            max={maxValue}
          />

          <span
            className="ant-alert-message"
            style={{ whiteSpace: 'nowrap', paddingLeft: 10 }}
          >
            <Typography.Text strong>
              {sliderValues[defaultTeam] || 0} horas
            </Typography.Text>
          </span>
        </div>
      </Form.Item>

      <Text>Adicionar Equipe</Text>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        allowClear={allowClear}
        defaultValue={defaultSelectOptions.filter(item => item !== defaultTeam)}
        onChange={e => {
          setTeams(e);
          setTotalHours(maxValue);
          setSliderValues({ [defaultTeam]: 0 });
        }}
      >
        {(data || []).map(selectSchema)}
      </Select>
      <br />
      <br />
      <br />

      {teams
        .filter(item => item !== defaultTeam)
        .map(team => {
          const { team_name } = JSON.parse(team);
          return (
            <Form.Item key={team} label={team_name}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Slider
                  style={{ width: '100%' }}
                  id={team}
                  name={team}
                  onAfterChange={e => {
                    if (totalHours < 0) {
                      setSliderValues(prev => ({
                        ...prev,
                        [team]: e + totalHours,
                      }));
                    }
                  }}
                  onChange={e => {
                    if (e < sliderValues[team]) {
                      setSliderValues(prev => ({
                        ...prev,
                        [team]: e,
                      }));
                    } else {
                      setSliderValues(prev => ({
                        ...prev,
                        [team]: e,
                      }));
                    }
                  }}
                  value={sliderValues[team] || 0}
                  min={0}
                  max={maxValue}
                />

                <span
                  className="ant-alert-message"
                  style={{ whiteSpace: 'nowrap', paddingLeft: 10 }}
                >
                  <Typography.Text strong>
                    {sliderValues[team] || 0} horas
                  </Typography.Text>
                </span>
              </div>
            </Form.Item>
          );
        })}
    </div>
  );
}

MultipleSliders.propTypes = {
  data: PropType.arrayOf(PropType.object),
  allowClear: PropType.bool,
  maxValue: PropType.number.isRequired,
  onChange: PropType.func.isRequired,
  selectSchema: PropType.func,
  totalHours: PropType.number.isRequired,
  setTotalHours: PropType.func,
};
MultipleSliders.defaultProps = {
  allowClear: undefined,
  data: [],
  selectSchema: undefined,
  setTotalHours: undefined,
  teamHours: [],
  teamDefault: {},
};
