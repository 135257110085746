import React, { useContext } from 'react';
import { Modal, Button, Row, Col, Typography, Divider, Select } from 'antd';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import moment from 'moment';
import safeRead from 'safely-read';
import { Item, ItemContainer } from './style';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import formatPrice from '~/services/utils/formatPrice';
import InputNumber from '~/components/DataEntry/InputNumber/';
import Input from '~/components/DataEntry/Input';
import { useSearchTeams, PostPayableRequest, CalcValue } from './index';
import { CollapsePanelContext } from '../../CollapseList';

export default function AbsenceSolicitationModal({
  absenceSolicitationModalVisible,
  setAbsenceSolicitationModalVisible,
}) {
  const {
    user,
    key,
    month,
    year,
    fetchTableData,
    setTableData,
    setLoadingTableData,
    fetchCompensatoryTime,
    setCompensatoryTime,
    setConsolidatedProduction,
    fetchConsolidatedProduction,
  } = useContext(CollapsePanelContext);

  const { teamsResponse, setSearch, loading } = useSearchTeams();

  const [calculate, setCalculate] = React.useState(0);

  const { Text, Title } = Typography;
  return (
    <Modal
      visible={absenceSolicitationModalVisible}
      onCancel={() => setAbsenceSolicitationModalVisible(false)}
      footer={null}
      closable
      destroyOnClose
      width={415}
      title={<Title level={4}>Solicitação de Ausência a descontar </Title>}
    >
      <ItemContainer>
        <Item width="60%">
          <Text strong style={{ display: 'block' }}>
            Usuário
          </Text>
          <Text type="secondary" style={{ display: 'block' }} id={key}>
            {safeRead(user, ['person_display', 'name_display'])}
          </Text>
        </Item>

        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Mês/Ano
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {`${moment(`${year}-${month}`).format('MMMM/YYYY')}`}
          </Text>
        </Item>

        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Valor/Hora
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['general', 'hourly_rate'], '--', rate =>
              formatPrice(rate)
            )}
          </Text>
        </Item>
      </ItemContainer>

      <Formik
        initialValues={{
          hours: 1,
          team: null,
          reason: '',
        }}
        onSubmit={async values => {
          await PostPayableRequest(
            key,
            values,
            'ausencia_a_descontar',
            setAbsenceSolicitationModalVisible,
            calculate
          );
          await fetchCompensatoryTime(key, setCompensatoryTime);
          await fetchTableData(
            key,
            month,
            year,
            setTableData,
            setLoadingTableData
          );
          await fetchConsolidatedProduction(
            year,
            month,
            setConsolidatedProduction
          );
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Text strong style={{ display: 'block' }}>
                    Quantidade de horas a descontar
                  </Text>
                  <Field
                    style={{ width: '100%' }}
                    id="hours"
                    name="hours"
                    component={InputNumber}
                    onChange={CalcValue(
                      user.general.hourly_rate,
                      values.hours,
                      setCalculate
                    )}
                    defaultValue={1}
                    min={1}
                  />
                </Col>
                <Col>
                  <Text strong>Valor a descontar</Text>
                  <br />
                  <Text>
                    {!isNaN(calculate)
                      ? formatPrice(calculate)
                      : 'Verifique se foi realizada a entrada apenas de números no campo de horas.'}
                  </Text>
                  <br />
                </Col>
                <br />
                <Col>
                  <Text strong>Equipe</Text>
                  <Field
                    id="team-input-select"
                    name="team"
                    mode="single"
                    loading={loading}
                    component={AutoCompleteSelect}
                    selectSchema={({ id, team_name }) => (
                      <Select.Option key={id} value={id}>
                        {team_name}
                      </Select.Option>
                    )}
                    onChange={e => {
                      setFieldValue('team', e);
                      setSearch(undefined);
                    }}
                    search={debounce(setSearch, 800)}
                    data={teamsResponse}
                    allowClear
                  />
                </Col>
                <Col>
                  <Text strong style={{ display: 'block' }}>
                    Motivo
                  </Text>
                  <Field
                    id="reason"
                    name="reason"
                    component={Input}
                    type="textarea"
                  />
                </Col>
              </Row>
              <Row type="flex" justify="end">
                <Col>
                  <Button
                    onClick={() => setAbsenceSolicitationModalVisible(false)}
                  >
                    Cancelar
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    id="amount-btn"
                    type="primary"
                    htmlType="submit"
                    disabled={!values.hours || !values.team || !values.reason}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

AbsenceSolicitationModal.propTypes = {
  absenceSolicitationModalVisible: PropTypes.bool.isRequired,
  setAbsenceSolicitationModalVisible: PropTypes.func.isRequired,
};
