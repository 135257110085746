import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Row, Col } from 'antd';
import { DatePicker } from 'antd';
import history, { 
  getAllQueriesParams, 
  pushWithQueriesParams,
} from '~/services/history';
import {
  handleSearch,
  getInitialValues,
} from './index';

export default function DateFilter() {
  const onDateChange = e => {
  const queries = getAllQueriesParams();

  const date = e ? {
    year: e.format('YYYY'),
    month: e.format('MM'),
    day: e.format('DD'),
  } : { year: '', month: '', day: '' };
  
  pushWithQueriesParams(history.location.pathname, { ...queries, ...date });
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={values => handleSearch(values)}
    >
        <Form>
          <Row gutter={24} type="flex" justify="start">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Field
                name="date"
                component={DatePicker}
                label='Data'
                format='DD/MM/YYYY'
                onChange={onDateChange}
                allowClear={false}
              />
            </Col>
          </Row>
        </Form>
    </Formik>
  );
}