import React from 'react';
import { notification, List, message, Row, Col } from 'antd';
import safeRead from 'safely-read';
import { IMPORT_PEOPLE, TEMPLATE_PEOPLE } from '~/constants/endpoints/import';
import api from '~/services/api';
import uploadPeopleModal from './UploadPeopleModal';

export async function ImportExcel(
  excelFile,
  setUploading,
  setUploadModalVisible,
  fetchTableData
) {
  setUploading(true);
  try {
    const response = await api.post(IMPORT_PEOPLE, excelFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.data.errors === null) {
      notification.success({
        message: 'Importação de dados de cooperado concluída com sucesso',
        description:
          'Importação de carga de cooperados foi concluído com sucesso e já está disponível para consulta na tabela de usuários.',
      });
    } else {
      notification.error({
        duration: 0,
        message: 'Erro ao realizar importação de carga de cooperados',
        description: safeRead(
          response,
          ['data', 'errors'],
          'Erro ao realizar importação de carga de cooperados, por favor tente novamente.',
          errors => (
            <List
              size="small"
              grid="none"
              dataSource={errors}
              renderItem={error => (
                <List.Item key={error.id} style={{ margin: 0 }}>
                  <Row gutter={12} type="flex" justify="start" aligh="center">
                    <Col>{error}</Col>
                  </Row>
                </List.Item>
              )}
            />
          )
        ),
      });
    }
    fetchTableData();
    setUploading(false);
    setUploadModalVisible(false);
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['data', 'errors'],
        'Não foi possível realizar a carga de dados.'
      )
    );
    setUploading(false);
  }
}

export async function getTemplateExcel() {
  const defaultResponse = {
    results: {},
  };

  try {
    const response = await api({
      url: TEMPLATE_PEOPLE,
      method: 'GET',
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Template de arquivo - Importação de cooperados.${response.data.type}`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Ocorreu um erro ao tentar realizar o download do arquivo.'
      )
    );
    return defaultResponse;
  }
}

export default uploadPeopleModal;
