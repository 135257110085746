import styled from 'styled-components';
import { mobileWidth } from '~/constants/metrics';

export const BudgetHolder = styled.div`
  padding: 12px;
  margin-top: 26px;
  text-align: center;
  border: 2px solid ${({ theme }) => theme.borderColorBase};
  border-radius: ${({ theme }) => theme.borderRadiusBase};

  @media (min-width: ${mobileWidth}) {
    & {
    }
  }
`;
export const Container = styled.div`
  text-align: left;
  .stats-title {
    color: ${({ theme }) => theme.textColorSecondary};
  }

  .stats-description {
    color: ${({ theme }) => theme.textColor};
    font-size: 22px;
    font-weight: bold;
  }
`;
