import moment from 'moment';

/**
 *
 * @param {(string|string[])} date ISO date string
 *
 */
export default function formatDate(date) {
  const isValidDate = moment(date).isValid();

  if (isValidDate) {
    return moment(date).format('DD/MM/YYYY');
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(
      '[formatDate] An invalid date was given as a parameter. Make sure if the date passed is ISO compatible.'
    );
  }

  return date;
}
