import React from 'react';
import { Divider, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Container,
  ItemContainer,
  LeadingContainer,
  ContentContainer,
  TrailingContainer,
  VacationAllowenceBadge,
  Title,
} from './styles';
import renderBadge from '~/services/utils/renderStatusBadge';

const { Text } = Typography;

export default function VacationsAllowencesHistory({
  items,
  actions: Actions,
}) {
  return (
    <Container>
      {(items || []).length > 0 ? (
        <>
          {items.map((history, index) => {
            const {
              id,
              status,
              status_display,
              paid_leave_type_display,
              begin_date,
              last_date,
              paid_leave_type,
            } = history;
            return (
              <ItemContainer key={index.toString()}>
                <LeadingContainer>
                  <VacationAllowenceBadge
                    vacation={paid_leave_type === 'ferias'}
                  >
                    {paid_leave_type_display}
                  </VacationAllowenceBadge>
                  {id && <p>ID: {id}</p>}
                </LeadingContainer>

                <Divider type="vertical" />

                <ContentContainer>
                  <Title>
                    {`${moment(begin_date).format('DD/MM/YYYY')}`}{' '}
                    {last_date &&
                      ` - ${moment(last_date).format('DD/MM/YYYY')}`}
                  </Title>
                  {renderBadge({ status, status_display })}
                </ContentContainer>

                <TrailingContainer>
                  {Actions && <Actions {...history} />}
                </TrailingContainer>
              </ItemContainer>
            );
          })}
        </>
      ) : (
        <Text level={4}>Nenhuma solicitação para este período aquisitivo.</Text>
      )}
    </Container>
  );
}

VacationsAllowencesHistory.propTypes = {
  actions: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object),
};

VacationsAllowencesHistory.defaultProps = {
  actions: undefined,
  items: [],
};
