import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Typography, Select, Button, Input, Alert, message } from 'antd';
import logo from '~/assets/image/logo-horizontal.png';
import analiticsLogo from '~/assets/image/analitics-logo.png';
import * as S from './styles';
import { useGetTeam } from '../../services/useGetTeam';
import { useCreateTeam } from '../../services/useCreateTeam';
import { useEditTeam } from '../../services/useEditTeam';
import { useGetPerson } from '../../services/useGetPerson';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useQuery, useQueryClient } from 'react-query';
const { Title } = Typography;
const { Option } = Select;

export const CreateNewTeamModal = ({ open, setOpen, editTeam }) => {
  const queryClient = useQueryClient();
  const [searchDiretoria, setSearchDiretoria] = useState('');
  const searchDiretoriaKey = useDebounce(searchDiretoria);
  const [searchPerson, setSearchPerson] = useState('');
  const searchPersonKey = useDebounce(searchPerson);

  const [nomeEquipe, setNomeEquipe] = useState(undefined);
  const [diretoria, setDiretoria] = useState(undefined);
  const [nomeDiretor, setNomeDiretor] = useState(undefined);
  const [coordenador, setCoordenador] = useState(undefined);
  const [liderEquipe, setLiderEquipe] = useState(undefined);
  const [teamId, setTeamId] = useState(undefined);
  const [membrosEquipe, setMembrosEquipe] = useState([]);

  console.log('editTeam', editTeam);

  useEffect(() => {
    if (editTeam) {
      setNomeEquipe(editTeam?.name);
      setDiretoria(editTeam?.directorship);
      setNomeDiretor(editTeam?.director?.id);
      setCoordenador(editTeam?.coordinator?.id);
      setLiderEquipe(editTeam?.leader?.id);
      setTeamId(editTeam?.id);
      setMembrosEquipe(
        editTeam?.persons?.length
          ? editTeam?.persons?.map(val => Number(val.id))
          : []
      );
    }else{
      setNomeEquipe(undefined);
      setDiretoria(undefined);
      setNomeDiretor(undefined);
      setCoordenador(undefined);
      setLiderEquipe(undefined);
      setTeamId(undefined);
      setMembrosEquipe([]);
    }
  }, [editTeam]);

  const { data: teamData, isLoading: teamLoading } = useGetTeam(
    searchDiretoriaKey
  );
  const { data: personData, isLoading: personLoading } = useGetPerson(
    searchPersonKey
  );

  const { mutate, isLoading: createTeamLoading } = useCreateTeam({
    onSuccess: () => {
      queryClient.invalidateQueries('useGetTeamListKey');
      message.success(`Equipe criada com sucesso`);
      setOpen(false);
    },
    onError: error => {
      // console.log(error?.response?.data)
      debugger;
      if (Object.keys(error?.response?.data)?.length) {
        const objKeys = Object.keys(error?.response?.data);
        let concatMessage = '';
        objKeys.forEach(val => {
          concatMessage += `\n - ${error?.response?.data[val][0]} \n`;
        });
        message.error(concatMessage);
      } else {
        message.error('Erro ao criar equipe');
      }
      return;
    },
  });
  const { mutate: EditTeamMutate, isLoading: editTeamMutate } = useEditTeam({
    onSuccess: () => {
      queryClient.invalidateQueries('useGetTeamListKey');
      message.success('Equipe editada com sucesso');
      setOpen(false);
    },
    onError: error => {
      if (error.response?.data?.name?.length) {
        message.error(error?.response?.data?.name[0]);
        return;
      } else {
        message.error('Erro ao editar equipe');
        return;
      }
    },
  });

  function handleChange(value) {
    setMembrosEquipe(value);
    console.log(`Selected: ${value}`);
  }

  function onSearchDiretoria(val) {
    console.log('onSearchDiretoria:', val);
    setSearchDiretoria(val);
  }
  function onSearchPerson(val) {
    console.log('onSearchDiretoria:', val);
    setSearchPerson(val);
  }

  const renderDiretorOptions = useMemo(() => {
    if (teamData && teamData?.results?.length) {
      return teamData?.results?.map(diretores => (
        <Option key={diretores.id} value={diretores.id}>
          {diretores.team_name}
        </Option>
      ));
    }
    return null;
  }, [teamData]);

  const renderPersonsOptions = useMemo(() => {
    if (personData && personData?.results?.length) {
      return personData?.results?.map(cooperados => (
        <Option key={cooperados.id} value={cooperados.id}>
          {cooperados.name_display}
        </Option>
      ));
    }
    return null;
  }, [personData]);

  const createNewTeam = () => {
    if (teamId) {
      EditTeamMutate({
        name: nomeEquipe,
        directorship: diretoria,
        director: nomeDiretor,
        coordinator: coordenador,
        leader: liderEquipe,
        persons: membrosEquipe?.length
          ? membrosEquipe?.map(val => Number(val))
          : membrosEquipe,
        id: teamId,
      });
    } else {
      mutate({
        name: nomeEquipe,
        directorship: diretoria,
        director: nomeDiretor,
        coordinator: coordenador,
        leader: liderEquipe,
        persons: membrosEquipe?.length
          ? membrosEquipe?.map(val => Number(val))
          : membrosEquipe,
      });
    }
  };

  const disableCreateButton = useMemo(
    () =>
      !!nomeEquipe &&
      !!diretoria &&
      !!nomeDiretor &&
      !!coordenador &&
      !!liderEquipe && 
      !!membrosEquipe?.length,
      [
        nomeEquipe,
        diretoria,
        nomeDiretor,
        coordenador,
        liderEquipe,
        membrosEquipe,
      ]
  );

  console.log(disableCreateButton);

  return (
    <>
      <Modal
        //   title={<Title level={4}>Cadastro de Equipe</Title>}
        footer={null}
        visible={open}
        onCancel={() => setOpen(false)}
        width="90%"
        centered
      >
        <S.Container>
          <S.ContainerLogo>
            <S.ContainerHeaderLogo>
              <S.HeaderLogo src={logo} />
            </S.ContainerHeaderLogo>
            <S.Logo>
              <S.ContainerTitle>
                <S.Title>
                  <S.IconPlus>+</S.IconPlus>
                  Criar nova <span>equipe</span>
                </S.Title>
              </S.ContainerTitle>

              <S.ContentLogo src={analiticsLogo} />
            </S.Logo>
          </S.ContainerLogo>
          {/* =================================== */}
          <S.Divider type="vertical" />
          {/* =================================== */}
          <S.ContainerContent>
            <S.Content>
              <S.TeamName>
                <Input
                  size="large"
                  placeholder="Nome da equipe"
                  onChange={e => setNomeEquipe(e.target.value)}
                  value={nomeEquipe}
                />
              </S.TeamName>
              <S.TeamManager>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  showSearch
                  placeholder="Nome do diretor"
                  optionFilterProp="children"
                  value={nomeDiretor}
                  onChange={val => setNomeDiretor(val)}
                  onSearch={onSearchPerson}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderPersonsOptions}
                </Select>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  showSearch
                  placeholder="Diretoria"
                  optionFilterProp="children"
                  value={diretoria}
                  onChange={val => setDiretoria(val)}
                  onSearch={onSearchDiretoria}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderDiretorOptions}
                </Select>
              </S.TeamManager>
              <S.TeamLeader>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  showSearch
                  placeholder="Coordenador"
                  optionFilterProp="children"
                  value={coordenador}
                  onChange={val => setCoordenador(val)}
                  onSearch={onSearchPerson}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderPersonsOptions}
                </Select>
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  showSearch
                  placeholder="Líder de equipe"
                  optionFilterProp="children"
                  value={liderEquipe}
                  onChange={val => setLiderEquipe(val)}
                  onSearch={onSearchPerson}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderPersonsOptions}
                </Select>
              </S.TeamLeader>
              <S.TeamMembers>
                <Select
                  mode="tags"
                  placeholder="Integrantes"
                  defaultValue={['643']}
                  size="large"
                  onChange={handleChange}
                  value={membrosEquipe}
                  onSearch={onSearchPerson}
                  style={{ width: '100%' }}
                >
                  {personData?.results?.length > 0 &&
                    personData?.results?.map(val => (
                      <Option key={val?.id}>{val?.name_display}</Option>
                    ))}
                </Select>
              </S.TeamMembers>
            </S.Content>
          </S.ContainerContent>
        </S.Container>
        <S.ContainerButtons>
          <Button
            type="danger"
            style={{ marginRight: '16px' }}
            onClick={() => setOpen(false)}
          >
            Fechar
          </Button>
          <Button
            type="primary"
            loading={createTeamLoading || editTeamMutate}
            onClick={createNewTeam}
            disabled={
              !disableCreateButton || createTeamLoading || editTeamMutate
            }
          >
            Concluir
          </Button>
        </S.ContainerButtons>
      </Modal>
    </>
  );
};
