import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import MultipleActionButton from '~/components/MultipleActionButton';
import Table from '~/components/DataDisplay/Table';
import checkPermission from '~/services/utils/checkPermission';
import messages from '../messages';
import {
  makeColumns,
  formatTableData,
  approvalModalTypes,
  from,
} from '../index';
import history, { pushWithQueriesParams } from '~/services/history';

export default function RectificationTable({
  id,
  selectedRowKeys,
  setSelectedRowKeys,
  tableData,
  loadingTableData,
  totalPages,
  page_size,
  page,
  date_start,
  date_end,
  status,
  setDetailRowData,
  setDetailModalVisible,
  setApprovalModalActionType,
  setApprovalModalVisible,
  setApprovalItems,
  setFrom,
}) {
  const { formatMessage } = useIntl();
  const canApproveRectification = checkPermission('change_rectification');

  const rowSelection = {
    selectedRowKeys,
    onChange: selected => setSelectedRowKeys(selected),
    getCheckboxProps: record => ({
      disabled: record.status !== 'em_analise',
    }),
  };

  const { results } = tableData;

  return (
    <Table
      title={
        canApproveRectification
          ? () => (
              <MultipleActionButton
                actions={[
                  {
                    title: formatMessage(messages.approveAction),
                    action: () => {
                      setApprovalItems(selectedRowKeys);
                      setFrom(from.table);
                      setApprovalModalActionType(approvalModalTypes.approve);
                      setApprovalModalVisible(true);
                    },
                  },
                  {
                    title: formatMessage(messages.denyAction),
                    action: () => {
                      setApprovalItems(selectedRowKeys);
                      setFrom(from.table);
                      setApprovalModalActionType(approvalModalTypes.disapprove);
                      setApprovalModalVisible(true);
                    },
                  },
                ]}
              >
                {formatMessage(messages.actionButtom)}
              </MultipleActionButton>
            )
          : null
      }
      columns={makeColumns(
        formatMessage,
        setDetailRowData,
        setDetailModalVisible
      )}
      dataSource={formatTableData(results)}
      paginated
      currentPage={page || 1}
      count={totalPages}
      pageSize={page_size || 10}
      rowSelection={canApproveRectification ? rowSelection : null}
      scroll={{ x: true }}
      loading={loadingTableData}
      onChange={(pagination, filters, sorter, extra) => {
        pushWithQueriesParams(history.location.pathname, {
          id: id,
          date_start: date_start,
          date_end: date_end,
          status: status,
          page: 1,
          page_size: page_size,
          ordering:
            sorter.order === 'ascend'
              ? sorter.columnKey
              : `-${sorter.columnKey}`,
        });
      }}
    />
  );
}

RectificationTable.defaultProps = {
  totalPages: undefined,
  page_size: null,
  page: null,
  ordering: null,
};

RectificationTable.propTypes = {
  selectedRowKeys: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  tableData: PropTypes.objectOf(PropTypes.any).isRequired,
  loadingTableData: PropTypes.bool.isRequired,
  totalPages: PropTypes.number,
  page_size: PropTypes.number,
  page: PropTypes.number,
  setDetailRowData: PropTypes.func.isRequired,
  setDetailModalVisible: PropTypes.func.isRequired,
  setApprovalModalActionType: PropTypes.func.isRequired,
  setApprovalModalVisible: PropTypes.func.isRequired,
  setApprovalItems: PropTypes.func.isRequired,
  setFrom: PropTypes.func.isRequired,
  ordering: PropTypes.string,
};
