import styled from 'styled-components';
import { shade } from 'polished';
import { tabletWidth, mobileWidth } from '~/constants/metrics';

export const TableActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 10px;

  button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const ModalItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
`;

export const ModalItem = styled.div`
  flex-grow: 0;
  width: ${({ width }) => width || '33%'};
  margin-bottom: 40px;

  @media (max-width: ${tabletWidth}) {
    width: ${({ width }) => (width && width === '100%' ? width : '50%')};
  }

  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;
export const GrossPaymentHolder = styled.div`
  margin: 16px 0 !important;
  padding: 12px;
  align-items: center;
  width: 100%;

  background-color: ${({ theme }) => shade(0.04, theme.componentBackground)};
  border-radius: ${({ theme }) => theme.borderRadiusBase};
`;
