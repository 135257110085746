import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';

import VacationsAllowences from './VacationsAllowences';
import api from '~/services/api';
import { getQueryParam } from '~/services/history';
import {
  PAID_LEAVE_REFERENCE_PERIODS,
  PAID_LEAVE,
  PAID_LEAVE_MANAGE,
  PAID_LEAVE_REFERENCE_PERIODS_BY_ID,
} from '~/constants/endpoints/paid_leave';

export const VacationsContext = React.createContext({});

export async function getPaidLeaveReferencePeriods() {
  const cooperativeId = getQueryParam('cooperative_member_id');
  let response;

  try {
    if (cooperativeId) {
      const { data } = await api.get(
        PAID_LEAVE_REFERENCE_PERIODS_BY_ID(cooperativeId)
      );

      const formatted = data.map(([start, end]) => ({ start, end }));

      response = formatted;
    } else {
      const { data } = await api.get(PAID_LEAVE_REFERENCE_PERIODS);

      const formatted = data.map(([start, end]) => ({ start, end }));

      response = formatted;
    }
  } catch (error) {
    message.error(
      safeRead(error, ['data'], 'Erro ao recuperar período aquisitivo.')
    );
  }

  return response;
}

export async function getPaidLeave(reference_period) {
  let response = [];

  try {
    const { data } = await api.get(PAID_LEAVE, {
      params: { start_period: reference_period },
    });

    response = data;
  } catch (error) {
    message.error(
      safeRead(error, ['data'], 'Erro ao recuperar período aquisitivo.')
    );
  }

  return response;
}

export async function getPaidLeaveManage(cooperativeId, reference_period) {
  let response = [];

  try {
    const { data } = await api.get(PAID_LEAVE_MANAGE(cooperativeId), {
      params: { start_period: reference_period },
    });

    response = data;
  } catch (error) {
    message.error(
      safeRead(error, ['data'], 'Erro ao recuperar período aquisitivo.')
    );
  }

  return response;
}

export function usePaidLeaveReferencePeriods() {
  const [referencePeriods, setReferencePeriods] = useState(undefined);

  useEffect(() => {
    async function loadReferencePeriods() {
      const data = await getPaidLeaveReferencePeriods();

      setReferencePeriods(data);
    }

    loadReferencePeriods();
  }, []);

  return {
    referencePeriods,
    setReferencePeriods,
  };
}

export function useGetPaidLeave() {
  const reference_period_query = getQueryParam('reference_period');
  const cooperativeId = getQueryParam('cooperative_member_id');

  const [paidLeave, setPaidLeave] = useState([]);
  const [paidLeaveLoading, setPaidLeaveLoading] = useState(true);

  useEffect(() => {
    async function loadPaidLeave() {
      setPaidLeaveLoading(true);
      if (cooperativeId) {
        const data = await getPaidLeaveManage(
          cooperativeId,
          reference_period_query
        );
        setPaidLeave(data);
      } else {
        setPaidLeaveLoading(true);
        const data = await getPaidLeave(reference_period_query);

        setPaidLeave(data);
      }
      setPaidLeaveLoading(false);
    }

    loadPaidLeave();
  }, [cooperativeId, reference_period_query]);

  return {
    paidLeave,
    setPaidLeave,
    paidLeaveLoading,
  };
}

export async function updatePaidLeave(setPaidLeave) {
  const reference_period_query = getQueryParam('reference_period');

  const data = await getPaidLeave(reference_period_query);

  setPaidLeave(data);
}

export default VacationsAllowences;
