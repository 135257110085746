import React, { useContext } from 'react';
import { Input as AntdInput, Typography, Button, Spin, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AiOutlineClose } from 'react-icons/ai';
import debounce from 'lodash.debounce';

import { deleteUser, AddUserContext } from '../index';

export default function UsersOnThisProfile() {
  const {
    updateUsers,
    users,
    group_id,
    setPageSize,
    fetchTableData,
    loading,
    setSearch,
  } = useContext(AddUserContext);

  const { Text, Paragraph } = Typography;
  const { confirm } = Modal;

  const debouncedUpdate = debounce(value => setSearch(value), 800);

  return (
    <div>
      <Text type="secondary">Usuários com este perfil</Text>

      <AntdInput.Search
        loading={loading}
        onChange={({ target: { value } }) => debouncedUpdate(value)}
      />

      <InfiniteScroll
        dataLength={users.results.length}
        next={() => setPageSize(prev => prev + 5)}
        hasMore={users.next !== null}
        loader={
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        }
        height="154px"
      >
        {users.results.map(user => (
          <div
            key={user.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <Paragraph ellipsis>{user.name_display}</Paragraph>
            <Button
              onClick={() =>
                confirm({
                  content: 'Remover Usuário?',
                  centered: true,
                  onOk: async () => {
                    deleteUser(group_id, user.id);
                    await updateUsers();
                    await fetchTableData();
                  },
                })
              }
              type="link"
            >
              <AiOutlineClose />
            </Button>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}
