import { message } from 'antd';
import safeRead from 'safely-read';
import uploadPeopleModal from './AlterHomeSilver';
import { HOME_GROWN } from '~/constants/endpoints/home_grown';
import api from '~/services/api';


export const formatTableData = array => {
  return array.map(item => ({
    ...item,
    required_time: safeRead(item, ['required_time'], 0),
    bonus_6hours:
      safeRead(item, ['bonus', [0], 'working_hours'], 0) === 6
      ? safeRead(item, ['bonus', [0], 'bonus_hours'], 0)
      : safeRead(item, ['bonus', [1], 'bonus_hours'], 0),
    bonus_8hours:
      safeRead(item, ['bonus', [1], 'working_hours'], 0) === 8
        ? safeRead(item, ['bonus', [1], 'bonus_hours'], 0)
        : safeRead(item, ['bonus', [0], 'bonus_hours'], 0),
    bonus_reward: safeRead(item, ['bonus', [0], 'bonus_reward'], 0),
  }));
};

export async function getHomeGrown(setData, setIsloading) {
  // const response = {
  //   data,
  // };
  try {
    setIsloading(true);

    const response = await api.get(HOME_GROWN);

    setIsloading(false);

    if (response.data) {
      setData(response.data);
    }

    return [];
  } catch (error) {
    return message.error(
      safeRead(
        error,
        ['response', 'data', [0]],
        `Ocorreu um erro ao tentar carregar os dados`
      )
    );
  }
}

export default uploadPeopleModal;
