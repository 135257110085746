import React, { useState, useContext } from 'react';
import { Row, Col, Button } from 'antd';
import Modal from '~/components/DataDisplay/Modals/DetailModal';
import { AbsenceOvertimeContext } from '../..';
import {
  details,
  handleShowCancel,
  popConfirm,
  handleShowSolicitarAusenciaHoraExtra,
} from '.';

export default function DetailModal() {
  const [cancelLoading, setCancelLoading] = useState(false);
  const {
    detailModalVisible,
    toggleDetailModalVisible,
    toggleConfirmRequestModal,
    itemData,
    setItemData,
    fetchTableData,
  } = useContext(AbsenceOvertimeContext);

  return (
    <Modal
      title="Detalhes da Solicitação"
      data={details(itemData)}
      visible={detailModalVisible}
      onCancel={toggleDetailModalVisible}
      actions={
        <Row gutter={20} type="flex" justify="end">
          <Col>
            {handleShowCancel(itemData) && (
              <Button
                type="danger"
                onClick={() =>
                  popConfirm(
                    itemData,
                    setCancelLoading,
                    setItemData,
                    toggleDetailModalVisible,
                    fetchTableData
                  )
                }
                loading={cancelLoading}
              >
                Cancelar solicitação
              </Button>
            )}
            {handleShowSolicitarAusenciaHoraExtra(itemData) && (
              <Button type="primary" onClick={toggleConfirmRequestModal}>
                Confirmar solicitação
              </Button>
            )}
          </Col>
        </Row>
      }
    />
  );
}
