export default {
  APPROVED: 'aprovado',
  DISAPPROVED: 'reprovado',
  IN_ANALYSIS: 'em_analise',
  NOT_REQUESTED: 'nao_solicitado',
  IN_FINANCIAL_ANALYSIS: 'em_analise_financeira',
  CANCELED: 'cancelado',
  ACTIVE: 'ativo',
  INACTIVE: 'inativo',
  APPROVED_BY_FINANCIAL: 'aprovado_pelo_financeiro',
  REPROVED_BY_FINANCIAL: 'reprovado_pelo_financeiro',
};
