import React, { useContext } from 'react';
import { Button } from 'antd';

import checkPermission from '~/services/utils/checkPermission';
import Table from '~/components/DataDisplay/Table';
import { makeColumns, formatTableData } from './index';
import { ProjectContext } from '../index';

export default function ProjectsTable() {
  const {
    tableData,
    loadingTableData,
    page,
    page_size,
    setRegisterVisible,
    setEditVisible,
    setCurrentProject,
  } = useContext(ProjectContext);

  return (
    <Table
      title={() =>
        checkPermission('add_team') ? (
          <Button onClick={() => setRegisterVisible(true)} type="primary">
            Nova Equipe
          </Button>
        ) : null
      }
      columns={makeColumns(setEditVisible, setCurrentProject)}
      dataSource={formatTableData(tableData.results)}
      paginated
      currentPage={parseInt(page, 10) || 1}
      pageSize={parseInt(page_size, 10) || 10}
      count={tableData.count}
      scroll={{ x: true }}
      loading={loadingTableData}
    />
  );
}
