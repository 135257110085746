import { message } from 'antd';
import safeRead from 'safely-read';

import RequestDayOff from './RequestDayOff';
import api from '~/services/api';
import {
  PAID_LEAVE,
  CALCULATE_LAST_DAY,
} from '~/constants/endpoints/paid_leave';

export const fieldType = {
  paidLeave: 'annual_leaves',
  dayOff: 'days_off',
};

export async function postPaidLeaveRequest(values, referencePeriod) {
  const { begin_date, total_days, receive_advance_money } = values;

  try {
    await api.post(PAID_LEAVE, {
      paid_leave_type: 'ferias',
      reference_period_start: referencePeriod,
      begin_date,
      total_days,
      receive_advance_money,
    });

    message.success('Solicitação feita com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao solicitar férias.'
      )
    );
  }
}

export async function postDayoffRequest(values, referencePeriod) {
  const { begin_date, total_days, receive_advance_money } = values;

  try {
    await api.post(PAID_LEAVE, {
      paid_leave_type: 'abono',
      reference_period_start: referencePeriod,
      begin_date,
      total_days,
      receive_advance_money,
    });

    message.success('Solicitação feita com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao solicitar abono.'
      )
    );
  }
}

export function getTotalBalance(paidLeave, type) {
  return paidLeave ? safeRead(paidLeave[0], [[type], 'remaining']) || 0 : 0;
}

export async function calculateLastDayOffOrVacations(
  startDate,
  totalDay,
  setLastDayOfVacationsOfDayOff,
  setLastDayOfVacationsOfDayOffLoading
) {
  setLastDayOfVacationsOfDayOffLoading(true);
  try {
    const { data } = await api.get(CALCULATE_LAST_DAY(startDate, totalDay));
    setLastDayOfVacationsOfDayOff(data);
  } catch (error) {
    setLastDayOfVacationsOfDayOff({});
  }
  setLastDayOfVacationsOfDayOffLoading(false);
}

export default RequestDayOff;
