import React, { useState, useEffect } from 'react';
import safeRead from 'safely-read';
import { message } from 'antd';
import CollapseList from './CollapseList';
import api from '~/services/api/index';
import { getQueryParam } from '~/services/history';
import {
  CONSOLIDATE_PRODUCTION,
  GET_CONSOLIDATE_PRODUCTION,
} from '~/constants/endpoints/consolidate_production';
import { MY_COMPENSATORY_TIME_BY_ID } from '~/constants/endpoints/my_compensatory_time';
import { TEAM } from '~/constants/endpoints/team';

export const CollapsePanelContext = React.createContext({});

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export async function fetchConsolidatedProduction(
  year,
  month,
  team,
  setConsolidatedProduction,
  setLoading,
  queries
) {
  if (month !== null && year !== null) {
    setLoading('yes');
    try {
      const { data } = await api.get(
        GET_CONSOLIDATE_PRODUCTION(year, month, team || ''),
        {
          params: {
            ...queries,
            team,
            directorship: true,
          },
        }
      );

      setConsolidatedProduction(data);
      setLoading('no');
    } catch (error) {
      message.error(
        safeRead(
          error,
          ['response', 'data', 'detail'],
          'Erro ao carregar informações de produção do usuários.'
        )
      );
      setConsolidatedProduction({
        results: [],
        count: 0,
        next: null,
        previous: null,
      });
      setLoading('no');
    }
  }
}

export function useConsolidatedProduction(year, month, team) {
  const [consolidatedProduction, setConsolidatedProduction] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });
  const [loading, setLoading] = useState('yes');

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const name = getQueryParam('name');
  const status = getQueryParam('status');

  useEffect(() => {
    async function loadConsolidadedProduction() {
      await fetchConsolidatedProduction(
        year,
        month,
        team,
        setConsolidatedProduction,
        setLoading,
        {
          page,
          page_size,
          name,
          status,
        }
      );
    }
    loadConsolidadedProduction();
  }, [month, year, team, name, status, page, page_size]);

  return {
    loading,
    setLoading,
    consolidatedProduction,
    setConsolidatedProduction,
    fetchConsolidatedProduction: () =>
      fetchConsolidatedProduction(
        year,
        month,
        team,
        setConsolidatedProduction,
        setLoading,
        {
          page,
          page_size,
          name,
          status,
        }
      ),
  };
}

export async function fetchCompensatoryTime(id, setCompensatoryTime) {
  if (id !== undefined && id !== null)
    try {
      const { data } = await api.get(MY_COMPENSATORY_TIME_BY_ID(id));

      setCompensatoryTime(data);
    } catch (error) {
      message.error(
        safeRead(error, ['response', 'data', 'detail'], 'Erro ao buscar dados')
      );
    }
}

export function useCompensatoryTime(id) {
  const [compensatoryTime, setCompensatoryTime] = React.useState(undefined);

  useEffect(() => {
    async function loadCompensatoryData() {
      fetchCompensatoryTime(id, setCompensatoryTime);
    }

    loadCompensatoryData();
  }, [id]);

  return {
    compensatoryTime,
    setCompensatoryTime,
    fetchCompensatoryTime: () => fetchCompensatoryTime(id, setCompensatoryTime),
  };
}

export async function patchStatus(id, year, month, status) {
  try {
    await api.patch(CONSOLIDATE_PRODUCTION(id, year, month), {
      status,
    });
    message.success('Produção aprovada com sucesso.');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Houve um erro ao aprovar status de produção.'
      )
    );
  }
}

export default CollapseList;
