import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
`;

const handleSelectBorder = ({ theme, selected }) =>
  selected ? theme.primaryColor : theme.borderColorBase;

const handleSelectText = ({ theme, selected }) =>
  selected ? theme.primaryColor : theme.textColor;

export const SelectButton = styled.button`
  background-color: transparent;
  border: 2px solid ${props => handleSelectBorder(props)};
  color: ${props => handleSelectText(props)};
  font-weight: ${({ selected }) => selected && 'bold'};
  padding: 3%;
  border-radius: 8px;
  width: 100%;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
