import { defineMessages } from 'react-intl';

export const scope = 'portal.sider.worktime';

export default defineMessages({
  geolocationWarn: {
    id: `${scope}.geolocation_warn`,
    defaultMessage:
      'Você precisa conceder acesso à sua geolocalização para registrar produção.',
  },
  geolocationWarnGrant: {
    id: `${scope}.geolocation_warn_grant`,
    defaultMessage: 'Clique aqui para permitir.',
  },
  checkIn: {
    id: `${scope}.check_in`,
    defaultMessage: 'Entrada',
  },
  checkOut: {
    id: `${scope}.check_out`,
    defaultMessage: 'Saída',
  },
  todayProduction: {
    id: `${scope}.today_production`,
    defaultMessage: 'Produção de hoje',
  },
  confirmCheckOut: {
    id: `${scope}.confirm_check_out`,
    defaultMessage: 'Deseja confirmar saída?',
  },
  confirmCheckIn: {
    id: `${scope}.confirm_check_in`,
    defaultMessage: 'Deseja confirmar entrada?',
  },
  rectification: {
    id: `portal.sider.worktime.modal.rectification.rectification`,
    defaultMessage: 'Rasura',
  },
});
