import { message } from 'antd';
import safeRead from 'safely-read';
import RegisterProject from './RegisterProject';

import api from '~/services/api';
import { TEAM } from '~/constants/endpoints/team';

export async function createProject(
  { parent_team, team_name, status },
  setRegisterVisible,
  updateTableData
) {
  try {
    await api.post(TEAM, {
      parent_team,
      team_name,
      status,
    });

    message.success('Equipe criada com sucesso!');

    setRegisterVisible(false);

    updateTableData();
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao criar equipe')
    );
  }
}

export default RegisterProject;
