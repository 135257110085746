import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Row, Col, Button, Divider } from 'antd';
import Input from '~/components/DataEntry/Input';
import { handleSearch, clearFields, getInitialValues } from '.';
import checkPermission from '~/services/utils/checkPermission';

export default function SearchForm() {
  return (
    checkPermission('view_edesenv') && (
      <>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={values => handleSearch(values)}
        >
          {({ handleSubmit, values, setValues }) => (
            <Form>
              <Row gutter={24} type="flex" justify="start">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Field
                    id="usernameField"
                    name="usernameField"
                    label="Username"
                    component={Input}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Field
                    id="nameField"
                    name="nameField"
                    label="Nome"
                    component={Input}
                  />
                </Col>
              </Row>
              <br />
              <Row type="flex" justify="end">
                <Col>
                  <Button onClick={() => clearFields(setValues)}>Limpar</Button>
                  <Divider type="vertical" />
                  <Button
                    type="primary"
                    icon="search"
                    htmlType="submit"
                    disabled={!values.usernameField && !values.nameField}
                  >
                    Pesquisar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Divider />
      </>
    )
  );
}
