import { COMPENSATORY_TIME } from 'constants/endpoints/compensatory_time';
import { message } from 'antd';
import api from '~/services/api';
import safeRead from 'safely-read';

export async function PostCompensatoryTime(
  key,
  maskedValue,
  setEditCompensatoryTimeVisible,
  setLoading
) {
  const balance = maskedValue;
  const person = key;

  setLoading(true);
  try {
    await api.post(COMPENSATORY_TIME, {
      balance,
      person,
    });
    message.success('Banco de horas aprovadas alterado com sucesso.');
    setLoading(false);
    setEditCompensatoryTimeVisible(false);
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Não foi possivel realizar a alteração.'
      )
    );
    setLoading(false);
    setEditCompensatoryTimeVisible(false);
  }
}
