import React from 'react';
import { ProductionChartContext } from '..';
import { Row, Col, Typography } from 'antd';

import { useMediaQuery } from 'react-responsive';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import safeRead from 'safely-read';

const { Text } = Typography;

export default function Chart() {
  const { chartData } = React.useContext(ProductionChartContext);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      {chartData.length > 0 && (
        <Row justify="center">
          <Col>
            Dias úteis:{' '}
            <Text strong>
              {safeRead(chartData[0], ['total_business_days'], '0')} dia(s)
            </Text>
          </Col>

          <Col>
            Horas previstas:
            <Text strong>
              {safeRead(
                chartData[0],
                ['total_expected_hours', 'display'],
                '0 h',
                item => ` ${item} h`
              )}
            </Text>
          </Col>

          <Col>
            Horas realizadas:
            <Text strong>
              {safeRead(
                chartData[0],
                ['total_realized_hours', 'display'],
                ' 0 h',
                item => ` ${item} h`
              )}
            </Text>
          </Col>
        </Row>
      )}
      <br />
      <ResponsiveContainer
        width={isMobileOrTablet ? '100%' : '35%'}
        height={isMobileOrTablet ? '35%' : '45%'}
        minWidth={300}
        minHeight={500}
      >
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barGap={30}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="total_business_days" />
          <YAxis />
          <Legend height={72} />
          <Bar
            dataKey="total_expected_hours.hours"
            name="Horas previstas"
            fill="#1a90ff"
            label={{ value: 'Horas previstas' }}
          />
          <Bar
            dataKey="total_realized_hours.hours"
            name="Horas realizadas"
            fill="#02a64a"
            label={{ value: 'Horas previstas' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
