import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import debounce from 'lodash.debounce';

import Input from '~/components/DataEntry/Input';
import { RangePicker } from '~/components/DataEntry/DatePicker';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
// import { getAllQueriesParams } from '~/services/history';
import {
  handleSearch,
  clearFields,
  getInitialValues,
  useSearchProject,
} from './index';

export default function SearchForm() {
  const { loadingProjects, projects, setSearch } = useSearchProject();

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={values => handleSearch(values)}
    >
      {({ handleSubmit, values, setFieldValue, setValues }) => (
        <Form onSubmit={handleSubmit}>
          <Row gutter={24} type="flex" justify="start">
            <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Field
                id="csysField"
                name="csysField"
                label="Matrícula Csys"
                component={Input}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <Field
                id="nameField"
                name="nameField"
                label="Nome"
                component={Input}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <Field
                id="teamField"
                name="teamField"
                label="Equipe"
                loading={loadingProjects}
                component={AutoCompleteSelect}
                placeholder=" "
                selectSchema={item => (
                  <AntdSelect.Option key={item.id} value={item.id}>
                    {item.team_name}
                  </AntdSelect.Option>
                )}
                onChange={e => {
                  if (!e) {
                    setFieldValue('teamField', null);
                  } else {
                    setFieldValue('teamField', e);
                  }

                  setSearch(undefined);
                }}
                search={debounce(setSearch, 800)}
                allowClear
                data={projects.results}
              />
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
              <Field
                id="date-range-input"
                name="formikDateRange"
                label="Periodo"
                component={RangePicker}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              <Button onClick={() => clearFields(setValues)}>Limpar</Button>
              <Divider type="vertical" />
              <Button
                type="primary"
                icon="search"
                htmlType="submit"
                disabled={
                  !values.nameField &&
                  !values.teamField &&
                  !values.position &&
                  !values.csysField &&
                  values.formikDateRange.length === 0
                }
              >
                Pesquisar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
