import React from 'react';

import { Divider, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import safeRead from 'safely-read';
import { AiOutlineUser } from 'react-icons/ai';

import Skeleton from '~/components/DataDisplay/Skeleton';
import logoLight from '~/assets/image/logo-horizontal.png';
import logoDark from '~/assets/image/logo-horizontal-white.png';
import Menu from './Menu';
import {
  Header,
  LogoImg,
  HeaderContainer,
  NavSection,
  UserContainer,
  ActionButton,
  Avatar,
} from './styles';

export default function DashboardHeader(props) {
  const { collapsed, handleCollapse } = props;
  const { colorScheme } = useSelector(state => state.theme);
  const { profile, loading } = useSelector(state => state.user);
  const dispatch = useDispatch();

  return (
    <HeaderContainer>
      <Header>
        <NavSection>
          <Link to="/">
            {colorScheme === 'dark' ? (
              <LogoImg
                id="header-logo"
                src={logoDark}
                alt="coopersystem-logo"
              />
            ) : (
              <LogoImg
                id="header-logo"
                src={logoLight}
                alt="coopersystem-logo"
              />
            )}
          </Link>
          <Divider className="logo-divider" type="vertical" />
          <ActionButton
            id="drawer-menu"
            type="link"
            icon={collapsed ? 'menu' : 'menu-fold'}
            size="large"
            onClick={() => handleCollapse(!collapsed)}
          />
        </NavSection>

        <NavSection>
          <ActionButton
            id="notification-btn"
            type="link"
            icon="bell"
            size="large"
          />
          <Divider className="action-divider" type="vertical" />
          <Dropdown overlay={() => Menu({ dispatch })} placement="bottomCenter">
            <UserContainer>
              {loading ? (
                <Skeleton count={1} width="50%" lineWidthList={['50%']} />
              ) : (
                <Avatar>
                  <img
                    alt="avatar"
                    src={`data:image/png;base64, ${
                      profile.avatar.split("'")[1]
                    }`}
                    style={{
                      width: 36,
                      height: 36,
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                </Avatar>
              )}

              {loading ? (
                <Skeleton count={1} width="50%" lineWidthList={['50%']} />
              ) : (
                <span className="username-text">
                  {safeRead(profile, ['first_name'])}
                </span>
              )}
            </UserContainer>
          </Dropdown>
        </NavSection>
      </Header>
    </HeaderContainer>
  );
}

DashboardHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.func.isRequired,
};
