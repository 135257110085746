import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd';
import safeRead from 'safely-read';
import SearchForm from './SearchForm';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import badgesStatus from '~/constants/badgesStatus';
import {
  buildDateRange,
  buildDateRangeStringPair,
} from '~/services/utils/dateRangeUtils';

import api from '~/services/api/index';
import { TEAM } from '~/constants/endpoints/team';

export function handleSearch(values) {
  const name = safeRead(values, ['nameField']);
  const type = safeRead(values, ['typeField']);
  const status = safeRead(values, ['statusField']);
  const team = safeRead(values, ['teamField']);
  const formikDateRange = safeRead(values, ['formikDateRange']);

  const dateStringPair = buildDateRangeStringPair(formikDateRange);
  const { ...queries } = getAllQueriesParams();

  return pushWithQueriesParams(history.location.pathname, {
    ...queries,
    name,
    type,
    status,
    team,
    date_start: dateStringPair[0],
    date_end: dateStringPair[1],
  });
}

export const typesSelect = {
  values: [
    {
      id: 'ferias',
      value: 'Férias',
    },
    {
      id: 'abono',
      value: 'Abono',
    },
    {
      id: 'acerto_por_desligamento',
      value: 'Acerto por desligamento',
    },
  ],
  render: item => (
    <Select.Option key={item.id} value={item.id}>
      {item.value}
    </Select.Option>
  ),
};

export const statusSelect = {
  values: [
    {
      id: badgesStatus.IN_ANALYSIS,
      value: 'Em análise',
    },
    {
      id: badgesStatus.APPROVED,
      value: 'Aprovado',
    },
    {
      id: badgesStatus.DISAPPROVED,
      value: 'Reprovado',
    },
    {
      id: badgesStatus.CANCELED,
      value: 'Cancelado',
    },
  ],
  render: item => (
    <Select.Option key={item.id} value={item.id}>
      {item.value}
    </Select.Option>
  ),
};

export function disableSearchButton({
  nameField,
  typeField,
  statusField,
  teamField,
  formikDateRange,
}) {
  if (
    !nameField &&
    !typeField &&
    !statusField &&
    !teamField &&
    !formikDateRange
  ) {
    return true;
  }

  return false;
}

export function getInitialValues() {
  const {
    name,
    type,
    status,
    team,
    date_start,
    date_end,
  } = getAllQueriesParams();

  return {
    nameField: name,
    typeField: type,
    statusField: status,
    teamField: team,
    formikDateRange: buildDateRange(date_start, date_end),
  };
}

export function clearFields(setValues) {
  setValues({
    nameField: null,
    typeField: null,
    statusField: null,
    teamField: null,
    formikDateRange: null,
  });
  pushWithQueriesParams(history.location.pathname, {});
}

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export default SearchForm;
