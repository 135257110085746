import produce from 'immer';
import { GROUPS_REQUEST, GROUPS_SUCCESS, GROUPS_FAILURE } from './actions';

const INITIAL_STATE = {
  loading: false,
  data: [],
};

export default function groups(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GROUPS_REQUEST:
        draft.loading = true;
        break;
      case GROUPS_FAILURE:
        draft.loading = false;
        break;
      case GROUPS_SUCCESS:
        draft.loading = false;
        draft.data = action.payload.groups;
        break;
      default:
    }
  });
}
