import React, { useState, useContext } from 'react';
import { Row, Col, Divider, Button } from 'antd';
import PropTypes from 'prop-types';
import safelyRead from 'safely-read';
import DetailModal from '~/components/DataDisplay/Modals/DetailModal';
import { usePayableRequest, formatData } from '.';
import ReasonModal from '../../FinancialRequestsTable/ReasonModal';
import { FinancialTableContext } from '../../FinancialRequestsTable/index';
import checkPermission from '~/services/utils/checkPermission';

function Details({ visible, setVisible, rowId }) {
  const { data, loading } = usePayableRequest(rowId);
  const [showReason, setShowReason] = useState(false);
  const { setcurrentStatuses, setSelectedRowKeys } = useContext(
    FinancialTableContext
  );

  return (
    <>
      <DetailModal
        title="Detalhes da solicitação"
        visible={visible}
        setVisible={setVisible}
        data={formatData(data)}
        actions={
          <Row type="flex" justify="end">
            <Col>
              {(safelyRead(data, 'status') === 'em_analise_financeira' ||
                safelyRead(data, 'status') === 'aprovado_pelo_financeiro') && (
                <>
                  <Button
                    type="danger"
                    onClick={() => {
                      setSelectedRowKeys([safelyRead(data, 'id')]);
                      setcurrentStatuses('reprovado_pelo_financeiro');
                      setShowReason(true);
                    }}
                  >
                    Reprovar
                  </Button>
                </>
              )}

              {safelyRead(data, 'status') === 'em_analise_financeira' && (
                <Divider type="vertical" />
              )}

              {checkPermission('can_approve_payable_request') &&
                (safelyRead(data, 'status') === 'em_analise_financeira' ||
                  safelyRead(data, 'status') ===
                    'reprovado_pelo_financeiro') && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedRowKeys([safelyRead(data, 'id')]);
                      setcurrentStatuses('aprovado_pelo_financeiro');
                      setShowReason(true);
                    }}
                  >
                    Aprovar
                  </Button>
                )}
            </Col>
          </Row>
        }
      />

      {showReason && !loading && (
        <ReasonModal
          visible={showReason}
          setVisible={setShowReason}
          callback={() => setVisible(false)}
        />
      )}
    </>
  );
}

Details.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  rowId: PropTypes.number.isRequired,
};

export default Details;
