import React from 'react';
import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SeachForm/SearchForm';
import ProductionInfo from './ProductionInfo/ProductionInfo';
import CollapseList from './CollapseList/CollapseList';

export default function ConsolidatedProduction() {
  return (
    <PageContainer title="Consolidar Produção">
      <SearchForm />
      <ProductionInfo />
      <CollapseList />
    </PageContainer>
  );
}
