import React, { useState, useEffect } from 'react';
import { Button, message, DatePicker } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import TeamTable from './TeamTable';
import { MY_TEAM } from '~/constants/endpoints/my_team';
import { RESIGNATION_AGREEMENT } from '~/constants/endpoints/resignation_agreement';
import history, {
  pushWithQueriesParams,
  getQueryParam,
  getAllQueriesParams,
} from '~/services/history';
import moment from 'moment';

let today = new Date();
let day = String(today.getDate()).padStart(2, '0');
let month = String(today.getMonth() + 1).padStart(2, '0');
let year = today.getFullYear();
const ActualDate = year + '-' + month + '-' + day;

export const handleGetInfoDetail = async (
  person_id,
  row_index,
  setIsLoading,
  setDataDetail,
  tableData,
) => {

  setIsLoading(true);
  try {
    const datefinal = safeRead(tableData, ['results', row_index, 'person_display', 'dataTeste'], ActualDate);
    const { data } = await api.get(RESIGNATION_AGREEMENT(person_id, datefinal));
    const person = tableData?.results.filter(valor => valor.person_display.id === person_id)
    setIsLoading(false);
    if (person.length) {
      setDataDetail({
        ...data,
        customdata: person[0].person_display.dataTeste
      })
      return
    }
    setDataDetail(data);
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao buscar dados da tabela'
      )
    );
    setIsLoading(false);
    setDataDetail();
  }
};

export const makeColumns = (
  setDetailModalVisible,
  setIsLoading,
  setDataDetail,
  isLoading,
  setTableData,
  tableData,
) => {

  function onChangeDate(date, dateString, item) {
    const datefinal = dateString == null ? ActualDate : moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');

    setTableData(old => ({
      ...old, results: old?.results?.map(val => {
        if (val?.person_display?.id === item) {
          return {
            ...val, person_display: {
              ...val.person_display, dataTeste: datefinal
            }
          }
        }
        return { ...val }
      })
    }))
  }


  return [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
    },
    {
      title: 'Cargo',
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
    },
    {
      title: 'Data Desligamento',
      dataIndex: 'date',
      key: 'date',
      render: item => (<DatePicker format={'DD/MM/YYYY'} onChange={(event, dateString) => onChangeDate(event, dateString, item)} />),
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (item, record, index) => (
        <Button
          type="link"
          onClick={async () => {
            await handleGetInfoDetail(item, index, setIsLoading, setDataDetail, tableData);
            setDetailModalVisible(true);
          }}
          disabled={isLoading}
        >
          Detalhes
        </Button>
      ),
    },
  ];
};

export async function fetchTableData(setTableData, setLoading) {
  setLoading(true);
  const { page, page_size, ...queries } = getAllQueriesParams();

  setLoading(true);

  try {
    const { data } = await api.get(MY_TEAM, {
      params: {
        page: page || 1,
        page_size: page_size || 10,
        ...queries,
      },
    });

    setLoading(false);
    setTableData(data);
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao buscar dados da tabela'
      )
    );
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const name = getQueryParam('person_name');
  const team = getQueryParam('team_name');
  const position = getQueryParam('position');
  const statusData = getQueryParam('statusData');

  useEffect(() => {
    const loadTableData = async () => {
      await fetchTableData(setTableData, setLoadingTableData);
    };

    loadTableData();
  }, [name, team, position, page, page_size, statusData]);

  return {
    tableData,
    setTableData,
    statusData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        person_name: name,
        team_id: team,
        position,
      }),
  };
}

export const handleAppovalDetail = async (person_id, date_end_work, setDetailModalVisible) => {
  const { ...queries } = getAllQueriesParams();

  try {
    const datefinal = date_end_work == null ? ActualDate : date_end_work;
    const response = await api.post(RESIGNATION_AGREEMENT(person_id, datefinal), {
      status: 'aprovado',
      keep_cooperative_status: false,
      receive_bonus: true
    });
    if (response.status === 201) {
      pushWithQueriesParams(history.location.pathname, {
        ...queries,
        statusData: 'aprovado',
      });
      setDetailModalVisible(false);

      message.success('Desligamento enviado com sucesso.');
    }
  } catch (error) {
    message.error(
      safeRead(error, ['response', 'data', 'detail'], 'Erro ao enviar status')
    );
  }
};

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      key: safeRead(item, ['person_display', 'id']),
      name: safeRead(item, ['person_display', 'name_display'], '--'),
      status: safeRead(item, ['person_display', 'status_display'], '--'),
      role: safeRead(item, ['position_display', 'position_display'], '--'),
      date: safeRead(item, ['person_display', 'id'], '--'),
      action: safeRead(item, ['person_display', 'id'], '--'),
    }));
  }

  return [];
}

export default TeamTable;
