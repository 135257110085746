import React from 'react';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import { Formik, Field, Form } from 'formik';
import debounce from 'lodash.debounce';

import badgeStatus from '~/constants/badgesStatus';
import createStatusSelectOptions from '~/services/utils/createStatusSelectOptions';
import Input from '~/components/DataEntry/Input';
import { RangePicker } from '~/components/DataEntry/DatePicker';
import Select from '~/components/DataEntry/Select';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import {
  buildDateRange,
  buildDateRangeStringPair,
  useSearchProject,
} from '../index';

export default function SearchForm() {
  const {
    page_size,
    status__in,
    date_end,
    date_start,
    name,
    id,
    request_type,
    team,
  } = getAllQueriesParams();
  const { loadingProjects, projects, setSearch } = useSearchProject();

  return (
    <Formik
      initialValues={{
        formikName: name,
        formikDateRange: buildDateRange(date_start, date_end),
        formikId: id,
        formikStatus: status__in,
        formikRequest: request_type,
        formikTeam: team,
      }}
      onSubmit={({
        formikName,
        formikDateRange,
        formikId,
        formikStatus,
        formikRequest,
        formikTeam,
      }) => {
        const dateStringPair = buildDateRangeStringPair(formikDateRange);

        pushWithQueriesParams(history.location.pathname, {
          page: 1,
          page_size: parseInt(page_size, 10) || null || 10,
          name: formikName,
          date_start: dateStringPair[0],
          date_end: dateStringPair[1],
          id: formikId,
          status__in: formikStatus,
          request_type: formikRequest,
          team: formikTeam,
        });
      }}
    >
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
              <Field
                id="id-input"
                name="formikId"
                label="ID"
                component={Input}
              />
            </Col>

            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="name-input"
                name="formikName"
                label="Nome"
                component={Input}
              />
            </Col>

            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="team-input"
                name="formikTeam"
                label="Equipe"
                loading={loadingProjects}
                component={AutoCompleteSelect}
                placeholder=" "
                selectSchema={item => (
                  <AntdSelect.Option key={item.id} value={item.id}>
                    {item.team_name}
                  </AntdSelect.Option>
                )}
                onChange={e => {
                  if (!e) {
                    setFieldValue('formikTeam', null);
                  } else {
                    setFieldValue('formikTeam', e);
                  }

                  setSearch(undefined);
                }}
                search={debounce(setSearch, 800)}
                allowClear
                data={projects.results}
              />
            </Col>

            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="date-range-input"
                name="formikDateRange"
                label="Data"
                component={RangePicker}
                style={{ width: '100%' }}
              />
            </Col>

            <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Field
                id="request-input"
                name="formikRequest"
                label="Solicitação"
                component={Select}
                data={[
                  { id: 'ausencia', value: 'Ausência' },
                  { id: 'ausencia_a_abonar', value: 'Ausência a Abonar' },
                  { id: 'ausencia_a_descontar', value: 'Ausência a Descontar' },
                  { id: 'hora_extra', value: 'Hora extra' },
                ]}
                renderItem={item => (
                  <AntdSelect.Option key={item.id} value={item.id}>
                    {item.value}
                  </AntdSelect.Option>
                )}
                allowClear
              />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Field
                id="status-input"
                name="formikStatus"
                label="Status"
                allowClear
                component={Select}
                data={createStatusSelectOptions([
                  badgeStatus.IN_FINANCIAL_ANALYSIS,
                  badgeStatus.ACTIVE,
                  badgeStatus.INACTIVE,
                  badgeStatus.REPROVED_BY_FINANCIAL,
                ])}
                renderItem={item => (
                  <AntdSelect.Option key={item.key} value={item.key}>
                    {item.value}
                  </AntdSelect.Option>
                )}
              />
            </Col>
          </Row>

          <Row type="flex" justify="end">
            <Col>
              <Button
                onClick={() => {
                  setValues({
                    formikDateRange: [],
                    formikId: null,
                    formikName: null,
                    formikStatus: null,
                    formikRequest: null,
                    formikTeam: null,
                  });
                  pushWithQueriesParams(history.location.pathname, {
                    page: 1,
                    page_size: parseInt(page_size, 10) || 10,
                  });
                }}
              >
                Limpar
              </Button>
              <Divider type="vertical" />
              <Button
                disabled={
                  !values.formikName &&
                  !values.formikId &&
                  !values.formikStatus &&
                  !values.formikRequest &&
                  !values.formikTeam &&
                  values.formikDateRange.length === 0
                }
                htmlType="submit"
                type="primary"
                icon="search"
              >
                Pesquisar
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
