import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import PageContainer from '~/components/Layout/PageContainer';
import prodControl from '~/assets/image/production-control.png';

import { Container, ContainerImage, LogoImg } from './styles';

import messages from './messages';

export default function Home() {
  const { Title } = Typography;

  return (
    <PageContainer>
      <Container>
        <Title level={3} style={{ textAlign: 'center' }}>
          <FormattedMessage {...messages.startProjectHeader} />
        </Title>
        <ContainerImage>
          <LogoImg src={prodControl} />
        </ContainerImage>
      </Container>
    </PageContainer>
  );
}
