import styled from 'styled-components';
import { Layout, Button } from 'antd';
import { mobileWidth, largeDesktopWidth } from '~/constants/metrics';

export const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  box-shadow: ${({ theme }) => theme.boxShadowBase};
  background-color: ${({ theme }) => theme.componentBackground};
  z-index: 1000;

  @media (max-width: ${mobileWidth}) {
    #header-logo {
      display: none;
    }
  }
`;

export const Header = styled(Layout.Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.componentBackground};
  padding: 0 12px;
  color: ${({ theme }) => theme.textColor};

  .username-text {
    margin-left: 12px;
  }

  .logo-divider {
    margin-left: 12px;
  }

  @media (max-width: ${mobileWidth}) {
    & {
      padding: 0 12px !important;
    }

    .logo-divider {
      display: none;
    }

    .username-text {
      display: none;
    }
  }

  @media (min-width: ${largeDesktopWidth}) {
    & {
      padding: 0 !important;
    }
  }
`;

export const ActionButton = styled(Button)`
  color: ${props => props.theme.textColor};
`;

export const NavSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .action-divider {
    margin-right: 18px;
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;

export const UserContainer = styled.div`
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoImg = styled.img`
  height: 35px;
  width: auto;
  cursor: pointer;
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 25px;
  border: 1px #333;
  background-color: #cccccc;
  cursor: pointer;
`;
