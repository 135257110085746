import React, { useState, createContext } from 'react';

import { Divider } from 'antd';
import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm/index';
import TeamTable from './TeamTable';
import DetailModal from './DetailModal';

export const HitByShutdownContext = createContext({});

export default function HitByShutdown() {
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState();

  return (
    <PageContainer title="Acerto para Desligamento">
      <HitByShutdownContext.Provider
        value={{
          isLoading,
          dataDetail,
          detailModalVisible,
          setIsLoading,
          setDataDetail,
          setDetailModalVisible,
        }}
      >
        <SearchForm />

        <Divider />

        <TeamTable />

        {detailModalVisible ? <DetailModal /> : null}
      </HitByShutdownContext.Provider>
    </PageContainer>
  );
}
