import safeRead from 'safely-read';
import { message } from 'antd';
import { useState, useEffect } from 'react';
import { AUTH_USERS_ID, PATCH_AUTH_USERS_ID } from '~/constants/endpoints/auth';
import { PATCH_PERSON } from '~/constants/endpoints/person';
import { AUTH_PASSWORD_RESET } from '~/constants/endpoints/auth';
import Access from './Access';
import api from '~/services/api';

export async function getAuthUsersGroups(id, setAuthUsersData) {
  let defaultResponse = {};

  try {
    const { data } = await api.get(AUTH_USERS_ID(id));
    defaultResponse = data;
  } catch (error) {
    const errorMessage = safeRead(
      error,
      ['response', 'data', 'detail'],
      'Erro ao buscar dados de Auth Users.'
    );
    message.error(errorMessage);
  }

  setAuthUsersData(defaultResponse);
}

export function useAuthUsersData(id) {
  const [authUsersData, setAuthGroupsData] = useState({});

  useEffect(() => {
    async function loadData() {
      if (id) {
        await getAuthUsersGroups(id, setAuthGroupsData);
      }
    }

    loadData();
  }, [id]);

  return {
    authUsersData,
    setAuthGroupsData,
    getAuthUsersGroups: () => getAuthUsersGroups(id, setAuthGroupsData),
  };
}

export async function PatchUserActive(id, username, values) {
  const is_active = safeRead(values, ['is_active']);

  try {
    await api.patch(PATCH_AUTH_USERS_ID(id), {
      username,
      is_active,
    });
    message.success({
      key: 'user-patch-success',
      content: 'Cadastro alterado com sucesso.',
    });
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Não foi possivel realizar a operação.')
    );
  }
}
export async function PatchPersonIsCooperativeMember(id, values) {
  const is_cooperative_member = safeRead(values, ['is_cooperative_member']);

  try {
    await api.patch(PATCH_PERSON(id), {
      is_cooperative_member,
    });
    message.success({
      key: 'user-patch-success',
      content: 'Cadastro alterado com sucesso.',
    });
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Não foi possivel realizar a operação.')
    );
  }
}

export async function createRecoverPasswordRequest(email, setLoading) {
  try {
    await api.post(AUTH_PASSWORD_RESET, {
      email,
    });

    message.success(
      'Solicitação feita com sucesso, por favor confira sua caixa de email.'
    );
    setLoading(true);
  } catch (error) {
    message.error('Por favor, confira o email digitado');
    setLoading(true);
  }
}

export default Access;
