import React from 'react';
import PropTypes from 'prop-types';

import { Table } from './styles';

export default function VacationsAllowencesCountTable({ items }) {
  return (
    <>
      {items[0].days_off === null ? (
        <>
          {items && (
            <Table border>
              <tbody>
                <tr>
                  <td />
                  <td className="tb-header">Férias</td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Adquirido</td>
                  <td className="tb-vacation-cell tb-first">
                    {items[0].annual_leaves.obtained} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Utilizado</td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {items[0].annual_leaves.approved} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Expirado</td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {items[0].annual_leaves.expired || 0} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">
                    <b>Restante</b>
                  </td>
                  <td className="tb-vacation-cell tb-last">
                    {items[0].annual_leaves.remaining} dia(s)
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      ) : (
        <>
          {items && (
            <Table>
              <tbody>
                <tr>
                  <td />
                  <td className="tb-header">Férias</td>
                  <td className="tb-header">Abonos</td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Adquirido</td>
                  <td className="tb-vacation-cell tb-first-left">
                    {items[0].annual_leaves.obtained} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-first-right">
                    {items[0].days_off.obtained} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Utilizado</td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {items[0].annual_leaves.approved} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-allowence-middle">
                    {items[0].days_off.approved} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">Expirado</td>
                  <td className="tb-vacation-cell tb-vacation-middle">
                    {items[0].annual_leaves.expired || 0} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-allowence-middle">
                    {items[0].days_off.expired || 0} dia(s)
                  </td>
                </tr>
                <tr>
                  <td className="tb-description-cell">
                    <b>Restante</b>
                  </td>
                  <td className="tb-vacation-cell tb-last-left">
                    {items[0].annual_leaves.remaining} dia(s)
                  </td>
                  <td className="tb-allowence-cell tb-last-right">
                    {items[0].days_off.remaining} dia(s)
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      )}
    </>
  );
}

VacationsAllowencesCountTable.propTypes = {
  items: PropTypes.objectOf(PropTypes.any),
};

VacationsAllowencesCountTable.defaultProps = {
  items: undefined,
};
