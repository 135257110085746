import React from 'react';
import { Tree, message } from 'antd';

import RegisterOrEdit from './RegisterOrEdit';
import api from '~/services/api';
import { AUTH_PROFILES, AUTH_PROFILES_ID } from '~/constants/endpoints/auth';
import { store } from '~/store';

const { TreeNode } = Tree;

export async function postPerfil({ name, groups }, fetchTableData, setVisible) {
  try {
    await api.post(AUTH_PROFILES, { name, groups });

    setVisible(false);

    message.success(`Perfil ${name} criado com sucesso!`);

    await fetchTableData();
  } catch (error) {
    if (error.response.data.name) {
      error.response.data.name.forEach(item => message.error(`name: ${item}`));
    } else {
      message.error(`Erro ao criar perfil ${name}`);
    }
  }
}

export async function patchPerfil(
  { name, groups },
  fetchTableData,
  setVisible,
  id
) {
  try {
    await api.patch(AUTH_PROFILES_ID(id), { name, groups });

    setVisible(false);

    message.success(`Perfil ${name} editado com sucesso!`);

    await fetchTableData();
  } catch (error) {
    if (error.response.data.name) {
      error.response.data.name.forEach(item => message.error(`name: ${item}`));
    } else {
      message.error(`Erro ao editar perfil ${name}`);
    }
  }
}

export function formatPermissionsData(data) {
  return (data || []).map(({ name, groups, id }) => ({
    title: name,
    key: id,
    children: groups.map(item => ({
      title: item.name,
      key: item.id,
    })),
  }));
}

export function buildTotalLength(groups = []) {
  let length = 0;

  groups.forEach(item => {
    length += item.children.length;
  });

  return length;
}

export function buildSelectedLength(selected = []) {
  // eslint-disable-next-line no-restricted-globals
  return selected.filter(item => !isNaN(item)).length;
}

export function generateTree(treeNodes = [], targetKeys = []) {
  return treeNodes.map(({ children, ...props }) => (
    <TreeNode
      {...props}
      disabled={targetKeys.includes(`${props.key}`)}
      key={props.key}
    >
      {generateTree(children, targetKeys)}
    </TreeNode>
  ));
}

export function buildSelectAllValue(groups = []) {
  return groups.reduce((result, value) => {
    value.children.forEach(item => result.push(`${item.key}`));
    result.push(value.title);

    return result;
  }, []);
}

export function isAllChecked(checkedKeys = [], dataSource = []) {
  return buildSelectedLength(checkedKeys) === buildTotalLength(dataSource);
}

export function isIndeterminate(checkedKeys = [], dataSource = []) {
  return (
    buildSelectedLength(checkedKeys) !== buildTotalLength(dataSource) &&
    buildSelectedLength(checkedKeys) !== 0
  );
}

export function isCheckAllDisabled(targetKeys = [], dataSource = []) {
  return buildSelectedLength(targetKeys) === buildTotalLength(dataSource);
}

export function checkAll(target, dataSource, setCheckedKeys) {
  if (target.checked) {
    setCheckedKeys(buildSelectAllValue(dataSource));
  } else {
    setCheckedKeys([]);
  }
}

export function addSelection(
  setTargetKeys,
  setCheckedKeysLeft,
  checkedKeysLeft,
  onChange
) {
  setTargetKeys(prevState => {
    const newState = [
      ...prevState,
      ...checkedKeysLeft.filter(item => !prevState.includes(item)),
    ];

    onChange(newState.filter(item => !isNaN(item)));

    return newState;
  });

  setCheckedKeysLeft([]);
}

export function removeSelection(
  setTargetKeys,
  setCheckedKeysRight,
  setHalfCheckedKeysRight,
  checkedKeysRight,
  halfCheckedKeysRight,
  onChange
) {
  setTargetKeys(prevState => {
    const newState = prevState
      .filter(item => !checkedKeysRight.includes(`${item}`))
      .filter(item => !halfCheckedKeysRight.includes(`${item}`));

    onChange(newState.filter(item => !isNaN(item)));

    return newState;
  });

  setCheckedKeysRight([]);
  setHalfCheckedKeysRight([]);
}

export function makeEditState(groups = []) {
  const permissionsStore = store.getState().groups.data;

  return permissionsStore.reduce((result, value) => {
    if (value.groups.every(i => result.includes(`${i.id}`))) {
      result.push(value.model);
    }

    return result;
  }, groups);
}

export default RegisterOrEdit;
