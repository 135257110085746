import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Modal } from 'antd';

import checkPermission from '~/services/utils/checkPermission';
import badgesStatus from '~/constants/badgesStatus';
import { VacationsContext } from '../index';
import { handlePaidLeaveApproval, DisapprovalReasonModal } from './index';

const { confirm } = Modal;

function ManagerActions({ detail }) {
  const { updatePaidLeave, setDetailModalVisible } = useContext(
    VacationsContext
  );

  const [visible, setVisible] = useState(false);

  const request_id = detail.find(item => item.key === 'id').value;
  const request_status = detail.find(item => item.key === 'status').status;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {(request_status === badgesStatus.APPROVED ||
        request_status === badgesStatus.IN_ANALYSIS) &&
        checkPermission('can_approve_paid_leave') && (
          <Button type="danger" onClick={() => setVisible(true)}>
            Reprovar
          </Button>
        )}

      {request_status === badgesStatus.IN_ANALYSIS &&
      checkPermission('can_approve_paid_leave') ? (
        <Divider type="vertical" />
      ) : null}

      {(request_status === badgesStatus.DISAPPROVED ||
        request_status === badgesStatus.IN_ANALYSIS) &&
        checkPermission('can_approve_paid_leave') && (
          <Button
            type="primary"
            onClick={() =>
              confirm({
                title: 'Deseja realmente aprovar esta solicitação?',
                centered: true,
                async onOk() {
                  await handlePaidLeaveApproval({
                    id: request_id,
                    status: badgesStatus.APPROVED,
                    updatePaidLeave,
                    setDetailModalVisible,
                  });
                },
              })
            }
          >
            Aprovar
          </Button>
        )}

      {visible && (
        <DisapprovalReasonModal
          visible={visible}
          setVisible={setVisible}
          request_id={request_id}
          setDetailModalVisible={setDetailModalVisible}
          updatePaidLeave={updatePaidLeave}
        />
      )}
    </div>
  );
}

ManagerActions.propTypes = {
  detail: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ManagerActions;
