import { takeLatest, call, put, all } from 'redux-saga/effects';
import {
  USER_PERMISSIONS_REQUEST,
  GET_USER_DATA_REQUEST,
  userPermissionsFailure,
  userPermissionsSuccess,
  getUserDataSuccess,
  getUserDataFailure,
} from './actions';
import { AUTH_USER_PERMISSIONS } from '~/constants/endpoints/auth';
import { CURRENT_PERSON } from '~/constants/endpoints/person';

import api from '~/services/api';

export function* getUserPermissions() {
  try {
    const { data } = yield call(api.get, AUTH_USER_PERMISSIONS);
    const { permissions } = data;

    yield put(userPermissionsSuccess(permissions));
  } catch (error) {
    yield put(userPermissionsFailure());
  }
}

export function* getUserData() {
  try {
    const { data } = yield call(api.get, CURRENT_PERSON);

    yield put(getUserDataSuccess(data));
  } catch (error) {
    yield put(getUserDataFailure());
  }
}

export default all([
  takeLatest(USER_PERMISSIONS_REQUEST, getUserPermissions),
  takeLatest(GET_USER_DATA_REQUEST, getUserData),
]);
