import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Sider from './Sider';
import Footer from './Footer';
import { Container, Content, PageContent } from './styles';
import Breadcomb from '../../DataDisplay/Breadcrumb';

export default function DashboardLayout({ children }) {
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <>
      <Container>
        <Header collapsed={collapsed} handleCollapse={setCollapsed} />
        <Content>
          <Sider collapsed={collapsed} />
          <PageContent collapsed={collapsed}>
            <Breadcomb />
            {children}
          </PageContent>
        </Content>
      </Container>
      <Footer />
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
