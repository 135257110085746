import React from 'react';
import { Table as AntTable, Pagination, Typography, Row } from 'antd';
import PropTypes from 'prop-types';

import history, {
  getAllQueriesParams,
  pushWithQueriesParams,
} from '~/services/history';

export default function Table({
  columns,
  dataSource,
  setTableFilter,
  tableFilter,
  currentPage,
  count,
  setCurrentPage,
  setPageSize,
  pageSize,
  paginated,
  tableLayout,
  scroll,
  ...props
}) {
  const { Text } = Typography;
  const { page_size, ...queries } = getAllQueriesParams();

  // para que o valor page não seja sobreescrito
  delete queries.page;

  return (
    <>
      <AntTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        tableLayout={tableLayout}
        scroll={scroll}
        {...props}
      />
      <br />
      {paginated && (
        <Row type="flex" justify="start">
          <Pagination
            current={currentPage <= 0 ? 1 : currentPage}
            total={count}
            showSizeChanger
            showTotal={(total, range) => (
              <div style={{ marginRight: 30 }}>
                <Text>
                  Exibindo
                  <Text strong>
                    {' '}
                    {total === 0 || null ? 0 : range[1] - range[0] + 1}{' '}
                  </Text>
                  registros de
                  <Text strong> {total} </Text>
                </Text>
              </div>
            )}
            onChange={val =>
              setCurrentPage
                ? setCurrentPage(val)
                : pushWithQueriesParams(history.location.pathname, {
                    page: val,
                    page_size: page_size || 10,
                    ...queries,
                  })
            }
            onShowSizeChange={(e, size) =>
              setPageSize
                ? setPageSize(size)
                : pushWithQueriesParams(history.location.pathname, {
                    page: 1,
                    page_size: size,
                    ...queries,
                  })
            }
            pageSizeOptions={['10', '20', '30', '40', '50']}
            defaultPageSize={10}
            defaultCurrent={1}
            pageSize={pageSize || parseInt(page_size, 10)}
          />
        </Row>
      )}
    </>
  );
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  setTableFilter: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  count: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  paginated: PropTypes.bool,
  emptyText: PropTypes.string,
  tableLayout: PropTypes.string,
  tableFilter: PropTypes.objectOf(PropTypes.any),
  scroll: PropTypes.objectOf(PropTypes.any),
};

Table.defaultProps = {
  setTableFilter: undefined,
  paginated: undefined,
  currentPage: undefined,
  setCurrentPage: undefined,
  count: undefined,
  pageSize: undefined,
  setPageSize: undefined,
  emptyText: undefined,
  tableFilter: undefined,
  tableLayout: 'fixed',
  scroll: undefined,
};
