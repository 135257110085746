import React, { useEffect } from 'react';
import { Button, Modal, Table, Typography } from 'antd';
import PropTypes from 'prop-types';
import formatPrice from '~/services/utils/formatPrice';
import checkPermission from '~/services/utils/checkPermission';
import { getHomeGrown, formatTableData } from '.';

const { Title } = Typography;

export default function AlterHomeSilver({
  uploadModalVisible,
  setUploadModalVisible,
  setRegisterVisible,
  setToEditVisible,
  homeGrown,
  setHomeGrown,
  isLoading,
  setIsLoading,
  setItem,
}) {
  useEffect(() => {
    getHomeGrown(setHomeGrown, setIsLoading);
  }, [setHomeGrown, setIsLoading]);

  const columns = [
    {
      title: 'Tempo de Casa',
      dataIndex: 'required_time',
      key: 'tempo',
      render: text => `${text} anos`,
    },
    {
      title: 'Contrato de 6h',
      dataIndex: 'bonus_6hours',
      key: 'seisHoras',
    },
    {
      title: 'Contrato de 8h',
      dataIndex: 'bonus_8hours',
      key: 'oitoHoras',
    },
    {
      title: 'Valor por Tempo de Casa',
      dataIndex: 'bonus_reward',
      key: 'bonus_reward',
      render: text => formatPrice(text),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (item, record) => (
        checkPermission('change_homegrown') && (
        <Button
          type="link"
          onClick={() => {
            setItem(record);
            setToEditVisible(true);
          }}
        >
          Editar
        </Button>
        )
      ),
    },
  ];

  return (
    <Modal
      visible={uploadModalVisible}
      onCancel={() => setUploadModalVisible(false)}
      footer={false}
      width={750}
      destroyOnClose
    >
      <Title level={3}>Configurar Prata da Casa</Title>
      <Table
        loading={isLoading}
        dataSource={formatTableData(homeGrown)}
        columns={columns}
        rowClassName="editable-row"
      />
      {checkPermission('add_homegrown') && (
      <Button type="primary" onClick={() => setRegisterVisible(true)}>
        Adicionar Bônus
      </Button>
      )}
    </Modal>
  );
}

AlterHomeSilver.propTypes = {
  uploadModalVisible: PropTypes.bool.isRequired,
  setUploadModalVisible: PropTypes.func.isRequired,
  homeGrown: PropTypes.objectOf(PropTypes.any).isRequired,
  setHomeGrown: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setRegisterVisible: PropTypes.func.isRequired,
  setToEditVisible: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
};
