import produce from 'immer';
import { SET_PRODUCTION } from './actions';

const INITIAL_STATE = {
  data: [],
};

export default function production(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_PRODUCTION:
        draft.data = action.payload.data;
        break;

      default:
    }
  });
}
