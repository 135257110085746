import { defineMessages } from 'react-intl';

export const scope = 'portal.authentication.recover';

export default defineMessages({
  recoverPassword: {
    id: `${scope}.recover_password`,
    defaultMessage: 'Recuperar Senha',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Enviar',
  },
  password: {
    id: 'portal.authentication.password_label',
    defaultMessage: 'Senha',
  },
  newPassword: {
    id: 'portal.authentication.new_password_label',
    defaultMessage: 'Nova Senha',
  },
  confirmPassword: {
    id: 'portal.authentication.confirm_password_label',
    defaultMessage: 'Confirmar nova senha',
  },
});
