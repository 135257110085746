import React from 'react';
import PageContainer from '~/components/Layout/PageContainer';
import Holidays, { useTableData } from './Holidays';
import WorkingDays from './WorkingDays/WorkindDays';
import { ProductionDaysContext } from './index';
import { useBusinessDays } from './WorkingDays/index';

export default function ProductionDays() {
  const {
    getholidays,
    loadingTableData,
    setLoadingTableData,
    setTableData,
    tableData,
  } = useTableData();

  const { businessDayData, fetchData, setBusinessDayData } = useBusinessDays();

  return (
    <PageContainer title="Dias de Produção">
      <ProductionDaysContext.Provider
        value={{
          getholidays,
          loadingTableData,
          setLoadingTableData,
          setTableData,
          tableData,
          businessDayData,
          fetchData,
          setBusinessDayData,
        }}
      >
        <Holidays />
        <WorkingDays />
      </ProductionDaysContext.Provider>
    </PageContainer>
  );
}
