import { useMutation } from 'react-query';
import api from '~/services/api';
import { parseResponseData } from '../../../../services/utils/parseResponseData';

export const useEditTeam = options =>
  useMutation(
    ({ id, ...rest }) =>
      api
        .put(`/squad/${id}/`, {
          ...rest,
        })
        .then(parseResponseData),
    options
  );
