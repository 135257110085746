import safeRead from 'safely-read';
import SearchForm from './SearchForm';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';

export function handleSearch(values) {
  const { ...queries } = getAllQueriesParams();
  const username = safeRead(values, ['usernameField']);
  const name = safeRead(values, ['nameField']);
  const page = 1;

  return pushWithQueriesParams(history.location.pathname, {
    ...queries,
    page,
    username__icontains: username,
    name: name,
  });
}

export function getInitialValues() {
  const { username__icontains, name } = getAllQueriesParams();

  return {
    usernameField: username__icontains,
    nameField: name,
  };
}

export function clearFields(setValues) {
  setValues({
    usernameField: null,
    userField: null,
  });
  pushWithQueriesParams(history.location.pathname, {});
}

export default SearchForm;
