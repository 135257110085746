import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import permissions from './permissions/sagas';
import groups from './profile_groups/sagas';

export default function* rootSaga() {
  return yield all([auth, user, permissions, groups]);
}
