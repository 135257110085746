import React from 'react';
import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm';
import UserTable from './UserTable';

export default function HomeSilver() {
  return (
    <PageContainer title="Prata da Casa">
      <SearchForm />
      <UserTable />
    </PageContainer>
  );
}
