import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import TeamHistoryTable from './TeamHistoryTable';
import { TEAM_PRODUCTION } from '~/constants/endpoints/team';
import TableCheckinsCheckouts from '~/constants/checkinsCheckouts';
import moment from 'moment';
import { getQueryParam } from '~/services/history';
import { Badge, Tooltip } from 'antd';

function generateColuns() {
  const columns = [];

  columns.push({
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    fixed: 'left',
    width: 240,
  });

  TableCheckinsCheckouts.forEach(item => {
    columns.push({
      title: `${item.pos}ª ${item.label}`,
      dataIndex: `${item.pos}${item.type}`,
      key: `${item.pos}${item.type}`,
      width: 100,
    });
  });

  columns.push({
    title: 'Produção',
    dataIndex: 'production',
    key: 'production',
    fixed: 'right',
    width: 100,
  });

  return columns;
}

export const makeColumns = generateColuns();

export async function fetchTableData(setTableData, setLoading, queries) {
  setLoading(true);
  try {
    const productionEndpoint = TEAM_PRODUCTION(queries.teamId);
    const { data } = await api.get(productionEndpoint, {
      params: queries,
    });
    setLoading(false);
    setTableData({
      results: safeRead(data, [0]),
    });
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar dados da tabela')
    );
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
  });

  const today = new Date();
  const year = parseInt(getQueryParam('year')) || today.getFullYear();
  const month = parseInt(getQueryParam('month')) || today.getMonth() + 1;
  const day = parseInt(getQueryParam('day')) || today.getDate();
  const teamId = parseInt(getQueryParam('team_id'));

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setTableData, setLoadingTableData, {
        year,
        month,
        day,
        teamId,
      });
    }

    loadTableData();
  }, [year, month, month, day, teamId]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        year,
        month,
        day,
        teamId,
      }),
  };
}

function handleStatus(status) {
  if (status === 'aprovado') {
    return 'green';
  }

  if (status === 'em_analise') {
    return 'yellow';
  }

  if (status === 'reprovado') {
    return 'tomato';
  }

  return 'transparent';
}

export function formatTableData(tableList) {
  if ('person' in tableList && tableList.person.length) {
    const people = tableList.person.map(person => ({
      key: safeRead(person, ['person_id']),
      name: safeRead(person, ['person']),
    }));

    tableList.timeline.map(item => {
      const type = item.check_in ? 'entrada' : 'saida';
      const personIndex = people.findIndex(
        person => person.key === item.person_id
      );

      people[personIndex][`${item.position}${type}`] = item.rectification ? (
        <Tooltip title={item.rectification?.status_display}>
          <Badge
            color={handleStatus(item.rectification.status)}
            text={`${moment(item.worktime_clock).format('HH:mm')}${
              item.rectification?.status !== 'aprovado' ? '*' : ''
            }`}
          />
        </Tooltip>
      ) : (
        moment(item.worktime_clock).format('HH:mm')
      );
    });

    tableList.production.map(item => {
      const personIndex = people.findIndex(
        person => person.key == item.person_id
      );
      people[personIndex]['production'] = moment(item.production).format(
        'HH:mm'
      );
    });

    return people;
  }

  return [];
}

export default TeamHistoryTable;
