import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Row, Col, Divider, Modal } from 'antd';

import checkPermission from '~/services/utils/checkPermission';
import { approvalModalTypes, from } from '../../index';
import { ModalItemContainer } from '../../styles';
import safeRead from 'safely-read';

export default function DetailModal({
  rowData,
  visible,
  setVisible,
  setApprovalItems,
  setApprovalModalActionType,
  setApprovalModalVisible,
  setFrom,
}) {
  const { Text, Title } = Typography;

  return (
    <Modal
      title={<Title level={4}>Detalhes da rasura</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <ModalItemContainer>
        <div>
          <Text strong style={{ display: 'block' }}>
            Numero (ID)
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {rowData.key}
          </Text>
        </div>

        <div>
          <Text strong style={{ display: 'block' }}>
            Data da rasura
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {rowData.created_at}
          </Text>
        </div>

        <div>
          <Text strong style={{ display: 'block' }}>
            Status
          </Text>

          {rowData.status_display}
        </div>
      </ModalItemContainer>

      <ModalItemContainer>
        <div>
          <Text strong style={{ display: 'block' }}>
            Nome
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {rowData.name}
          </Text>
        </div>
      </ModalItemContainer>

      <ModalItemContainer>
        <div>
          <Text strong style={{ display: 'block' }}>
            Registro
          </Text>
          {rowData.daily_production}
        </div>
      </ModalItemContainer>

      <ModalItemContainer>
        <div>
          <Text strong style={{ display: 'block' }}>
            Motivo
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {rowData.reason}
          </Text>
        </div>
      </ModalItemContainer>

      {rowData.status === 'aprovado' || rowData.status === 'reprovado' ? (
        <ModalItemContainer>
          <div>
            <Text strong style={{ display: 'block' }}>
              Responsável pela
              {rowData.status === 'aprovado' ? ' aprovação' : ' reprovação'}
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {safeRead(rowData, ['examiner_display', 'name_display'], '--')}
            </Text>
          </div>
        </ModalItemContainer>
      ) : null}

      {rowData.status === 'reprovado' ? (
        <ModalItemContainer>
          <div>
            <Text strong style={{ display: 'block' }}>
              Motivo da reprovação
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {rowData.disapproval_reason}
            </Text>
          </div>
        </ModalItemContainer>
      ) : null}

      {/* <ModalItemContainer>
        <a href="a">Ver produção deste usuário</a>
      </ModalItemContainer> */}

      {checkPermission('change_rectification') ? (
        <Row type="flex" justify="end">
          <Col>
            {rowData.status !== 'reprovado' ? (
              <Button
                onClick={() => {
                  setApprovalItems([rowData.key]);
                  setFrom(from.detail);
                  setApprovalModalActionType(approvalModalTypes.disapprove);
                  setApprovalModalVisible(true);
                }}
                type="danger"
              >
                Reprovar
              </Button>
            ) : null}

            {rowData.status === 'em_analise' ? (
              <Divider type="vertical" />
            ) : null}

            {rowData.status !== 'aprovado' ? (
              <Button
                onClick={() => {
                  setApprovalItems([rowData.key]);
                  setFrom(from.detail);
                  setApprovalModalActionType(approvalModalTypes.approve);
                  setApprovalModalVisible(true);
                }}
                type="primary"
              >
                Aprovar
              </Button>
            ) : null}
          </Col>
        </Row>
      ) : null}
    </Modal>
  );
}

DetailModal.propTypes = {
  rowData: PropTypes.objectOf(PropTypes.any).isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setApprovalItems: PropTypes.func.isRequired,
  setApprovalModalActionType: PropTypes.func.isRequired,
  setApprovalModalVisible: PropTypes.func.isRequired,
  setFrom: PropTypes.func.isRequired,
};
