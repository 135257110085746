import React from 'react';
import { Modal, message } from 'antd';
import { useDeleteTeam } from '../../services/useDeleteTeam';
import { useQuery, useQueryClient } from 'react-query';

export const DeleteTeamModal = ({open, setOpen, data }) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useDeleteTeam({
    onError: (err) => {
        if(err.response?.data?.detail){

            message.error(err.response?.data?.detail);
        }else{
            
            message.error('Erro ao deletar equipe');
        }
    },
    onSuccess: () => {
      message.success('Equipe deletada com sucesso');
      queryClient.invalidateQueries('useGetTeamListKey');
      setOpen(false);
    },
  });

  const onDelete = () => {
    mutate(data?.id);
  };

  return (
    <Modal
      title="Deletar equipe"
      visible={open}
      onOk={onDelete}
      confirmLoading={isLoading}
      onCancel={() => setOpen(false)}
    >
      <p>Deseja mesmo deletar a equipe <strong>{data?.name}</strong></p>
    </Modal>
  );
};
