import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Spin } from 'antd';
import { Container } from './styles';

import messages from './messages';

function Loading({ intl }) {
  return (
    <Container>
      <Spin size="large" tip={`${intl.formatMessage(messages.loading)}...`} />
    </Container>
  );
}

Loading.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Loading);
