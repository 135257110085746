import React from 'react';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import { Formik, Field, Form } from 'formik';

import badgeStatus from '~/constants/badgesStatus';
import createStatusSelectOptions from '~/services/utils/createStatusSelectOptions';
import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';

export default function SearchForm() {
  const { team_name, status, page_size } = getAllQueriesParams();

  return (
    <>
      <Formik
        initialValues={{
          formik_team_name: team_name,
          formik_status: status,
        }}
        onSubmit={({ formik_team_name, formik_status }) => {
          pushWithQueriesParams(history.location.pathname, {
            page: 1,
            page_size,
            team_name__icontains: formik_team_name,
            status: formik_status,
          });
        }}
      >
        {({ setFieldValue, handleSubmit, values, setValues }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={24}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Field
                  id="formik_team_name"
                  name="formik_team_name"
                  label="Equipe"
                  component={Input}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Field
                  id="formik_status"
                  name="formik_status"
                  label="Status"
                  component={Select}
                  onChange={formik_status => {
                    setFieldValue('formik_status', formik_status);
                  }}
                  data={createStatusSelectOptions([
                    badgeStatus.APPROVED,
                    badgeStatus.CANCELED,
                    badgeStatus.NOT_REQUESTED,
                    badgeStatus.DISAPPROVED,
                    badgeStatus.IN_ANALYSIS,
                    badgeStatus.IN_FINANCIAL_ANALYSIS,
                    badgeStatus.APPROVED_BY_FINANCIAL,
                    badgeStatus.REPROVED_BY_FINANCIAL,
                  ])}
                  renderItem={item => (
                    <AntdSelect.Option key={item.key} value={item.key}>
                      {item.value}
                    </AntdSelect.Option>
                  )}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Col>
                <Button
                  onClick={() => {
                    setValues({
                      formik_team_name: null,
                      formik_status: null,
                    });
                    pushWithQueriesParams(history.location.pathname, {
                      page: 1,
                      page_size,
                    });
                  }}
                >
                  Limpar
                </Button>

                <Divider type="vertical" />

                <Button
                  disabled={!values.formik_team_name && !values.formik_status}
                  htmlType="submit"
                  type="primary"
                  icon="search"
                >
                  Pesquisar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Divider />
    </>
  );
}
