import { useEffect, useState } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import ProductionInfo from './ProductionInfo';
import { getAllQueriesParams } from '~/services/history';
import api from '~/services/api';
import { WORK_MONTH_SUMMARY_GENERIC } from '~/constants/endpoints/work_month_summary';
import { BUDGET } from '~/constants/endpoints/budget';
import { EXPORT_PAYROLL_SHEET } from '~/constants/endpoints/export_payroll_sheet';

export async function production(year, month, setProductionLoading) {
  setProductionLoading(true);
  try {
    if (year && month) {
      const { data } = await api.get(WORK_MONTH_SUMMARY_GENERIC(year, month));
      setProductionLoading(false);
      return data;
    }
  } catch (error) {
    setProductionLoading(false);
    message.error('Erro ao carregar informações de productionInfo');
  }
}

export async function budget(verba) {
  const defaultResponse = {
    results: {},
  };
  try {
    const { data } = await api.get(BUDGET(verba));
    return data;
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        `Ocorreu um erro ao tentar carregar valor ${verba}`
      )
    );
    return defaultResponse;
  }
}

export function useProductionInfoData() {
  const { year, month } = getAllQueriesParams();

  const [productionInfo, setProductionInfo] = useState(undefined);
  const [productionLoading, setProductionLoading] = useState(false);

  useEffect(() => {
    async function LoadBudgetKind() {
      const infoData = await production(year, month, setProductionLoading);
      setProductionInfo(infoData);
    }

    LoadBudgetKind();
  }, [month, year]);

  return { productionInfo, productionLoading };
}

export function useBudget() {
  const [budgetLeader, setBudgetLeader] = useState(undefined);
  const [budgetPrepository, setBudgetPrepository] = useState(undefined);
  const [budgetLoading, setBudgetLoading] = useState(undefined);

  useEffect(() => {
    setBudgetLoading(true);
    const LoadBudgetKind = async () => {
      const verbaLiderData = await budget('verba_lider');
      const verbaPrepostoData = await budget('verba_preposto');
      setBudgetLeader(verbaLiderData);
      setBudgetPrepository(verbaPrepostoData);
      setBudgetLoading(false);
    };
    LoadBudgetKind();
  }, []);

  return {
    budgetLeader,
    setBudgetLeader,
    budgetPrepository,
    setBudgetPrepository,
    budgetLoading,
  };
}

export async function payrollSheet(
  year,
  month,
  team_id,
  setLoading,
  status,
  name
) {
  const defaultResponse = {
    results: {},
  };
  setLoading(true);
  try {
    const response = await api({
      url: EXPORT_PAYROLL_SHEET(year, month || ''),
      method: 'GET',
      responseType: 'blob',
      params: { status, person_name: name, team_id },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `folha-${month}-${year}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  } catch (error) {
    setLoading(false);
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Ocorreu um erro ao tentar realizar o download do arquivo.'
      )
    );
    return defaultResponse;
  }
}

export default ProductionInfo;
