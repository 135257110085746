import Parents from './Parents';

export function makeColumns() {
  return [
    {
      title: 'Grau de Parentesco',
      dataIndex: 'kinship',
      key: 'kinship',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 90,
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      ellipsis: true,
      width: 90,
    },
    {
      title: 'Data de Nascimento',
      dataIndex: 'birth_date',
      key: 'birth_date',
      ellipsis: true,
      width: 90,
    },
  ];
}

export default Parents;
