import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import safeRead from 'safely-read';
import moment from 'moment';
import { getQueryParam } from '~/services/history';
import api from '~/services/api';
import { GET_PERSON } from '~/constants/endpoints/person';
import UserTable from './UserTable';

export function makeColumns(setPersonModalVisible) {
  return [
    {
      title: 'Matrícula Csys',
      dataIndex: 'csys',
      key: 'csys',
      ellipsis: true,
      width: '12%',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'Cargo',
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
      width: '25%',
    },
    {
      title: 'Data de Início',
      dataIndex: 'start_date',
      key: 'start_date',
      ellipsis: true,
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: item => (
        <>
          <Button type="link" onClick={() => setPersonModalVisible(item)}>
            Detalhar
          </Button>
        </>
      ),
    },
  ];
}

export async function fetchTableData(setTableData, setLoading, queries) {
  setLoading(true);
  try {
    const { data } = await api.get(GET_PERSON, {
      params: queries,
    });
    setLoading(false);
    setTableData(data);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar dados da tabela')
    );
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const name = getQueryParam('name');
  const is_active = getQueryParam('is_active');
  const position = getQueryParam('position');
  const team = getQueryParam('team');
  const csys_registration_number = getQueryParam('csys_registration_number');

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        name,
        is_active,
        position,
        csys_registration_number,
        default_team: team,
      });
    }

    loadTableData();
  }, [
    page,
    page_size,
    name,
    is_active,
    position,
    team,
    csys_registration_number,
  ]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        name,
        is_active,
        position,
        csys_registration_number,
        default_team: team,
      }),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      key: safeRead(item, ['id']),
      csys: safeRead(item, ['csys_registration_number'], '--'),
      name: safeRead(item, ['name_display'], '--'),
      role: safeRead(item, ['position_display', 'position_display'], '--'),
      start_date: moment(item.start_date).format('DD/MM/YYYY'),
      is_active: safeRead(item, ['is_active'], '--'),
      is_active_display: safeRead(item, ['is_active_display'], '--'),
      action: safeRead(item, ['id'], '--'),
    }));
  }

  return [];
}

export default UserTable;
