import React from 'react';
import safeRead from 'safely-read';
import { HistoricalTable } from './style';
import { useTableData, makeColumns, formatTableData } from './index';
import { getAllQueriesParams } from '~/services/history';

export default function EdesenvHistoricalTable() {
  const { tableData, loadingTableData } = useTableData();
  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);
  const { page, page_size } = getAllQueriesParams();

  function ScrollableTable() {
    const slide = document.querySelector('.ant-table-body');
    let isDown = false;
    let startX;
    let scrollLeft;

    slide.addEventListener('mousedown', e => {
      isDown = true;
      slide.classList.add('on');
      startX = e.pageX - slide.offsetLeft;
      scrollLeft = slide.scrollLeft;
    });

    slide.addEventListener('mouseleave', () => {
      isDown = false;
      slide.classList.remove('on');
    });

    slide.addEventListener('mouseup', () => {
      isDown = false;
      slide.classList.remove('on');
    });

    slide.addEventListener('mousemove', e => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slide.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slide.scrollLeft = scrollLeft - walk;
    });
  }

  React.useEffect(() => {
    ScrollableTable();
  }, []);

  return (
    <>
      <HistoricalTable
        dataSource={formatTableData(tableRows)}
        columns={makeColumns}
        loading={loadingTableData}
        paginated
        currentPage={parseInt(page, 10) || 1}
        count={tableCount}
        pageSize={parseInt(page_size, 10) || 10}
        scroll={{ x: 1300 }}
      />
    </>
  );
}
