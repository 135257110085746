import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';

import api from '~/services/api';
import history, {
  getAllQueriesParams,
  getQueryParam,
  pushWithQueriesParams,
} from '~/services/history';
import { TEAM } from '~/constants/endpoints/team';
import Project from './Project';

export const ProjectContext = React.createContext();

export async function fetchTableData() {
  const queries = getAllQueriesParams();

  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, { params: { ...queries } });

    response = data;
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar dados da tabela')
    );
  }

  return response;
}

export function useDefaultQueries() {
  const page = parseInt(getQueryParam('page'), 10) || null;
  const page_size = parseInt(getQueryParam('page_size'), 10) || null;

  useEffect(() => {
    if (!page || !page_size) {
      pushWithQueriesParams(history.location.pathname, {
        page: 1,
        page_size: 10,
      });
    }
  }, [page, page_size]);

  return { page, page_size };
}

export function useTableData() {
  const page = parseInt(getQueryParam('page'), 10) || null;
  const page_size = parseInt(getQueryParam('page_size'), 10) || null;
  const status = getQueryParam('status');
  const team_name = getQueryParam('team_name__icontains');
  const cost_center = getQueryParam('cost_center__id');

  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  useEffect(() => {
    async function loadTableData() {
      setLoadingTableData(true);

      const data = await fetchTableData();

      setTableData(data);

      setLoadingTableData(false);
    }

    loadTableData();
  }, [page, page_size, status, team_name, cost_center]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
  };
}

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}

export default Project;
