import styled from 'styled-components';
import { Table } from 'antd';

export const Container = styled.div`
  .ant-table-header {
    background-color: transparent;
  }
`;

export const AntdTable = styled(Table)`
  .ant-table-body {
    cursor: grab;
  }

  .ant-table-body.on {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }

  .markedRow {
    background-color: #d6f3d7;
  }
  .ant-table-tbody > .markedRow > td {
    background-color: ${({ theme }) =>
      theme.displayMarkedInitialRow} !important;
  }

  .ant-table-tbody > .ant-table-row-selected > td {
    background-color: ${({ theme }) => theme.bodyBackground} !important;
  }
`;
