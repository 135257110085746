import { useState, useEffect } from 'react';
import { message } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import formatPrice from '~/services/utils/formatPrice';
import api from '~/services/api';
import ProductionControlTable from './ProductionControlTable';
import { PRODUCTION_CONTROL } from '~/constants/endpoints/production_control';
import { EXPORT_PRODUCTION_CONTROL_SHEET } from '~/constants/endpoints/export_production_control_sheet';
import { getQueryParam } from '~/services/history';

export const makeColumns = [
  {
    title: 'Matricula',
    dataIndex: 'matricula',
    key: 'matricula',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Valor/Hora',
    dataIndex: 'valorHora',
    key: 'valorHora',
    width: 110,
  },
  {
    title: 'Horas Previstas',
    dataIndex: 'horasPrevistas',
    key: 'horasPrevistas',
    width: 150,
  },
  {
    title: 'Horas Realizadas',
    dataIndex: 'horasRealizadas',
    key: 'horasRealizadas',
    width: 150,
  },
  {
    title: 'Banco de Horas Aprovadas',
    dataIndex: 'bancoHoraAprovada',
    key: 'bancoHoraAprovada',
    width: 150,
  },
  {
    title: 'Banco de Horas em Analise',
    dataIndex: 'bancoHoraAnalise',
    key: 'bancoHoraAnalise',
    width: 160,
  },
  {
    title: 'Banco de Horas não Solicitadas',
    dataIndex: 'bancoHoraNaoSolicitada',
    key: 'bancoHoraNaoSolicitada',
    width: 150,
  },
  {
    title: 'Horas Extras a Faturar',
    dataIndex: 'horaExtra',
    key: 'horaExtra',
    width: 130,
  },
  {
    title: 'Ausências a Descontar',
    dataIndex: 'ausenciaDescontar',
    key: 'ausenciaDescontar',
    width: 120,
  },
  {
    title: 'Ausências a Abonar',
    dataIndex: 'ausenciaAbonar',
    key: 'ausenciaAbonar',
    width: 120,
  },
];

export async function fetchTableData(setTableData, setLoading, queries) {
  setLoading(true);
  try {
    const { data } = await api.get(PRODUCTION_CONTROL, {
      params: queries,
    });
    setLoading(false);
    setTableData(data);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar dados da tabela')
    );
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const full_name = getQueryParam('full_name');
  const team_id = getQueryParam('team_id');
  const begin_date = getQueryParam('begin_date');
  const end_date = getQueryParam('end_date');
  const csys_registration_number = getQueryParam('csys_registration_number');

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        full_name,
        team_id,
        begin_date,
        end_date,
        csys_registration_number,
      });
    }

    loadTableData();
  }, [
    page,
    page_size,
    full_name,
    team_id,
    begin_date,
    end_date,
    csys_registration_number,
  ]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        full_name,
        team_id,
        begin_date,
        end_date,
        csys_registration_number,
      }),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      matricula: safeRead(item, ['csys_registration_number'], '--'),
      key: safeRead(item, ['csys_registration_number'], '--'),
      name: safeRead(item, ['full_name'], '--'),
      valorHora: formatPrice(item.hourly_rate),
      horasPrevistas: safeRead(item, ['expected_hours']),
      horasRealizadas: safeRead(item, ['realized_hours']),
      bancoHoraAprovada: safeRead(item, ['approved_hours']),
      bancoHoraAnalise: safeRead(item, ['pending_hours']),
      bancoHoraNaoSolicitada: safeRead(item, ['unsolicited_hours']),
      horaExtra: safeRead(item, ['paid_overtime']),
      ausenciaDescontar: safeRead(item, ['deducted_absence']),
      ausenciaAbonar: safeRead(item, ['allowed_absence']),
    }));
  }

  return [];
}

export async function productionControlSheet(
  full_name,
  begin_date,
  end_date,
  team_id,
  csys_registration_number,
  setLoading
) {
  const defaultResponse = {
    results: {},
  };
  setLoading(true);
  try {
    const response = await api({
      url: EXPORT_PRODUCTION_CONTROL_SHEET(
        full_name ? `&full_name=${full_name}` : '',
        begin_date ? `&begin_date=${begin_date}` : '',
        end_date ? `&end_date=${end_date}` : '',
        team_id ? `&team_id=${team_id}` : '',
        csys_registration_number
          ? `&csys_registration_number=${csys_registration_number}`
          : ''
      ),
      method: 'GET',
      responseType: 'blob',
    });

    const type = response.data.type.split('/');
    const day = moment(new Date()).date();

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `controle_de_produção-${begin_date ||
        moment(new Date())
          .subtract(day - 1, 'days')
          .format('YYYY-MM-DD')}-${end_date ||
        moment(new Date()).format('YYYY-MM-DD')}.${type[1]}`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    setLoading(false);
  } catch (error) {
    setLoading(false);
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Ocorreu um erro ao tentar realizar o download do arquivo.'
      )
    );
    return defaultResponse;
  }
}

export default ProductionControlTable;
