import React from 'react';
import { Badge } from 'antd';

import { common as theme } from '~/constants/theme';
import status from '~/constants/badgesStatus';

export default function renderBadge(item) {
  const {
    APPROVED,
    DISAPPROVED,
    IN_ANALYSIS,
    NOT_REQUESTED,
    CANCELED,
    ACTIVE,
    INACTIVE,
    IN_FINANCIAL_ANALYSIS,
    APPROVED_BY_FINANCIAL,
    REPROVED_BY_FINANCIAL,
  } = status;

  if (!item) {
    return null;
  }

  if (item.status === APPROVED) {
    return <Badge color={theme.successColor} text={item.status_display} />;
  }

  if (item.status === APPROVED_BY_FINANCIAL) {
    return <Badge color={theme.successColor} text={item.status_display} />;
  }

  if (item.status === IN_ANALYSIS) {
    return <Badge color={theme.warningColor} text={item.status_display} />;
  }

  if (item.status === IN_FINANCIAL_ANALYSIS) {
    return <Badge color={theme.warningColor} text={item.status_display} />;
  }

  if (item.status === DISAPPROVED) {
    return <Badge color={theme.errorColor} text={item.status_display} />;
  }

  if (item.status === CANCELED) {
    return <Badge color={theme.errorColor} text={item.status_display} />;
  }

  if (item.status === REPROVED_BY_FINANCIAL) {
    return <Badge color={theme.errorColor} text={item.status_display} />;
  }

  if (item.status === NOT_REQUESTED) {
    return <Badge color="gray" text={item.status_display} />;
  }

  if (item.status === ACTIVE) {
    return <Badge color={theme.successColor} text={item.status_display} />;
  }

  if (item.status === INACTIVE) {
    return <Badge color="gray" text={item.status_display} />;
  }

  return item.status_display;
}
