import styled from 'styled-components';
import { shade } from 'polished';
import { Button } from 'antd';

export const GrossPaymentHolder = styled.div`
  margin: 16px 0 !important;
  padding: 12px;
  align-items: center;
  width: 100%;

  background-color: ${({ theme }) => shade(0.04, theme.componentBackground)};
  border-radius: ${({ theme }) => theme.borderRadiusBase};
`;

export const WarningButton = styled(Button)`
  background-color: ${({ theme }) => theme.warningColor};
  border-color: ${({ theme }) => theme.borderColorBase};
  color: ${({ theme }) => theme.white};

  --antd-wave-shadow-color: ${({ theme }) => theme.warningColor};

  &:hover {
    background: rgb(250, 173, 19, 0.8);
    border-color: ${({ theme }) => theme.borderColorBase};
    color: ${({ theme }) => theme.white};
  }

  &:active {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.warningColor};
    border-color: ${({ theme }) => theme.borderColorBase};
    background: rgb(250, 173, 19, 0.3);
  }
  &:focus {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.warningColor};
    border-color: ${({ theme }) => theme.borderColorBase};
  }
`;
