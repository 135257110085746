import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Container, Title, Description } from './styles';
import history from '~/services/history';

import messages from './messages';

export default function NotFound() {
  return (
    <Container>
      <Title>404</Title>
      <Description>
        <h1>
          <b>
            <FormattedMessage {...messages.pageNotFound} />
          </b>
        </h1>
        <FormattedMessage {...messages.brokenOrRemoved} />
        <br />
        <FormattedMessage {...messages.checkLink} />
      </Description>
      <br />
      <Link to="/">
        <Button type="primary">
          <FormattedMessage {...messages.goHome} />
        </Button>
      </Link>
      <br />
      <Button type="link" onClick={history.goBack}>
        <FormattedMessage {...messages.goBack} />
      </Button>
    </Container>
  );
}
