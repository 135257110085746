import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Typography, Alert } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import { ItemContainer, Item } from './styles';
import { makeColumns, formatTableData } from './index';
import DetailModal from '../DetailModal';
import AbsenceSolicitationModal from '../SolicitationModal/AbsenceSolicitationModal';
import PaidAbsenceSolicitationModal from '../SolicitationModal/PaidAbsenceSolicitationModal';
import OvertimeSolicitationModal from '../SolicitationModal/OvertimeSolicitationModal';
import MultipleActionButton from '~/components/MultipleActionButton';
import formatPrice from '~/services/utils/formatPrice';
import { CollapsePanelContext } from '../../CollapseList/index';

export default function ManageAbsenceOvertimeModal({
  manageAbsenceOvertimeModalVisible,
  setManageAbsenceOvertimeModalVisible,
}) {
  const {
    user,
    compensatoryTime,
    year,
    month,
    tableData,
    loadingTableData,
    pageTable,
    page_sizeTable,
  } = React.useContext(CollapsePanelContext);
  const { Text, Title } = Typography;

  const [
    absenceSolicitationModalVisible,
    setAbsenceSolicitationModalVisible,
  ] = React.useState(false);

  const [
    paidAbsenceSolicitationModalVisible,
    setPaidAbsenceSolicitationModalVisible,
  ] = React.useState(false);

  const [
    overtimeSolicitationModalVisible,
    setOvertimeSolicitationModalVisible,
  ] = React.useState(false);

  const [detailModalVisible, setDetailModalVisible] = React.useState(false);
  const [payableRequestKey, setPayableRequestKey] = React.useState(undefined);

  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);
  const hours = safeRead(compensatoryTime, ['balance']);

  const typesSolicitationToSelect = [
    // {
    //   title: 'Ausência a abonar',
    //   // action: () => {
    //   //   setPaidAbsenceSolicitationModalVisible(true);
    //   // },
    // },
    {
      title: 'Ausência a descontar',
      action: () => {
        setAbsenceSolicitationModalVisible(true);
      },
    },
    {
      title: 'Hora extra a faturar',
      action: () => {
        setOvertimeSolicitationModalVisible(true);
      },
    },
  ];

  const optionsSelect = useMemo(() => {
    const formatHour = hours.substring(1);

    const hour = Number(formatHour.split(':')[0]);
    const minutes = Number(formatHour.split(':')[1]);

    if ((hour === 0 && minutes < 60) || hours.charAt(0) === '00:00') {
      return [];
    }

    if (hours.charAt(0) === '+') {
      return typesSolicitationToSelect.filter(
        item => item.title !== 'Ausência a descontar'
      );
    }
    return typesSolicitationToSelect.filter(
      item => item.title === 'Ausência a descontar'
    );
  }, [typesSolicitationToSelect, hours]);

  const available = useMemo(() => {
    return safeRead(compensatoryTime, ['available_balance']) !== null;
  }, [compensatoryTime]);

  return (
    <Modal
      visible={manageAbsenceOvertimeModalVisible}
      onCancel={() => setManageAbsenceOvertimeModalVisible(false)}
      footer={null}
      width={1000}
      closable
      destroyOnClose
      title={<Title level={4}>Gerenciar Ausências e Horas extras</Title>}
    >
      <ItemContainer>
        <Item width={available ? '25%' : '30%'}>
          <Text strong style={{ display: 'block' }}>
            Usuário
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['person_display', 'name_display'])}
          </Text>
        </Item>

        <Item width={available ? '15%' : '20%'}>
          <Text strong style={{ display: 'block' }}>
            Mês/Ano
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {`${moment(`${year}-${month}`).format('MMMM/YYYY')}`}
          </Text>
        </Item>

        <Item width={available ? '23%' : '30%'}>
          <Text strong style={{ display: 'block' }}>
            Banco de Horas aprovadas
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(compensatoryTime, ['balance'])}
          </Text>
        </Item>

        {available && (
          <Item width="20%">
            <Text strong style={{ display: 'block' }}>
              Horas Disponíveis
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {safeRead(compensatoryTime, ['available_balance'])}
            </Text>
          </Item>
        )}

        <Item width="15%">
          <Text strong style={{ display: 'block' }}>
            Valor/Hora
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['general', 'hourly_rate'], '--', rate =>
              formatPrice(rate)
            )}
          </Text>
        </Item>
      </ItemContainer>
      {moment().format('MM/YYYY') === `${month}/${year}` ? (
        <div>
          {optionsSelect.length !== 0 && (
            <MultipleActionButton actions={optionsSelect}>
              Nova Solicitação
            </MultipleActionButton>
          )}
        </div>
      ) : null}
      {tableRows.length !== 0 ? (
        <>
          {tableRows.some(item => item.request_type === 'ausencia_a_abonar') ? (
            <div style={{ paddingTop: 10 }}>
              <Text strong style={{ display: 'block' }}>
                Ausência a abonar
              </Text>
              <Table
                dataSource={formatTableData(
                  tableRows.filter(
                    item => item.request_type === 'ausencia_a_abonar'
                  )
                )}
                columns={makeColumns(
                  setDetailModalVisible,
                  setPayableRequestKey
                )}
                loading={loadingTableData}
                paginated
                currentPage={parseInt(pageTable, 10) || 1}
                count={tableCount}
                pageSize={parseInt(page_sizeTable, 10) || 10}
              />
            </div>
          ) : null}

          {tableRows.some(
            item => item.request_type === 'ausencia_a_descontar'
          ) ? (
            <div style={{ paddingTop: 10 }}>
              <Text strong style={{ display: 'block' }}>
                Ausência a descontar
              </Text>
              <Table
                dataSource={formatTableData(
                  tableRows.filter(
                    item => item.request_type === 'ausencia_a_descontar'
                  )
                )}
                columns={makeColumns(
                  setDetailModalVisible,
                  setPayableRequestKey
                )}
                loading={loadingTableData}
                paginated
                currentPage={parseInt(pageTable, 10) || 1}
                count={tableCount}
                pageSize={parseInt(page_sizeTable, 10) || 10}
              />
            </div>
          ) : null}

          {tableRows.some(
            item => item.request_type === 'hora_extra_a_faturar'
          ) ? (
            <div style={{ paddingTop: 10 }}>
              <Text strong style={{ display: 'block' }}>
                Hora extra a faturar
              </Text>
              <Table
                dataSource={formatTableData(
                  tableRows.filter(
                    item => item.request_type === 'hora_extra_a_faturar'
                  )
                )}
                columns={makeColumns(
                  setDetailModalVisible,
                  setPayableRequestKey
                )}
                loading={loadingTableData}
                paginated
                currentPage={parseInt(pageTable, 10) || 1}
                count={tableCount}
                pageSize={parseInt(page_sizeTable, 10) || 10}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div style={{ paddingTop: 20 }}>
          <Alert
            message="Este cooperado não possui pedidos de ausências ou horas extras."
            type="info"
            showIcon
          />
        </div>
      )}

      {detailModalVisible ? (
        <DetailModal
          detailModalVisible={detailModalVisible}
          setDetailModalVisible={setDetailModalVisible}
          payableRequestKey={payableRequestKey}
        />
      ) : null}

      {absenceSolicitationModalVisible ? (
        <AbsenceSolicitationModal
          absenceSolicitationModalVisible={absenceSolicitationModalVisible}
          setAbsenceSolicitationModalVisible={
            setAbsenceSolicitationModalVisible
          }
        />
      ) : null}

      {paidAbsenceSolicitationModalVisible ? (
        <PaidAbsenceSolicitationModal
          paidAbsenceSolicitationModalVisible={
            paidAbsenceSolicitationModalVisible
          }
          setPaidAbsenceSolicitationModalVisible={
            setPaidAbsenceSolicitationModalVisible
          }
        />
      ) : null}

      {overtimeSolicitationModalVisible ? (
        <OvertimeSolicitationModal
          overtimeSolicitationModalVisible={overtimeSolicitationModalVisible}
          setOvertimeSolicitationModalVisible={
            setOvertimeSolicitationModalVisible
          }
        />
      ) : null}
    </Modal>
  );
}

ManageAbsenceOvertimeModal.propTypes = {
  manageAbsenceOvertimeModalVisible: PropTypes.bool.isRequired,
  setManageAbsenceOvertimeModalVisible: PropTypes.func.isRequired,
};
