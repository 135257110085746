// Actions

// SIGN
export const SIGN_IN_REQUEST = '@portal/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@portal/SIGN_IN_SUCCESS';
export const SIGN_UP_REQUEST = '@portal/SIGN_UP_REQUEST';
export const SIGN_FAILURE = '@portal/SIGN_IN_FAILURE';
export const TOGGLE_KEEP_SIGNED = '@portal/TOGGLE_KEEP_SIGNED';

// SIGNOUT
export const SIGN_OUT = '@portal/SIGN_OUT';

// PERSIST AUTHENTICATION
export const REFRESH_TOKEN_INTERCEPTOR_REQUEST =
  '@portal/REFRESH_TOKEN_INTERCEPTOR_REQUEST';
export const REFRESH_TOKEN_SUCCESS = '@portal/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = '@portal/REFRESH_TOKEN_FAILURE';

// Actions creators
export function signInRequest(username, password, recaptcha) {
  return {
    type: SIGN_IN_REQUEST,
    payload: { username, password, recaptcha },
  };
}

export function signInSuccess(access, refresh) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { access, refresh },
  };
}

export function signUpRequest(name, email, password) {
  return {
    type: SIGN_UP_REQUEST,
    payload: { name, email, password },
  };
}

export function signFailure(errorcode) {
  return {
    type: SIGN_FAILURE,
    payload: { errorcode },
  };
}

export function refreshTokenInterceptorRequest(access) {
  return {
    type: REFRESH_TOKEN_INTERCEPTOR_REQUEST,
    payload: { access },
  };
}

export function refreshTokenSuccess(access, refresh) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: { access, refresh },
  };
}

export function refreshTokenFailure() {
  return {
    type: REFRESH_TOKEN_FAILURE,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export function toggleKeepSigned() {
  return {
    type: TOGGLE_KEEP_SIGNED,
  };
}
