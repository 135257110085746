import styled from 'styled-components';
import { Table } from 'antd';

export const AntdTable = styled(Table)`
  .ant-row {
    margin-top: 3px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
  }
`;
