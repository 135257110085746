import { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import { TEAM } from '~/constants/endpoints/team';
import { PAYABLE_REQUEST } from '~/constants/endpoints/payable_request';

export function CalcValue(valueTime, value, setCalculate) {
  const result = valueTime * value;
  setCalculate(result);
}

export function useSearchTeams() {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(undefined);
  const [teamsResponse, setTeamsResponse] = useState(null);

  useEffect(() => {
    async function searchTeams() {
      setLoading(true);

      const { data } = await api.get(TEAM, {
        params: {
          page_size: 10,
          team_name__icontains: search,
        },
      });

      setTeamsResponse(data.results);

      setLoading(false);
    }

    searchTeams();
  }, [search]);

  return {
    teamsResponse,
    setTeamsResponse,
    setSearch,
    loading,
  };
}

export async function PostPayableRequest(
  key,
  values,
  request_type,
  setVisible,
  calculate
) {
  const value_ = safeRead(values, ['value']);
  const value_hour = parseFloat(calculate || 0).toFixed(2);
  const hours = safeRead(values, ['hours']);
  const reason = safeRead(values, ['reason']);
  const person = key;
  const team = safeRead(values, ['team']);
  const value = value_ || value_hour;

  try {
    await api.post(PAYABLE_REQUEST, {
      value,
      hours,
      reason,
      person,
      request_type,
      team,
    });
    message.success('Solicitação realizada com sucesso');
    setVisible(false);
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Não foi possivel realizar a solicitação.'
      )
    );
    setVisible(false);
  }
}
