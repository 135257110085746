import { message } from 'antd';
import safeRead from 'safely-read';
import Financial from './Financial';
import { PATCH_PERSON_BUDGET } from '~/constants/endpoints/person';
import api from '~/services/api';

export async function PatchPersonBudgetLeader(id, values) {
  const verba_lider = safeRead(values, ['verba_lider']);

  try {
    await api.patch(PATCH_PERSON_BUDGET(id, 'verba_lider'), {
      has: verba_lider,
    });
    message.success({
      key: 'user-patch-success',
      content: 'Cadastro alterado com sucesso.',
    });
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['detail'],
        'Não foi possivel atualizar cadastro de Verba líder.'
      )
    );
  }
}

export async function PatchPersonBudgetPrepository(id, values) {
  const verba_preposto = safeRead(values, ['verba_preposto']);

  try {
    await api.patch(PATCH_PERSON_BUDGET(id, 'verba_preposto'), {
      has: verba_preposto,
    });
    message.success({
      key: 'user-patch-success',
      content: 'Cadastro alterado com sucesso.',
    });
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['detail'],
        'Não foi possivel atualizar cadastro de Verba preposto.'
      )
    );
  }
}

export function readBudgets(budgets_display) {
  const [a, b] = budgets_display;

  if (!a && !b) {
    return {
      verba_lider: null,
      verba_preposto: null,
    };
  }

  if (!b) {
    return {
      [a.budget_kind]: a,
    };
  }

  return {
    [a.budget_kind]: a,
    [b.budget_kind]: b,
  };
}

export default Financial;
