import styled from 'styled-components';
import { darken, shade } from 'polished';


export function getColor(days) {
  const colors = {
    green: 'seaGreen',
    nulo: '#7E7E7E',
  };
  
  if (days === undefined) {
    return colors.nulo;
  }
  
  if (days === false ) {
    return colors.nulo;
  }
  
  if (days === true ) {
    return colors.green;
  }
  
  return colors.green;
}

export const TimelineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;

  border: 2px solid ${({ theme }) => darken(0.02, theme.componentBackground)};
  background-color: ${({ theme }) => darken(0.01, theme.componentBackground)};
  border-radius: 4px;
  padding: 10px 14px;

  @media (max-width: 1200px) {
    min-width: 983px;
  }
`;

export const TimelineItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  width: 100%;

  &:after {
    content: ' ';
    position: absolute;
    height: 70px;
    right: 0;
    border-right: 1px solid ${({ theme }) => darken(0.1, theme.borderColorBase)};
    bottom: 0;
    display: ${({ last }) => (last ? 'none' : 'block')};
  }
`;

export const TimelineItemTitle = styled.div`
  text-transform: uppercase;
`;

export const TimelineItemContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 14px 0 14px 0;
  background-color: ${({days}) => getColor(days)};
  width: 100%;
  height: 40px;
  color: white !important;
  border-bottom-left-radius: ${({ first }) => (first ? '40px' : null)};
  border-top-left-radius: ${({ first }) => (first ? '40px' : null)};
  border-bottom-right-radius: ${({ last }) => (last ? '40px' : null)};
  border-top-right-radius: ${({ last }) => (last ? '40px' : null)};
`;

export const Container = styled.div`
  padding: 12px;
  margin: 16px 0 !important;
  background-color: ${({ theme }) => shade(0.04, theme.componentBackground)};
  border-radius: ${({ theme }) => theme.borderRadiusBase};

  .info-title {
    color: ${({ theme }) => theme.textColor};
    font-size:16px;
  }

  .info-description {
    color: seaGreen;
    font-size: 16px;
    font-weight: bold;
  }
`;