import styled from 'styled-components';

export const Container = styled.div`
  padding: 48px;
  width: 100%;

  align-items: center;
  justify-content: center;
`;
export const ContainerImage = styled.div`
  align-items: center;
  justify-content: center;
  margin: auto;
  display: flex;
`;

export const LogoImg = styled.img`
  bottom: 18px;
  height: 450px;
  width: 550px;
  margin-top: 80px;
`;
