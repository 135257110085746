import React, { useState } from 'react';

import { useTableData, ProfileContext, fetchTableData } from './index';
import PageContainer from '~/components/Layout/PageContainer';
import ProfileTable from './ProfileTable';
import RegisterOrEditModal from './Modals/RegisterOrEdit';
import AddUser from './Modals/AddUser';

export default function ProfileAndPermissions() {
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [currentRow, setCurrentRow] = useState(undefined);
  const [groupEditMode, setGroupEditMode] = useState(false);
  const [registerOrEditModalVisible, setRegisterOrEditModalVisible] = useState(
    false
  );
  const {
    tableData,
    loadingTableData,
    setLoadingTableData,
    setTableData,
  } = useTableData();

  return (
    <PageContainer title="Grupos e Permissões">
      <ProfileContext.Provider
        value={{
          fetchTableData: () =>
            fetchTableData(setLoadingTableData, setTableData),
          setCurrentRow,
          currentRow,
          setGroupEditMode,
          groupEditMode,
        }}
      >
        <ProfileTable
          tableData={tableData}
          loadingTableData={loadingTableData}
          setAddUserModalVisible={setAddUserModalVisible}
          setRegisterOrEditModalVisible={setRegisterOrEditModalVisible}
        />

        {registerOrEditModalVisible ? (
          <RegisterOrEditModal
            visible={registerOrEditModalVisible}
            setVisible={setRegisterOrEditModalVisible}
          />
        ) : null}

        {addUserModalVisible ? (
          <AddUser
            visible={addUserModalVisible}
            setVisible={setAddUserModalVisible}
          />
        ) : null}
      </ProfileContext.Provider>
    </PageContainer>
  );
}
