import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { message, Typography, Button } from 'antd';
import safeRead from 'safely-read';

import history, {
  getAllQueriesParams,
  getQueryParam,
  pushWithQueriesParams,
} from '~/services/history';
import { StatusCircle, CircleContainer } from './styles';
import api from '~/services/api';
import {
  PUT_RECTIFICATION_APPROVAL,
  RECTIFICATION_APPROVAL,
} from '~/constants/endpoints/rectification_approval';
import { TEAM } from '~/constants/endpoints/team';

import formatOrdinal from '~/services/utils/formatOrdinal';
import messages from './messages';

export const approvalModalTypes = {
  approve: 'aprovar',
  disapprove: 'recusar',
};

export const from = {
  detail: 'detail',
  table: 'table',
};

export async function fetchRectificationApproval({ setData, setLoading }) {
  let apiData = { results: [] };

  const { page, page_size, ...queries } = getAllQueriesParams();

  setLoading(true);

  try {
    const { data } = await api.get(RECTIFICATION_APPROVAL, {
      params: {
        page: page || 1,
        page_size: page_size || 10,
        ordering: '-id',
        ...queries,
      },
    });

    setData(data);

    apiData = data;
  } catch (error) {
    let errorMessage;

    if (safeRead(error, ['response', 'data', 'detail'], null)) {
      errorMessage = safeRead(error, ['response', 'data', 'detail'], null);
    } else if (safeRead(error, ['response', 'data', 'date_end'], null)) {
      errorMessage = safeRead(error, ['response', 'data', 'date_end'], null);
    } else if (safeRead(error, ['response', 'data', 'date_start'], null)) {
      errorMessage = safeRead(error, ['response', 'data', 'date_start'], null);
    } else {
      errorMessage = 'Erro ao carregar dados da tabela';
    }

    message.error(errorMessage);

    if (errorMessage === 'Página inválida.') {
      pushWithQueriesParams(history.location.pathname, {
        page: 1,
        page_size,
        ...queries,
      });
    }
  }

  setLoading(false);

  return apiData;
}

export function useTableData() {
  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const name = getQueryParam('name');
  const date_start = getQueryParam('date_start');
  const date_end = getQueryParam('date_end');
  const status = getQueryParam('status');
  const id = getQueryParam('id');
  const ordering = getQueryParam('ordering');
  const team = getQueryParam('team');

  const [tableData, setTableData] = useState({ results: [] });
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    async function loadTableData() {
      const data = await fetchRectificationApproval({
        setData: setTableData,
        setLoading: setLoadingTableData,
      });

      const { count } = data;

      setTotalPages(count);
    }

    loadTableData();
  }, [name, page, page_size, status, id, date_start, date_end, ordering, team]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    totalPages,
    setTotalPages,
    page_size,
    page,
    name,
    date_start,
    date_end,
    status,
    id,
  };
}

export function formatTableData(data) {
  const { Text } = Typography;

  return data.map(item => ({
    ...item,
    key: safeRead(item, ['id'], null),
    disapproval_reason: safeRead(item, ['disapproval_reason'], '--'),
    status_display: (
      <CircleContainer>
        <StatusCircle status={safeRead(item, ['status'], '--')} />{' '}
        <Text>{safeRead(item, ['status_display'], '--')}</Text>
      </CircleContainer>
    ),
    status: safeRead(item, ['status'], '--'),
    raw_status_display: safeRead(item, ['status_display'], '--'),
    name: safeRead(item, ['person', 'name_display'], '--'),
    created_at: safeRead(item, ['worktime_clock'], '--', date =>
      moment(date).format('DD/MM/YYYY')
    ),
    reason: safeRead(item, ['reason'], '--'),
    daily_production: !item.previous ? (
      <Text>
        {formatOrdinal(item.position, 'female')} {item.check_in_display}{' '}
        <FormattedMessage {...messages.from} />{' '}
        <strong>
          <FormattedMessage {...messages.empty} />
        </strong>{' '}
        <FormattedMessage {...messages.to} />{' '}
        <strong>{moment(item.worktime_clock).format('HH:mm')}</strong>
      </Text>
    ) : (
      <Text>
        {formatOrdinal(item.position, 'female')}{' '}
        {item.previous.check_in_display} <FormattedMessage {...messages.from} />{' '}
        <strong>{moment(item.previous.worktime_clock).format('HH:mm')}</strong>{' '}
        <FormattedMessage {...messages.to} />{' '}
        <strong>{moment(item.worktime_clock).format('HH:mm')}</strong>
      </Text>
    ),
  }));
}

export async function handleRectificationsApproval(
  rectifications = [],
  status,
  disapproval_reason
) {
  const promises = rectifications.map(rectification => {
    async function approve() {
      try {
        await api.put(PUT_RECTIFICATION_APPROVAL(rectification), {
          status,
          disapproval_reason,
        });
        message.success(
          status === 'aprovado'
            ? 'Rasura aprovada com sucesso.'
            : 'Rasura reprovada com sucesso.'
        );
      } catch (error) {
        message.error(
          status === 'aprovado'
            ? safeRead(
                error,
                ['response', 'data', 'detail'],
                `Ocorreu um erro ao aprovar rasura ${rectification}.`
              )
            : safeRead(
                error,
                ['response', 'data', 'detail'],
                `Ocorreu um erro ao reprovar rasura ${rectification}.`
              )
        );
      }
    }

    return approve();
  });

  await Promise.all(promises);
}

export function makeColumns(
  formatMessage,
  setDetailRowData,
  setDetailModalVisible
) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 120,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: formatMessage(messages.rectificationDateLabel),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 140,
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: formatMessage(messages.nameLabel),
      dataIndex: 'name',
      key: 'first_name,last_name',
      width: 160,
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: formatMessage(messages.registerLabel),
      dataIndex: 'daily_production',
      key: 'daily_production',
      ellipsis: true,
      width: 260,
    },
    {
      title: formatMessage(messages.statusLabel),
      dataIndex: 'status_display',
      key: 'status_display',
      ellipsis: true,
      width: 200,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 200,
      render: (_, rowData) => (
        <Button
          onClick={() => {
            setDetailRowData(rowData);
            setDetailModalVisible(true);
          }}
          type="link"
        >
          Detalhes
        </Button>
      ),
    },
  ];
}

export async function searchProjects(name) {
  let response = {
    count: 1,
    next: null,
    previous: null,
    results: [],
  };

  try {
    const { data } = await api.get(TEAM, {
      params: { page: 1, page_size: 10, team_name__icontains: name },
    });

    response = data;
  } catch (error) {
    message.error(safeRead(error, ['detail'], 'Erro ao buscar equipes'));
  }

  return response;
}

export function useSearchProject() {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [search, setSearch] = useState(undefined);
  const [projects, setProjects] = useState({
    count: 1,
    next: null,
    previous: null,
    results: [],
  });

  useEffect(() => {
    async function loadProjects() {
      setLoadingProjects(true);

      const data = await searchProjects(search);

      setProjects(data);

      setLoadingProjects(false);
    }

    loadProjects();
  }, [search]);

  return {
    search,
    setSearch,
    loadingProjects,
    projects,
  };
}
