import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import moment from 'moment';
import debounce from 'lodash.debounce';
import Select from '~/components/DataEntry/Select';
import checkPermission from '~/services/utils/checkPermission';
import Input from '~/components/DataEntry/Input';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { MonthPicker } from '~/components/DataEntry/DatePicker';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
  getQueryParam,
} from '~/services/history';
import {
  useConsolidatedProduction,
  useSearchProject,
} from '../CollapseList/index';

export default function SearchForm() {
  const { loadingProjects, projects, setSearch } = useSearchProject();
  const queries = getAllQueriesParams();

  const onMonthYearChange = e =>
    pushWithQueriesParams(history.location.pathname, {
      ...queries,
      year: e.format('YYYY'),
      month: e.format('MM'),
    });

  const month =
    !getQueryParam('month') ||
    parseInt(getQueryParam('month'), 10) > 12 ||
    parseInt(getQueryParam('month'), 10) < 1
      ? null
      : getQueryParam('month');

  const year = getQueryParam('year') || null;
  const mountedDate =
    month && year
      ? `${year}-${month}`
      : `${moment().format('YYYY')}-${moment().format('MM')}`;

  const { loading } = useConsolidatedProduction(year, month);

  return (
    <>
      {checkPermission('view_consolidatedproduction') && (
        <Formik
          initialValues={{
            mesAno: mountedDate,
            nameField: queries.name,
            statusField: queries.status,
            teamField: queries.team,
          }}
          onSubmit={values => {
            pushWithQueriesParams(history.location.pathname, {
              year,
              month,
              name: values.nameField,
              status: values.statusField,
              team: values.teamField,
            });
          }}
        >
          {({ handleSubmit, values, setFieldValue, setValues }) => (
            <Form onSubmit={handleSubmit}>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Field
                    name="mesAno"
                    component={MonthPicker}
                    label="Mês/Ano"
                    format="MMMM / YYYY"
                    onChange={onMonthYearChange}
                    allowClear={false}
                    style={{ width: '100%' }}
                    disabled={loading === 'yes'}
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Field
                    id="teamField"
                    name="teamField"
                    label="Equipe"
                    loading={loadingProjects}
                    component={AutoCompleteSelect}
                    placeholder=" "
                    selectSchema={item => (
                      <AntdSelect.Option key={item.id} value={item.id}>
                        {item.team_name}
                      </AntdSelect.Option>
                    )}
                    onChange={e => {
                      if (!e) {
                        setFieldValue('teamField', null);
                      } else {
                        setFieldValue('teamField', e);
                      }

                      setSearch(undefined);
                    }}
                    search={debounce(setSearch, 800)}
                    allowClear
                    data={projects.results}
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Field
                    id="nameField"
                    name="nameField"
                    label="Nome"
                    component={Input}
                    disabled={loading === 'yes'}
                  />
                </Col>

                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Field
                    id="statusField"
                    name="statusField"
                    label="Status"
                    component={Select}
                    disabled={loading === 'yes'}
                    data={[
                      { id: 'Em Análise', value: 'em_analise' },
                      { id: 'Aprovado', value: 'aprovado' },
                    ]}
                    renderItem={item => (
                      <AntdSelect.Option key={item.id} value={item.value}>
                        {item.id}
                      </AntdSelect.Option>
                    )}
                    allowClear
                  />
                </Col>
              </Row>

              <Row type="flex" justify="end">
                <Col>
                  <Button
                    onClick={() => {
                      setValues({
                        mesAno: `${moment().format('YYYY')}-${moment().format(
                          'MM'
                        )}`,
                        nameField: null,
                        statusField: null,
                      });
                      pushWithQueriesParams(history.location.pathname, {
                        // page: 1,
                        // page_size: 10,
                      });
                    }}
                    disabled={loading === 'yes'}
                  >
                    Limpar
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    disabled={
                      (!values.dateField &&
                        !values.nameField &&
                        !values.teamField &&
                        !values.statusField) ||
                      loading === 'yes'
                    }
                    htmlType="submit"
                    type="primary"
                    icon="search"
                  >
                    Pesquisar
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      <Divider />
    </>
  );
}
