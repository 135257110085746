import React, { useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Modal, Divider } from 'antd';
import { Formik, Field, Form } from 'formik';

import Input from '~/components/DataEntry/Input';
import { AbsenceOvertimeContext, filterSelectedRowKeys } from '../../index';

export default function ConfirmationModal({ visible, setVisible }) {
  const {
    confirmationType,
    patchRequestApproval,
    itemsToUpdateStatus,
    fetchTableData,
    setSelectedRowKeys,
    selectedRowRequestTypes,
    setSelectedRowRequestTypes,
    from,
    selectedRowKeys,
    setDetailModalVisible,
  } = useContext(AbsenceOvertimeContext);

  const [loading, setLoading] = useState(false);
  const { Title } = Typography;

  const titleShow = useMemo(() => {
    if (confirmationType === 'aprovado') {
      return 'Aprovar';
    }
    if (confirmationType === 'aprovado_pelo_financeiro') {
      return 'Abonar';
    }
    return 'Reprovar';
  }, [confirmationType]);

  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <Title level={4}>{`${titleShow} itens selecionados`}</Title>

      {!confirmationType.includes('aprovado') ? (
        <Formik
          validateOnBlur={false}
          initialValues={{ disapproval_reason: undefined }}
          onSubmit={async ({ disapproval_reason }) => {
            setLoading(true);
            await patchRequestApproval(
              itemsToUpdateStatus,
              confirmationType,
              disapproval_reason,
              selectedRowRequestTypes,
            );
            fetchTableData();

            if (from === 'tableActions') {
              setSelectedRowKeys([]);
              setSelectedRowRequestTypes([]);
            } else {
              const resultKeys = filterSelectedRowKeys(
                selectedRowKeys,
                itemsToUpdateStatus[0]
              );
              const resultTypes = filterSelectedRowKeys(
                selectedRowRequestTypes,
                itemsToUpdateStatus[0]
              );
              setSelectedRowKeys(resultKeys);
              setSelectedRowRequestTypes(resultTypes);
            }

            setLoading(false);
            setVisible(false);
            setDetailModalVisible(false);
          }}
        >
          {({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Field
                id="disapproval_reason-input"
                name="disapproval_reason"
                label="Motivo*"
                type="textarea"
                component={Input}
              />

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginTop: 40,
                }}
              >
                <Button onClick={() => setVisible(false)}>Cancelar</Button>
                <Divider type="vertical" />
                <Button
                  disabled={!values.disapproval_reason}
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                >
                  Ok
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 40,
          }}
        >
          <Button onClick={() => setVisible(false)}>Cancelar</Button>
          <Divider type="vertical" />
          <Button
            onClick={async () => {
              setLoading(true);
              await patchRequestApproval(itemsToUpdateStatus, confirmationType, undefined, selectedRowRequestTypes);
              fetchTableData();

              if (from === 'tableActions') {
                setSelectedRowKeys([]);
              } else {
                const resultKeys = filterSelectedRowKeys(
                  selectedRowKeys,
                  itemsToUpdateStatus[0]
                );
                const resultTypes = filterSelectedRowKeys(
                  selectedRowRequestTypes,
                  itemsToUpdateStatus[0]
                );
                setSelectedRowKeys(resultKeys);
                setSelectedRowRequestTypes(resultTypes);
              }

              setLoading(false);
              setVisible(false);
              setDetailModalVisible(false);
            }}
            loading={loading}
            type="primary"
          >
            Ok
          </Button>
        </div>
      )}
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
