/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import moment from 'moment';
import {
  Button,
  Divider,
  Typography,
  Select as AntdSelect,
  Spin,
  Alert,
} from 'antd';
import safeRead from 'safely-read';

import Select from '~/components/DataEntry/Select';
import DatePicker from '~/components/DataEntry/DatePicker';
import Checkbox from '~/components/DataEntry/Checkbox';

import {
  fieldType,
  getTotalBalance,
  postPaidLeaveRequest,
  calculateLastDayOffOrVacations,
} from '../index';
import { VacationsContext } from '../../../index';
import { BackgroundContainer, DateContainer } from '../styles';

const { Text } = Typography;

function PaidLeave({
  type,
  lastDayOfVacationsOfDayOff,
  setLastDayOfVacationsOfDayOff,
  lastDayOfVacationsOfDayOffLoading,
  setLastDayOfVacationsOfDayOffLoading,
  setVisible,
  referencePeriod,
  values,
  setFieldValue,
  setSubmitting,
  isSubmitting,
}) {
  const { total_days } = values;

  const { updatePaidLeave, paidLeave, paidLeaveLoading } = useContext(
    VacationsContext
  );

  const situation = safeRead(paidLeave, ['timeline', 'situation']);

  useEffect(() => {
    if (values.begin_date && values.total_days) {
      calculateLastDayOffOrVacations(
        values.begin_date,
        values.total_days,
        setLastDayOfVacationsOfDayOff,
        setLastDayOfVacationsOfDayOffLoading
      );
    }
  }, [values.begin_date, values.total_days]);

  useEffect(() => {
    setFieldValue('total_days', undefined);
    setFieldValue('begin_date', undefined);
    setFieldValue('receive_advance_money', false);
  }, [referencePeriod]);

  return (
    <>
      {paidLeaveLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {situation === 'ABLE' && (
            <>
              <BackgroundContainer>
                <Text strong>Saldo atual</Text>

                <Text strong>
                  {`${getTotalBalance(
                    safeRead(paidLeave, ['timeline', 'balance']),
                    type
                  )}`}{' '}
                  dias
                </Text>
              </BackgroundContainer>

              <Field
                id="duration-input"
                name="total_days"
                label="Duração (dias úteis)"
                component={Select}
                placeholder="Selecione..."
                style={{ width: '100%', maxWidth: 280 }}
                data={
                  getTotalBalance(
                    safeRead(paidLeave, ['timeline', 'balance']),
                    type
                  ) < 10
                    ? [{ value: 5 }]
                    : [{ value: 5 }, { value: 10 }]
                }
                disabled={
                  getTotalBalance(
                    safeRead(paidLeave, ['timeline', 'balance']),
                    type
                  ) < 5
                }
                renderItem={item => (
                  <AntdSelect.Option key={item.value} value={item.value}>
                    {item.value}
                  </AntdSelect.Option>
                )}
                allowClear
              />

              <>
                <DateContainer>
                  <Field
                    id="begin-date-input"
                    name="begin_date"
                    label="Data de início"
                    allowClear={false}
                    component={DatePicker}
                    onChange={item => {
                      setFieldValue(
                        'begin_date',
                        new Date(item._d).toISOString().split('T')[0]
                      );
                    }}
                  />
                </DateContainer>
                {values.begin_date && values.total_days ? (
                  <BackgroundContainer>
                    <Text strong>Data de término do descanso:</Text>
                    {lastDayOfVacationsOfDayOffLoading === false ? (
                      <Text strong>
                        {safeRead(
                          lastDayOfVacationsOfDayOff,
                          ['last_date'],
                          'Erro ao tentar buscar data de descanso.',
                          item => ` ${moment(item).format('DD/MM/YYYY')}`
                        )}
                      </Text>
                    ) : (
                      <Spin />
                    )}
                  </BackgroundContainer>
                ) : null}
              </>

              <Field
                id="receive-advance-money"
                name="receive_advance_money"
                label="Desejo receber 70% de adiantamento para estas Férias"
                component={Checkbox}
              />

              <BackgroundContainer col>
                <div style={{ width: '100%' }}>
                  <Text strong>Período selecionado</Text>

                  <Text strong>{total_days || '0'} dia(s)</Text>
                </div>

                <Divider />

                <div style={{ width: '100%' }}>
                  <Text strong>Saldo restante após aprovação</Text>

                  {total_days && (
                    <Text strong>
                      {getTotalBalance(
                        safeRead(paidLeave, ['timeline', 'balance']),
                        type
                      ) - total_days}{' '}
                      dia(s)
                    </Text>
                  )}

                  {!total_days && (
                    <Text strong>
                      {paidLeave &&
                        getTotalBalance(
                          safeRead(paidLeave, ['timeline', 'balance']),
                          type
                        )}{' '}
                      dia(s)
                    </Text>
                  )}
                </div>
              </BackgroundContainer>
            </>
          )}
          {situation === 'EXPIRED' && (
            <Alert
              message="Esse período aquisitivo já expirou. Contate seu coordenador."
              type="info"
              showIcon
            />
          )}

          {situation === 'ACTUAL' && (
            <Alert
              message={
                <>
                  Você ainda não completou este período selecionado. Por Favor
                  selecione um período válido.
                </>
              }
              type="info"
              showIcon
            />
          )}
          <br />
        </>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => setVisible(false)}>Cancelar</Button>

        {type === fieldType.paidLeave &&
          situation !== 'ACTUAL' &&
          getTotalBalance(safeRead(paidLeave, ['timeline', 'balance']), type) >=
            5 && (
            <>
              <Divider type="vertical" />
              <Button
                loading={isSubmitting}
                onClick={async () => {
                  setSubmitting(true);
                  await postPaidLeaveRequest(values, referencePeriod);
                  await updatePaidLeave();
                  setVisible(false);
                }}
                type="primary"
                disabled={
                  !values.begin_date || !values.total_days || !referencePeriod
                }
              >
                Solicitar
              </Button>
            </>
          )}
      </div>
    </>
  );
}

PaidLeave.propTypes = {
  type: PropTypes.string.isRequired,
  lastDayOfVacationsOfDayOff: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastDayOfVacationsOfDayOff: PropTypes.func.isRequired,
  lastDayOfVacationsOfDayOffLoading: PropTypes.bool.isRequired,
  setLastDayOfVacationsOfDayOffLoading: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  referencePeriod: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    total_days: undefined,
    begin_date: undefined,
    receive_advance_money: false,
  }),
})(PaidLeave);
