import React, { useState } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/pt-br';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { ContainerCalendar } from './calendar.styles';
import checkPermission from '~/services/utils/checkPermission';

const { Text } = Typography;

export default function Calendar({ item, addControlConsolidated }) {
  const { id, key, payroll_closing_date, year, month } = item;
  const [selectedDate, setSelectedDate] = useState(
    moment(payroll_closing_date)
  );

  const handleDayClick = day => {
    setSelectedDate(day);

    const selectedDay = moment(day).format('YYYY-MM-DD');
    addControlConsolidated({ id, selectedDay });
  };
  return (
    <>
      <ContainerCalendar>
        <>
          <Text>{item.month}</Text>
          <DayPickerInput
            inputProps={{
              disabled:
                (key < moment().month() && year === moment().year()) ||
                !checkPermission('change_controlconsolidatedproduction'),
            }}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={`${
              payroll_closing_date === null
                ? `${month} - ${year}`
                : moment(selectedDate).format('DD/MM/YYYY')
            }`}
            dayPickerProps={{
              canChangeMonth: false,
              disabledDays: [{ before: new Date() }],
              month: new Date(year, key),
              locale: 'pt-br',
              localeUtils: MomentLocaleUtils,
              selectedDays: new Date(selectedDate),
              onDayClick: handleDayClick,
            }}
          />
        </>
      </ContainerCalendar>
    </>
  );
}

Calendar.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  addControlConsolidated: PropTypes.func.isRequired,
};
