import React from 'react';
import { Row, Col } from 'antd';
import { FooterLayout, LinkButton } from './styles';
import moment from 'moment';

export default function Footer() {
  return (
    <>
      <FooterLayout style={{ textAlign: 'center' }}>
        <Row>
          <Col>
            Coopersystem © {moment().format('YYYY')} - Todos os direitos
            reservados
          </Col>
          <Col>
            <LinkButton type="link" href='https://dpo.privacytools.com.br/policy-view/dqy1D5RoQ/1/politica-de-privacidade-portal-coopersystem/pt_BR' target="_blank">
              Política de Privacidade
            </LinkButton>
          </Col>
        </Row>
      </FooterLayout>
    </>
  );
}
