import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Modal } from 'antd';

import { ModalItemContainer, ModalItem } from './styles';

export default function DetailModal({
  title,
  visible,
  setVisible,
  onCancel,
  data,
  actions,
}) {
  const { Text, Title } = Typography;

  const handleOnCancel = () => {
    if (setVisible) {
      return setVisible(false);
    }

    if (onCancel) {
      return onCancel();
    }

    return null;
  };

  return (
    <Modal
      title={<Title level={4}>{title}</Title>}
      footer={null}
      visible={visible}
      onCancel={handleOnCancel}
      closable
      destroyOnClose
      centered
    >
      <ModalItemContainer>
        {data.map(item => (
          <ModalItem key={item.key} width={item.width}>
            <Text strong style={{ display: 'block' }}>
              {item.title}
            </Text>
            <Text type="secondary" style={{ display: 'block' }}>
              {item.value}
            </Text>
          </ModalItem>
        ))}
      </ModalItemContainer>
      {actions}
    </Modal>
  );
}

DetailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  setVisible: PropTypes.func,
  onCancel: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  actions: PropTypes.element,
};

DetailModal.defaultProps = {
  onCancel: undefined,
  setVisible: undefined,
  title: undefined,
  actions: undefined,
};
