import styled from 'styled-components';
import { tabletWidth, mobileWidth } from '~/constants/metrics';

export const ModalItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
`;

export const ModalItem = styled.div`
  flex-grow: 0;
  width: ${({ width }) => width || '33%'};
  margin-bottom: 40px;

  @media (max-width: ${tabletWidth}) {
    width: ${({ width }) => (width && width === '100%' ? width : '50%')};
  }

  @media (max-width: ${mobileWidth}) {
    width: 100%;
  }
`;
