export const PERMISSIONS_REQUEST = '@portal/PERMISSIONS_REQUEST';
export const PERMISSIONS_SUCCESS = '@portal/PERMISSIONS_SUCCESS';
export const PERMISSIONS_FAILURE = '@portal/PERMISSIONS_FAILURE';

export function permissionsRequest() {
  return {
    type: PERMISSIONS_REQUEST,
  };
}

export function permissionsSuccess(permissions) {
  return {
    type: PERMISSIONS_SUCCESS,
    payload: { permissions },
  };
}

export function permissionsFailure() {
  return {
    type: PERMISSIONS_FAILURE,
  };
}
