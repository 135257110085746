import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

export default function LanguageProvider({ children, messages }) {
  const locale = useSelector(state => state.language.locale);

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

LanguageProvider.defaultProps = {
  messages: undefined,
};

LanguageProvider.propTypes = {
  messages: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.element.isRequired,
};
