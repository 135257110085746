import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.rectification';

export default defineMessages({
  pageTitle: {
    id: `${scope}.rectification_approval`,
    defaultMessage: 'Aprovação de Rasuras',
  },
  nameLabel: {
    id: `${scope}.name_label`,
    defaultMessage: 'Nome',
  },
  rectificationDateLabel: {
    id: `${scope}.rectification_date_label`,
    defaultMessage: 'Data',
  },
  statusLabel: {
    id: `${scope}.status_label`,
    defaultMessage: 'Status',
  },
  registerLabel: {
    id: `${scope}.register_label`,
    defaultMessage: 'Registro',
  },
  reasonLabel: {
    id: `${scope}.reason_label`,
    defaultMessage: 'Motivo',
  },
  searchButtom: {
    id: `${scope}.search_buttom`,
    defaultMessage: 'Pesquisar',
  },
  clearButtom: {
    id: `${scope}.clear_buttom`,
    defaultMessage: 'Limpar',
  },
  actionButtom: {
    id: `${scope}.action_buttom`,
    defaultMessage: 'Ações',
  },
  approveAction: {
    id: `${scope}.approve_action`,
    defaultMessage: 'Aprovar',
  },
  denyAction: {
    id: `${scope}.deny_action`,
    defaultMessage: 'Reprovar',
  },
  approvedStatusSelect: {
    id: `${scope}.approved_status_select`,
    defaultMessage: 'Aprovado',
  },
  deniedStatusSelect: {
    id: `${scope}.denied_status_select`,
    defaultMessage: 'Recusado',
  },
  inAnalysisSelect: {
    id: `${scope}.in_analysis_status_select`,
    defaultMessage: 'Em análise',
  },
  empty: {
    id: `${scope}.empty`,
    defaultMessage: 'vazio',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'de',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'para',
  },
});
