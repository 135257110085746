import React, { useState } from 'react';
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  Divider,
  Select,
  Spin,
  Alert,
} from 'antd';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import safeRead from 'safely-read';
import formatPrice from '~/services/utils/formatPrice';

import MultipleSliders from '~/components/DataEntry/MultipleSlider';

import { ItemContainer, Item } from './style';
import { useSearchTeams, PostTeamHours } from './index';
import { CollapsePanelContext } from '../../CollapseList/index';

export default function ManageMensalProductionModal({
  manageMensalProductionModalVisible,
  setManageMensalProductionModalVisible,
  teamHours,
}) {
  const {
    user,
    key,
    setConsolidatedProduction,
    fetchConsolidatedProduction,
    compensatoryTime,
    year,
    month,
  } = React.useContext(CollapsePanelContext);

  const { teamsResponse, loading } = useSearchTeams();

  const { Text, Title } = Typography;

  const [totalHours, setTotalHours] = useState(0);

  return (
    <Modal
      visible={manageMensalProductionModalVisible}
      onCancel={() => setManageMensalProductionModalVisible(false)}
      footer={null}
      width={580}
      closable
      destroyOnClose
      title={<Title level={4}>Gerenciar Produção Mensal</Title>}
    >
      <ItemContainer>
        <Item width="45%">
          <Text strong style={{ display: 'block' }}>
            Usuário
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['person_display', 'name_display'])}
          </Text>
        </Item>

        <Item width="30%">
          <Text strong style={{ display: 'block' }}>
            Mês/Ano
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {`${moment(`${year}-${month}`).format('MMMM/YYYY')}`}
          </Text>
        </Item>

        <Item width="20%">
          <Text strong style={{ display: 'block' }}>
            Valor/Hora
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['general', 'hourly_rate'], '--', rate =>
              formatPrice(rate)
            )}
          </Text>
        </Item>
      </ItemContainer>
      <Item width="60%">
        <Text strong style={{ display: 'block' }}>
          Banco de Horas aprovadas
        </Text>
        <Text type="secondary" style={{ display: 'block' }}>
          {safeRead(compensatoryTime, ['balance'])}
        </Text>
      </Item>

      <Formik
        initialValues={{
          month_team_hours: [],
        }}
        onSubmit={values => {
          PostTeamHours(
            key,
            values.month_team_hours,
            setManageMensalProductionModalVisible
          );
          fetchConsolidatedProduction(
            key,
            year,
            month,
            setConsolidatedProduction
          );
        }}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {!loading ? (
                <>
                  <Row gutter={24} type="flex" justify="start" align="middle">
                    <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                      <MultipleSliders
                        id="month_team_hours"
                        name="month_team_hours"
                        maxValue={safeRead(user, [
                          'team_hours',
                          'maximum_allowed_hours',
                        ])}
                        allowClear
                        loading={loading}
                        totalHours={totalHours}
                        setTotalHours={setTotalHours}
                        teamDefault={safeRead(user, ['default_team'])}
                        onChange={e => {
                          const keys = Object.keys(e).map(
                            k => JSON.parse(k).id
                          );
                          const values = Object.values(e);
                          const obj = keys.map((id, index) => ({
                            team: id,
                            hours: values[index],
                          }));
                          setFieldValue('month_team_hours', obj);
                        }}
                        data={teamsResponse}
                        teamHours={teamHours}
                        selectSchema={({ id, team_name }) => (
                          <Select.Option
                            key={id}
                            value={JSON.stringify({ id, team_name })}
                          >
                            {team_name}
                          </Select.Option>
                        )}
                      />
                    </Col>
                  </Row>

                  <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <Alert
                      message="Atribua pelo menos uma 1 hora para cada equipe adicionada."
                      type="info"
                      showIcon
                    />
                  </div>

                  <Row gutter={24} type="flex" justify="start">
                    <Col>
                      <Text>{`Total restante: ${totalHours}h`}</Text>
                    </Col>
                    <Col>
                      <Text>{`Total utilizado: ${user.team_hours
                        .maximum_allowed_hours - totalHours}h`}</Text>
                    </Col>
                    <Col>
                      <Text>Total do mês: </Text>
                      <span style={{ color: '#1DA57A', fontWeight: 'bold' }}>
                        {safeRead(
                          user,
                          ['team_hours', 'maximum_allowed_hours'],
                          '--',
                          value => `${value}h`
                        )}
                      </span>
                    </Col>
                  </Row>

                  <br />
                  <Row type="flex" justify="end">
                    <Col>
                      <Button
                        onClick={() =>
                          setManageMensalProductionModalVisible(false)
                        }
                      >
                        Cancelar
                      </Button>
                      <Divider type="vertical" />
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          user.team_hours.maximum_allowed_hours - totalHours < 1
                        }
                      >
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row type="flex" justify="center" align="middle">
                  <Spin size="large" />
                </Row>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

ManageMensalProductionModal.propTypes = {
  manageMensalProductionModalVisible: PropTypes.bool.isRequired,
  setManageMensalProductionModalVisible: PropTypes.func.isRequired,
  teamHours: PropTypes.arrayOf(PropTypes.any).isRequired,
};
