import { takeLatest, call, put, all } from 'redux-saga/effects';

import { AUTH_PROFILE_GROUPS } from '~/constants/endpoints/auth';
import api from '~/services/api';
import { GROUPS_REQUEST, groupsSuccess, groupsFailure } from './actions';

export function* getGroups() {
  try {
    const { data } = yield call(api.get, AUTH_PROFILE_GROUPS);

    yield put(groupsSuccess(data.results));
  } catch (error) {
    yield put(groupsFailure());
  }
}

export default all([takeLatest(GROUPS_REQUEST, getGroups)]);
