import React from 'react';
import safeRead from 'safely-read';
import Table from '~/components/DataDisplay/Table';
import { useTableData, makeColumns, formatTableData } from './index';

function ScrollableTable() {
  const slide = document.querySelector('.ant-table-body');
  let isDown = false;
  let startX;
  let scrollLeft;

  slide.addEventListener('mousedown', e => {
    isDown = true;
    slide.classList.add('on');
    startX = e.pageX - slide.offsetLeft;
    scrollLeft = slide.scrollLeft;
  });

  slide.addEventListener('mouseleave', () => {
    isDown = false;
    slide.classList.remove('on');
  });

  slide.addEventListener('mouseup', () => {
    isDown = false;
    slide.classList.remove('on');
  });

  slide.addEventListener('mousemove', e => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - slide.offsetLeft;
    const walk = (x - startX) * 3; // scroll-fast
    slide.scrollLeft = scrollLeft - walk;
  });
}

export default function TeamHistoryTable() {
  const { tableData, loadingTableData } = useTableData();
  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);

  React.useEffect(() => {
    ScrollableTable();
  }, []);

  return (
    <Table
      scroll={{ x: true }}
      dataSource={formatTableData(tableRows)}
      columns={makeColumns}
      loading={loadingTableData}
      count={tableCount}
    />
  );
}
