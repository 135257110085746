import { useState } from 'react';
import Statistics from './Statistics';
import api from '~/services/api';
import {
  MY_COMPENSATORY_TIME,
  MY_COMPENSATORY_TIME_BY_ID,
} from '~/constants/endpoints/my_compensatory_time';
import { useDeepCompareEffect } from 'react-use';
import { useSelector } from 'react-redux';

export async function fetchStatisticsData(
  cooperativeMemberId,
  setData,
  setLoading
) {
  setLoading(true);

  try {
    if (cooperativeMemberId) {
      const { data } = await api.get(
        MY_COMPENSATORY_TIME_BY_ID(cooperativeMemberId)
      );

      setData(data);
    } else {
      const { data } = await api.get(MY_COMPENSATORY_TIME);

      setData(data);
    }
    setLoading(false);
  } catch (error) {
    setData(null);
    setLoading(false);
  }
}

export function useCompensatoryStatistics(cooperativeMemberId) {
  const { data: productionData } = useSelector(state => state.production);
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(false);

  useDeepCompareEffect(() => {
    fetchStatisticsData(cooperativeMemberId, setStatistics, setLoading);
  }, [cooperativeMemberId, productionData]);

  return {
    statistics,
    loading,
    setLoading,
    cooperativeMemberId,
    setStatistics,
    fetchStatisticsData: () =>
      fetchStatisticsData(cooperativeMemberId, setStatistics, setLoading),
  };
}

export default Statistics;
