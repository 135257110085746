import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.profile';

export default defineMessages({
  resetPassword: {
    id: `${scope}.reset_password`,
    defaultMessage: 'Redefinir Senha',
  },
  fullName: {
    id: `${scope}.full_name`,
    defaultMessage: 'Nome Completo',
  },
  registrationNumber: {
    id: `${scope}.registration_number`,
    defaultMessage: 'Matrícula',
  },
  matrialStatus: {
    id: `${scope}.matrial_status`,
    defaultMessage: 'Estado Civil',
  },
  schooling: {
    id: `${scope}.schooling`,
    defaultMessage: 'Escolaridade',
  },
  jobPosition: {
    id: `${scope}.job_position`,
    defaultMessage: 'Cargo',
  },
  hourlyRate: {
    id: `${scope}.hourly_rate`,
    defaultMessage: 'Valor/hora',
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: 'Perfil',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  accessionDate: {
    id: `${scope}.accession_date`,
    defaultMessage: 'Data de Adesão',
  },
  startDate: {
    id: `${scope}.start_date`,
    defaultMessage: 'Data de Início',
  },
  leaderBudget: {
    id: `${scope}.leader_budget`,
    defaultMessage: 'Recebe Verba Líder?',
  },
  responsibleBudget: {
    id: `${scope}.responsible_budget`,
    defaultMessage: 'Recebe Verba Preposto?',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Sim',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'Não',
  },
});
