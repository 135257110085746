import React, { useEffect, useState } from 'react';
import { Table as AntdTable, Badge, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import safeRead from 'safely-read';
import { FormattedMessage } from 'react-intl';
import 'moment/locale/pt-br';
import TableCheckinsCheckouts from '~/constants/checkinsCheckouts';
import messages from '../messages';
import formatOrdinal from '~/services/utils/formatOrdinal';
import ProductionTable from './ProductionTable';

const { Column } = AntdTable;

export function renderHour(obj) {
  const status = safeRead(obj, ['rectification', 'status']);
  const statusDisplay = safeRead(obj, ['rectification', 'status_display']);
  const handleStatus = () => {
    if (status === 'aprovado') {
      return 'green';
    }

    if (status === 'em_analise') {
      return 'yellow';
    }

    if (status === 'reprovado') {
      return 'tomato';
    }

    return 'transparent';
  };

  if (obj === '--') {
    return (
      <span style={{ opacity: 0.5 }}>
        <FormattedMessage {...messages.empty} />
      </span>
    );
  }

  if (status) {
    return (
      <Tooltip title={statusDisplay}>
        <Badge
          color={handleStatus()}
          text={`${moment(obj.worktime_clock).format('HH:mm')}${
            status !== 'aprovado' ? '*' : ''
          }`}
        />
      </Tooltip>
    );
  }

  return (
    <span style={{ textAlign: 'center' }}>
      {moment(obj.worktime_clock).format('HH:mm')}
    </span>
  );
}

export function renderProduction(obj) {
  const status = safeRead(obj, ['producao', 'status']);
  const statusDisplay = safeRead(obj, ['producao', 'status_display']);

  const handleStatus = () => {
    if (status === 'aprovado') {
      return 'green';
    }

    if (status === 'em_analise') {
      return 'yellow';
    }

    if (status === 'reprovado') {
      return 'tomato';
    }

    return 'transparent';
  };

  if (status) {
    return (
      <Tooltip title={statusDisplay}>
        <Badge
          color={handleStatus()}
          text={`${obj.producao.day_type_display}`}
        />
      </Tooltip>
    );
  }

  if (obj.producao.day_type === 'final_de_semana') {
    return (
      <Tooltip title={statusDisplay}>
        <Badge color="blue" text={`${obj.producao.day_type_display}`} />
      </Tooltip>
    );
  }

  if (obj.producao.day_type === 'feriado') {
    return (
      <Tooltip title={statusDisplay}>
        <Badge color="blue" text={`${obj.producao.day_type_display}`} />
      </Tooltip>
    );
  }

  if (obj.producao.request_type === 'ausencia_a_abonar') {
    return (
      <Tooltip title={statusDisplay}>
        <Badge color="orange" text={`${obj.producao.request_type_display}`} />
      </Tooltip>
    );
  }

  if (obj.producao.request_type === 'ausencia') {
    return (
      <Tooltip title={statusDisplay}>
        <Badge color="orange" text={`${obj.producao.request_type_display}`} />
      </Tooltip>
    );
  }

  return <Badge color={handleStatus()} text={`${obj.producao}`} />;
}

export function renderBalance(obj) {
  const statusDisplay = safeRead(obj, ['balance', 'solicitation'], [])[0];
  const status = safeRead(statusDisplay, ['status']);
  const type = safeRead(statusDisplay, ['request_type']);

  const displayStatus = {
    aprovado: 'Aprovada',
    em_analise: 'Em Análise',
    nao_solicitado: 'Não Solicitada',
    reprovado: 'Reprovada',
    aprovado_pelo_financeiro: 'Aprovado Financeiro',
  };

  const typeRequest = {
    ausencia: 'Ausência',
    ausencia_a_abonar: 'Ausência a abonar',
    hora_extra: 'Hora Extra',
  };

  const handleStatus = () => {
    if (status === 'aprovado' || status === 'aprovado_pelo_financeiro') {
      return 'green';
    }

    if (status === 'em_analise') {
      return 'yellow';
    }

    if (status === 'reprovado') {
      return 'tomato';
    }

    if (status === 'nao_solicitado') {
      return 'orange';
    }

    return 'transparent';
  };

  if (status) {
    return (
      <Tooltip title={`${typeRequest[type]} - ${displayStatus[status]}`}>
        <Badge color={handleStatus()} text={`${obj.saldo}`} />
      </Tooltip>
    );
  }

  return <Badge color={handleStatus()} text={`${obj.saldo}`} />;
}

export function renderColumns() {
  const renderLabel = label => (
    <>
      {formatOrdinal(label.pos, 'female')}{' '}
      {label.type === 'entrada' ? (
        <FormattedMessage {...messages.columnCheckIn} />
      ) : (
        <FormattedMessage {...messages.columnCheckOut} />
      )}
    </>
  );

  return TableCheckinsCheckouts.map(item => (
    <Column
      className="group-column"
      title={renderLabel(item)}
      dataIndex={`${item.pos}${item.type}`}
      key={`${item.pos}${item.type}`}
      render={renderHour}
      align="center"
    />
  ));
}

export function renderColumnProduction() {
  return (
    <Column
      className="group-column"
      title={<FormattedMessage {...messages.columnProduction} />}
      render={renderProduction}
      fixed="right"
      align="center"
      width={110}
    />
  );
}

export function renderColumnBalance() {
  return (
    <Column
      className="group-column"
      title={<FormattedMessage {...messages.columnBalance} />}
      render={renderBalance}
      fixed="right"
      align="center"
      width={100}
    />
  );
}

function compareDates(date) {
  const parts = date.split('-');
  const today = new Date();

  date = new Date(parts[0], parts[1] - 1, parts[2]);

  return date >= today;
}

export default function Main({ rawData, formattedData, loading }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowItems, setSelectedRowItems] = useState([]);
  const [rectificationModalVisible, setRetificationModalVisible] = useState(
    false
  );
  const [
    absenceOvertimeModalVisible,
    setAbsenceOvertimeRetificationModalVisible,
  ] = useState(false);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowPosition, selectedRowItem) => {
      setSelectedRowKeys(selectedRowPosition);
      setSelectedRowItems(selectedRowItem);
    },
    getCheckboxProps: record => ({
      disabled: compareDates(record.key) === true,
    }),
  };

  const toggleRectficationModal = () =>
    setRetificationModalVisible(prevState => !prevState);
  const toggleAbsenceOvertimeModal = () =>
    setAbsenceOvertimeRetificationModalVisible(prevState => !prevState);

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRowItems([]);
  }, [rawData]);

  return (
    <ProductionTable
      rawData={rawData}
      formattedData={formattedData}
      rectificationModalVisible={rectificationModalVisible}
      absenceOvertimeModalVisible={absenceOvertimeModalVisible}
      renderColumnProduction={renderColumnProduction}
      renderColumnBalance={renderColumnBalance}
      renderColumns={renderColumns}
      rowSelection={rowSelection}
      selectedRowItems={selectedRowItems}
      toggleRectficationModal={toggleRectficationModal}
      toggleAbsenceOvertimeModal={toggleAbsenceOvertimeModal}
      loading={loading}
    />
  );
}

Main.propTypes = {
  rawData: PropTypes.arrayOf(PropTypes.any).isRequired,
  formattedData: PropTypes.arrayOf(PropTypes.any).isRequired,
  loading: PropTypes.bool.isRequired,
};
