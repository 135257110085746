import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import { VacationsContext } from '../index';
import { resignationRequest } from './index';
import { Container } from './styles';

const { confirm } = Modal;

export default function ResignationRequest({ referencePeriods }) {
  const { updatePaidLeave } = useContext(VacationsContext);

  return (
    <Container>
      <Button
        onClick={() =>
          confirm({
            title: 'Solicitar acerto por desligamento?',
            async onOk() {
              await resignationRequest(referencePeriods);
              await updatePaidLeave();
            },
            onCancel() {},
            content: 'O saldo restante de férias e abonos será pago.',
            centered: true,
          })
        }
        type="link"
      >
        Solicitar acerto por desligamento
      </Button>
    </Container>
  );
}

ResignationRequest.propTypes = {
  referencePeriods: PropTypes.arrayOf(PropTypes.any).isRequired,
};
