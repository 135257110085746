import styled from 'styled-components';
import { darken } from 'polished';

export const HistoryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;

  margin-top: 8px;

  @media (max-width: 1288px) {
    align-items: center;
    justify-content: center;
  }
`;

export const LoadingContainer = styled.div`
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: ${({ theme }) => darken(0.05, theme.componentBackground)};
  border-radius: 4px;
`;
