import styled from 'styled-components';
import { Divider as AntDivider } from 'antd';

export const Container = styled.div`
  display: grid;
  width: 100%;

  @media (min-width: 1012px) {
    display: grid;
    grid-template-columns: 40% min-content 50%;
    width: auto;
    min-height: 760px;
    column-gap: 10px;
  }
`;

export const ContainerLogo = styled.div`
  display: none;
  @media (min-width: 1012px) {
    display: grid;
    border-right-width: 1px;
    border-right-color: black;
    width: 100%;
    box-sizing: border-box;
    grid-auto-rows: min-content;
  }
`;
export const ContainerHeaderLogo = styled.div``;
export const HeaderLogo = styled.img`
  height: 40px;
  width: 216px;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const ContainerTitle = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 34px;
  line-height: 57px;
  color: '#5F5F5F';
  span {
    font-weight: 700;
  }
`;
export const IconPlus = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #43e185;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  left: 100%;
  bottom: 70%;
`;

export const ContentLogo = styled.img`
  width: 246px;
  height: 175px;
`;

export const ContainerContent = styled.div`
  /* min-width: 550px; */

  display: grid;
  /* justify-content: flex-start;
  align-items: center; */
  row-gap: 10px;
  padding: 20px;
  width: 100%;
  @media (min-width: 1012px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 80px 32px 0 32px;
  }
`;
export const Content = styled.div`
  min-width: 100%;
  display: grid;
  row-gap: 13px;
`;
export const TeamName = styled.div``;
export const TeamManager = styled.div`
  display: grid;
  grid-template-columns: 40% 58%;
  gap: 2%;
`;
export const TeamLeader = styled.div`
  display: grid;
  row-gap: 2%;
  grid-template-columns: 50% auto;
  gap: 2%;
`;
export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media (min-width: 1012px) {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
export const TeamMembers = styled.div``;

export const Divider = styled(AntDivider)`
  display: none;
  @media (min-width: 1012px) {
    display: block;
    background-color: '#FBFDF7';
    color: '#FBFDF7';
    border-radius: '32px 0px 0px 32px';
    /* position: absolute; */
    /* right: 55%; */
    border: '1px solid #333';
    height: 100%;
    box-shadow: '10px 0 5px -2px #888';
  }
`;
