import React, { useContext } from 'react';
import { Col, Row, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import safeRead from 'safely-read';
import { HistoryContext } from '../index';
import { StatisticHolder, TooltipWrapper } from './styles';
import messages from '../messages';
import Skeleton from '~/components/DataDisplay/Skeleton';

export default function Statistics() {
  const { statistics, loadingStatistics } = useContext(HistoryContext);

  const RenderInfo = () => {
    return (
      <TooltipWrapper>
        <Row>
          <span className="stats-title">
            {`O cálculo é baseado nas\n seguintes informações:`}
          </span>
        </Row>
        <br />
        <Row>
          <Col sm={24} md={12} lg={12} xl={12}>
            <span className="stats-title">Ausência: </span>
            <span className="stats-description">
              {safeRead(statistics, ['absence_analyze'])}
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={24} md={12} lg={12} xl={12}>
            <span className="stats-title">Hora Extra: </span>
            <span className="stats-description">
              {safeRead(statistics, ['overtime_analyze'])}
            </span>
          </Col>
        </Row>
        <br />
        <Row>
          <span className="stats-title-tooltip">(Ausência) - (Hora extra)</span>
        </Row>
      </TooltipWrapper>
    );
  };

  return (
    <Row gutter={24}>
      <Col sm={24} md={12} lg={12} xl={12}>
        <StatisticHolder>
          <span className="stats-title">
            <FormattedMessage {...messages.compTimeApproved} />
          </span>
          <br />
          <span className="stats-description">
            {loadingStatistics ? (
              <Skeleton lineWidthList={['20%']} />
            ) : (
              safeRead(statistics, ['balance'], '--:--')
            )}
          </span>
        </StatisticHolder>
      </Col>
      <Col sm={24} md={12} lg={12} xl={12}>
        <StatisticHolder>
          <Tooltip title={RenderInfo}>
            <span className="stats-title">
              <FormattedMessage {...messages.compTimeInAnalisys} />
            </span>
            <br />
            <span className="stats-description">
              {loadingStatistics ? (
                <Skeleton lineWidthList={['20%']} />
              ) : (
                safeRead(statistics, ['pending'], '--:--')
              )}
              <AiOutlineInfoCircle
                size={16}
                style={{ marginLeft: 8, marginBottom: 8 }}
              />
            </span>
          </Tooltip>
        </StatisticHolder>
      </Col>
      {/* <Col sm={24} md={8} lg={8} xl={8}>
        <StatisticHolder>
          <span className="stats-title">
            <FormattedMessage {...messages.compTimeNotRequested} />
          </span>
          <br />
          <span className="stats-description">
            {loading ? (
              <Skeleton lineWidthList={['20%']}  />
            ) : (
              safeRead(statistics, ['not_requested'], '--:--')
            )}
          </span>
        </StatisticHolder>
      </Col> */}
    </Row>
  );
}
