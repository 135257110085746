import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { AiOutlineDown } from 'react-icons/ai';

import { Container, Button } from './styles';

export default function MultipleActionButton({ children, actions }) {
  const menu = (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {actions.map(item => (
        <Button key={item.title} type="link" onClick={item.action}>
          {item.title}
        </Button>
      ))}
    </Container>
  );

  return (
    <Dropdown overlay={menu}>
      <Button>
        {children} <AiOutlineDown size={10} />
      </Button>
    </Dropdown>
  );
}

MultipleActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
};
