import React from 'react';
import safeRead from 'safely-read';
import moment from 'moment';
import { message, Modal } from 'antd';

import api from '~/services/api';
import renderBadge from '~/services/utils/renderStatusBadge';
import RequestHistory from './RequestHistory';
import { PAID_LEAVE_ID } from '~/constants/endpoints/paid_leave';

const { confirm } = Modal;

export async function cancelRequest(id, updatePaidLeave, setVisible) {
  confirm({
    title: 'Cancelar solictação?',
    onOk: async () => {
      try {
        await api.patch(PAID_LEAVE_ID(id), {
          status: 'cancelado',
        });

        message.success('Solictação cancelada com sucesso!');

        await updatePaidLeave();

        setVisible(false);
      } catch (error) {
        message.error('Erro ao cancelar solictação');
      }
    },
    centered: true,
  });
}

export function buildReferencePeriodDisplay(period = []) {
  return period[0].map(item => moment(item).format('DD/MM/YYYY')).join(' - ');
}

export function formatDetails(data) {
  const type = safeRead(data, ['paid_leave_type'], null);

  const formatted = [
    {
      key: 'id',
      title: 'Número (ID)',
      value: safeRead(data, ['paid_leave_id'], '-'),
      width: '50%',
    },
    {
      key: 'name',
      title: 'Nome',
      value: safeRead(data, ['name_display'], '-'),
      width: '50%',
    },
    {
      key: 'paid_leave_type_display',
      title: 'Tipo',
      value: safeRead(data, ['paid_leave_type_display'], '-'),
      width: '50%',
    },
    {
      key: 'status',
      title: 'Status',
      value: renderBadge(data),
      width: '50%',
      status: safeRead(data, ['status_display'], '-'),
    },
    {
      key: 'reference_period_display',
      title: 'Período aquisitivo',
      value: safeRead(data, ['reference_period_start'], '-', val =>
        moment(val).format('DD/MM/YYYY')
      ),
      width: '50%',
    },
    {
      key: 'updated_at',
      title: 'Data de solicitção',
      value: safeRead(data, ['updated_at'], '-', val =>
        moment(val).format('DD/MM/YYYY')
      ),
      width: '50%',
    },
    {
      key: 'paid_leave_period',
      title: type === 'abono' ? 'Período de Abono' : 'Período de Férias',
      value: safeRead(data, ['begin_date'], '-', val => {
        const last_date = safeRead(data, ['last_date'], '-');
        if (last_date !== '-') {
          return (
            <>
              <b>de</b> {moment(val).format('DD/MM/YYYY')} <b>até</b>{' '}
              {moment(last_date).format('DD/MM/YYYY')}
            </>
          );
        }
        return `${moment(val).format('DD/MM/YYYY')}`;
      }),
      width: '50%',
    },
    {
      key: 'total_days',
      title: 'Duração',
      value: safeRead(data, ['total_days'], 0),
      width: '50%',
    },
    // {
    //   key: 'Saldo atual',
    //   title: 'Saldo atual',
    //   value: safeRead(data, ['balance', 'remaining'], '-'),
    //   width: '50%',
    // },
    // {
    //   key: 'Saldo após aprovação',
    //   title: 'Saldo após aprovação',
    //   value: safeRead(
    //     data,
    //     ['balance', 'remaining'],
    //     '-',
    //     val => val - safeRead(data, ['total_days'], 0)
    //   ),
    //   width: '50%',
    // },
    {
      key: 'receive_advance_money',
      title: 'Deseja 70% de adiantamento?',
      value: safeRead(data, ['receive_advance_money'], 'Não', val =>
        val ? 'Sim' : 'Não'
      ),
      width: '100%',
    },
    {
      key: 'disapproval_reason',
      title: 'Motivo da reprovação',
      value: safeRead(data, ['disapproval_reason'], '-'),
      width: '100%',
    },
  ];

  if (type === 'abono') {
    return formatted.filter(item => item.key !== 'receive_advance_money');
  }

  return formatted;
}

export default RequestHistory;
