import React from 'react';
import {
  Modal,
  Button,
  Checkbox,
  Row,
  Col,
  Alert,
  Typography,
  message as AntMessage,
} from 'antd';
import PropTypes from 'prop-types';
import safeRead from 'safely-read';

import api from '~/services/api';
import { WORKTIME_CLOCK } from '~/constants/endpoints/daily_worktime_clock';

const { Title } = Typography;

export default function ConfirmModal({
  confirmModalVisible,
  setConfirmModalVisible,
  checkInType,
  setCheckinLoading,
  getProductionHistory,
  setRectificationModalVisible,
  latitude,
  longitude,
  hadMinimumBreak,
  setCheckBoxValue,
  checkBoxValue,
}) {
  const handleCheckin = async (type, checkBoxValue) => {
    try {
      setCheckinLoading(true);

      let requestBody;

      if (type === false && hadMinimumBreak === false) {
        requestBody = {
          check_in: type,
          latitude,
          longitude,
          minimum_break: checkBoxValue,
        };
      } else {
        requestBody = {
          check_in: type,
          latitude,
          longitude,
        };
      }

      await api.post(WORKTIME_CLOCK, requestBody);
      getProductionHistory();
    } catch (e) {
      if (
        e.response.data.error_code &&
        e.response.data.error_code ===
          'DAILY_WORKTIME_CLOCK_INVALID_NEW_CHECK_IN'
      ) {
        setRectificationModalVisible(true);
      } else {
        AntMessage.error(
          safeRead(
            e,
            ['response', 'data', 'detail'],
            'Ocorreu um erro ao registrar ponto'
          )
        );
      }
    }

    setCheckinLoading(false);
  };

  return (
    <Modal
      visible={confirmModalVisible}
      footer={null}
      onCancel={() => setConfirmModalVisible(false)}
      width={450}
    >
      <>
        <Title level={4}>
          Deseja confirmar {checkInType ? 'Entrada' : 'Saída'}?
        </Title>
        <br />
        {checkInType === false && hadMinimumBreak === false && (
          <>
            <Checkbox
              onChange={e => {
                setCheckBoxValue(e.target.checked);
              }}
            >
              Essa é minha pausa de no mínimo 30 minutos.
            </Checkbox>
            <br />
            <br />
            <Alert
              message="Se você for produzir 6 horas ou menos no dia de hoje, não é necessário o descanso mínimo de 30 minutos."
              type="info"
              showIcon
            />
            <br />
          </>
        )}
        <br />
        <Row type="flex" justify="end" gutter={12}>
          <Col>
            <Button
              onClick={() => {
                setConfirmModalVisible(false);
                setCheckBoxValue(false);
              }}
            >
              Cancelar
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              onClick={() => {
                handleCheckin(checkInType, checkBoxValue);
                setConfirmModalVisible(false);
              }}
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </>
    </Modal>
  );
}

ConfirmModal.propTypes = {
  confirmModalVisible: PropTypes.bool.isRequired,
  setConfirmModalVisible: PropTypes.func.isRequired,
  checkInType: PropTypes.bool.isRequired,
  setCheckinLoading: PropTypes.func.isRequired,
  getProductionHistory: PropTypes.func.isRequired,
  setRectificationModalVisible: PropTypes.func.isRequired,
  latitude: PropTypes.objectOf(PropTypes.any),
  longitude: PropTypes.objectOf(PropTypes.any),
  hadMinimumBreak: PropTypes.bool.isRequired,
  setCheckBoxValue: PropTypes.func.isRequired,
  checkBoxValue: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  latitude: null,
  longitude: null,
  checkBoxValue: null,
};
