import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Typography, Row, Col, Button, Alert } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import { ItemContainer, Item } from './styles';
import { makeColumns, formatTableData } from './index';
import DetailModal from '../DetailModal';
import PackageSolicitationModal from '../SolicitationModal/PackageSolicitationModal';
import formatPrice from '~/services/utils/formatPrice';
import { CollapsePanelContext } from '../../CollapseList/index';

export default function ManagePackageModal({
  managePackageModalVisible,
  setManagePackageModalVisible,
}) {
  const {
    user,
    compensatoryTime,
    year,
    month,
    tableData,
    loadingTableData,
    pageTable,
    page_sizeTable,
  } = React.useContext(CollapsePanelContext);
  const { Text, Title } = Typography;

  const [
    packageSolicitationModalVisible,
    setPackageSolicitationModalVisible,
  ] = React.useState(false);

  const [detailModalVisible, setDetailModalVisible] = React.useState(false);
  const [payableRequestKey, setPayableRequestKey] = React.useState(undefined);

  const tableCount = safeRead(tableData, ['count'], 0);
  const tableRows = safeRead(tableData, ['results'], []);

  return (
    <Modal
      visible={managePackageModalVisible}
      onCancel={() => setManagePackageModalVisible(false)}
      footer={null}
      width={1000}
      closable
      destroyOnClose
      title={<Title level={4}>Gerenciar Pacotes</Title>}
    >
      <ItemContainer>
        <Item width="35%">
          <Text strong style={{ display: 'block' }}>
            Usuário
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['person_display', 'name_display'])}
          </Text>
        </Item>

        <Item width="20%">
          <Text strong style={{ display: 'block' }}>
            Mês/Ano
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {`${moment(`${year}-${month}`).format('MMMM/YYYY')}`}
          </Text>
        </Item>

        <Item width="30%">
          <Text strong style={{ display: 'block' }}>
            Banco de Horas aprovadas
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(compensatoryTime, ['balance'])}
          </Text>
        </Item>

        <Item width="15%">
          <Text strong style={{ display: 'block' }}>
            Valor/Hora
          </Text>
          <Text type="secondary" style={{ display: 'block' }}>
            {safeRead(user, ['general', 'hourly_rate'], '--', rate =>
              formatPrice(rate)
            )}
          </Text>
        </Item>
      </ItemContainer>
      <Row type="flex" justify="start">
        <Col>
          <Button
            type="primary"
            onClick={() => setPackageSolicitationModalVisible(true)}
          >
            Adicionar pacote
          </Button>
        </Col>
      </Row>
      {tableRows.some(item => item.request_type === 'pacote') ? (
        <div style={{ paddingTop: 10 }}>
          <Table
            dataSource={formatTableData(
              tableRows.filter(item => item.request_type === 'pacote')
            )}
            columns={makeColumns(setDetailModalVisible, setPayableRequestKey)}
            loading={loadingTableData}
            paginated
            currentPage={parseInt(pageTable, 10) || 1}
            count={tableCount}
            pageSize={parseInt(page_sizeTable, 10) || 10}
          />
        </div>
      ) : (
        <div style={{ paddingTop: 20 }}>
          <Alert
            message="Este cooperado não possui solicitação de pacote."
            type="info"
            showIcon
          />
        </div>
      )}

      {detailModalVisible ? (
        <DetailModal
          detailModalVisible={detailModalVisible}
          setDetailModalVisible={setDetailModalVisible}
          payableRequestKey={payableRequestKey}
        />
      ) : null}

      {packageSolicitationModalVisible ? (
        <PackageSolicitationModal
          packageSolicitationModalVisible={packageSolicitationModalVisible}
          setPackageSolicitationModalVisible={
            setPackageSolicitationModalVisible
          }
        />
      ) : null}
    </Modal>
  );
}

ManagePackageModal.propTypes = {
  managePackageModalVisible: PropTypes.bool.isRequired,
  setManagePackageModalVisible: PropTypes.func.isRequired,
};
