import React from 'react';

import { Divider } from 'antd';
import PageContainer from '~/components/Layout/PageContainer';
import DateFilter from './DateFilter/index';
import TeamHistoryTable from './TeamHistoryTable';

export default function TeamProductionHistory() {
  return (
    <PageContainer title="Produção da Equipe">
      <DateFilter />
      <Divider />
      <TeamHistoryTable />
      <br />
    </PageContainer>
  );
}
