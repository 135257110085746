import React from 'react';
import { Row, Col, Card, Divider, Button, Typography, List } from 'antd';
import safeRead from 'safely-read';
import moment from 'moment';
import formatPrice from '~/services/utils/formatPrice';
import checkPermission from '~/services/utils/checkPermission';
import ManageAbsenceOvertimeModal from '../../Modals/ManageAbsenceOvertimeModal/ManageAbsenceOvertimeModal';
import ManageMensalProductionModal from '../../Modals/ManageMensalProduction';
import ManagePackageModal from '../../Modals/ManagePackageModal/ManagePackageModal';
import { CollapsePanelContext } from '..';

export default function Cards() {
  const { user, year, month } = React.useContext(CollapsePanelContext);

  const { Text } = Typography;

  const [
    manageMensalProductionModalVisible,
    setManageMensalProductionModalVisible,
  ] = React.useState(false);

  const [
    manageAbsenceOvertimeModalVisible,
    setManageAbsenceOvertimeModalVisible,
  ] = React.useState(false);

  const [
    managePackageModalVisible,
    setManagePackageModalVisible,
  ] = React.useState(false);

  return (
    <>
      <Row gutter={[8, 8]} type="flex" justify="start">
        <Col>
          <Card style={{ textAlign: 'start' }}>
            <Row type="flex" justify="start">
              <Col>
                <Text strong>Produção do Mês</Text>
                <br />
                <br />
                {user.team_hours.team_hours !== null &&
                user.team_hours.team_hours.length > 0 ? (
                  <List
                    size="small"
                    grid="none"
                    dataSource={safeRead(
                      user,
                      ['team_hours', 'team_hours'],
                      []
                    )}
                    renderItem={item => (
                      <List.Item>
                        <Text>
                          {safeRead(
                            item,
                            ['team_display'],
                            '--',
                            team_display => `${team_display}: `
                          )}
                        </Text>
                        <Text>
                          {safeRead(
                            item,
                            ['hours'],
                            '--',
                            hours_display => `${hours_display}h`
                          )}
                        </Text>
                      </List.Item>
                    )}
                  />
                ) : (
                  <Row>
                    <Text>--</Text>
                  </Row>
                )}

                <Text strong>{`Total: ${safeRead(
                  user,
                  ['team_hours', 'total_hours'],
                  '0h',
                  value => `${value}h`
                )}`}</Text>
                {checkPermission('change_teamhours') &&
                moment().format('MM/YYYY') === `${month}/${year}` ? (
                  <Button
                    type="link"
                    onClick={() => setManageMensalProductionModalVisible(true)}
                  >
                    Gerenciar
                  </Button>
                ) : null}
              </Col>
              <Divider type="vertical" style={{ height: 'auto' }} />
              <Col>
                <Text strong>Ausências e Horas Extras</Text>
                <br />
                <br />
                {user.absence_overtime.absence_overtime.length > 0 ? (
                  <List
                    size="small"
                    grid="none"
                    dataSource={safeRead(
                      user,
                      ['absence_overtime', 'absence_overtime'],
                      []
                    )}
                    renderItem={item => (
                      <List.Item>
                        <Text>
                          {safeRead(
                            item,
                            ['team_name'],
                            '--',
                            team_display => `${team_display}: `
                          )}
                        </Text>
                        <Text>
                          {safeRead(
                            item,
                            ['hours_display'],
                            '--',
                            hours_display => `${hours_display}h`
                          )}
                        </Text>
                      </List.Item>
                    )}
                  />
                ) : (
                  <Row>
                    <Text>--</Text>
                  </Row>
                )}

                <Text strong>{`Total: ${safeRead(
                  user,
                  ['absence_overtime', 'total_hours'],
                  '0h',
                  value => `${value}h`
                )}`}</Text>
                {checkPermission('add_payablerequest') && (
                  <Button
                    type="link"
                    onClick={() => setManageAbsenceOvertimeModalVisible(true)}
                  >
                    Gerenciar
                  </Button>
                )}
              </Col>
              <Divider type="vertical" style={{ height: 'auto' }} />
              <Col style={{ alignSelf: 'center' }}>
                <Text strong>Horas a pagar</Text>
                <br />
                <Text strong>Total: </Text>
                <span
                  style={{
                    color: '#1DA57A',
                    fontWeight: 'bold',
                  }}
                >
                  {user.general.total_hours + user.absence_overtime.total_hours}
                  h
                </span>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col>
          <Card
            style={{
              textAlign: 'start',
            }}
          >
            <Text strong>Pacotes</Text>
            <br />
            <br />
            {user.service_bundle.service_bundle.length > 0 ? (
              <List
                size="small"
                grid="none"
                dataSource={safeRead(
                  user,
                  ['service_bundle', 'service_bundle'],
                  []
                )}
                renderItem={item => (
                  <List.Item>
                    <Text>
                      {safeRead(
                        item,
                        ['team_name'],
                        '--',
                        team_display => `${team_display}: `
                      )}
                    </Text>
                    <Text>
                      {safeRead(item, ['value'], '--', value =>
                        formatPrice(value)
                      )}
                    </Text>
                  </List.Item>
                )}
              />
            ) : (
              <Row>
                <Text>--</Text>
              </Row>
            )}

            <Row type="flex" justify="start">
              <Col>
                <Text strong>{`Total: ${safeRead(
                  user,
                  ['service_bundle', 'total_value'],
                  'R$ 0,00',
                  value => formatPrice(value)
                )}`}</Text>
                {checkPermission('add_payablerequest') && (
                  <Button
                    type="link"
                    onClick={() => setManagePackageModalVisible(true)}
                  >
                    Gerenciar
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {manageMensalProductionModalVisible ? (
        <ManageMensalProductionModal
          manageMensalProductionModalVisible={
            manageMensalProductionModalVisible
          }
          setManageMensalProductionModalVisible={
            setManageMensalProductionModalVisible
          }
          teamHours={user.team_hours.team_hours}
        />
      ) : null}

      {manageAbsenceOvertimeModalVisible ? (
        <ManageAbsenceOvertimeModal
          manageAbsenceOvertimeModalVisible={manageAbsenceOvertimeModalVisible}
          setManageAbsenceOvertimeModalVisible={
            setManageAbsenceOvertimeModalVisible
          }
          user={user}
        />
      ) : null}

      {managePackageModalVisible ? (
        <ManagePackageModal
          managePackageModalVisible={managePackageModalVisible}
          setManagePackageModalVisible={setManagePackageModalVisible}
          user={user}
        />
      ) : null}
    </>
  );
}
