import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect, Form } from 'antd';

export default function AutoCompleteSelect({
  label,
  field,
  form,
  placeholder,
  data,
  search,
  allowClear,
  selectSchema,
  mode,
  disabled,
  onChange,
  required,
  extraLabel,
  loading,
}) {
  return (
    <Form.Item
      label={label}
      style={{ width: '100%' }}
      required={required}
      validateStatus={form.errors[field.name] ? 'error' : null}
      help={form.errors[field.name]}
      extra={extraLabel}
    >
      <AntSelect
        loading={loading}
        showSearch
        showArrow={false}
        onChange={onChange}
        value={field.value}
        placeholder={placeholder || 'Selecione...'}
        style={{ width: '100%' }}
        onSearch={search}
        allowClear={allowClear}
        mode={mode}
        disabled={disabled}
        notFoundContent={null}
        filterOption={false}
      >
        {(data || []).map(selectSchema)}
      </AntSelect>
    </Form.Item>
  );
}

AutoCompleteSelect.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  search: PropTypes.func,
  allowClear: PropTypes.bool,
  selectSchema: PropTypes.func,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  extraLabel: PropTypes.string,
};

AutoCompleteSelect.defaultProps = {
  label: undefined,
  extraLabel: undefined,
  data: [],
  placeholder: undefined,
  search: undefined,
  allowClear: undefined,
  selectSchema: undefined,
  mode: undefined,
  disabled: undefined,
  required: undefined,
};
