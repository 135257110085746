export const AUTH = '/auth';
export const AUTH_PASSWORD_RESET = '/auth/password_reset/';
export const AUTH_PASSWORD_CHANGE = '/auth/password_change/';
export const AUTH_PASSWORD_RESET_CONFIRM = '/auth/password_reset/confirm/';
export const AUTH_USER_PERMISSIONS = '/auth/user-permissions/';
export const AUTH_PERMISSIONS = '/auth/permissions/';
export const AUTH_REFRESH = '/auth/refresh/';
export const AUTH_USER = '/auth/user/';
export const AUTH_USERS = '/auth/users/';
export const AUTH_USERS_ID = id => `/auth/users/${id}/`;
export const PATCH_AUTH_USERS_ID = id => `/auth/users/${id}/`;
export const AUTH_GROUPS = '/auth/groups/';
export const AUTH_PROFILE_GROUPS = '/auth/profile_groups/';
export const AUTH_PROFILES = '/auth/profiles/';
export const AUTH_PROFILES_ID = id => `/auth/profiles/${id}/`;
export const AUTH_GROUPS_ID = id => `/auth/groups/${id}/`;
export const POST_USER_AUTH_PROFILE = profile_id =>
  `/auth/profiles/${profile_id}/user/`;
export const DELETE_USER_AUTH_PROFILE = (profile_id, user_id) =>
  `/auth/profiles/${profile_id}/user/${user_id}`;
export const POST_USER_AUTH_GROUP = group_id =>
  `/auth/groups/${group_id}/user/`;
export const DELETE_USER_AUTH_GROUP = (group_id, user_id) =>
  `/auth/groups/${group_id}/user/${user_id}`;
export const AUTH_PRIVACY_TOOLS = '/auth/privacy_tools/';
