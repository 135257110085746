/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import Login from '~/pages/Autenticacao/Login';
import ForgotPassword from '~/pages/Autenticacao/ForgotPassword';
import Home from '~/pages/Home';
import NotFound from '~/pages/NotFound';
import Profile from '~/pages/Profile';
import History from '~/pages/Production/History';
import AbsenceOvertime from '~/pages/Production/AbsenceOvertime';
import { routePaths } from './paths';
import Settings from '~/pages/Settings';
import Budget from '~/pages/Budget/';
import ProductionDays from '~/pages/ProductionDays/';
import Rectification from '~/pages/Management/RectificationApproval/RectificationApproval';
import ProfileAndPermissions from '~/pages/GroupsAndPermissions';
import ProfilePermissions from '~/pages/ProfilePermissions';
import AbsenceOvertimeApproval from '~/pages/Management/AbsenceOvertimeApproval/AbsenceOvertimeApproval';
import VacationsAllowences from '~/pages/Production/VacationsAllowences';
import VacationsAllowenceApproval from '~/pages/Management/VacationsAllowenceApproval';
import Project from '~/pages/Management/Project';
import MyTeam from '~/pages/Management/MyTeam/MyTeam';
import ManageUser from '~/pages/ManageUser/ManageUser';
import HomeSilver from '~/pages/HomeSilver/HomeSilver';
import HomeSilverUser from '~/pages/HomeSilverUser/HomeSilverUser';
import UserRegistry from '~/pages/ManageUser/UserRegistry';
import FinancialRequests from '~/pages/FinancialRequests';
import ConsolidatedProduction from '~/pages/ConsolidateProductionV2/ConsolidateProductionV2';
// import Rpc from '~/pages/Rpc/Rpc';
// import MyRpc from '~/pages/Rpc/MyRpc';
import EdesenvHistorical from '~/pages/EdesenvHistorical/EdesenvHistorical';
import Report from '~/pages/Management/Report/Report';
import ProductionControl from '~/pages/Management/Report/ProductionControl/ProductionControl';
import HitByShutdown from '~/pages/Management/Hit_by_shutdown/HitByShutdown';
import ConsolidateProductionExecutive from '~/pages/ConsolidateProductionExecutive/ConsolidateProductionExecutive';
import TeamProductionHistory from '~/pages/Management/Project/ProjectsTable/TeamProductionHistory/TeamProductionHistory';
import MySquad from '~/pages/Management/MySquad/MySquad';
// eslint-disable-next-line import/named
import { TeamList } from '~/pages/TeamList';

export default function Routes() {
  return (
    <Switch>
      <Route path={routePaths.AUTH} exact component={Login} />

      <Route
        path={routePaths.FORGOT_PASSWORD}
        exact
        component={ForgotPassword}
      />

      <Route exact path={routePaths.HOME} component={Home} isPrivate />

      <Route exact path={routePaths.PROFILE} component={Profile} isPrivate />

      <Route
        exact
        path={routePaths.PROD_HISTORY}
        component={History}
        isPrivate
        // cooperative_member
      />

      <Route
        exact
        path={routePaths.ABSENCE_ORVETIME}
        component={AbsenceOvertime}
        isPrivate
        cooperative_member
      />

      <Route
        exact
        path={routePaths.HOME_SILVER_USER}
        component={HomeSilverUser}
        isPrivate
        cooperative_member
      />

      <Route exact path={routePaths.SETTINGS} component={Settings} isPrivate />

      <Route
        exact
        path={routePaths.RECTIFICATION}
        component={Rectification}
        isPrivate
        permissions="view_rectification"
      />

      <Route exact path={routePaths.BUDGET} component={Budget} isPrivate />

      <Route
        exact
        path={routePaths.PRODUCTION_DAYS}
        component={ProductionDays}
        isPrivate
      />

      <Route
        exact
        path={routePaths.PROFILE_AND_PERMISSIONS}
        component={ProfileAndPermissions}
        isPrivate
        permissions="view_group"
      />

      <Route
        exact
        path={routePaths.PROFILE_PERMISSIONS}
        component={ProfilePermissions}
        isPrivate
        permissions="view_profile"
      />

      <Route
        exact
        path={routePaths.ABSENCE_OVERTIME_APPROVAL}
        component={AbsenceOvertimeApproval}
        isPrivate
        permissions="view_request"
      />

      <Route
        exact
        path={routePaths.VACATIONS_ALLOWENCES}
        component={VacationsAllowences}
        isPrivate
        cooperative_member
      />

      <Route
        exact
        path={routePaths.VACATIONS_ALLOWENCES_APPROVAL}
        component={VacationsAllowenceApproval}
        isPrivate
        permissions="view_paidleave"
      />

      <Route
        exact
        path={routePaths.PROJECT}
        component={Project}
        isPrivate
        permissions="view_team"
      />

      <Route
        exact
        path={routePaths.TEAM_PRODUCTION}
        component={TeamProductionHistory}
        isPrivate
        permissions="view_team"
      />

      <Route
        exact
        path={routePaths.MY_TEAM}
        component={MyTeam}
        isPrivate
        permissions="view_team"
      />

      <Route
        exact
        path={routePaths.MY_SQUAD}
        component={MySquad}
        isPrivate
        permissions="view_squad"
      />

      <Route
        exact
        path={routePaths.TEAM_LIST}
        component={TeamList}
        isPrivate
        permissions="view_squad"
      />

      <Route
        exact
        path={routePaths.MANAGE_USER}
        component={ManageUser}
        isPrivate
      />

      <Route
        exact
        path={routePaths.HOME_SILVER}
        component={HomeSilver}
        isPrivate
      />

      <Route
        exact
        path={`${routePaths.USER_RESGISTRY}/:id`}
        component={UserRegistry}
        isPrivate
      />

      <Route
        exact
        path={routePaths.CONSOLIDATE_PRODUCTION}
        component={ConsolidatedProduction}
        isPrivate
        permissions="view_consolidatedproduction"
      />

      <Route
        exact
        path={routePaths.FINANCIAL_REQUESTS}
        component={FinancialRequests}
        isPrivate
        permissions="view_payablerequest"
      />

      <Route exact path={routePaths.REPORT} component={Report} isPrivate />

      <Route
        exact
        path={routePaths.PRODUCTION_CONTROL}
        component={ProductionControl}
        isPrivate
      />

      <Route
        exact
        path={routePaths.SHUTDOWN}
        component={HitByShutdown}
        isPrivate
      />

      <Route
        exact
        path={routePaths.CONSOLIDATE_PRODUCTION_EXECUTIVE}
        component={ConsolidateProductionExecutive}
        isPrivate
      />
      {/* <Route
        exact
        path={routePaths.MY_RPC}
        component={MyRpc}
        isPrivate
        cooperative_member
      />

      <Route
        exact
        path={routePaths.RPC}
        component={Rpc}
        isPrivate
        permissions="view_consolidatedproduction"
      /> */}

      <Route
        exact
        path={routePaths.EDESENV}
        component={EdesenvHistorical}
        isPrivate
      />

      <Route path="*" component={NotFound} notFound />
    </Switch>
  );
}
