import React from 'react';
import { Divider } from 'antd';

import PageContainer from '~/components/Layout/PageContainer';
import SearchForm from './SearchForm/index';
import ProductionControlTable from './ProductionControlTable';

export default function ProductionControl() {
  return (
    <PageContainer title="Relatórios de Produção dos Cooperados">
      <SearchForm />
      <Divider />
      <ProductionControlTable />
    </PageContainer>
  );
}
