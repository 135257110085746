import React from 'react';
import {
  Modal,
  Col,
  Typography,
  Row,
  Button,
  Divider,
  Select as AntdSelect,
} from 'antd';
import { Formik, Field, Form } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Container } from '../../../style';
import Select from '~/components/DataEntry/Select';
import { fetchData, postDaytransfer } from '../..';
import { ProductionDaysContext } from '../../../index';

function RelocateDayModal({ visible, setVisible }) {
  const months = moment.months().map(month => ({ value: month }));

  const { setBusinessDayData } = React.useContext(ProductionDaysContext);

  const { Title, Text } = Typography;

  return (
    <Modal
      title={<Title level={4}>Remanejar Dias</Title>}
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
      destroyOnClose
      closable
    >
      <Formik
        initialValues={{ from_month: null, to_month: null }}
        onSubmit={values => {
          postDaytransfer(values, setVisible);
          fetchData(setBusinessDayData);
        }}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Container>
              <Row gutter={24} type="flex" justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Text>Transferir 1(um) dia de</Text>
                  <Field
                    id="from_month"
                    name="from_month"
                    data={months}
                    renderItem={(item, length) => (
                      <AntdSelect.Option key={item.value} value={length + 1}>
                        {item.value}
                      </AntdSelect.Option>
                    )}
                    component={Select}
                    allowClear
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Text>Para o mês de</Text>
                  <Field
                    id="to_month"
                    name="to_month"
                    data={months}
                    renderItem={(item, lenght) => (
                      <AntdSelect.Option key={item.value} value={lenght + 1}>
                        {item.value}
                      </AntdSelect.Option>
                    )}
                    component={Select}
                    allowClear
                  />
                </Col>
              </Row>
              <br />
              <br />
              <Row gutter={24} type="flex" justify="end">
                <Col>
                  <Button onClick={() => setVisible(false)}>Cancelar</Button>
                  <Divider type="vertical" />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!values.from_month || !values.to_month}
                  >
                    Incluir
                  </Button>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

RelocateDayModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default RelocateDayModal;
