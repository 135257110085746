import React, { useContext, useEffect } from 'react';
import { Typography, Button, Row, Col, Divider, Modal, Spin } from 'antd';
import safeRead from 'safely-read';
import moment from 'moment';

import history, {
  getAllQueriesParams,
  pushWithQueriesParams,
} from '~/services/history';
import formatPrice from '~/services/utils/formatPrice';
import { HitByShutdownContext } from '../HitByShutdown';

import { ModalItemContainer, ModalItem, GrossPaymentHolder } from './styles';
import { handleAppovalDetail } from '../TeamTable';

const { confirm } = Modal;

export default function DetailModal() {
let today = new Date();
let day = String(today.getDate()).padStart(2, '0');
let month = String(today.getMonth() + 1).padStart(2, '0');
let year = today.getFullYear();
const ActualDate = year + '-' + month + '-' + day;
  const {
    isLoading,
    dataDetail,
    detailModalVisible,
    setDetailModalVisible,
  } = useContext(HitByShutdownContext);
  const { ...queries } = getAllQueriesParams();
  const { Text, Title } = Typography;

  useEffect(() => {
    if (!dataDetail) {
      setDetailModalVisible(false);
    }
  }, [dataDetail]);

  return (
    <Modal
      title={<Title level={4}>Detalhes</Title>}
      footer={null}
      visible={detailModalVisible}
      onCancel={() => setDetailModalVisible(false)}
      closable
      destroyOnClose
      centered
    >
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <ModalItemContainer>
            <ModalItem width="25%">
              <Text strong style={{ display: 'block' }}>
                ID
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {safeRead(dataDetail, ['id'], '--')}
              </Text>
            </ModalItem>

            <ModalItem width="30%">
              <Text strong style={{ display: 'block' }}>
                Matricula CSYS
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {safeRead(dataDetail, ['csys_registration_number'], '--')}
              </Text>
            </ModalItem>

            <ModalItem width="45%">
              <Text strong style={{ display: 'block' }}>
                Valor por hora
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {formatPrice(safeRead(dataDetail, ['hourly_rate'], '0'))}
              </Text>
            </ModalItem>

            <ModalItem width={dataDetail?.status_display ? '70%' : '80%'}>
              <Text strong style={{ display: 'block' }}>
                Nome
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {safeRead(dataDetail, ['name_display'], '--')}
              </Text>
            </ModalItem>

            <ModalItem width="20%">
              <Text strong style={{ display: 'block' }}>
                Data
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {moment(safeRead(dataDetail, ['customdata'], ActualDate)).format('DD/MM/YYYY')}
              </Text>
            </ModalItem>

            {dataDetail?.status_display && (
              <ModalItem width="30%">
                <Text strong style={{ display: 'block' }}>
                  Status
                </Text>
                <Text type="secondary" style={{ display: 'block' }}>
                  {safeRead(dataDetail, ['status_display'], '--')}
                </Text>
              </ModalItem>
            )}
          </ModalItemContainer>
          <GrossPaymentHolder>
            <Text>
              <Row gutter={24} type="flex" justify="space-between">
                <Col>
                  <Text>Férias (D.A.R.)</Text>
                  <br />
                  <br />
                  <Text>Abonos</Text>
                  <br />
                  <br />
                  <Text>Produção do mês</Text>
                  <br />
                  <br />
                  <Text>Banco de Horas</Text>
                  <br />
                  <br />
                  <Text>Prata da Casa</Text>
                </Col>

                <Col>
                  <Text strong>
                    {safeRead(
                      dataDetail,
                      ['paid_leave_balance', 'annual_leaves_balance', 'total'],
                      '0'
                    )}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {safeRead(
                      dataDetail,
                      ['paid_leave_balance', 'days_off_balance', 'total'],
                      '0'
                    )}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {safeRead(dataDetail, ['production_balance', 'total'], '0')}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {safeRead(dataDetail, ['hours_balance', 'total'], '0')}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {safeRead(dataDetail, ['home_grown', 'bonus_hours'], '0')+'h'}
                  </Text>
                </Col>

                <Col>
                  <Text strong>
                    {formatPrice(
                      safeRead(
                        dataDetail,
                        [
                          'paid_leave_balance',
                          'annual_leaves_balance',
                          'value',
                        ],
                        '0'
                      )
                    )}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {formatPrice(
                      safeRead(
                        dataDetail,
                        ['paid_leave_balance', 'days_off_balance', 'value'],
                        '0'
                      )
                    )}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {formatPrice(
                      safeRead(dataDetail, ['production_balance', 'value'], '0')
                    )}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {formatPrice(
                      safeRead(dataDetail, ['hours_balance', 'value'], '0')
                    )}
                  </Text>
                  <br />
                  <br />
                  <Text strong>
                    {formatPrice(
                      safeRead(dataDetail, ['home_grown', 'bonus_hours_value'], '0')
                    )}
                  </Text>
                </Col>
              </Row>
            </Text>

            <Divider
              type="horizontal"
              className="ant-divider-horizontal-text"
            />
            <Row type="flex" justify="space-between">
              <Col>
                <Text strong>Bruto a pagar:</Text>
              </Col>
              <Col>
                <span style={{ color: '#1DA57A', fontWeight: 'bold' }}>
                  {formatPrice(safeRead(dataDetail, ['total_value'], '0'))}
                </span>
              </Col>
            </Row>
          </GrossPaymentHolder>
        </>
      )}

      {dataDetail?.status_display === null && (
        <Row type="flex" justify="end">
          <Col>
            <Button
              onClick={() => {
                confirm({
                  title: 'Deseja realmente aprovar este desligamento?',
                  centered: true,
                  async onOk() {
                    pushWithQueriesParams(history.location.pathname, {
                      ...queries,
                      statusData: '',
                    });

                    await handleAppovalDetail(
                      dataDetail.id,
                      dataDetail.date_end_work,
                      setDetailModalVisible
                    );
                  },
                });
              }}
              type="primary"
              disabled={isLoading}
            >
              Aprovar
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
}
