import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select as AntdSelect, Button } from 'antd';
import { Formik, Form, Field } from 'formik';
import debounce from 'lodash.debounce';

import checkPermission from '~/services/utils/checkPermission';
import badgeStatus from '~/constants/badgesStatus';
import createStatusSelectOptions from '~/services/utils/createStatusSelectOptions';
import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { ActionsContainer } from './styles';
import { patchProject } from './index';
import { useSearchProject } from '../../../../index';

export default function ProjectData({ project, updateTableData }) {
  const { loadingProjects, projects, setSearch } = useSearchProject();

  const { team_name, parent_team, status, parent_team_display, id } = project;

  useEffect(() => {
    if (parent_team_display) {
      setSearch(parent_team_display);
    }
  }, [parent_team_display, setSearch]);

  return (
    <Formik
      initialValues={{
        team_name,
        parent_team,
        status,
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await patchProject(id, values);

        updateTableData();

        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Field
            id="team_name"
            name="team_name"
            label="Nome da Equipe"
            component={Input}
            disabled={!checkPermission('change_team')}
          />

          <Field
            id="parent_team"
            name="parent_team"
            label="Equipe Pai"
            loading={loadingProjects}
            component={AutoCompleteSelect}
            selectSchema={item => (
              <AntdSelect.Option key={item.id} value={item.id}>
                {item.team_name}
              </AntdSelect.Option>
            )}
            onChange={e => {
              if (!e) {
                setFieldValue('parent_team', null);
              } else {
                setFieldValue('parent_team', e);
              }

              setSearch(undefined);
            }}
            search={debounce(setSearch, 800)}
            allowClear
            data={projects.results}
            disabled={!checkPermission('change_team')}
          />

          <Field
            id="status"
            name="status"
            label="Status"
            allowClear
            component={Select}
            onChange={formikStatus => {
              setFieldValue('status', formikStatus);
            }}
            data={createStatusSelectOptions([
              badgeStatus.APPROVED,
              badgeStatus.CANCELED,
              badgeStatus.NOT_REQUESTED,
              badgeStatus.DISAPPROVED,
              badgeStatus.IN_ANALYSIS,
              badgeStatus.IN_FINANCIAL_ANALYSIS,
              badgeStatus.APPROVED_BY_FINANCIAL,
              badgeStatus.REPROVED_BY_FINANCIAL,
            ])}
            renderItem={item => (
              <AntdSelect.Option key={item.key} value={item.key}>
                {item.value}
              </AntdSelect.Option>
            )}
            disabled={!checkPermission('change_team')}
          />

          {checkPermission('change_team') && (
            <ActionsContainer>
              <Button
                disabled={!values.team_name || !values.status}
                loading={isSubmitting}
                htmlType="submit"
                type="primary"
              >
                Salvar
              </Button>
            </ActionsContainer>
          )}
        </Form>
      )}
    </Formik>
  );
}

ProjectData.defaultProps = {
  project: undefined,
};

ProjectData.propTypes = {
  project: PropTypes.objectOf(PropTypes.any),
  updateTableData: PropTypes.func.isRequired,
};
