import React, { useEffect, useState } from 'react';
import { Modal, Typography, Descriptions, message} from 'antd';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { PERSON_HOME_GROWN } from '~/constants/endpoints/home_grown';

const { Title } = Typography;


export default function DetailPerson({
    personModalVisible,
    setPersonModalVisible,
    person_id
}) {
    const [data, setData] = useState({});

    async function getPersonInfo(person_id) {
        try{
        var response = await api.get(PERSON_HOME_GROWN(person_id))
        setData(response.data)
    } catch (error) {
        return message.error(
            error,
            'Ocorreu um erro ao tentar obter informações do Cooperado.'
            )
        }
    }

    useEffect(() => {
        if (person_id) { getPersonInfo(person_id) }
    }, [person_id])

    return (
        <Modal
            visible={personModalVisible}
            onCancel={setPersonModalVisible}
            footer={false}
            width={750}
            destroyOnClose
        >
            <Title level={3}>Benefícios Adquiridos</Title>
            <Descriptions title={data[0]?.person} bordered>
                <Descriptions.Item label="Bônus de Horas" span={3}>{data[0]?.bonus_hours ?? '0'}h</Descriptions.Item>
                <Descriptions.Item label="Bônus em dinheiro" span={3}>R${data[1]?.bonus_reward ?? '0'}</Descriptions.Item>
                <Descriptions.Item label="Tempo de Prata" span={3}>{data[0]?.home_grown_age ?? '0'} Anos</Descriptions.Item>
                <Descriptions.Item label="Data de Aniversário" span={3}>{data[0]?.birthday_home_grown ?? '--/--/----'}</Descriptions.Item>
            </Descriptions>

        </Modal>
    )
}

DetailPerson.propTypes = {
    personModalVisible: PropTypes.bool.isRequired,
    setPersonModalVisible: PropTypes.func.isRequired,
}