import produce from 'immer';
import {
  USER_PERMISSIONS_REQUEST,
  USER_PERMISSIONS_FAILURE,
  USER_PERMISSIONS_SUCCESS,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_FAILURE,
} from './actions';

const INITIAL_STATE = {
  profile: {
    id: undefined,
    first_name: '',
    last_name: '',
    name_display: '',
    csys_registration_number: undefined,
    is_cooperative_member: false,
    hourly_rate: '',
    status: '',
    status_display: '',
    position_display: { id: undefined, position_display: '' },
    avatar: '',
    permissions: [],
    budgets: [],
    groups: [],
  },
  loading: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case USER_PERMISSIONS_REQUEST:
        draft.loading = true;
        break;
      case USER_PERMISSIONS_SUCCESS:
        draft.profile.permissions = action.payload.permissions;
        draft.loading = false;
        break;
      case USER_PERMISSIONS_FAILURE:
        draft.loading = false;
        break;
      case GET_USER_DATA_REQUEST:
        draft.loading = true;
        break;
      case GET_USER_DATA_SUCCESS:
        draft.profile = { ...draft.profile, ...action.payload.data };
        draft.loading = false;
        break;
      case GET_USER_DATA_FAILURE:
        draft.loading = false;
        break;
      default:
    }
  });
}
