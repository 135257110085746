import React, { useState } from 'react';
import Table from '~/components/DataDisplay/Table';
import MultipleActionButton from '~/components/MultipleActionButton';
import history, {
  getAllQueriesParams,
  pushWithQueriesParams,
} from '~/services/history';
import ReasonModal from './ReasonModal';
import Details from '../Modals/Details/Details';
import {
  makeColumns,
  useTableData,
  FinancialTableContext,
  fetchTableData,
} from '.';

function FinancialRequestsTable() {
  const {
    page,
    page_size,
    date_start,
    date_end,
    request_type,
    status,
  } = getAllQueriesParams();
  const { tableData, loading, setTableData, setLoading } = useTableData();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentStatuses, setcurrentStatuses] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [rowId, setRowId] = useState();

  const rowSelection = {
    selectedRowKeys,
    onChange: selected => setSelectedRowKeys(selected),
    getCheckboxProps: record => ({
      disabled: record.status !== 'em_analise_financeira',
    }),
  };

  return (
    <FinancialTableContext.Provider
      value={{
        currentStatuses,
        setcurrentStatuses,
        selectedRowKeys,
        setSelectedRowKeys,
        updateTableData: async () => {
          setLoading(true);
          const data = await fetchTableData();
          setTableData(data);
          setLoading(false);
        },
      }}
    >
      <MultipleActionButton
        actions={[
          {
            title: 'Aprovar',
            action: async () => {
              setcurrentStatuses('aprovado_pelo_financeiro');
              setVisible(true);
            },
          },
          {
            title: 'Reprovar',
            action: () => {
              setcurrentStatuses('reprovado_pelo_financeiro');
              setVisible(true);
            },
          },
        ]}
      >
        Ações
      </MultipleActionButton>

      <Table
        style={{ marginTop: 10 }}
        columns={makeColumns(setShowDetails, setRowId)}
        dataSource={tableData.results.map(
          ({ id, request_type_display, person_display, ...rest }) => ({
            key: id,
            id: id,
            request: request_type_display,
            name: person_display.name_display,
            ...rest,
          })
        )}
        paginated
        currentPage={Number(page) || 1}
        count={tableData.count}
        pageSize={Number(page_size) || 10}
        rowSelection={rowSelection}
        scroll={{ x: true }}
        loading={loading}
        onChange={(pagination, filters, sorter, extra) => {
          pushWithQueriesParams(history.location.pathname, {
            status: status,
            date_start: date_start,
            date_end: date_end,
            request_type: request_type,
            page: 1,
            page_size: page_size,
            ordering:
              sorter.order === 'ascend'
                ? sorter.columnKey
                : `-${sorter.columnKey}`,
          });
        }}
      />

      {visible && <ReasonModal visible={visible} setVisible={setVisible} />}
      {showDetails && (
        <Details
          visible={showDetails}
          setVisible={setShowDetails}
          rowId={rowId}
        />
      )}
    </FinancialTableContext.Provider>
  );
}

export default FinancialRequestsTable;
