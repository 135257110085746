import { message } from 'antd';
import safeRead from 'safely-read';

import Supervisors from './Supervisors';
import api from '~/services/api';
import {
  TEAM_MANAGE_ID,
  DELETE_TEAM_MANAGER,
} from '~/constants/endpoints/team';

export async function deleteManager(team_id, supervisor) {
  try {
    await api.delete(DELETE_TEAM_MANAGER(team_id, supervisor));

    message.success('Supervisor deletado com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao deletar supervisor.'
      )
    );
  }
}

export async function addManagers(team_id, supervisors) {
  try {
    await api.post(TEAM_MANAGE_ID(team_id), { supervisors });

    message.success('Supervisores atribuídos com sucesso!');
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'data', 'detail'],
        'Erro ao atribuir supervisores.'
      )
    );
  }
}

export default Supervisors;
