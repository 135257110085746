import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }) => theme.textColor};
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 90px;
  font-weight: bold;
  text-shadow: 0 0 12px ${({ theme }) => theme.primaryColor};
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.textColor};

  b {
    color: ${({ theme }) => theme.textColor};
  }
`;
