import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.Home';

export default defineMessages({
  startProjectHeader: {
    id: `${scope}.well_come`,
    defaultMessage: 'Bem-vindo ao Portal Coopersystem',
  },
});
