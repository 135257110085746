import { useState, useEffect } from 'react';
import { message } from 'antd';
import safeRead from 'safely-read';
import api from '~/services/api';
import EdesenvHistoricalTable from '.';
import { EDESENV } from '~/constants/endpoints/edesenv';
import { getQueryParam } from '~/services/history';
import moment from 'moment';

export const makeColumns = [
  {
    title: 'Data Solicitação',
    dataIndex: 'date',
    key: 'date',
    width: 110,
  },
  {
    title: 'Usuário',
    dataIndex: 'user',
    key: 'user',
    width: 180,
  },
  {
    title: 'Status Alocação',
    dataIndex: 'requestType',
    key: 'requestType',
  },
  {
    title: 'Ausência a Abonar (AA)',
    dataIndex: 'AA',
    key: 'AA',
  },
  {
    title: 'Ausência a Compensar (AC)',
    dataIndex: 'AC',
    key: 'AC',
  },
  {
    title: 'Ausência a Descontar (AD)',
    dataIndex: 'AD',
    key: 'AD',
  },
  {
    title: 'Hr. Extra a Compensar (EC)',
    dataIndex: 'EC',
    key: 'EC',
  },
  {
    title: 'Hr. Extra a Faturar (EF)',
    dataIndex: 'EF',
    key: 'EF',
  },
  {
    title: 'Motivo',
    dataIndex: 'reason',
    key: 'reason',
  },
  {
    title: 'Justificativa',
    dataIndex: 'justification',
    key: 'justification',
  },
];

export async function fetchTableData(setTableData, setLoading, queries) {
  setLoading(true);
  try {
    const { data } = await api.get(EDESENV, {
      params: queries,
    });
    setLoading(false);
    setTableData(data);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Erro ao buscar dados da tabela')
    );
    setLoading(false);
    setTableData([]);
  }
}

export function useTableData() {
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [tableData, setTableData] = useState({
    results: [],
    count: 0,
    next: null,
    previous: null,
  });

  const page = parseInt(getQueryParam('page'), 10) || 1;
  const page_size = parseInt(getQueryParam('page_size'), 10) || 10;
  const username = getQueryParam('username__icontains');
  const name = getQueryParam('name');

  useEffect(() => {
    async function loadTableData() {
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        username__icontains: username,
        name: name,
      });
    }

    loadTableData();
  }, [page, page_size, username, name]);

  return {
    tableData,
    setTableData,
    loadingTableData,
    setLoadingTableData,
    fetchTableData: () =>
      fetchTableData(setTableData, setLoadingTableData, {
        page,
        page_size,
        username__icontains: username,
        name: name,
      }),
  };
}

export function formatTableData(tableList) {
  if (tableList.length) {
    return tableList.map(item => ({
      key: safeRead(item, ['id']),
      date: safeRead(item, ['date'], '--', value =>
        moment(value).format('DD/MM/YYYY')
      ),
      user: safeRead(item, ['username'], '--'),
      requestType: safeRead(item, ['request_type'], '--'),
      AA: safeRead(item, ['allowed_absence'], '--'),
      AC: safeRead(item, ['compensated_absence'], '--'),
      AD: safeRead(item, ['deducted_absence'], '--'),
      EC: safeRead(item, ['compensatory_overtime'], '--'),
      EF: safeRead(item, ['paid_overtime'], '--'),
      reason: safeRead(item, ['reason'], '--'),
      justification: safeRead(item, ['justification'], '--'),
    }));
  }

  return [];
}

export default EdesenvHistoricalTable;
