import styled from 'styled-components';
import { darken } from 'polished';

export function getColor(position, days) {
  const colors = {
    ferias: 'seaGreen',
    abono: 'steelBlue',
    nulo: '#7E7E7E',
  };

  if (days === undefined) {
    return 'transparent';
  }

  if (days === 0) {
    return colors.nulo;
  }

  if (position !== 5 && position !== 11) {
    return colors.ferias;
  }

  return colors.abono;
}

export const Container = styled.div`
  margin: 8px 0 40px 0;

  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 100px;
    background-color: ${({ theme }) => darken(0.05, theme.componentBackground)};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: ${({ theme }) => darken(0.12, theme.componentBackground)};
  }

  ::-webkit-scrollbar-corner {
    background-color: ${({ theme }) => darken(0.05, theme.componentBackground)};
  }

  overflow-x: scroll;
`;

export const TimelineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;

  border: 2px solid ${({ theme }) => darken(0.02, theme.componentBackground)};
  background-color: ${({ theme }) => darken(0.01, theme.componentBackground)};
  border-radius: 4px;
  padding: 10px 14px;

  @media (max-width: 1200px) {
    min-width: 983px;
  }
`;

export const TimelineItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  width: 100%;

  &:after {
    content: ' ';
    position: absolute;
    height: 70px;
    right: 0;
    border-right: 1px solid ${({ theme }) => darken(0.1, theme.borderColorBase)};
    bottom: 0;
    display: ${({ last }) => (last ? 'none' : 'block')};
  }
`;

export const TimelineItemTitle = styled.div`
  text-transform: uppercase;
`;

export const TimelineItemContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 14px 0 14px 0;
  background-color: ${({ position, days }) => getColor(position, days)};
  width: 100%;
  height: 40px;
  color: white !important;
  border-bottom-left-radius: ${({ first }) => (first ? '40px' : null)};
  border-top-left-radius: ${({ first }) => (first ? '40px' : null)};
  border-bottom-right-radius: ${({ last }) => (last ? '40px' : null)};
  border-top-right-radius: ${({ last }) => (last ? '40px' : null)};
`;
