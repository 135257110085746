import styled from 'styled-components';

export const Container = styled.div`
  #input-name {
    width: 280px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 20px;
`;

export const TransferContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TreeContainerHeader = styled.div`
  position: sticky;
  z-index: 1000;
  top: 0;
  height: 20px;
  margin-bottom: 12px;
  background: ${({ theme }) => theme.componentBackground};
  color: ${({ theme }) => theme.textColor};
`;

export const TreeContainer = styled.div`
  &:not(:last-child) {
    margin-right: 20px;
  }

  &:not(:first-child) {
    margin-left: 20px;
  }

  .tree-holder {
    padding: 0 6px 6px 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border: 1px solid ${({ theme }) => theme.borderColorBase};
    border-radius: 4px;
    min-width: 300px;
    height: 440px;
  }

  .ant-typography-secondary {
    display: inline-block;
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

export const TransferActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;

  margin-top: 32px;

  button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;
