/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import {
  Button,
  Divider,
  Typography,
  Select as AntdSelect,
  Spin,
  Alert,
} from 'antd';
import safeRead from 'safely-read';
import moment from 'moment';
import Select from '~/components/DataEntry/Select';

import DatePicker from '~/components/DataEntry/DatePicker';

import {
  getTotalBalance,
  postDayoffRequest,
  calculateLastDayOffOrVacations,
} from '../index';
import { VacationsContext } from '../../../index';
import { BackgroundContainer, DateContainer } from '../styles';

const { Text } = Typography;

function DayOff({
  type,
  lastDayOfVacationsOfDayOff,
  setLastDayOfVacationsOfDayOff,
  lastDayOfVacationsOfDayOffLoading,
  setLastDayOfVacationsOfDayOffLoading,
  setVisible,
  referencePeriod,
  values,
  setFieldValue,
  setSubmitting,
  isSubmitting,
}) {
  const { total_days } = values;

  const { updatePaidLeave, paidLeave, paidLeaveLoading } = useContext(
    VacationsContext
  );

  const situation = safeRead(paidLeave, ['timeline', 'situation']);

  const paidDays = [];

  const days_off = safeRead(paidLeave, [
    'timeline',
    'balance',
    [0],
    'days_off',
  ]);

  // eslint-disable-next-line no-unused-vars
  const CreateArrayOfDaysOff = Array.from(
    { length: days_off.remaining },
    (v, k) => {
      return paidDays.push({ key: k + 1, value: k + 1 });
    }
  );

  useEffect(() => {
    if (values.begin_date && values.total_days) {
      calculateLastDayOffOrVacations(
        values.begin_date,
        values.total_days,
        setLastDayOfVacationsOfDayOff,
        setLastDayOfVacationsOfDayOffLoading
      );
    }
  }, [values.begin_date, values.total_days]);

  useEffect(() => {
    setFieldValue('total_days', undefined);
    setFieldValue('begin_date', undefined);
  }, [referencePeriod]);

  return (
    <>
      <>
        {paidLeaveLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            {situation !== 'EXPIRED' && days_off.remaining !== 0 && (
              <>
                <BackgroundContainer>
                  <Text strong>Saldo atual</Text>

                  <Text strong>{`${days_off.remaining}`} dias</Text>
                </BackgroundContainer>

                <Field
                  id="duration-input"
                  name="total_days"
                  label="Duração (dias úteis)"
                  placeholder="Selecione..."
                  component={Select}
                  style={{ width: '100%', maxWidth: 280 }}
                  data={paidDays}
                  disabled={days_off.remaining === 0}
                  renderItem={item => (
                    <AntdSelect.Option key={item.key} value={item.value}>
                      {item.value}
                    </AntdSelect.Option>
                  )}
                  allowClear
                />

                <>
                  <DateContainer>
                    <Field
                      id="date-dayoff-input"
                      name="begin_date"
                      label="Data de abono"
                      allowClear={false}
                      component={DatePicker}
                      onChange={item => {
                        setFieldValue(
                          'begin_date',
                          new Date(item._d).toISOString().split('T')[0]
                        );
                      }}
                    />
                  </DateContainer>
                  {values.begin_date && values.total_days ? (
                    <BackgroundContainer>
                      <Text strong>Data de término do descanso:</Text>
                      {lastDayOfVacationsOfDayOffLoading === false ? (
                        <Text strong>
                          {safeRead(
                            lastDayOfVacationsOfDayOff,
                            ['last_date'],
                            'Erro ao tentar buscar data de descanso.',
                            item => ` ${moment(item).format('DD/MM/YYYY')}`
                          )}
                        </Text>
                      ) : (
                        <Spin />
                      )}
                    </BackgroundContainer>
                  ) : null}
                </>

                <BackgroundContainer col>
                  <div style={{ width: '100%' }}>
                    <Text strong>Período selecionado</Text>

                    <Text strong>{total_days || '0'} dia(s)</Text>
                  </div>

                  <Divider />

                  <div style={{ width: '100%' }}>
                    <Text strong>Saldo restante após aprovação</Text>

                    {total_days && (
                      <Text strong>
                        {days_off.remaining - total_days} dia(s)
                      </Text>
                    )}

                    {!total_days && (
                      <Text strong>{days_off.remaining} dia(s)</Text>
                    )}
                  </div>
                </BackgroundContainer>
              </>
            )}
            {situation === 'EXPIRED' && (
              <Alert
                message="Esse período aquisitivo já expirou. Contate seu coordenador."
                type="info"
                showIcon
              />
            )}
            {situation === 'ACTUAL' && days_off.remaining === 0 && (
              <Alert
                message="Você não possui saldo de abonos para esse período aquisitivo."
                type="info"
                showIcon
              />
            )}
            <br />
          </>
        )}
      </>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => setVisible(false)}>Cancelar</Button>
        {getTotalBalance(safeRead(paidLeave, ['timeline', 'balance']), type) >
          0 && (
          <>
            <Divider type="vertical" />
            <Button
              loading={isSubmitting}
              onClick={async () => {
                setSubmitting(true);
                await postDayoffRequest(values, referencePeriod);
                await updatePaidLeave();
                setVisible(false);
              }}
              type="primary"
              disabled={
                !values.begin_date || !values.total_days || !referencePeriod
              }
            >
              Solicitar
            </Button>
          </>
        )}
      </div>
    </>
  );
}

DayOff.propTypes = {
  type: PropTypes.string.isRequired,
  lastDayOfVacationsOfDayOff: PropTypes.objectOf(PropTypes.any).isRequired,
  setLastDayOfVacationsOfDayOff: PropTypes.func.isRequired,
  lastDayOfVacationsOfDayOffLoading: PropTypes.bool.isRequired,
  setLastDayOfVacationsOfDayOffLoading: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  referencePeriod: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    total_days: undefined,
    begin_date: undefined,
  }),
})(DayOff);
