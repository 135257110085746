import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.settings';

export default defineMessages({
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Configurações',
  },
  themeLabel: {
    id: `${scope}.theme_label`,
    defaultMessage: 'Tema',
  },
  darkTheme: {
    id: `${scope}.theme_dark`,
    defaultMessage: 'Tema',
  },
  lightTheme: {
    id: `${scope}.theme_light`,
    defaultMessage: 'Claro',
  },
  languageLabel: {
    id: `${scope}.language_label`,
    defaultMessage: 'Idioma',
  },
  selectLanguagePlaceHolder: {
    id: `${scope}.select_place_holder`,
    defaultMessage: 'Selecione um idioma',
  },
});
