import React from 'react';
import { Row, Col, Button } from 'antd';
import safeRead from 'safely-read';
import { useProductionInfoData, useBudget, payrollSheet } from './index';
import formatPrice from '~/services/utils/formatPrice';
import checkPermission from '~/services/utils/checkPermission';
import Skeleton from '~/components/DataDisplay/Skeleton';

import { Container } from './styles';
import { getAllQueriesParams } from '~/services/history';
import ControlConsolidatedProduction from '../Modals/ControlConsolidatedProduction/ControlConsolidatedProduction';

export default function ProductionInfo() {
  const [loading, setLoading] = React.useState(false);
  const [
    manageSheetClosureVisible,
    setManageSheetClosureVisible,
  ] = React.useState(false);
  const { productionInfo, productionLoading } = useProductionInfoData();

  const { year, month, team, status, name } = getAllQueriesParams();

  const { budgetLeader, budgetPrepository, budgetLoading } = useBudget();

  return (
    <>
      <Container>
        <Row gutter={24} type="flex">
          <Col sm={24} md={12} lg={6} xl={6}>
            <span className="info-title">Dias de pagamento deste mês</span>
            <br />
            <span className="info-description">
              {productionLoading || budgetLoading ? (
                <Skeleton lineWidthList={['70%']} />
              ) : (
                safeRead(productionInfo, ['paid_days'], '--')
              )}
            </span>
          </Col>
          <Col sm={24} md={12} lg={6} xl={6}>
            <span className="info-title">Horas de pagamento deste mês</span>
            <br />
            {productionLoading || budgetLoading ? (
              <span className="info-description">
                <Skeleton lineWidthList={['70%', '70%', '70%']} />
              </span>
            ) : (
              <>
                <span className="info-description">
                  {safeRead(
                    productionInfo,
                    ['4h'],
                    '--',
                    production => `4h/dia - ${production}h`
                  )}
                </span>
                <br />
                <span className="info-description">
                  {safeRead(
                    productionInfo,
                    ['6h'],
                    '--',
                    production => `6h/dia - ${production}h`
                  )}
                </span>
                <br />
                <span className="info-description">
                  {safeRead(
                    productionInfo,
                    ['8h'],
                    '--',
                    production => `8h/dia - ${production}h`
                  )}
                </span>
              </>
            )}
          </Col>
          <Col sm={24} md={12} lg={6} xl={6}>
            <span className="info-title">Valor/hora da Verba líder</span>
            <br />
            {checkPermission('view_budget') && (
              <span className="info-description">
                {productionLoading || budgetLoading ? (
                  <Skeleton lineWidthList={['70%']} />
                ) : (
                  safeRead(
                    budgetLeader,
                    ['amount'],
                    '--',
                    amount => `${formatPrice(amount)}/h`
                  )
                )}
              </span>
            )}
          </Col>
          <Col sm={24} md={12} lg={6} xl={6}>
            <span className="info-title">Valor fixo da Verba preposto</span>
            <br />
            {checkPermission('view_budget') && (
              <span className="info-description">
                {productionLoading || budgetLoading ? (
                  <Skeleton lineWidthList={['70%']} />
                ) : (
                  safeRead(
                    budgetPrepository,
                    ['amount'],
                    '--',
                    amount => `${formatPrice(amount)}`
                  )
                )}
              </span>
            )}
          </Col>
        </Row>
      </Container>
      <Row type="flex" justify="start">
        <Col>
          <Button
            type="primary"
            onClick={() =>
              payrollSheet(year, month, team, setLoading, status, name)
            }
            download
            loading={loading}
            disabled={budgetLoading}
          >
            Exportar Excel
          </Button>
        </Col>
      </Row>

      {manageSheetClosureVisible && (
        <ControlConsolidatedProduction
          visible={manageSheetClosureVisible}
          setVisible={setManageSheetClosureVisible}
          // setManageSheetClosureVisible={setManageSheetClosureVisible}
        />
      )}
    </>
  );
}
