import { useState, useEffect } from 'react';
import { message } from 'antd';

import SearchForm from './SearchForm';
import api from '~/services/api';
import { AUTH_USERS, POST_USER_AUTH_GROUP } from '~/constants/endpoints/auth';

export function useSearchUsers(group_id) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(undefined);
  const [usersResponse, setUsersResponse] = useState([]);

  useEffect(() => {
    async function searchUsers() {
      setLoading(true);

      if (search) {
        const { data } = await api.get(AUTH_USERS, {
          params: {
            page_size: 10,
            name: search,
            group_id_to_exclude: group_id,
          },
        });

        setUsersResponse(data.results);
      }

      setLoading(false);
    }

    searchUsers();
  }, [group_id, search]);

  return {
    usersResponse,
    setUsersResponse,
    setSearch,
    loading,
  };
}

export async function postUsersToGroup(
  group_id,
  users = [],
  updateUsers,
  fetchTableData
) {
  try {
    await api.post(POST_USER_AUTH_GROUP(group_id), { users });

    await updateUsers();

    await fetchTableData();

    message.success('Usuários atribuidos com sucesso');
  } catch (error) {
    message.error('Erro ao atribuir Usuários');
  }
}

export default SearchForm;
