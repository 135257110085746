import produce from 'immer';
import {
  PERMISSIONS_REQUEST,
  PERMISSIONS_SUCCESS,
  PERMISSIONS_FAILURE,
} from './actions';

const INITIAL_STATE = {
  loading: false,
  data: [],
};

export default function permissions(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case PERMISSIONS_REQUEST:
        draft.loading = true;
        break;
      case PERMISSIONS_FAILURE:
        draft.loading = false;
        break;
      case PERMISSIONS_SUCCESS:
        draft.loading = false;
        draft.data = action.payload.permissions;
        break;
      default:
    }
  });
}
