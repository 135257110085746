import React from 'react';
import { Modal, Upload, Button, Typography, Row, Col, Alert } from 'antd';
import { AiOutlineInbox } from 'react-icons/ai';
import PropTypes from 'prop-types';
import { ImportExcel, getTemplateExcel } from './index';

const { Dragger } = Upload;
const { Title, Text } = Typography;

export default function UploadPositionsModal({
  uploadModalVisible,
  setUploadModalVisible,
  fetchTableData,
}) {
  const [excelFile, setExcelFile] = React.useState({});
  const [uploading, setUploading] = React.useState(false);

  const formData = new FormData();

  const props = {
    name: 'Excel',
    // The "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" refers to a .xlsx file.
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    showUploadList: { showDownloadIcon: false, removeIcon: true },
    onChange: e => {
      if (e.fileList.length > 1) {
        e.fileList.shift();
      }
    },
    beforeUpload: file => {
      setExcelFile(file);
      return false;
    },
    onRemove: () => {
      setExcelFile({});
    },
  };

  return (
    <Modal
      visible={uploadModalVisible}
      onCancel={() => setUploadModalVisible(false)}
      footer={false}
      destroyOnClose
    >
      <Title level={3}>Importar cargos de cooperado</Title>
      <Alert
        message="Atente-se ao código do cargo pois ele é utilizado como referência para localizar o cargo. Erros de digitação neste campo podem gerar inconsistências."
        type="info"
        showIcon
      />
      <br />
      <Alert
        message="Matenha os cargos atualizados para evitar problemas na importação de carga de cooperados."
        type="info"
        showIcon
      />

      <br />
      <Button type="link" onClick={() => getTemplateExcel()}>
        Clique aqui para fazer o download do template.
      </Button>
      <br />
      <br />
      <Dragger {...props}>
        <AiOutlineInbox
          style={{
            width: 100,
            height: 100,
            color: '#1DA57A',
          }}
        />
        <br />
        <Text>Arraste o arquivo ou clique aqui para realizar o upload</Text>
        <br />
        <Text strong>Formato aceito: .xlsx</Text>
      </Dragger>
      <br />
      <br />
      <Row type="flex" justify="end">
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Button
            type="primary"
            block
            disabled={Object.keys(excelFile).length === 0}
            loading={uploading}
            onClick={async () => {
              formData.append('sheet', excelFile);
              await ImportExcel(
                formData,
                setUploading,
                setUploadModalVisible,
                fetchTableData
              );
            }}
          >
            {uploading
              ? 'Enviando arquivo de carga'
              : 'Enviar arquivo de carga'}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

UploadPositionsModal.propTypes = {
  uploadModalVisible: PropTypes.bool.isRequired,
  setUploadModalVisible: PropTypes.func.isRequired,
  fetchTableData: PropTypes.func.isRequired,
};
