import { takeLatest, call, put, all } from 'redux-saga/effects';

import { AUTH_PERMISSIONS } from '~/constants/endpoints/auth';
import api from '~/services/api';
import {
  PERMISSIONS_REQUEST,
  permissionsSuccess,
  permissionsFailure,
} from './actions';

export function* getPermissions() {
  try {
    const { data } = yield call(api.get, AUTH_PERMISSIONS);

    yield put(permissionsSuccess(data));
  } catch (error) {
    yield put(permissionsFailure());
  }
}

export default all([takeLatest(PERMISSIONS_REQUEST, getPermissions)]);
