export const common = {
  primaryColor: '#1DA57A',
  linkColor: '#1DA57A',
  successColor: '#52c41a',
  warningColor: '#faad14',
  errorColor: '#f5222d',
  fontSizeBase: '14px',
  borderRadiusBase: '4px',
  boxShadowBase: '0 2px 8px rgba(0, 0, 0, 0.15)',
  white: '#fff',
};

const THEME = {
  dark: {
    bodyBackground: '#2D2D2D',
    componentBackground: '#414141',
    headingColor: '#DCDCDC',
    textColor: '#D1D1D1',
    textColorSecondary: '#7E7E7E',
    textColorInvert: 'rgba(0, 0, 0, 0.75)',
    disabledColor: '#5e5c5c',
    borderColorBase: '#4D4D4D',
    displayMarkedInitialRow: '#3196f9',
    ...common,
  },
  light: {
    bodyBackground: '#FAFAFA',
    componentBackground: '#FFFFFF',
    headingColor: 'rgba(0, 0, 0, 0.85)',
    textColor: 'rgba(0, 0, 0, 0.75)',
    textColorSecondary: 'rgba(0, 0, 0, 0.55)',
    textColorInvert: '#fff',
    disabledColor: 'rgba(0, 0, 0, 0.35)',
    borderColorBase: '#e6e6e6',
    displayMarkedInitialRow: '#EBF3FB',
    ...common,
  },
};

export default THEME;
