import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Divider, Button, Input, Form, Typography } from 'antd';
import { FinancialTableContext, handleFinnancialApproval } from '.';

const { TextArea } = Input;
const { Title } = Typography;

export default function ReasonModal({ visible, setVisible, callback }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const {
    currentStatuses,
    updateTableData,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useContext(FinancialTableContext);

  return (
    <Modal
      centered
      visible={visible}
      destroyOnClose
      onCancel={() => setVisible(prev => !prev)}
      closable
      footer={null}
    >
      <Title level={4}>
        {currentStatuses === 'aprovado_pelo_financeiro'
          ? 'Aprovar'
          : 'Reprovar'}{' '}
        solicitações
      </Title>

      {currentStatuses !== 'aprovado_pelo_financeiro' && (
        <Form.Item style={{ marginTop: 30 }} label="Motivo*">
          <TextArea onChange={e => setValue(e.target.value)} value={value} />
        </Form.Item>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 40,
        }}
      >
        <Button onClick={() => setVisible(false)}>Cancelar</Button>

        <Divider type="vertical" />

        <Button
          loading={loading}
          type="primary"
          onClick={async () => {
            setLoading(true);
            await handleFinnancialApproval(
              selectedRowKeys,
              currentStatuses,
              value
            );
            await updateTableData();
            setSelectedRowKeys([]);
            setVisible(false);

            if (typeof callback === 'function') {
              callback();
            }
          }}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
}

ReasonModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

ReasonModal.defaultProps = {
  callback: undefined,
};
