/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import moment from 'moment';
import safeRead from 'safely-read';

import History from './History';
import api from '~/services/api';
import {
  MY_PRODUCTION_HISTORY,
  PRODUCTION_HISTORY_BY_ID,
} from '~/constants/endpoints/my_production_history';
import checkinsCheckouts from '~/constants/checkinsCheckouts';
import { getQueryParam } from '~/services/history';
import { GET_PERSON } from '~/constants/endpoints/person';

export async function fetchTableData(
  cooperativeMemberId,
  setLoading,
  setTableData,
  setFormattedTableData,
  month,
  year
) {
  setLoading(true);

  try {
    const productionEndpoint = cooperativeMemberId
      ? PRODUCTION_HISTORY_BY_ID(cooperativeMemberId)
      : MY_PRODUCTION_HISTORY;

    const { data } = await api.get(productionEndpoint, {
      params: { year, month },
    });

    const formattedData = data.map(item => {
      const pushedData = {};

      const dayProduction = safeRead(item, ['day_type'], '--');

      checkinsCheckouts.map(
        // eslint-disable-next-line no-return-assign
        (x, counter) =>
          (pushedData[`${x.pos}${x.type}`] = safeRead(
            item,
            ['timeline', [counter]],
            '--'
          ))
      );

      return {
        key: item.date,
        dia: moment(item.date).format('DD/MM - ddd'),
        ...pushedData,
        producao:
          !item.production ||
          moment(item.production).format('HH:mm') === '00:00'
            ? dayProduction
            : moment(item.production).format('HH:mm'),
        saldo: safeRead(item, ['balance', 'total_balance'], '--'),
        balance: safeRead(item, ['balance']),
      };
    });

    setTableData(data);
    setFormattedTableData(formattedData);
  } catch (error) {
    setTableData([]);
    setFormattedTableData([]);
  }

  setLoading(false);
}

export function useCooperativeMember(id) {
  const [initialMember, setInitialMember] = useState([]);

  async function fetchMember(memberId, name) {
    try {
      const { data } = await api.get(GET_PERSON, {
        params: { id: memberId, name },
      });

      const formattedData = safeRead(data, ['results'], []);

      const payload = formattedData.map(item => ({
        id: item.id,
        label: item.name_display,
      }));

      return payload;
    } catch (error) {
      return [];
    }
  }

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const initialMemberResponse = await fetchMember(id);

        setInitialMember(initialMemberResponse);
      };

      fetchData();
    }
  }, []);

  return { initialMember, fetchMemberByName: val => fetchMember(null, val) };
}

export const HistoryContext = React.createContext({
  fetchTableData: () => {},
});

export default withFormik({
  mapPropsToValues: () => {
    const month =
      !getQueryParam('month') ||
      parseInt(getQueryParam('month'), 10) > 12 ||
      parseInt(getQueryParam('month'), 10) < 1
        ? null
        : getQueryParam('month');

    const year = getQueryParam('year') || null;
    const mountedDate =
      month && year
        ? `${year}-${month}`
        : `${moment().format('YYYY')}-${moment().format('MM')}`;

    return {
      cooperativeField: '',
      mesAno: moment(mountedDate, 'YYYY-MM'),
    };
  },
})(History);
