import React from 'react';
import { Modal, Upload, Button, Typography, Alert, message } from 'antd';
import { useDispatch } from 'react-redux';
import { store } from '~/store';

import { getUserDataRequest } from '~/store/modules/user/actions';

const { Title } = Typography;

const urlAvatar = `${process.env.REACT_APP_BASE_URL}/current_person_avatar`;

export default function UploadAvatarModal({
  uploadModalVisible,
  setUploadModalVisible,
  fetchTableData,
}) {
  const [fileList, setFileList] = React.useState([]);
  const { access } = store.getState().auth.token;
  const dispatch = useDispatch();

  const props = {
    name: 'avatar_personal',
    action: urlAvatar,
    method: 'PATCH',
    headers: {
      authorization: `Bearer ${access}`,
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        setFileList(info.file);
      }
      if (info.file.status === 'done') {
        setFileList(info.file);
        message.success(`${info.file.name} imagem enviada com sucesso`);
        setTimeout(() => {
          dispatch(getUserDataRequest());
          setUploadModalVisible(false);
        }, 2000);
      } else if (info.file.status === 'error') {
        setFileList('');
        message.error(
          `Erro ao tentar enviar sua imagem como avatar. Tente novamente`
        );
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  return (
    <Modal
      visible={uploadModalVisible}
      onCancel={() => setUploadModalVisible(false)}
      footer={false}
      destroyOnClose
    >
      <Title level={3}>Alterar avatar do usuário</Title>
      <Alert
        message="Precisamos que sua imagem tenha um tamanho igual ou menor que 500kb para melhor performance."
        type="info"
        showIcon
      />

      <br />
      <div style={{ width: '100%', alignItems: 'center' }}>
        {fileList.thumbUrl ? (
          <img
            src={fileList.thumbUrl}
            alt="avatar"
            style={{
              width: '100%',
              borderRadius: '50%',
            }}
          />
        ) : (
          <Upload {...props} listType="picture">
            <Button>Fazer upload de imagem</Button>
          </Upload>
        )}
      </div>
    </Modal>
  );
}
