import React, { useContext } from 'react';
import { Button, Row, Col, Typography } from 'antd';
import AddHolidaysModal from './Modals/AddHolidaysModal/AddHolidaysModal';
import Table from '~/components/DataDisplay/Table';
import { makeColumns, formatTableData } from './index';
import { ProductionDaysHolder } from '../style';
import { ProductionDaysContext } from '../index';

export default function Holidays() {
  const {
    loadingTableData,
    setLoadingTableData,
    setTableData,
    tableData,
    fetchData,
  } = useContext(ProductionDaysContext);

  const [visible, setVisible] = React.useState(false);

  const year = new Date().getFullYear();

  const { Title } = Typography;

  return (
    <ProductionDaysHolder>
      <Title level={2}>{`Feriados - ${year}`}</Title>
      <Row type="flex" justify="start">
        <Col>
          <Button type="primary" onClick={() => setVisible(true)}>
            Adicionar feriado
          </Button>
        </Col>
      </Row>
      <br />

      <Table
        columns={makeColumns(setLoadingTableData, setTableData, fetchData)}
        dataSource={formatTableData(tableData)}
        pagination={false}
        loading={loadingTableData}
      />

      {visible ? (
        <AddHolidaysModal visible={visible} setVisible={setVisible} />
      ) : null}
    </ProductionDaysHolder>
  );
}
