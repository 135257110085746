import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Menu as AntdMenu, Icon } from 'antd';
import { Link } from 'react-router-dom';

import { signOut } from '~/store/modules/auth/actions';

import messages from './messages';
import { routePaths } from '~/components/routes/paths';

export default function Menu({ dispatch }) {
  return (
    <AntdMenu>
      <AntdMenu.Item>
        <Link to={routePaths.PROFILE}>
          <Icon type="user" style={{ marginRight: 8 }} />
          <FormattedMessage {...messages.myProfile} />
        </Link>
      </AntdMenu.Item>
      <AntdMenu.Item>
        <Icon type="swap" />
        <FormattedMessage {...messages.switchProfile} />
      </AntdMenu.Item>
      <AntdMenu.Item>
        <Link to={routePaths.SETTINGS}>
          <Icon type="setting" style={{ marginRight: 8 }} />
          <FormattedMessage {...messages.settings} />
        </Link>
      </AntdMenu.Item>
      <AntdMenu.Item onClick={() => dispatch(signOut())}>
        <Icon type="logout" />
        <FormattedMessage {...messages.exit} />
      </AntdMenu.Item>
    </AntdMenu>
  );
}

Menu.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
