import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Description } from './styles';

function Button({ title, description }) {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}

export default Button;

Button.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
