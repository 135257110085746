import React, { useContext } from 'react';

import Table from '~/components/DataDisplay/Table';
import history, {
  getAllQueriesParams,
  pushWithQueriesParams,
} from '~/services/history';
import { makeColumns, AbsenceOvertimeContext, formatTableData, getRequestTypeById } from '../index';

export default function AbsenceOvertimeTable() {
  const {
    page,
    page_size,
    date_start,
    date_end,
    request_type,
    status__in,
  } = getAllQueriesParams();
  const {
    tableData,
    loadingTableData,
    selectedRowKeys,
    setSelectedRowKeys,
    setSelectedRowRequestTypes,
    setDetailModalVisible,
    setDetailData,
    setItemsToUpdateStatus,
  } = useContext(AbsenceOvertimeContext);

  const rowSelection = {
    selectedRowKeys,
    onChange: selected => {
      let selectedObj = [];
      selected.map(item => {
        selectedObj.push({
          id:item,
          requestType: getRequestTypeById(item, tableData.results)
        });
      });

      setSelectedRowKeys(selected);
      setSelectedRowRequestTypes(selectedObj);
    },
    getCheckboxProps: record => ({
      disabled: record.status.status !== 'em_analise',
    }),
  };

  return (
    <Table
      columns={makeColumns(
        setDetailModalVisible,
        setDetailData,
        setItemsToUpdateStatus
      )}
      dataSource={formatTableData(tableData.results)}
      paginated
      currentPage={parseInt(page, 10) || 1}
      count={tableData.count}
      pageSize={parseInt(page_size, 10) || 10}
      rowSelection={rowSelection}
      scroll={{ x: true }}
      loading={loadingTableData}
      onChange={(pagination, filters, sorter, extra) => {
        pushWithQueriesParams(history.location.pathname, {
          status__in: status__in,
          date_start: date_start,
          date_end: date_end,
          request_type: request_type,
          page: 1,
          page_size: page_size,
          ordering:
            sorter.order === 'ascend'
              ? sorter.columnKey
              : `-${sorter.columnKey}`,
        });
      }}
    />
  );
}
