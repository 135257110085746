import React from 'react';
import { Modal, Button, Row, Col, Typography, Divider } from 'antd';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import Input from '~/components/DataEntry/Input';
import { Container } from '../../../style';
import DatePicker from '~/components/DataEntry/DatePicker';
import { fetchTableData, PostHoliday } from '../..';
import { fetchData } from '../../../WorkingDays/index';
import { ProductionDaysContext } from '../../../index';

function AddHolidayModal({ visible, setVisible }) {
  const { Title } = Typography;

  const {
    setTableData,
    setLoadingTableData,
    setBusinessDayData,
  } = React.useContext(ProductionDaysContext);

  const year = new Date().getFullYear();
  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      title={<Title level={4}>Adicionar Feriado</Title>}
      closable
      destroyOnClose
    >
      <Formik
        initialValues={{
          date: null,
          description: null,
        }}
        onSubmit={values => {
          PostHoliday(values, setVisible);
          fetchTableData(setLoadingTableData, setTableData);
          fetchData(setBusinessDayData);
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <Container>
              <Form onSubmit={handleSubmit}>
                <Row gutter={24} type="flex" justify="center">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Field
                      id="date"
                      name="date"
                      component={DatePicker}
                      format="DD/MM/YYYY"
                      label="Data"
                      disabledDate={current =>
                        (current &&
                          current < moment(year, 'YYYY').startOf('year')) ||
                        current > moment(year, 'YYYY').endOf('year')
                      }
                    />
                  </Col>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <Field
                      id="description"
                      name="description"
                      component={Input}
                      label="Feriado"
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24} type="flex" justify="end">
                  <Col>
                    <Button onClick={() => setVisible(false)}>Cancelar</Button>
                    <Divider type="vertical" />
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!values.date || !values.description}
                    >
                      Incluir
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          );
        }}
      </Formik>
    </Modal>
  );
}

AddHolidayModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default AddHolidayModal;
