import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography, Tooltip } from 'antd';
import api from '~/services/api';
import { MY_HOME_GROWN, HOME_GROWN } from '~/constants/endpoints/home_grown';
import {
  Container,
  TimelineContainer,
  TimelineItem,
  TimelineItemTitle,
  TimelineItemContent,
} from './styles';
import Pdf from '~/assets/docs/Prata-da-Casa.pdf'

const { Title } = Typography;

export default function Timeline() {

  const [homeGrown, setHomeGrown] = useState({});
  const [rewards, setRewards] = useState([])
  var yearsathome;
  var workingHours;

  async function getPersonHomeGrown() {
    var response = await api.get(MY_HOME_GROWN)
    yearsathome = response.data.home_grown_age;
    workingHours = response.data.working_hours;
    setHomeGrown(response.data);
  }


  async function getRewards() {
    var reward = await (await api.get(HOME_GROWN)).data;

    if (workingHours == 8) {
      for (let i = 0; i < reward.length; i++) {
        if (reward[i].bonus[0].working_hours != 8)
          reward[i].bonus.shift();
      }
    }

    const list = CreateLists();
    const formattedData = list.map((val) => {
      const findRewards = reward.filter(r => r.required_time === val.year_id)
      return { ...val, rewards: findRewards }
    })
    const result = [formattedData.slice(0, 10), formattedData.slice(10, 20), formattedData.slice(20, 30)];
    setRewards(result);
  }

  function CreateLists() {
    let yearList = []
    for (let counter = 1; counter < 31; counter++) {
      let valid = false
      if (counter <= yearsathome) { valid = true; }
      yearList.push({
        "year_id": counter,
        "year_display": `${counter}º Ano`,
        "worked": valid
      })
    }
    return yearList;
  }

  function openPDF() {
    fetch(Pdf).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.setAttribute('download', `Prata-da-Casa.pdf`);
        document.body.appendChild(alink);
        alink.click();
      })
    })
  }

  useEffect(() => {
    getPersonHomeGrown();
    getRewards();
  }, []);

  return (
    <>
      <Title level={4}><br />Meu Prata da Casa</Title>
      <Container>
        <Row gutter={24} type="flex">
          <Col sm={24} md={12} lg={6} xl={12}>
            <span className="info-title">
              {homeGrown.person}
            </span>
          </Col>
          <Col sm={24} md={12} lg={6} xl={3}>
            <span className='info-title'>
              Saldo Horas
            </span>
            <span className="info-description">
              <br /> {!!(homeGrown.bonus_hours) ? `${homeGrown.bonus_hours}h` : '--'}
            </span>
          </Col>
          <Col sm={24} md={12} lg={6} xl={3}>
            <span className="info-title">
              Saldo Recebido
            </span>
            <span className="info-description">
              <br /> {!!(homeGrown.bonus_reward) ? `R$ ${homeGrown.bonus_reward}` : '--'}
            </span>
          </Col>
          <Col sm={24} md={12} lg={6} xl={6}>
            <span className="info-title">
              Aniversário Prata
            </span>
            <span className="info-description">
              <br /> {!!(homeGrown.birthday_home_grown) ? `${homeGrown.birthday_home_grown}` : '--/--/----'}
            </span>
          </Col>
        </Row>
      </Container>
      {rewards && (
        <Container>
          <Title level={4}>Linha do tempo</Title>

          <TimelineContainer>
            {rewards &&
              (rewards[0] || []).map((item, index, arr) => (
                <div
                  key={item.year_display}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <TimelineItem last={index === 10}>
                    <TimelineItemTitle>
                      {item.year_display.substring(0, 10)}
                    </TimelineItemTitle>

                    {item.worked !== null ? (
                      <Tooltip>
                        <TimelineItemContent
                          days={item.worked}
                          first={index === 0}
                        >
                          {!!(item?.rewards[0]?.bonus[0]?.bonus_hours) ? `${item?.rewards[0]?.bonus[0]?.bonus_hours}h` : '0h'}<br />
                          {!!(item?.rewards[0]?.bonus[0]?.bonus_reward) ? `+R$ ${item?.rewards[0]?.bonus[0]?.bonus_reward}` : '--'}
                        </TimelineItemContent>
                      </Tooltip>
                    ) : (
                      <TimelineItemContent> </TimelineItemContent>
                    )}
                  </TimelineItem>
                </div>
              ))}
          </TimelineContainer>

          <TimelineContainer>
            {rewards &&
              (rewards[1] || []).map((item, index, arr) => (
                <div
                  key={item.year_display}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <TimelineItem last={index === 10}>
                    <TimelineItemTitle>
                      {item.year_display.substring(0, 10)}
                    </TimelineItemTitle>

                    {item.worked !== null ? (
                      <Tooltip
                      >
                        <TimelineItemContent
                          days={item.worked}
                        >
                          {!!(item?.rewards[0]?.bonus[0]?.bonus_hours) ? `${item?.rewards[0]?.bonus[0]?.bonus_hours}h` : '0h'}<br />
                          {!!(item?.rewards[0]?.bonus[0]?.bonus_reward) ? `+R$ ${item?.rewards[0]?.bonus[0]?.bonus_reward}` : '--'}
                        </TimelineItemContent>
                      </Tooltip>
                    ) : (
                      <TimelineItemContent> </TimelineItemContent>
                    )}
                  </TimelineItem>
                </div>
              ))}
          </TimelineContainer>

          <TimelineContainer>
            {rewards &&
              (rewards[2] || []).map((item, index, arr) => (
                <div
                  key={item.year_display}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <TimelineItem last={index === 10}>
                    <TimelineItemTitle>
                      {item.year_display.substring(0, 10)}
                    </TimelineItemTitle>

                    {item.worked !== null ? (
                      <Tooltip
                      >
                        <TimelineItemContent
                          days={item.worked}
                          last={index === 9}
                        >
                          {!!(item?.rewards[0]?.bonus[0]?.bonus_hours) ? `${item?.rewards[0]?.bonus[0]?.bonus_hours}h` : '0h'}<br />
                          {!!(item?.rewards[0]?.bonus[0]?.bonus_reward) ? `+R$ ${item?.rewards[0]?.bonus[0]?.bonus_reward}` : '--'}
                        </TimelineItemContent>
                      </Tooltip>
                    ) : (
                      <TimelineItemContent> </TimelineItemContent>
                    )}
                  </TimelineItem>

                </div>
              ))}

          </TimelineContainer>
        </Container>
      )}
      <div>
        <Col sm={24} md={12} lg={6} xl={18}>
        </Col>
        <Col sm={24} md={12} lg={6} xl={3}>
          <Button type="primary" onClick={() => openPDF()} style={{ height: 100, width: 270 }}>
            Confira o regulamento do programa <br /> <b>Prata da Casa </b> clicando aqui.
          </Button>
        </Col>
      </div>
    </>
  );
}