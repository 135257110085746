import React from 'react';
import { Icon, message as AntMessage } from 'antd';
import { RiUserSettingsLine } from 'react-icons/ri';
import { FiUsers, FiShield } from 'react-icons/fi';
import { MdAttachMoney, MdHistory } from 'react-icons/md';
import { FaRegRegistered, FaLock, FaUserCog } from 'react-icons/fa';
import safeRead from 'safely-read';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import WorkTime from './WorkTime';
import { routePaths } from '~/components/routes/paths';
import { version } from '../../../../../package.json';
import checkPermission from '~/services/utils/checkPermission';
import messages from './messages';
import { SideMenu, Menu, MenuItem, VersionTag } from './styles';

import api from '~/services/api';
import { AUTH_PRIVACY_TOOLS } from '~/constants/endpoints/auth';

export default function Sider(props) {
  const { profile } = useSelector(state => state.user);
  const { pathname } = useLocation();
  const { collapsed } = props;

  const loadURL = async () => {
    try {
      const { data } = await api.get(AUTH_PRIVACY_TOOLS);
      window.open(data);
    } catch (e) {
      AntMessage.error(
        safeRead(
          e,
          ['response', 'data', 'detail'],
          'Ocorreu um erro ao tentar abrir Privacy Portal'
        )
      );
    }
  };

  const menuData = [
    {
      key: 'production',
      mensage: <FormattedMessage {...messages.production} />,
      icon: <Icon type="clock-circle" />,
      subMenu: [
        {
          key: routePaths.PROD_HISTORY,
          mensage: <FormattedMessage {...messages.myProduction} />,
          route: routePaths.PROD_HISTORY,
          cooperative_member: true,
        },
        {
          key: routePaths.ABSENCE_ORVETIME,
          mensage: <FormattedMessage {...messages.absenceOvertime} />,
          route: routePaths.ABSENCE_ORVETIME,
          cooperative_member: true,
        },
        {
          key: routePaths.VACATIONS_ALLOWENCES,
          mensage: 'Férias e Abonos',
          route: routePaths.VACATIONS_ALLOWENCES,
          cooperative_member: true,
        },
        // {
        //   key: routePaths.HOME_SILVER_USER,
        //   mensage: 'Prata da Casa',
        //   route: routePaths.HOME_SILVER_USER,
        //   cooperative_member: true,
        // }
      ],
    },
    !checkPermission('view_edesenv') && {
      key: 'e-desenv',
      mensage: 'Históricos E-desenv',
      icon: <MdHistory size={16} style={{ marginRight: 10 }} />,
      subMenu: [
        {
          key: routePaths.EDESENV,
          mensage: 'Histórico Aus. e Hr. Ext.',
          route: routePaths.EDESENV,
          cooperative_member: true,
        },
      ],
    },
    {
      key: 'e-desenvLeadView',
      mensage: 'Históricos E-desenv',
      icon: <MdHistory size={16} style={{ marginRight: 10 }} />,
      subMenu: [
        {
          key: routePaths.EDESENV,
          mensage: 'Histórico Aus. e Hr. Ext.',
          route: routePaths.EDESENV,
          permissions: 'view_edesenv',
        },
      ],
    },
    {
      key: 'management',
      mensage: <FormattedMessage {...messages.management} />,
      icon: <FiUsers size={14} style={{ marginRight: 10 }} />,
      subMenu: [
        // {
        //   key: routePaths.TEAM_LIST,
        //   mensage: 'Lista de Equipes',
        //   route: routePaths.TEAM_LIST,
        //   permissions: 'view_squad',
        // },
        {
          key: routePaths.MY_SQUAD,
          mensage: 'Meus Liderados',
          route: routePaths.MY_SQUAD,
          permissions: 'view_squad',
        },
        {
          key: routePaths.MY_TEAM,
          mensage: 'Minha Equipe',
          route: routePaths.MY_TEAM,
          permissions: 'view_team',
        },
        {
          key: routePaths.RECTIFICATION,
          mensage: <FormattedMessage {...messages.rectification} />,
          route: routePaths.RECTIFICATION,
          permissions: 'view_rectification',
        },
        {
          key: routePaths.ABSENCE_OVERTIME_APPROVAL,
          mensage: <FormattedMessage {...messages.absenceOvertime} />,
          route: routePaths.ABSENCE_OVERTIME_APPROVAL,
          permissions: 'view_request',
        },
        {
          key: routePaths.VACATIONS_ALLOWENCES_APPROVAL,
          mensage: <FormattedMessage {...messages.vacationsAllowences} />,
          route: routePaths.VACATIONS_ALLOWENCES_APPROVAL,
          permissions: 'view_paidleave',
        },
        {
          key: routePaths.PROJECT,
          mensage: 'Gerenciar Equipes',
          route: routePaths.PROJECT,
          permissions: 'view_team',
        },
        {
          key: routePaths.SHUTDOWN,
          mensage: 'Acerto para Desligamento',
          route: routePaths.SHUTDOWN,
          permissions: 'view_personresignation',
        },
        // {
        //   key: routePaths.REPORT,
        //   mensage: 'Relatórios',
        //   route: routePaths.REPORT,
        //   permissions: 'view_person',
        // },
      ],
    },
    {
      key: 'admin',
      mensage: 'Administrativo',
      icon: <RiUserSettingsLine size={16} style={{ marginRight: 10 }} />,
      subMenu: [
        {
          key: routePaths.MANAGE_USER,
          mensage: 'Gerenciar Usuários',
          route: routePaths.MANAGE_USER,
          permissions: 'change_person',
        },
        {
          key: routePaths.PROFILE_AND_PERMISSIONS,
          mensage: 'Grupos e Permissões',
          route: routePaths.PROFILE_AND_PERMISSIONS,
          permissions: 'view_group',
        },
        {
          key: routePaths.PROFILE_PERMISSIONS,
          mensage: 'Perfis',
          route: routePaths.PROFILE_PERMISSIONS,
          permissions: 'view_profile',
        },
        {
          key: routePaths.BUDGET,
          mensage: 'Verbas',
          route: routePaths.BUDGET,
          permissions: ['view_budget'],
        },
        // {
        //   key: routePaths.HOME_SILVER,
        //   mensage: 'Prata da Casa',
        //   route: routePaths.HOME_SILVER,
        //   permissions: 'change_person',
        // },
        {
          key: routePaths.PRODUCTION_DAYS,
          mensage: 'Dias de Produção',
          route: routePaths.PRODUCTION_DAYS,
          permissions: 'view_daytransfer',
        },
      ],
    },
    {
      key: 'payments',
      mensage: 'Pagamentos',
      icon: <MdAttachMoney size={16} style={{ marginRight: 10 }} />,
      subMenu: [
        //        {
        //          key: routePaths.CONSOLIDATE_PRODUCTION,
        //          mensage: 'Consolidar Produção',
        //          route: routePaths.CONSOLIDATE_PRODUCTION,
        //          permissions: 'view_consolidatedproduction',
        //        },
        {
          key: routePaths.CONSOLIDATE_PRODUCTION_EXECUTIVE,
          mensage: 'Consolidar Produção',
          route: routePaths.CONSOLIDATE_PRODUCTION_EXECUTIVE,
          permissions: 'view_consolidatedproduction',
        },
        {
          key: routePaths.FINANCIAL_REQUESTS,
          mensage: 'Solicitações financeiras',
          route: routePaths.FINANCIAL_REQUESTS,
          permissions: 'view_payablerequest',
        },
        // checkPermission('view_consolidatedproduction')
        //   ? {
        //       key: routePaths.RPC,
        //       mensage: 'RPC',
        //       route: routePaths.RPC,
        //       permissions: 'view_consolidatedproduction',
        //     }
        //   : {
        //       key: routePaths.MY_RPC,
        //       mensage: 'Meu RPC',
        //       route: routePaths.MY_RPC,
        //       cooperative_member: true,
        //     },
      ],
    },
  ].filter(Boolean);

  return (
    <SideMenu collapsed={collapsed}>
      {profile.is_cooperative_member ? <WorkTime /> : null}

      <Menu selectedKeys={[pathname]} defaultSelectedKeys={[pathname]}>
        {/* <MenuItem key="/">
          <span>
            <Icon type="home" /> <FormattedMessage {...messages.home} />
          </span>
          <Link to="/" />
        </MenuItem> */}

        {menuData
          .filter(menu => {
            const filteredSubmenu = menu.subMenu.filter(item => {
              if (item.permissions) {
                return checkPermission(`${item.permissions}` || '');
              }

              return profile.is_cooperative_member;
            });

            return filteredSubmenu.length !== 0;
          })
          .map(item => (
            <Menu.SubMenu
              key={item.key}
              title={
                <span>
                  {item.icon}
                  <span>{item.mensage}</span>
                </span>
              }
            >
              {item.subMenu.map(
                ({
                  cooperative_member,
                  permissions,
                  operation,
                  key,
                  mensage,
                  route,
                }) => {
                  if (cooperative_member) {
                    return profile.is_cooperative_member ? (
                      <MenuItem key={key}>
                        <span>{mensage}</span>
                        <Link to={route} />
                      </MenuItem>
                    ) : null;
                  }

                  if (permissions) {
                    return checkPermission(permissions, operation) ? (
                      <MenuItem key={key}>
                        <span>{mensage}</span>
                        <Link to={route} />
                      </MenuItem>
                    ) : null;
                  }

                  return (
                    <MenuItem key={key}>
                      <span>{mensage}</span>
                      <Link to={route} />
                    </MenuItem>
                  );
                }
              )}
            </Menu.SubMenu>
          ))}

        <MenuItem key="privacyToolsURL">
          <FiShield size={16} style={{ marginRight: 6 }} />
          <button
            type="button"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={loadURL}
          >
            Portal de privacidade
          </button>
        </MenuItem>

        <MenuItem key="redmineURL">
          <FaRegRegistered size={16} style={{ marginRight: 6 }} />
          <button
            type="button"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open(
                'http://redmine.coopersystem.com.br/login?back_url=http%3A%2F%2Fredmine.coopersystem.com.br%2F'
              )
            }
          >
            Redmine
          </button>
        </MenuItem>
        {checkPermission('view_consolidatedproduction') &&
          <MenuItem key="hcm" style={{ color: '#1DA57A' }}>
            <FaUserCog size={16} style={{ marginRight: 6 }} />
            <button
              type="button"
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                fontWeight: 'bold'
              }}
              onClick={() =>
                window.open(
                  'https://fa-eras-saasfaprod1.fa.ocs.oraclecloud.com'
                )
              }
            >
              HCM
            </button>
          </MenuItem>
        }
        <MenuItem key="informationSecurityURL" style={{ color: '#1DA57A' }}>
          <FaLock size={16} style={{ marginRight: 6 }} />
          <button
            type="button"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              fontWeight: 'bold'
            }}
            onClick={() =>
              window.open(
                'http://extranet.coopersystem.com.br/images/pdfs/psi_v02_2023.pdf'
              )
            }
          >
            Segurança da informação
          </button>
        </MenuItem>

      </Menu>

      {/* <VersionTag>v{version}</VersionTag> */}
    </SideMenu>
  );
}

Sider.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};
