import React, { useContext, useState } from 'react';
import { Modal, Typography, Row, Col, Button, Divider } from 'antd';
import { Formik, Field } from 'formik';
import safeRead from 'safely-read';
import { AbsenceOvertimeContext } from '../..';
import { Container } from './styles';
import Input from '~/components/DataEntry/Input';
import validation from '~/constants/formSchemas/confirmAbsenceOvertimeRequest';
import { submitConfirmRequest } from '.';

const { Title } = Typography;

export default function ConfirmRequest() {
  const [submitLoading, setSubmittingLoading] = useState(false);

  const {
    confirmRequestModalVisible,
    toggleConfirmRequestModal,
    toggleDetailModalVisible,
    fetchTableData,
    itemData,
  } = useContext(AbsenceOvertimeContext);

  const itemId = safeRead(itemData, ['id']);

  return (
    <Modal
      title={<Title level={4}>Confirmar solicitação</Title>}
      footer={false}
      visible={confirmRequestModalVisible}
      onCancel={toggleConfirmRequestModal}
      centered
      destroyOnClose
    >
      <Container>
        <Formik
          validationSchema={validation}
          initialValues={{ reasonField: '' }}
          onSubmit={({ reasonField }) =>
            submitConfirmRequest(
              itemId,
              reasonField,
              toggleConfirmRequestModal,
              toggleDetailModalVisible,
              fetchTableData,
              setSubmittingLoading
            )
          }
        >
          {({ handleSubmit, values }) => (
            <>
              <Field
                id="reasonField"
                name="reasonField"
                component={Input}
                type="textarea"
                label="Motivo da solicitação"
                required
              />
              <br />
              <Row type="flex" justify="end">
                <Col>
                  <Button
                    ghost
                    type="primary"
                    onClick={toggleConfirmRequestModal}
                  >
                    Cancelar
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={submitLoading}
                    disabled={!safeRead(values, ['reasonField'])}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}
