import React from 'react';

import PropTypes from 'prop-types';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import { Formik, Field, Form } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import debounce from 'lodash.debounce';

import Input from '~/components/DataEntry/Input';
import { RangePicker } from '~/components/DataEntry/DatePicker';
import Select from '~/components/DataEntry/Select';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import history, {
  pushWithQueriesParams,
  getAllQueriesParams,
} from '~/services/history';
import messages from '../messages';
import {
  buildDateRange,
  buildDateRangeStringPair,
} from '~/services/utils/dateRangeUtils';
import { useSearchProject } from '../index';

export default function SearchForm({
  id,
  page_size,
  name,
  date_start,
  date_end,
  status,
}) {
  const { formatMessage } = useIntl();
  const { loadingProjects, projects, setSearch } = useSearchProject();

  const { team } = getAllQueriesParams();

  return (
    <Formik
      initialValues={{
        formikName: name,
        formikDateRange: buildDateRange(date_start, date_end),
        formikStatus: status,
        formikId: id,
        formikTeam: team,
      }}
      onSubmit={({
        formikName,
        formikDateRange,
        formikStatus,
        formikId,
        formikTeam,
      }) => {
        const dateStringPair = buildDateRangeStringPair(formikDateRange);

        pushWithQueriesParams(history.location.pathname, {
          page: 1,
          page_size,
          name: formikName,
          date_start: dateStringPair[0],
          date_end: dateStringPair[1],
          status: formikStatus,
          id: formikId,
          team: formikTeam,
        });
      }}
    >
      {({ setFieldValue, handleSubmit, values, setValues }) => (
        <Form onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Field
                id="id-input"
                name="formikId"
                label="ID"
                component={Input}
              />
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="name-input"
                name="formikName"
                label={`${formatMessage(messages.nameLabel)}`}
                component={Input}
              />
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="team-input"
                name="formikTeam"
                label="Equipe"
                loading={loadingProjects}
                component={AutoCompleteSelect}
                placeholder=" "
                selectSchema={item => (
                  <AntdSelect.Option key={item.id} value={item.id}>
                    {item.team_name}
                  </AntdSelect.Option>
                )}
                onChange={e => {
                  if (!e) {
                    setFieldValue('formikTeam', null);
                  } else {
                    setFieldValue('formikTeam', e);
                  }

                  setSearch(undefined);
                }}
                search={debounce(setSearch, 800)}
                allowClear
                data={projects.results}
              />
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="date-range-input"
                name="formikDateRange"
                label={formatMessage(messages.rectificationDateLabel)}
                component={RangePicker}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              <Field
                id="status-input"
                name="formikStatus"
                label={formatMessage(messages.statusLabel)}
                allowClear
                component={Select}
                onChange={formikStatus => {
                  setFieldValue('status', formikStatus);
                }}
                data={[
                  {
                    descricao: formatMessage(messages.inAnalysisSelect),
                    value: 'em_analise',
                  },
                  {
                    descricao: formatMessage(messages.approvedStatusSelect),
                    value: 'aprovado',
                  },
                  {
                    descricao: formatMessage(messages.deniedStatusSelect),
                    value: 'reprovado',
                  },
                ]}
                renderItem={item => (
                  <AntdSelect.Option key={item.value} value={item.value}>
                    {item.descricao}
                  </AntdSelect.Option>
                )}
              />
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              <Button
                onClick={() => {
                  setValues({
                    formikDateRange: [],
                    formikId: null,
                    formikName: null,
                    formikStatus: null,
                    formikTeam: null,
                  });
                  pushWithQueriesParams(history.location.pathname, {
                    page: 1,
                    page_size,
                  });
                }}
              >
                <FormattedMessage {...messages.clearButtom} />
              </Button>
              <Divider type="vertical" />
              <Button
                disabled={
                  !values.formikName &&
                  values.formikDateRange.length === 0 &&
                  !values.formikStatus &&
                  !values.formikTeam &&
                  !values.formikId
                }
                htmlType="submit"
                type="primary"
                icon="search"
              >
                <FormattedMessage {...messages.searchButtom} />
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

SearchForm.defaultProps = {
  id: null,
  name: null,
  date_start: null,
  date_end: null,
  status: null,
  page_size: null,
};

SearchForm.propTypes = {
  page_size: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string,
  date_start: PropTypes.string,
  date_end: PropTypes.string,
  status: PropTypes.string,
};
