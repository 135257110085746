import React, { useContext } from 'react';
import { Input as AntdInput, Typography, Button, Spin, Modal } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AiOutlineClose } from 'react-icons/ai';
import debounce from 'lodash.debounce';

import { deleteUser, AddUserContext, getUserGroups } from '../index';
import { ProfileContext } from '../../../index';

export default function UsersOnThisProfile() {
  const {
    updateUsers,
    users,
    profile_id,
    setPageSize,
    fetchTableData,
    loading,
    setSearch,
  } = useContext(AddUserContext);

  const {
    setRegisterOrEditPersonVisible,
    setAddUserModalVisible,
    setCurrentUser,
  } = useContext(ProfileContext);

  const { Text, Paragraph } = Typography;
  const { confirm } = Modal;

  const debouncedUpdate = debounce(value => setSearch(value), 800);

  return (
    <div>
      <Text type="secondary">Usuários com este perfil</Text>

      <AntdInput.Search
        loading={loading}
        onChange={({ target: { value } }) => debouncedUpdate(value)}
      />

      <InfiniteScroll
        dataLength={users.results.length}
        next={() => setPageSize(prev => prev + 5)}
        hasMore={users.next !== null}
        loader={
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        }
        height="154px"
      >
        {users.results.map(user => (
          <div
            key={user.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <Paragraph ellipsis>{user.name_display}</Paragraph>
            <div>
              <Button
                onClick={() =>
                  confirm({
                    content: 'Remover Usuário?',
                    centered: true,
                    onOk: async () => {
                      deleteUser(profile_id, user.id);
                      await updateUsers();
                      await fetchTableData();
                    },
                  })
                }
                type="link"
              >
                <AiOutlineClose />
              </Button>
              <Button
                onClick={() => {
                  getUserGroups(user.id, setCurrentUser);
                  setAddUserModalVisible(false);
                  setRegisterOrEditPersonVisible(true);
                }}
                type="link"
              >
                Personalizar
              </Button>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}
