import React, { useContext } from 'react';
import { Row, Col, Button, Divider, Typography, Input } from 'antd';
import { Form, Formik } from 'formik';
import safeRead from 'safely-read';
import DetailModal from '~/components/DataDisplay/Modals/DetailModal';
import { UserRegistryContext } from '../../../../index';
import { PostCompensatoryTime } from './index';

const { Text } = Typography;

function EditCompensatoryTime({
  editCompensatoryTimeVisible,
  setEditCompensatoryTimeVisible,
}) {
  const {
    userData,
    fetchCompensatoryTime,
    compensatoryTime,
    setCompensatoryTime,
    fetchCompensatoryTimeHistory,
    setCompensatoryTimeHistory,
  } = useContext(UserRegistryContext);

  const [loading, setLoading] = React.useState(false);

  const [maskedValue, setMaskedValue] = React.useState('+00:00');

  function compensatoryTime_mask(value) {
    let input = value.toString().replace(/[^0-9-+]/g, '');

    if (input === null || input === '' || input === undefined) {
      setMaskedValue('+00:00');
    } else {
      if (input.length === 1) {
        input = `00${input}`;
      }

      const regex = /([-+]?)(.*)(\d{2}$)/;
      const match = input.match(regex);

      if (match !== null) {
        setMaskedValue(`${match[1]}${match[2] || 0}:${match[3]}`);
      }
    }
  }

  React.useEffect(() => {
    let currentValue = maskedValue || '+00:00';

    if (currentValue.startsWith('+0') && currentValue.length >= 7) {
      let value = currentValue.split('');
      value.splice(1, 1);
      value = value.join('');
      if (value === '-' || value === '+:') {
        compensatoryTime_mask('+00:00');
      } else {
        compensatoryTime_mask(value);
      }
    } else if (currentValue.startsWith('-0') && currentValue.length >= 7) {
      let value = currentValue.split('');
      value.splice(1, 1);
      value = value.join('');
      if (value === '-' || value === '-:') {
        compensatoryTime_mask('-00:00');
      } else {
        compensatoryTime_mask(value);
      }
    }
  }, [maskedValue]);

  return (
    <DetailModal
      title="Editar Banco de horas aprovadas"
      visible={editCompensatoryTimeVisible}
      setVisible={setEditCompensatoryTimeVisible}
      data={[
        {
          key: 'Nome',
          title: 'Nome',
          value: safeRead(userData, ['name_display'], '--'),
          width: '100%',
        },
        {
          key: 'CompensatoryTime',
          title: 'Banco de horas aprovadas atual',
          value: safeRead(compensatoryTime, ['balance'], '--'),
          width: '100%',
        },
      ]}
      actions={
        <Formik initialValues={{ newCompensatoryTime: '' }}>
          {({ handleSubmit, values }) => {
            return (
              <Form>
                <Row gutter={[12, 12]} type="flex">
                  <Col xs={16} sm={16} md={16} lg={16}>
                    <Text strong style={{ display: 'block' }}>
                      Novo saldo do Banco de horas aprovadas
                    </Text>
                    <Input
                      id="newCompensatoryTime"
                      name="newCompensatoryTime"
                      size="large"
                      maxLength={8}
                      value={maskedValue}
                      onChange={e => compensatoryTime_mask(e.target.value)}
                    />
                  </Col>
                  <Col>
                    <Text>
                      Coloque o "+" para deixar o banco positivo ou "-" para
                      deixar o banco negativo.
                    </Text>
                  </Col>
                </Row>
                <br />
                <Row type="flex" justify="end">
                  <Col>
                    <Button
                      onClick={() => {
                        setEditCompensatoryTimeVisible(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      onClick={async () => {
                        await PostCompensatoryTime(
                          safeRead(userData, ['id']),
                          maskedValue,
                          setEditCompensatoryTimeVisible,
                          setLoading
                        );
                        await fetchCompensatoryTime(
                          safeRead(userData, ['id']),
                          setCompensatoryTime
                        );
                        await fetchCompensatoryTimeHistory(
                          safeRead(userData, ['id']),
                          setCompensatoryTimeHistory
                        );
                      }}
                      id="amount-btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Confirmar
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      }
    ></DetailModal>
  );
}

export default EditCompensatoryTime;
