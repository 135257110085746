import React from 'react';

import PropTypes from 'prop-types';
import { Modal, Typography, Timeline, Button } from 'antd';
import { Formik, Form, Field } from 'formik';
import safeRead from 'safely-read';
import { FormattedMessage, useIntl } from 'react-intl';
import shortid from 'shortid';

import createYupSchema from '~/constants/formSchemas/rectification';
import TimePicker from '~/components/DataEntry/TimePicker';
import Input from '~/components/DataEntry/Input';
import messages from './messages';
import { renderLabel } from '../../index';
import { TimelineBox, TimelineHolder, TimelineItem } from '../../styles';

export function makeDisabledHours(checkInType, checkinList) {
  const listLength = checkinList.length;
  const lastCheckIn = safeRead(
    checkinList,
    [[listLength - 1], [checkInType ? 0 : 1], 'worktime_clock'],
    undefined
  );
  const currentHour = new Date().getHours();
  const lastCheckInHour = lastCheckIn ? new Date(lastCheckIn).getHours() : 0;
  const hoursList = Array.from({ length: 24 }, (item, index) => index);
  const canSelect = hoursList.filter(
    item => item <= currentHour && item >= lastCheckInHour
  );

  return hoursList.filter(item => !canSelect.includes(item));
}

export function makeDisabledMinutes(checkInType, checkinList, { time }) {
  const listLength = checkinList.length;
  const lastCheckIn = safeRead(
    checkinList,
    [[listLength - 1], [checkInType ? 0 : 1], 'worktime_clock'],
    undefined
  );

  const slectedHour = time ? new Date(time._d).getHours() : undefined;
  const currentHour = new Date().getHours();
  const lastCheckInHour = lastCheckIn ? new Date(lastCheckIn).getHours() : 0;
  const minutesList = Array.from({ length: 60 }, (item, index) => index);
  const currentMinute = new Date().getMinutes();
  const lastCheckInMinute = lastCheckIn
    ? new Date(lastCheckIn).getMinutes()
    : 0;

  if (
    (slectedHour &&
      slectedHour > lastCheckInHour &&
      slectedHour < currentHour) ||
    !slectedHour
  ) {
    return [];
  }

  if (
    slectedHour &&
    slectedHour === lastCheckInHour &&
    slectedHour === currentHour
  ) {
    return minutesList.filter(
      item => item < lastCheckInMinute || item > currentMinute
    );
  }

  if (slectedHour && slectedHour === lastCheckInHour) {
    return minutesList.filter(item => item < lastCheckInMinute);
  }

  if (slectedHour && slectedHour === currentHour) {
    return minutesList.filter(item => item > currentMinute);
  }

  const canSelect = minutesList.filter(item => item > lastCheckInMinute);

  return minutesList.filter(item => !canSelect.includes(item));
}

export function getRectificationPosition(checkinList = []) {
  if (checkinList.length === 0) {
    return 1;
  }

  const checkOutPosition = safeRead(
    checkinList[checkinList.length - 1],
    [[1], 'position'],
    null,
    value => value + 1
  );

  if (!checkOutPosition) {
    return safeRead(
      checkinList[checkinList.length - 1],
      [[0], 'position'],
      null
    );
  }

  return checkOutPosition;
}

export default function RectificationModal({
  visible,
  setVisible,
  onOk,
  checkInType,
  checkinList,
}) {
  const { formatMessage } = useIntl();

  const { Text, Title } = Typography;

  const rectificationList = (values, setFieldValue) => {
    if (!checkInType) {
      return [
        [
          {
            id: shortid(),
            check_in: false,
            rectification: null,
            position: checkinList.length + 1,
          },
          {
            id: shortid(),
            check_in: !checkInType,
            position: checkinList.length + 1,
            rectification: !checkInType
              ? {
                  status_display: 'Ponto a ser rasurado.',
                }
              : null,
            field: !checkInType ? (
              <Field
                id="time-picker"
                disabledHours={() =>
                  makeDisabledHours(checkInType, checkinList)
                }
                disabledMinutes={() =>
                  makeDisabledMinutes(checkInType, checkinList, values)
                }
                name="time"
                placeholder={`${formatMessage(messages.select)}...`}
                style={{ width: 140 }}
                format="HH:mm"
                component={TimePicker}
                onChange={time => setFieldValue('time', time)}
              />
            ) : null,
          },
        ],
        ...checkinList,
      ];
    }

    const filtered = checkinList.filter(
      (item, index) => index === checkinList.length - 1
    );

    return [
      [
        {
          id: shortid(),
          check_in: true,
          rectification: null,
          position: checkinList.length + 1,
        },
      ],
      [
        {
          id: shortid(),
          check_in: !checkInType,
          position: checkinList.length,
          rectification: checkInType
            ? {
                status_display: 'Ponto a ser rasurado.',
              }
            : null,
          field: checkInType ? (
            <Field
              id="time-picker"
              disabledHours={() => makeDisabledHours(checkInType, checkinList)}
              disabledMinutes={() =>
                makeDisabledMinutes(checkInType, checkinList, values)
              }
              name="time"
              placeholder={`${formatMessage(messages.select)}...`}
              style={{ width: 140 }}
              format="HH:mm"
              component={TimePicker}
              onChange={time => setFieldValue('time', time)}
            />
          ) : null,
        },
        { ...checkinList[0][0] },
      ],
      ...filtered,
    ];
  };

  return (
    <Modal
      title={formatMessage(messages.title)}
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      width={340}
      closable
      destroyOnClose
      centered
    >
      <Title level={4}>Está faltando um registro</Title>

      <Formik
        onSubmit={onOk}
        initialValues={{ time: undefined, reason: '' }}
        validationSchema={createYupSchema(checkinList, checkInType)}
        validateOnChange
        validateOnBlur={false}
      >
        {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <TimelineHolder>
              {rectificationList(values, setFieldValue).map(tmitem => (
                <Timeline key={shortid()}>
                  {tmitem.map(item => (
                    <TimelineItem
                      key={item.id}
                      check_in={item.check_in ? 1 : 0}
                      has_rectification={item.rectification ? 1 : 0}
                    >
                      <TimelineBox>
                        {renderLabel(item, formatMessage)}
                      </TimelineBox>
                    </TimelineItem>
                  ))}
                </Timeline>
              ))}
            </TimelineHolder>

            <Field
              id="reason-field"
              name="reason"
              component={Input}
              maxLength={300}
              label={`${formatMessage(messages.reason)} (${formatMessage(
                messages.optional
              )})`}
            />

            <Text style={{ marginBottom: 10, display: 'block' }}>
              Preciso dessa sua rasura antes de confirmar seu ponto
            </Text>

            <div
              style={{
                margin: '20px 0',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{ marginRight: 20 }}
                onClick={() => setVisible(false)}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
              <Button
                disabled={!values.time}
                loading={isSubmitting}
                htmlType="submit"
                type="primary"
              >
                <FormattedMessage {...messages.submitRectification} />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

RectificationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  checkInType: PropTypes.bool.isRequired,
  checkinList: PropTypes.arrayOf(PropTypes.any).isRequired,
};
