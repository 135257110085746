import { defineMessages } from 'react-intl';

export const scope = 'portal.pages.profile';

export default defineMessages({
  currentPassword: {
    id: `${scope}.current_password`,
    defaultMessage: 'Senha Atual',
  },
  newPassword: {
    id: `${scope}.new_password`,
    defaultMessage: 'Nova Senha',
  },
  confirmPassword: {
    id: `${scope}.confirm_password`,
    defaultMessage: 'Confirme a Nova Senha',
  },
  warnNormal: {
    id: `${scope}.warn_normal`,
    defaultMessage: 'Esta senha será a mesma utilizada para fazer login na ',
  },
  warnBold: {
    id: `${scope}.warn_bold`,
    defaultMessage: 'extranet e na sua máquina.',
  },
  sendButton: {
    id: `${scope}.send_button`,
    defaultMessage: 'Enviar',
  },
  cancelButton: {
    id: `${scope}.cancel_button`,
    defaultMessage: 'Cancelar',
  },
});
