import React, { useState, useContext } from 'react';
import { Row, Col, Typography, Button, Divider, Modal } from 'antd';
import { Container, SelectButton } from './styles';
import { RequestAbsenceOvertimeContext } from '../RequestAbsenceOvertime';

const { Title } = Typography;

export default function Type() {
  const {
    step1,
    onTypeSubmit,
    toggleModal,
  } = useContext(RequestAbsenceOvertimeContext);
  const [selectedOption, setSelectedOption] = useState(step1 || null);

  return (
    <>
      <br />
      <Row>
        <Title level={4}>Defina o tipo de solicitação</Title>
      </Row>
      <Container>
        <SelectButton
          onClick={() => setSelectedOption('hora_extra')}
          selected={selectedOption === 'hora_extra'}
        >
          Hora Extra
        </SelectButton>
        <SelectButton
          onClick={() => setSelectedOption('ausencia')}
          selected={selectedOption === 'ausencia'}
        >
          Ausência
        </SelectButton>
      </Container>
      <Row type="flex" justify="end">
        <Col>
          <Button type="link" onClick={toggleModal}>
            Cancelar
          </Button>
          <Divider type="vertical" />
          <Button
            type="primary"
            disabled={!selectedOption}
            onClick={() => onTypeSubmit(selectedOption)}
          >
            Avançar
          </Button>
        </Col>
      </Row>
    </>
  );
}
