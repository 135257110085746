import { Modal as AntModal, message } from 'antd';
import moment from 'moment';
import safeRead from 'safely-read';
import api from '~/services/api';
import renderStatusBadges from '~/services/utils/renderStatusBadge';
import { REQUEST_UPDATE_ABSENCE_OVERTIME } from '~/constants/endpoints/request';
import DetailModal from './DetailModal';
import badgesStatus from '~/constants/badgesStatus';

const { confirm } = AntModal;

export function toggleModal(setItemData, toggleDetailModalVisible) {
  setItemData(null);
  toggleDetailModalVisible();
}

export async function cancelRequest(
  itemData,
  setCancelLoading,
  setItemData,
  toggleDetailModalVisible,
  fetchTableData
) {
  const { CANCELED } = badgesStatus;
  const id = safeRead(itemData, ['id']);
  setCancelLoading(true);

  const bodyPayload = {
    status: CANCELED,
  };

  if (id) {
    try {
      await api.patch(REQUEST_UPDATE_ABSENCE_OVERTIME(id), bodyPayload);

      toggleModal(setItemData, toggleDetailModalVisible);
      fetchTableData();
    } catch (error) {
      message.error('Ocorreu um erro ao cancelar a solicitação.');
    }
  }

  setCancelLoading(false);
}

export function popConfirm(
  itemData,
  setCancelLoading,
  setItemData,
  toggleDetailModalVisible,
  fetchTableData
) {
  confirm({
    title: 'Cancelar solicitação',
    content: 'Deseja realmente cancelar a solicitação?',
    onOk: () =>
      cancelRequest(
        itemData,
        setCancelLoading,
        setItemData,
        toggleDetailModalVisible,
        fetchTableData
      ),
  });
}

export function handleShowCancel(itemData) {
  const { IN_ANALYSIS } = badgesStatus;

  if (
    safeRead(itemData, ['status']) === IN_ANALYSIS ||
    safeRead(itemData, ['status']) === 'em_analise_financeira'
  ) {
    return true;
  }

  return false;
}

export function handleShowSolicitarAusenciaHoraExtra(itemData) {
  const { NOT_REQUESTED } = badgesStatus;

  if (safeRead(itemData, ['status']) === NOT_REQUESTED) {
    return true;
  }

  return false;
}

export const details = itemData =>
  [
    { key: 1, title: 'ID', value: safeRead(itemData, ['id'], '--') },
    {
      key: 3,
      title: 'Data de solicitação',
      value: safeRead(itemData, ['created_at'], '--', i =>
        moment(i).format('DD/MM/YYYY')
      ),
    },
    {
      key: 5,
      title: 'Solicitação',
      value: safeRead(itemData, ['request_type_display'], '--'),
    },
    // { key: 6, title: 'Tipo', value: safeRead(itemData, ['type'], '--') },
    {
      key: 7,
      title: 'Duração',
      value: safeRead(itemData, ['hours'], '--', i =>
        moment(i, 'HH:mm:ss').format('HH:mm')
      ),
    },
    {
      key: 8,
      title: 'Status',
      value: renderStatusBadges({
        status: safeRead(itemData, ['status']),
        status_display: safeRead(itemData, ['status_display']),
      }),
    },
    {
      key: 9,
      title:
        safeRead(itemData, ['request_type']) === 'hora_extra'
          ? 'Data da hora extra'
          : 'Data da ausência',
      value: safeRead(itemData, ['date'], '--', i =>
        moment(i).format('DD/MM/YYYY')
      ),
      width: '33%',
    },
    {
      key: 10,
      title: 'Última modificação',
      value: safeRead(itemData, ['updated_at'], '--', i =>
        moment(i).format('DD/MM/YYYY')
      ),
      width: '100%',
    },
    {
      key: 11,
      title: 'Motivo',
      value: safeRead(itemData, ['reason'], '--'),
      width: '100%',
    },
    safeRead(itemData, ['status'], '--') === 'reprovado' && {
      key: 12,
      title: 'Responsável pela reprovação',
      value: safeRead(itemData, ['examiner_display', 'name_display'], '--'),
      width: '100%',
    },
    safeRead(itemData, ['status'], '--') === 'reprovado' && {
      key: 13,
      title: 'Motivo da reprovação',
      value: safeRead(itemData, ['disapproval_reason'], '--'),
      width: '100%',
    },
    safeRead(itemData, ['status'], '--') === 'aprovado' && {
      key: 12,
      title: 'Responsável pela aprovação',
      value: safeRead(itemData, ['examiner_display', 'name_display'], '--'),
      width: '100%',
    },
  ].filter(Boolean);

export default DetailModal;
