import React from 'react';
import { Row, Col, Button, Divider, Select as AntdSelect } from 'antd';
import { Formik, Field, Form } from 'formik';
import debounce from 'lodash.debounce';

import { RangePicker } from '~/components/DataEntry/DatePicker';
import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';
import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import {
  handleSearch,
  disableSearchButton,
  getInitialValues,
  clearFields,
  typesSelect,
  statusSelect,
  useSearchProject,
} from '.';

export default function SearchForm() {
  const { loadingProjects, projects, setSearch } = useSearchProject();

  return (
    <>
      <Formik initialValues={getInitialValues()} onSubmit={handleSearch}>
        {({ handleSubmit, values, setValues, setFieldValue }) => (
          <Form>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Field
                  id="nameField"
                  name="nameField"
                  label="Nome"
                  component={Input}
                />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <Field
                  id="teamField"
                  name="teamField"
                  label="Equipe"
                  loading={loadingProjects}
                  component={AutoCompleteSelect}
                  placeholder=" "
                  selectSchema={item => (
                    <AntdSelect.Option key={item.id} value={item.id}>
                      {item.team_name}
                    </AntdSelect.Option>
                  )}
                  onChange={e => {
                    if (!e) {
                      setFieldValue('teamField', null);
                    } else {
                      setFieldValue('teamField', e);
                    }

                    setSearch(undefined);
                  }}
                  search={debounce(setSearch, 800)}
                  allowClear
                  data={projects.results}
                />
              </Col>

              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <Field
                  id="date-range-input"
                  name="formikDateRange"
                  label="Data"
                  component={RangePicker}
                  style={{ width: '100%' }}
                />
              </Col>

              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                <Field
                  id="typeField"
                  name="typeField"
                  label="Tipo"
                  component={Select}
                  data={typesSelect.values}
                  renderItem={typesSelect.render}
                  allowClear
                />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <Field
                  id="statusField"
                  name="statusField"
                  label="Status"
                  component={Select}
                  data={statusSelect.values}
                  renderItem={statusSelect.render}
                  allowClear
                />
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Col>
                <Button onClick={() => clearFields(setValues)}>Limpar</Button>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  icon="search"
                  onClick={handleSubmit}
                  disabled={disableSearchButton(values)}
                  htmlType="submit"
                >
                  Pesquisar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Divider />
    </>
  );
}
