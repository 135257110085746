import React from 'react';
import safeRead from 'safely-read';
import { Modal, Typography, List, Col, Row, Button } from 'antd';
import moment from 'moment';

import PropTypes from 'prop-types';
import { UserRegistryContext, fetchDeleteScheduling } from '../../index';
import { ModalItemContainer } from '../../styles';

export default function HistoryModal({ visible, setVisible }) {
  const {
    userData,
    modalType,
    compensatoryTimeHistory,
    setUserData,
    setLoadingUserData,
  } = React.useContext(UserRegistryContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const { Text, Title } = Typography;

  const handleCancelScheduling = async () => {
    await fetchDeleteScheduling(
      userData.id,
      setIsLoading,
      setUserData,
      setLoadingUserData
    );
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      closable
      destroyOnClose
      width={450}
      title={
        <Title level={4}>
          {modalType === 'status' && 'Histórico de status'}
          {modalType === 'jornadaDiaria' && 'Histórico de jornada diária'}
          {modalType === 'compensatory' &&
            'Histórico de Banco de horas aprovadas'}
          {modalType === 'scheduling' && 'Detalhes do Agendamento'}
        </Title>
      }
    >
      {modalType === 'status' && (
        <List
          size="small"
          grid="none"
          dataSource={safeRead(userData, ['history', 'status'])}
          style={{
            alignItems: 'center',
          }}
          renderItem={item => (
            <List.Item>
              <Text>
                <Row gutter={12} type="flex" justify="space-between">
                  <Col>
                    {' '}
                    <Text strong>
                      {moment(item.history_date).format('DD/MM/YYYY')}{' '}
                    </Text>
                  </Col>
                  <Col>
                    <Text>Status atualizado para</Text>
                  </Col>
                  <Col>
                    <Text style={{ textTransform: 'capitalize' }} strong>
                      {safeRead(item, ['status'], '--')}
                    </Text>
                  </Col>
                </Row>
              </Text>
            </List.Item>
          )}
        />
      )}
      {modalType === 'jornadaDiaria' && (
        <List
          size="large"
          grid="none"
          dataSource={safeRead(userData, ['history', 'working_hours'], [])}
          renderItem={item => (
            <List.Item>
              <Text>
                <Row gutter={6} type="flex" justify="space-between">
                  <Col>
                    {' '}
                    <Text strong>
                      {moment(item.history_date).format('DD/MM/YYYY')}
                    </Text>
                  </Col>
                  <Col>
                    <Text>Jornada atualizada para</Text>
                  </Col>
                  <Col>
                    {' '}
                    <Text strong>
                      {safeRead(
                        item,
                        ['working_hours'],
                        '--',
                        working_hours => `${working_hours} horas`
                      )}
                    </Text>
                  </Col>
                  <Col>
                    {' '}
                    <Text>Mat.: </Text>
                    <Text strong>
                      {safeRead(item, ['csys_registration_number'])}
                    </Text>
                  </Col>
                </Row>
              </Text>
            </List.Item>
          )}
        />
      )}
      {modalType === 'compensatory' && (
        <List
          size="small"
          grid="none"
          dataSource={compensatoryTimeHistory || []}
          renderItem={item => (
            <List.Item>
              <Text>
                <Row gutter={12} type="flex" justify="space-between">
                  <Col>
                    {' '}
                    <Text strong>
                      {moment(item.created_at).format('DD/MM/YYYY')}
                    </Text>
                  </Col>
                  <Col>
                    <Text>De</Text>
                  </Col>
                  <Col>
                    {' '}
                    <Text strong>
                      {safeRead(item, ['previous_balance'], '--')}
                    </Text>
                  </Col>
                  <Col>
                    <Text>Para</Text>
                  </Col>
                  <Col>
                    {' '}
                    <Text strong>{safeRead(item, ['balance'], '--')}</Text>
                  </Col>
                </Row>
              </Text>
            </List.Item>
          )}
        />
      )}
      {modalType === 'scheduling' && userData.working_day_schedule && (
        <>
          <ModalItemContainer>
            <div>
              <Text strong style={{ display: 'block' }}>
                Nome
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {userData.working_day_schedule.created_by.replace('.', ' ')}
              </Text>
            </div>

            <div>
              <Text strong style={{ display: 'block' }}>
                Agendado para
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {moment(userData.working_day_schedule.start_working_day).format(
                  'DD/MM/YYYY'
                )}
              </Text>
            </div>
          </ModalItemContainer>

          <ModalItemContainer>
            <div>
              <Text strong style={{ display: 'block' }}>
                Horas
              </Text>
              <Text type="secondary" style={{ display: 'block' }}>
                {userData.working_day_schedule.hour}
              </Text>
            </div>
          </ModalItemContainer>

          <ModalItemContainer>
            <div>
              <Text strong style={{ display: 'block' }}>
                Equipe principal
              </Text>
              {userData.working_day_schedule.team_name}
            </div>
          </ModalItemContainer>

          <br />
          <br />

          <Row type="flex" justify="end">
            <Col>
              <Button
                onClick={handleCancelScheduling}
                type="danger"
                loading={isLoading}
              >
                Cancelar agendamento
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}

HistoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
