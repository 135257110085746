import moment from 'moment';

export function buildDateRange(date_start, date_end) {
  const date_range = [];

  if (!date_start && date_end) {
    date_range.push(null);
    date_range.push(moment(date_end));

    return date_range;
  }

  if (date_start) date_range.push(moment(date_start));
  if (date_end) date_range.push(moment(date_end));

  return date_range;
}

export function buildDateRangeStringPair(datePair) {
  return datePair
    ? datePair.reduce((result, value) => {
        const date = new Date(value._d).toISOString().split('T')[0];

        result.push(date);

        return result;
      }, [])
    : null;
}
