import React, { useContext, useEffect, useState } from 'react';
import { Modal, Typography, Button } from 'antd';
import safeRead from 'safely-read';

import Skeleton from '~/components/DataDisplay/Skeleton';
import { ProjectData, Supervisors, Users } from './Tabs';
import { useProjectData, tabs } from './index';
import { ProjectContext } from '../../index';
import { ButtonGroup } from './styles';

const { Title } = Typography;

export default function EditProject() {
  const [tab, setTab] = useState(tabs.PROJECT_DATA);

  const {
    editVisible,
    setEditVisible,
    currentProject,
    setCurrentProject,
    updateTableData,
  } = useContext(ProjectContext);

  const { project, loadingProject } = useProjectData(currentProject);

  useEffect(() => {
    return () => setCurrentProject(undefined);
  }, [setCurrentProject]);

  return (
    <Modal
      title={
        loadingProject ? (
          <Skeleton count={10} width="100%" lineWidthList={['100%']} />
        ) : (
          <Title level={4}>{safeRead(project, ['team_name'], null)}</Title>
        )
      }
      footer={null}
      visible={editVisible}
      onCancel={() => setEditVisible(false)}
      closable
      destroyOnClose
      centered
    >
      <ButtonGroup>
        <Button
          type={tab === tabs.PROJECT_DATA ? 'primary' : 'default'}
          onClick={() => setTab(tabs.PROJECT_DATA)}
        >
          Dados da Equipe
        </Button>

        <Button
          type={tab === tabs.SUPERVISORS ? 'primary' : 'default'}
          onClick={() => setTab(tabs.SUPERVISORS)}
        >
          Responsáveis
        </Button>

        <Button
          type={tab === tabs.USER ? 'primary' : 'default'}
          onClick={() => setTab(tabs.USER)}
        >
          Usuários
        </Button>
      </ButtonGroup>

      {loadingProject ? (
        <Skeleton
          count={10}
          width="100%"
          lineWidthList={[
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
            '100%',
          ]}
        />
      ) : (
        <>
          {tab === tabs.PROJECT_DATA && (
            <ProjectData project={project} updateTableData={updateTableData} />
          )}
          {tab === tabs.SUPERVISORS && <Supervisors project={project} />}
          {tab === tabs.USER && <Users project={project} />}
        </>
      )}
    </Modal>
  );
}
