import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

export default function GenericLayout({ children }) {
  return <Container>{children}</Container>;
}

GenericLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
