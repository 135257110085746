import React, { useState } from 'react';

import PageContainer from '~/components/Layout/PageContainer';
import ProjectsTable from './ProjectsTable';
import SearchForm from './SearchForm';
import RegisterProjectModal from './Modals/RegisterProject';
import EditProjectModal from './Modals/EditProject';
import {
  ProjectContext,
  useDefaultQueries,
  useTableData,
  fetchTableData,
} from './index';

export default function Project() {
  const { page, page_size } = useDefaultQueries();
  const {
    loadingTableData,
    tableData,
    setTableData,
    setLoadingTableData,
  } = useTableData();

  const [registerVisible, setRegisterVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(undefined);

  return (
    <PageContainer title="Gerenciar Equipes">
      <ProjectContext.Provider
        value={{
          page,
          page_size,
          loadingTableData,
          tableData,
          registerVisible,
          setRegisterVisible,
          editVisible,
          setEditVisible,
          setCurrentProject,
          currentProject,
          updateTableData: async () => {
            setLoadingTableData(true);

            const data = await fetchTableData();

            setTableData(data);

            setLoadingTableData(false);
          },
        }}
      >
        <SearchForm />

        <ProjectsTable />

        {registerVisible && <RegisterProjectModal />}

        {editVisible && <EditProjectModal />}
      </ProjectContext.Provider>
    </PageContainer>
  );
}
