import React from 'react';
import safeRead from 'safely-read';
import { Modal, Button, Row, Col, Typography } from 'antd';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import { BudgetContext } from '../../index';
import { Container } from '../../styles';
import formatPrice from '~/services/utils/formatPrice';
import InputCurrency from '~/components/DataEntry/InputCurrency/';

export default function BudgetModal({ visible, setVisible }) {
  const {
    modalType,
    budget,
    BudgetHistory,
    budgetleader,
    budgetPrepository,
    setBudgetPrepository,
    budgetAmount,
    setBudgetleaderHistory,
    setBudgetPrepositoryHistory,
    setBudgetleader,
  } = React.useContext(BudgetContext);
  const { confirm } = Modal;

  const showConfirm = ({ amount }) => {
    confirm({
      content: 'Deseja realmente salvar um novo valor?',
      onOk: async () => {
        await budgetAmount(amount, modalType);
        const budgetHistory = await BudgetHistory(modalType);
        const budgetData = await budget(modalType);
        if (modalType === 'verba_lider') {
          setBudgetleader(budgetData);
          setBudgetleaderHistory({ results: budgetHistory.results });
        } else {
          setBudgetPrepository(budgetData);
          setBudgetPrepositoryHistory({ results: budgetHistory.results });
        }
        setVisible(false);
      },
    });
  };
  const { Text, Title } = Typography;
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      closable
      destroyOnClose
      width={225}
      title={
        <Title level={4}>
          {modalType === 'verba_lider'
            ? safeRead(budgetleader, ['budget_kind_display'])
            : safeRead(budgetPrepository, ['budget_kind_display'])}
        </Title>
      }
    >
      <Formik
        initialValues={{
          amount: 0,
        }}
        onSubmit={showConfirm}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Container>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Text strong style={{ display: 'block' }}>
                      Valor:
                    </Text>
                    <Text style={{ display: 'block' }}>
                      {modalType === 'verba_lider'
                        ? safeRead(
                            budgetleader,
                            ['amount'],
                            '--,--',
                            amount => `${formatPrice(amount)}/h`
                          )
                        : safeRead(
                            budgetPrepository,
                            ['amount'],
                            '--,--',
                            amount => formatPrice(amount)
                          )}
                    </Text>
                    <br />
                  </Col>
                  <Col>
                    <Text strong>Novo Valor:</Text>
                    <Field
                      id="amount-field"
                      name="amount"
                      component={InputCurrency}
                      max={100000000}
                      onChange={(obj, val) => {
                        setFieldValue('amount', val);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      id="amount-btn"
                      type="primary"
                      htmlType="submit"
                      block
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          );
        }}
      </Formik>
    </Modal>
  );
}

BudgetModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
