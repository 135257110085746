import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker as AntdTimePicker, Form } from 'antd';
import moment from 'moment';

const { Item } = Form;

function getValidationStatus(field, form) {
  const { errors, touched } = form;
  const { name } = field;

  if (touched[name]) {
    return errors[name] ? 'error' : 'success';
  }

  return '';
}

function getHelpMessage(field, form) {
  const { errors, touched } = form;
  const { name } = field;

  if (touched[name]) {
    return errors[name];
  }

  return '';
}

export default function TimePicker({
  id,
  label,
  required,
  field,
  form,
  helpMessage,
  disabled,
  onChange,
  onBlur,
  ...props
}) {
  const { name } = field;
  const { setFieldValue } = form;

  return (
    <label htmlFor={id}>
      <div>
        {label}
        {required && <span style={{ color: 'tomato' }}> *</span>}
      </div>

      <Item
        validateStatus={getValidationStatus(field, form)}
        help={getHelpMessage(field, form)}
        extra={helpMessage}
      >
        <AntdTimePicker
          disabled={disabled}
          value={field.value}
          defaultOpenValue={moment('00:00', 'HH:mm')}
          onChange={val => {
            setFieldValue(name, val);
            return onChange && onChange(val);
          }}
          onBlur={event => onBlur && onBlur(event)}
          {...props}
        />
      </Item>
    </label>
  );
}

TimePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  helpMessage: PropTypes.string,
  disabled: PropTypes.bool,
};

TimePicker.defaultProps = {
  required: false,
  helpMessage: '',
  disabled: false,
  onChange: undefined,
  onBlur: undefined,
  label: undefined,
};
