import styled from 'styled-components';
import { Row } from 'antd';

export const TableRowItem = styled(Row).attrs({ gutter: 12 })`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-time-picker,
  .ant-time-picker-input {
    width: 80px;
  }
`;

export const TableHeadCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
