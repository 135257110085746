import React from 'react';
import { Row, Col, Select as AntdSelect, Alert } from 'antd';
import { Formik, Field, Form } from 'formik';
import debounce from 'lodash.debounce';

import AutoCompleteSelect from '~/components/DataEntry/AutoCompleteSelect';
import { RangePicker } from '~/components/DataEntry/DatePicker';
import { useSearchProject } from '.';
import { ProductionChartContext } from '..';
import { buildDateRangeStringPair } from '~/services/utils/dateRangeUtils';

export default function SearchForm() {
  const { loadingProjects, projects, setSearch } = useSearchProject();

  const { team, setTeam, dateRange, setDateRange } = React.useContext(
    ProductionChartContext
  );

  return (
    <>
      <Formik initialValues={{ teamField: '', dateRangeField: [] }}>
        {({ setFieldValue }) => (
          <Form>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <Field
                  id="teamField"
                  name="teamField"
                  label="Equipe"
                  loading={loadingProjects}
                  component={AutoCompleteSelect}
                  placeholder=" "
                  selectSchema={item => (
                    <AntdSelect.Option key={item.id} value={item.id}>
                      {item.team_name}
                    </AntdSelect.Option>
                  )}
                  onChange={e => {
                    if (!e) {
                      setFieldValue('teamField', null);
                      setTeam(undefined);
                    } else {
                      setFieldValue('teamField', e);

                      setTeam(e);
                    }

                    setSearch(undefined);
                  }}
                  search={debounce(setSearch, 800)}
                  allowClear
                  data={projects.results}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <Field
                  id="dateRangeField"
                  name="dateRangeField"
                  label="Período"
                  component={RangePicker}
                  style={{ width: '100%' }}
                  onChange={e => setDateRange(buildDateRangeStringPair(e))}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {(!team || !dateRange.length) && (
        <>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Alert
                message={
                  <>
                    É necessário atribuir a Equipe e o Período para buscar as
                    informações de produção.
                  </>
                }
                showIcon
              />
            </Col>
          </Row>
          <br />
        </>
      )}
    </>
  );
}
