import React, { useEffect, useState } from 'react';
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  Divider,
  Spin,
  Select as AntdSelect,
} from 'antd';
import { Field, Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fetchMonthInfo, patchControlConsolidadeProduction } from './index';
import Select from '~/components/DataEntry/Select';
import Calendar from './Calendar';
import checkPermission from '~/services/utils/checkPermission';

const { Title } = Typography;

export default function ControlConsolidatedProduction({ visible, setVisible }) {
  const [year, setYear] = useState();
  const [countYear, setCountYear] = useState(0);
  const [controlConsolidated, setControlConsolidated] = useState([]);
  const [monthDataInfo, setMonthDataInfo] = useState([]);
  const [monthDataInfoLoading, setMonthDataInfoLoading] = useState(false);
  const [isVisibleMonth, setIsVisibleMonth] = useState(false);

  const totalYear = [];

  // eslint-disable-next-line no-unused-vars
  const CreateArrayOfYear = Array.from({ length: 4 + countYear }, (v, k) => {
    return totalYear.push({
      key: (2021 + k).toString(),
      value: (2021 + k).toString(),
    });
  });

  useEffect(() => {
    const initialYear = 2021;
    const finalYear = moment().year();

    const yearDate = finalYear - initialYear;
    setCountYear(yearDate + 1);
  }, []);

  function addControlConsolidated(dateDay) {
    setControlConsolidated([...controlConsolidated, dateDay]);
  }

  useEffect(() => {
    const fetchMonth = async () => {
      setMonthDataInfoLoading(true);
      await fetchMonthInfo(year, setMonthDataInfo);
      setMonthDataInfoLoading(false);
    };
    fetchMonth();
    setIsVisibleMonth(true);
  }, [year]);

  return (
    <Modal
      title={<Title level={4}>Gerenciar Fechamento da Folha</Title>}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={680}
      closable
      destroyOnClose
    >
      <Formik
        initialValues={{
          reference_year: moment().format('YYYY'),
        }}
        onSubmit={() => {
          patchControlConsolidadeProduction(controlConsolidated);
          setVisible(false);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Field
                id="year-field"
                name="reference_year"
                label="Selecione o ano:"
                placeholder="Selecione..."
                component={Select}
                style={{ width: '100%', maxWidth: 280 }}
                data={totalYear}
                renderItem={item => (
                  <AntdSelect.Option key={item.key} value={item.value}>
                    {item.value}
                  </AntdSelect.Option>
                )}
                onChange={e => {
                  setYear(e);
                  setIsVisibleMonth(true);
                }}
              />

              {monthDataInfoLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  {isVisibleMonth ? (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col>
                          <Col span={12}>
                            {monthDataInfo
                              .filter((item, index) => index < 6)
                              .map(item => (
                                <Calendar
                                  item={item}
                                  key={item.id}
                                  addControlConsolidated={
                                    addControlConsolidated
                                  }
                                />
                              ))}
                          </Col>
                          <Col span={12}>
                            {monthDataInfo
                              .filter((item, index) => index > 5)
                              .map(item => (
                                <Calendar
                                  item={item}
                                  key={item.id}
                                  addControlConsolidated={
                                    addControlConsolidated
                                  }
                                />
                              ))}
                          </Col>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                </>
              )}

              <Row type="flex" justify="end">
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="default"
                      onClick={() => {
                        setVisible(false);
                      }}
                    >
                      {checkPermission('change_controlconsolidatedproduction')
                        ? 'Cancelar'
                        : 'Fechar'}
                    </Button>
                    {checkPermission(
                      'change_controlconsolidatedproduction'
                    ) && (
                      <>
                        <Divider type="vertical" />
                        <Button type="primary" htmlType="submit">
                          Confirmar
                        </Button>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

ControlConsolidatedProduction.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};
