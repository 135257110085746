import * as yup from 'yup';
import safeRead from 'safely-read';

export default function createYupSchema(checkinList, checkInType) {
  return yup.object().shape({
    time: yup
      .object()
      .test(
        'dateTime in future',
        'Você selecionou um horário maior do que o horário atual.',
        value => {
          const slectedDate = new Date(value && value._d);

          if (slectedDate > new Date()) {
            return false;
          }

          return true;
        }
      )
      .test(
        'dateTime before last checkIn time',
        'Você selecionou um horário inferior ao horário do último registro.',
        value => {
          const listLength = checkinList.length;
          const lastCheckIn = safeRead(
            checkinList,
            [[listLength - 1], [checkInType ? 0 : 1], 'worktime_clock'],
            undefined
          );

          const lastCheckInHour = lastCheckIn
            ? new Date(lastCheckIn).getHours()
            : 0;
          const lastCheckInMinute = lastCheckIn
            ? new Date(lastCheckIn).getMinutes()
            : 0;
          const slectedHour = new Date(value && value._d).getHours();
          const selecedMinute = new Date(value && value._d).getMinutes();

          if (
            lastCheckInHour === slectedHour &&
            lastCheckInMinute > selecedMinute
          ) {
            return false;
          }

          return true;
        }
      )
      .required()
      .nullable(),
  });
}
