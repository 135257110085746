import React from 'react';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import ptBR from 'antd/es/locale/pt_BR';
import enUS from 'antd/es/locale/en_US';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import LanguageProvider from '~/components/LanguageProvider';
import GlobalStyles from './globalStyles';
import theme from './constants/theme';
import Routes from './components/routes';
import history from './services/history';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import messages from './translations';

const antdLocale = {
  'pt-BR': ptBR,
  'en-US': enUS,
};

function App() {
  const { colorScheme } = useSelector(state => state.theme);
  const { locale } = useSelector(state => state.language);

  const query = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: Infinity,
        cacheTime: 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: true,
      },
    },
  });

  return (
    <QueryClientProvider client={query}>
    <Sentry.ErrorBoundary fallback={'An error has occurred'}>
      <ConfigProvider locale={antdLocale[locale]}>
        <LanguageProvider messages={messages}>
          <ThemeProvider theme={theme[colorScheme]}>
            <Router history={history}>
              <Routes />
              <GlobalStyles />
              <Helmet
                htmlAttributes={{ lang: locale }}
                meta={[
                  {
                    'http-equiv': 'Content-Security-Policy',
                    content:
                      `default-src 'self'; script-src 'self' https://www.google.com/ https://www.gstatic.com/; style-src 'self' 'unsafe-inline' https://cdn.privacytools.com.br/; img-src 'self' data: https://*.tile.openstreetmap.org/ https://cdn.privacytools.com.br/; font-src 'self' https://fonts.googleapis.com/ https://fonts.gstatic.com/; frame-src 'self' https://www.google.com/; connect-src 'self' https://cdn.privacytools.com.br/ https://*.portal.coopersystem.com.br/ https://hm-minhas-horas.coopersystem.com.br/ http://localhost:8000/;`,
                  },
                  { 'http-equiv': 'X-Content-Type-Options', content: 'nosniff' },
                ]}
              />
            </Router>
          </ThemeProvider>
        </LanguageProvider>
      </ConfigProvider>
    </Sentry.ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
