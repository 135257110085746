import styled from 'styled-components';

export const ContainerCalendar = styled.div`
  .DayPicker-Day--disabled {
    color: ${({ theme }) => theme.borderColorBase};
    background: transparent !important;
    /* cursor: not-allowed; */
    pointer-events: none;
  }

  .DayPicker {
    background: ${({ theme }) => theme.bodyBackground};
  }

  .DayPickerInput {
    width: 100%;
  }

  .DayPickerInput-OverlayWrapper {
    margin-top: -250px;
  }

  input {
    margin-bottom: 12px;
    width: 100%;
    background-color: ${({ theme }) => theme.bodyBackground};
    color: ${({ theme }) => theme.textColor};
    padding: 4px 11px;
    border: 1px solid ${({ theme }) => theme.borderColorBase};
    display: inline-block;
    height: 32px;
    border-radius: 4px;
    box-sizing: inherit;
    outline: none;
  }

  input:hover {
    border-color: ${({ theme }) => theme.primaryColor};
  }

  input:disabled {
    border-color: ${({ theme }) => theme.disabledColor};
    cursor: not-allowed;
  }
`;
