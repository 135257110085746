import { message } from 'antd';
import safeRead from 'safely-read';
import { AUTH_PASSWORD_CHANGE } from '~/constants/endpoints/auth';
import api from '~/services/api';

export async function confirmChangePassword(
  { currentPassword, newPassword },
  { setSubmitting, setFieldError, resetForm },
) {
  try {
    await api.put(AUTH_PASSWORD_CHANGE, {
      old_password: currentPassword,
      new_password: newPassword,
    });

    message.success('Senha alterada com sucesso!');
    setSubmitting(false);
    resetForm();
  } catch (error) {
    setSubmitting(false);

    const status = safeRead(error, ['request', 'response'], null, value => {
      const obj = JSON.parse(value);

      return obj.status;
    });

    const passwordError = safeRead(
      error,
      ['request', 'response'],
      null,
      value => {
        const obj = JSON.parse(value);

        return obj.password[0];
      }
    );

    if (status && status === 'notfound') {
      message.error('Token inválido');
    }

    if (passwordError) {
      setFieldError('currentPassword', 'Senha incorreta!');
    }
  }
}