import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import safeRead from 'safely-read';
import FinancialRequestsTable from './FinancialRequestsTable';
import api from '~/services/api';
import { getAllQueriesParams } from '~/services/history';
import renderBadge from '~/services/utils/renderStatusBadge';
import formatPrice from '~/services/utils/formatPrice';
import {
  PAYABLE_REQUEST,
  PAYABLE_REQUEST_FINANCIAL_APPROVAL_ID,
} from '~/constants/endpoints/payable_request';

export const FinancialTableContext = React.createContext({});

export async function fetchTableData() {
  let defaultResponse = { count: 0, results: [] };
  const { page_size, ...queries } = getAllQueriesParams();

  try {
    const { data } = await api.get(PAYABLE_REQUEST, {
      params: { ...queries, page_size: Number(page_size) || 10 },
    });

    defaultResponse = data;
  } catch (error) {
    message.error('Error ao buscar dados da tabela');
  }

  return defaultResponse;
}

export async function handleFinnancialApproval(
  ids = [],
  status,
  disapproval_reason
) {
  const promises = ids.map(id => {
    async function approve() {
      try {
        await api.put(PAYABLE_REQUEST_FINANCIAL_APPROVAL_ID(id), {
          status,
          disapproval_reason,
        });
      } catch (error) {
        message.error(
          safeRead(
            error,
            ['response', 'data', 'detail'],
            `Ocorreu um erro ao aprovar/reprovar solicitação`
          )
        );
      }
    }

    return approve();
  });

  await Promise.all(promises);
}

export function useTableData() {
  const queries = getAllQueriesParams();
  const [tableData, setTableData] = useState({ count: 0, results: [] });
  const [loading, setLoading] = useState(false);
  const queriesString = JSON.stringify(queries);

  useEffect(() => {
    async function loadTableData() {
      setLoading(true);
      const data = await fetchTableData();

      setTableData(data);
      setLoading(false);
    }

    loadTableData();
  }, [queriesString]);

  return {
    tableData,
    setTableData,
    loading,
    setLoading,
  };
}

export function makeColumns(setShowDetails, setRowId) {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'first_name,last_name',
      width: '20%',
      ellipsis: true,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Solicitação',
      dataIndex: 'request',
      key: 'request',
      width: '18%',
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: '18%',
      render: (_, { value }) => formatPrice(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status_display',
      width: '26%',
      render: (_, row) => renderBadge(row),
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '12%',
      render: (_, row) => (
        <Button
          onClick={() => {
            setRowId(row.key);
            setShowDetails(true);
          }}
          type="link"
        >
          Detalhes
        </Button>
      ),
    },
  ];
}

export default FinancialRequestsTable;
