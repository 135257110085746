import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Field, Formik } from 'formik';
import { Button } from 'antd';
import Input from '~/components/DataEntry/Input';
import { confirmPassword } from '~/constants/formSchemas/forgotPassword';
import { confirmRecoverPassword } from './index';

import messages from '../messages';

function Recover({ intl }) {
  return (
    <Formik
      onSubmit={confirmRecoverPassword}
      validationSchema={confirmPassword}
      validateOnChange
      validateOnBlur={false}
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
    >
      {({ handleSubmit, values, errors }) => (
        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <Field
            id="password-field"
            name="password"
            component={Input}
            label={intl.formatMessage(messages.newPassword)}
            type="password"
          />

          <Field
            id="confirm-password-field"
            name="confirmPassword"
            component={Input}
            label={intl.formatMessage(messages.confirmPassword)}
            type="password"
          />

          <Button
            id="submit-btn"
            disabled={
              !values.password || errors.password || errors.confirmPassword
            }
            type="primary"
            block
            size="large"
            htmlType="submit"
          >
            <FormattedMessage {...messages.send} />
          </Button>
        </Form>
      )}
    </Formik>
  );
}

Recover.propTypes = {
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default injectIntl(Recover);
