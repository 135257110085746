import React, { useEffect } from 'react';
import safeRead from 'safely-read';
import { message } from 'antd';
import WorkingDays from './WorkindDays';
import api from '~/services/api';
import { PAID_DAYS } from '~/constants/endpoints/paid_days';
import { DAY_TRANSFER_POST } from '~/constants/endpoints/day_transfer';

export async function getBusinessDays() {
  const year = new Date().getFullYear();
  try {
    const { data } = await api.get(PAID_DAYS(year));
    return data;
  } catch (error) {
    message.error(
      safeRead(
        error,
        ['response', 'detail', [0]],
        `Ocorreu um erro ao carregar dias úteis`
      )
    );
    return [];
  }
}

export async function fetchData(setBusinessDayData) {
  const data = await getBusinessDays();
  setBusinessDayData(data);
}

export function useBusinessDays() {
  const [businessDayData, setBusinessDayData] = React.useState([]);

  useEffect(() => {
    function loadTableData() {
      fetchData(setBusinessDayData);
    }

    loadTableData();
  }, []);

  return {
    setBusinessDayData,
    businessDayData,
    fetchData: () => fetchData(setBusinessDayData),
  };
}

export async function postDaytransfer(values, setVisible) {
  const from_month = safeRead(values, ['from_month']);
  const to_month = safeRead(values, ['to_month']);

  try {
    await api.post(DAY_TRANSFER_POST, { from_month, to_month });
    message.success('Dia útil remanejado com sucesso');
    setVisible(false);
  } catch (error) {
    message.error(
      safeRead(error, ['detail'], 'Não foi possível remanejenar dia útil')
    );
    setVisible(false);
  }
}

export default WorkingDays;
