import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import Helmet from 'react-helmet';
import { Container } from './styles';

export default function PageContainer({ children, title }) {
  const { Title } = Typography;

  const renderTitle = title
    ? `${title} | Portal Coopersystem`
    : 'Portal Coopersystem';

  return (
    <Container>
      <Helmet title={renderTitle} />
      {title && (
        <>
          <Title level={2}>{title}</Title>
          <br />
        </>
      )}
      {children}
    </Container>
  );
}

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

PageContainer.defaultProps = {
  title: undefined,
};
