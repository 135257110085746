import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider } from 'antd';

import { useTableData } from '.';
import PageContainer from '~/components/Layout/PageContainer';
import RectificationTable from './RectificationTable';
import SearchForm from './SearchForm';
import messages from './messages';
import DetailModal from './Modals/DetailModal';
import ApprovalModal from './Modals/ApprovalModal';

export default function Rectification() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [approvalItems, setApprovalItems] = useState([]);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [detailRowData, setDetailRowData] = useState({});
  const [approvalModalVisible, setApprovalModalVisible] = useState(false);
  const [approvalModalActionType, setApprovalModalActionType] = useState('');
  const [from, setFrom] = useState('');
  const { formatMessage } = useIntl();
  const {
    tableData,
    loadingTableData,
    totalPages,
    page_size,
    page,
    setLoadingTableData,
    setTableData,
    name,
    date_start,
    date_end,
    status,
    id,
    setTotalPages,
  } = useTableData();

  return (
    <PageContainer title={formatMessage(messages.pageTitle)}>
      <SearchForm
        id={id}
        page={page}
        page_size={page_size}
        name={name}
        date_start={date_start}
        date_end={date_end}
        status={status}
      />
      <Divider />

      <RectificationTable
        id={id}
        name={name}
        date_start={date_start}
        date_end={date_end}
        status={status}
        page_size={page_size}
        page={page}
        totalPages={totalPages}
        setDetailRowData={setDetailRowData}
        setDetailModalVisible={setDetailModalVisible}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        tableData={tableData}
        loadingTableData={loadingTableData}
        setApprovalModalActionType={setApprovalModalActionType}
        setApprovalModalVisible={setApprovalModalVisible}
        setApprovalItems={setApprovalItems}
        setFrom={setFrom}
      />

      {detailModalVisible ? (
        <DetailModal
          rowData={detailRowData}
          visible={detailModalVisible}
          setVisible={setDetailModalVisible}
          setLoadingTableData={setLoadingTableData}
          setTableData={setTableData}
          setApprovalItems={setApprovalItems}
          setApprovalModalActionType={setApprovalModalActionType}
          setApprovalModalVisible={setApprovalModalVisible}
          setFrom={setFrom}
        />
      ) : null}

      {approvalModalVisible ? (
        <ApprovalModal
          visible={approvalModalVisible}
          approvalItems={approvalItems}
          setVisible={setApprovalModalVisible}
          setLoadingTableData={setLoadingTableData}
          setTableData={setTableData}
          actionType={approvalModalActionType}
          setTotalPages={setTotalPages}
          setSelectedRowKeys={setSelectedRowKeys}
          setDetailModalVisible={setDetailModalVisible}
          from={from}
        />
      ) : null}
    </PageContainer>
  );
}
