import { createGlobalStyle } from 'styled-components';
import { shade, lighten, transparentize, darken } from 'polished';

export default createGlobalStyle`
  html {
    height: 100%;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  #root {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: -apple-system,
    "Open Sans", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  code {
    font-family: 'Open Sans',
      source-code-pro,
      Menlo, Monaco,
      Consolas,
      "Courier New",
      monospace;
  }

  .ant-checkbox-group-item {
    display: block !important;
    margin: 6px 0 !important;
  }

  .ant-form-item-label {
    line-height: 20px !important;
  }

  .ant-select-dropdown-menu-item {
    overflow: unset !important;
  }

  .ant-typography,
  .ant-tree-title, .ant-tree-switcher-icon,
  .ant-checkbox-wrapper,
  .ant-modal-close {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-typography-warning {
    color: ${({ theme }) => theme.warningColor} !important;
  }

  .ant-typography-danger {
    color: ${({ theme }) => theme.dangerColor} !important;
  }

  .ant-divider {
    background-color: ${({ theme }) => theme.borderColorBase};
  }

  .ant-input, .ant-time-picker-input,
  .ant-time-picker-panel-inner {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-color: ${({ theme }) => theme.disabledColor};
    color: ${({ theme }) => theme.textColor};
  }

  .anticon-close-circle,
  .ant-time-picker-panel-input,
  li.ant-time-picker-panel-select-option-selected,
  li.ant-time-picker-panel-select-option-selected:hover {
    background-color: ${({ theme }) => lighten(0.2, theme.bodyBackground)};
  }

  .ant-input-suffix {
    i {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-checkbox-inner, .ant-tree-checkbox-inner {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-color: ${({ theme }) => theme.disabledColor};
  }

  .ant-btn-primary[disabled] {
    background-color: ${({ theme }) => theme.borderColorBase};
    border-color: ${({ theme }) => theme.borderColorBase};

    &:hover {
      background-color: ${({ theme }) => theme.disabledColor};
      border-color: ${({ theme }) => theme.borderColorBase};
    }
  }

  .ant-btn-danger[disabled] {
    background-color: ${({ theme }) => theme.borderColorBase};
    border-color: ${({ theme }) => theme.borderColorBase};

    &:hover {
      background-color: ${({ theme }) => theme.disabledColor};
      border-color: ${({ theme }) => theme.borderColorBase};
    }
  }

  .ant-modal-content {
    border: 1px solid ${({ theme }) => theme.borderColorBase} !important;
  }

  .ant-modal-header {
    background: ${({ theme }) => theme.bodyBackground} !important;
    border-bottom: 1px solid ${({ theme }) => theme.borderColorBase} !important;

    .ant-modal-title {
      color: ${({ theme }) => theme.textColor} !important;
    }

    h4 {
      margin: 0;
    }
  }

  .ant-modal-body {
    background: ${({ theme }) => theme.componentBackground} !important;
  }

  .ant-modal-footer {
    background: ${({ theme }) => theme.bodyBackground} !important;
    border-top: 1px solid ${({ theme }) => theme.borderColorBase} !important;
  }

  .ant-modal-content {
    background-color: ${({ theme }) => theme.borderColorBase};

    .ant-modal-confirm-title {
      color: ${({ theme }) => theme.textColor} !important;
    }

    .ant-modal-confirm-content {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-select {
    color: ${({ theme }) => theme.textColor};


    .ant-select-selection {
      background-color: ${({ theme }) => theme.bodyBackground};
      border-color: ${({ theme }) => theme.disabledColor};
    }

    i {
      /* background-color: ${({ theme }) => theme.bodyBackground}; */
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-table-row-cell-last,
  .ant-table-placeholder,
  th,
  td {
    background-color: ${({ theme }) =>
      shade(0.04, theme.componentBackground)} !important;
  }

  .ant-table-placeholder,
  table,
  th,
  td {
    border-color: ${({ theme }) =>
      lighten(0.1, theme.componentBackground)} !important;
  }

  .ant-empty-description,
  .ant-table-column-title,
  td {
    color: ${({ theme }) => theme.textColor};
  }

  .ant-table-body,
  .ant-table-body-inner {
    ::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      border-radius: 100px;
      background-color: ${({ theme }) =>
        darken(0.05, theme.componentBackground)};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: ${({ theme }) =>
        darken(0.12, theme.componentBackground)};
    }

    ::-webkit-scrollbar-corner {
      background-color: ${({ theme }) =>
        darken(0.05, theme.componentBackground)};
    }
  }

  .ant-table-fixed-header
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body {
    background-color: transparent;
  }

  /* .ant-alert {
    background-color: ${({ theme }) => theme.componentBackground};
    border: none;
  }

  .ant-alert-message,.ant-alert, label  {
    color:${({ theme }) => theme.textColor} !important;
  } */

  label  {
    color:${({ theme }) => theme.textColor} !important;
  } 

  .ant-calendar {
    background-color: ${({ theme }) => theme.bodyBackground};
    border: 1px solid ${({ theme }) => theme.borderColorBase};

    .ant-calendar-ym-select > a {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-calendar-picker {
    i {
      background-color: ${({ theme }) => theme.bodyBackground};
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-calendar-body {
    background-color: ${({ theme }) => theme.componentBackground};
  }

  .ant-calendar-range {
    border: 0;
  }

  .ant-calendar-input-wrap {
    border: 0;
  }

  .ant-calendar table {
    background-color: ${({ theme }) => theme.componentBackground};
    color: ${({ theme }) => theme.primaryColor};

    td.ant-calendar-next-month-btn-day > div {
      color: ${({ theme }) => theme.disabledColor} !important;
    }

    .ant-calendar-date {
      color: ${({ theme }) => theme.textColor};
    }
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: ${({ theme }) => transparentize(0.8, theme.primaryColor)};
  }

  .ant-calendar-month-panel-header {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-bottom: 1px solid ${({ theme }) => theme.borderColorBase};

    .ant-calendar-month-panel-year-select-content {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-calendar-month-panel-month {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-calendar-year-panel-year {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-calendar-year-panel-header {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-bottom: 1px solid ${({ theme }) => theme.borderColorBase};

    .ant-calendar-year-panel-decade-select-content {
      color: ${({ theme }) => theme.textColor} !important;
    }
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month, .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover{
    color:  ${({ theme }) => theme.textColorInvert} !important;
  }

  .ant-table-row-selected {
    color: ${({ theme }) => theme.bodyBackground} !important;
  }

  .ant-checkbox-inner, .ant-tree-checkbox-inner {
    background-color: ${({ theme }) => theme.bodyBackground} !important;

    border-color: ${({ theme }) => theme.disabledColor} !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner,
  .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    background-color: ${({ theme }) =>
      transparentize(0.2, theme.disabledColor)} !important;

    border-color: ${({ theme }) => theme.borderColorBase} !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after,
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
    border-color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-table-placeholder, td {
    background-color: ${({ theme }) => theme.componentBackground} !important;
  }

  .ant-table-row table, th, td {
    border-bottom: 1px solid ${({ theme }) => theme.borderColorBase} !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-table-row-selected td {
    background-color: ${({ theme }) =>
      darken(0.05, theme.componentBackground)} !important;
  }

  .ant-layout-sider-children {
    width: 100% !important;
  }

  .ant-badge-status-text {
    color: ${({ theme }) => theme.textColor};
  }

  .disabled-badge-text {
    .ant-badge-status-text {
      color: ${({ theme }) => theme.disabledColor} !important;
    }
  }

  .ant-pagination-disabled > .ant-pagination-item-link {
    background-color: ${({ theme }) => theme.disabledColor} !important;
    border-color: ${({ theme }) => theme.disabledColor} !important;
    color: ${({ theme }) => theme.textColor} !important;
  }

  .ant-pagination > .ant-pagination-item {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-color: ${({ theme }) => theme.disabledColor};

    a {
      color: ${({ theme }) => theme.textColor};
    }
  }

  .ant-pagination > .ant-pagination-item-active {
    a {
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  .ant-pagination-prev > .ant-pagination-item-link {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-color: ${({ theme }) => theme.disabledColor};
    color: ${({ theme }) => theme.textColor};
  }

  .ant-pagination-next > .ant-pagination-item-link {
    background-color: ${({ theme }) => theme.bodyBackground};
    border-color: ${({ theme }) => theme.disabledColor};
    color: ${({ theme }) => theme.textColor};
  }

  .ant-steps {
    .ant-steps-item-title {
      color: ${({ theme }) => theme.textColor} !important;
    }

    .ant-steps-item-finish {
      .ant-steps-item-icon {
        background-color:  ${({ theme }) => theme.primaryColor} !important;

        i {
          color: white;
        }
      }
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
      background-color: ${({ theme }) => theme.disabledColor};
    }
  }

  .ant-radio-button-wrapper {
      background-color: transparent !important;
      border: 1px solid ${({ theme }) => theme.borderColorBase};

      &:first-child {
        border: 1px solid ${({ theme }) => theme.borderColorBase};
      }

      &:not(:first-child)::before {
        background-color: transparent;
      }
  }

  .ant-radio-button-wrapper-checked {
    background-color: ${({ theme }) => theme.primaryColor} !important;

    span {
      font-weight: bold;
    }
  }

  .DayPicker-Day {
    color: ${({ theme }) => theme.textColor};
    border-radius: 8px;
    margin: 10px;
  }

  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: bold;
  }

  .DayPicker-Caption {
    color: ${({ theme }) => theme.textColor};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.primaryColor};
    font-weight: bold;
  }

  .DayPicker-Day--today {
    color: ${({ theme }) => theme.primaryColor};
  }

  .DayPicker-Day--disabled {
    color: ${({ theme }) => theme.disabledColor};
  }

  .ant-tooltip-inner {
    white-space: pre;
  }

  .ant-timeline-item-tail {
    border-color: ${({ theme }) => theme.disabledColor};
  }

  .ant-tabs {
    color: ${({ theme }) => theme.textColor};
  }
  .ant-collapse {
    border-radius: ${({ theme }) => theme.borderRadiusBase};
    background-color:${({ theme }) => theme.componentBackground};
    border-color: ${({ theme }) => theme.borderColorBase}
  }

  .ant-collapse-header {
    background-color: ${({ theme }) => darken(0.04, theme.componentBackground)};
    color: ${({ theme }) => theme.textColor} !important;
    border-color: ${({ theme }) => theme.borderColorBase}
  }

  .ant-collapse-content {
    background-color: ${({ theme }) => theme.componentBackground};
    border-color: ${({ theme }) => theme.borderColorBase}
  }

  .ant-card {
    background-color: ${({ theme }) => theme.componentBackground};
  }


  .ant-divider-horizontal-text {
  background-color: ${({ theme }) => theme.textColor}
  }

  .ant-popover-inner{
    background-color: ${({ theme }) => theme.bodyBackground}
  }

  .ant-popover-arrow{
    border-color: ${({ theme }) => theme.bodyBackground} !important;
    
  }

  .ant-upload.ant-upload-drag {
    background: ${({ theme }) => theme.componentBackground} !important

  }

`;
