import React from 'react';
import PageContainer from '~/components/Layout/PageContainer';
import FinancialRequestsTable from './FinancialRequestsTable';
import Filters from './Filters';

function FinancialRequests() {
  return (
    <PageContainer title="Solicitações Financeiras">
      <Filters />

      <FinancialRequestsTable />
    </PageContainer>
  );
}

export default FinancialRequests;
