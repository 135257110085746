import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Select as AntdSelect,
  Typography,
  Divider,
} from 'antd';
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';

import Input from '~/components/DataEntry/Input';
import Select from '~/components/DataEntry/Select';

import { postHomeGrown, dataSelectTime, dataSelect } from '.';
import { getHomeGrown } from '../AlterHomeSilver';

const { Title } = Typography;

export default function AddHomeSilver({
  registerVisible,
  setRegisterVisible,
  setHomeGrown,
  setIsLoading,
}) {
  return (
    <Modal
      title={<Title level={4}>Cadastro de Bônus</Title>}
      footer={null}
      visible={registerVisible}
      onCancel={() => setRegisterVisible(false)}
      centered
      destroyOnClose
    >
      <Formik
        initialValues={{
          required_time: undefined,
          working_hours: undefined,
          bonus_hours: undefined,
          bonus_reward: undefined,
        }}
        onSubmit={async values => {
          await postHomeGrown(values);
          await getHomeGrown(setHomeGrown, setIsLoading);
          setRegisterVisible(false);
        }}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row gutter={[16, 16]} type="flex" justify="start">
              <Col sm={24} md={12} lg={12} xl={12}>
                <Field
                  id="required_time"
                  name="required_time"
                  label="Tempo de Casa"
                  component={Select}
                  placeholder="Selecione o período..."
                  onChange={status => {
                    setFieldValue('required_time', status);
                  }}
                  data={dataSelectTime}
                  renderItem={item => (
                    <AntdSelect.Option key={item.key} value={item.value}>
                      {item.value}
                    </AntdSelect.Option>
                  )}
                  allowClear
                />
              </Col>
              <Col sm={24} md={12} lg={12} xl={12}>
                <Field
                  id="working_hours"
                  name="working_hours"
                  label="Carga Horária"
                  component={Select}
                  placeholder="Selecione as horas..."
                  onChange={status => {
                    setFieldValue('working_hours', status);
                  }}
                  data={dataSelect}
                  renderItem={item => (
                    <AntdSelect.Option key={item.key} value={item.value}>
                      {item.value}
                    </AntdSelect.Option>
                  )}
                  allowClear
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} type="flex" justify="start">
              <Col sm={24} md={12} lg={12} xl={12}>
                <Field
                  id="bonus_hours"
                  name="bonus_hours"
                  label="Horas Bônus"
                  component={Input}
                />
              </Col>
              <Col sm={24} md={12} lg={12} xl={12}>
                <Field
                  id="bonus_reward"
                  name="bonus_reward"
                  label="Valor R$ 0,00"
                  component={Input}
                />
              </Col>
            </Row>

            <div>
              <Button onClick={() => setRegisterVisible(false)}>
                Cancelar
              </Button>

              <Divider type="vertical" />

              <Button
                disabled={!values.bonus_hours}
                loading={isSubmitting}
                htmlType="submit"
                type="primary"
              >
                Cadastrar
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

AddHomeSilver.propTypes = {
  registerVisible: PropTypes.bool.isRequired,
  setRegisterVisible: PropTypes.func.isRequired,
  setHomeGrown: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
